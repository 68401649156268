import React from "react"
import { Col, Row, Space, Button, Card } from "uiComponents"
import getSVG from "../../../images/svgs/svg"
import "../LandingPage/LandingPage.css"
import { isMobile } from "helpers"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { AppPaths } from "shoppio-constants"

const GetStarted = () => {
  const history = useHistory()
  return (
    <>
      <Card>
        <Row middle='xs'>
          <Col sm={12} md={5}>
            {isMobile &&
              getSVG("Ecommerce_web_page", { width: 350 }, undefined, true)}
            <div className='header-text-home'>
              <span>Revolutionize</span> Your Business with <span>Shoppio</span>
            </div>
            <Space lg />
            <p className='subheader-text'>
              Transform your business operations with our cutting-edge digital
              tools.
            </p>
            <Space lg />
            <Space lg />
            <Button
              fullWidth={isMobile === true}
              extraPad
              fullRound
              onClick={() => history.push(AppPaths.SELLER_SIGNUP)}
            >
              Start Free Trial
            </Button>
            <Button fullWidth={isMobile === true} extraPad fullRound alt>
              Learn More
            </Button>
          </Col>
          <Col sm={12} md={7}>
            {!isMobile && getSVG("Ecommerce_web_page", { width: 500 })}
          </Col>
        </Row>
      </Card>
    </>
  )
}

export default GetStarted
