import React, { Fragment, useState } from "react"
import { isMobile } from "helpers"
import "./Expander.css"
import { Button, Loader } from "uiComponents"
import {
  formStatus,
  formStatusColors,
  formStatusIcon,
} from "shoppio-constants/Status"

export default function Expander({
  children,
  initiallyOpen,
  showOnMobile,
  title,
  subTitle,
  label,
  onClearShow,
  onClearClick,
  deleteAction,
  padded,
  step,
  stepStatus,
  lastUpdated,
  createdAt,
  themed,
  expanded,
  onToggle,
  disableExpander,
  busy,
}) {
  const [open, setOpen] = useState(initiallyOpen)

  console.log("SteoStats", stepStatus)
  // Update local state when the 'expanded' prop changes
  React.useEffect(() => {
    setOpen(expanded)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expanded])

  React.useEffect(() => {
    setOpen(initiallyOpen)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleToggle = () => {
    if (!disableExpander) {
      setOpen(!open)
      onToggle && onToggle(!open)
    }
  }

  return (
    <div
      className='expander'
      style={{
        marginBottom: isMobile && open ? "3rem" : "",
        opacity: disableExpander ? "0.3" : "",
      }}
    >
      <div
        style={{ cursor: disableExpander ? "not-allowed" : "pointer" }}
        className={`expander-header ${disableExpander ? "disabled" : ""}`}
        onClick={handleToggle}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <h1 style={{ color: themed ? "var(--background)" : "" }}>
              {step !== undefined && <StepCounter step={step} />}
              {title}
            </h1>
            <h4>{subTitle}</h4>
          </div>
          {!isMobile && <span className='label-css'>{label}</span>}
        </div>
        {busy ? (
          <Loader />
        ) : (
          <>
            <div style={{ display: "flex", alignItems: "center" }}>
              {deleteAction ? (
                <Button
                  disabled={deleteAction?.disabled || disableExpander}
                  onClick={() => deleteAction?.onClick()}
                  small
                  danger
                >
                  {deleteAction?.label}
                </Button>
              ) : null}
              {onClearShow && (
                <Button
                  circle
                  small
                  accent
                  onClick={onClearClick}
                  disabled={disableExpander}
                >
                  <span className='fa fa-times'></span>
                </Button>
              )}
              {showOnMobile && isMobile ? (
                <button
                  className='exp-btn'
                  onClick={handleToggle}
                  disabled={disableExpander}
                >
                  <span
                    className={
                      open ? "fas fa-chevron-up" : "fas fa-chevron-down"
                    }
                  />
                </button>
              ) : (
                <>
                  {createdAt && (
                    <span
                      style={{
                        marginRight: "10px",
                        color: "var(--theme-color)",
                      }}
                    >
                      {createdAt}
                    </span>
                  )}
                  <>
                    {isMobile || !lastUpdated ? null : (
                      <span style={{ marginRight: "10px" }}>
                        Last Updated: {lastUpdated}
                      </span>
                    )}
                  </>

                  {stepStatus && <StepStatus status={stepStatus} />}

                  <button
                    type='button'
                    className='exp-btn'
                    onClick={handleToggle}
                    disabled={disableExpander}
                    style={{ cursor: disableExpander && "not-allowed" }}
                  >
                    <span
                      className={
                        open ? "fas fa-chevron-up" : "fas fa-chevron-down"
                      }
                    />
                  </button>
                </>
              )}
            </div>
          </>
        )}
      </div>
      <div className={`expander-content ${open ? "open" : ""}`}>
        <div
          style={{
            padding: padded && open ? (isMobile ? "0 1rem" : "0 2rem") : "",
          }}
        >
          {showOnMobile && open ? children : <Fragment />}
          {showOnMobile && !isMobile ? children : ""}
          {!showOnMobile && open ? children : ""}
        </div>
      </div>
    </div>
  )
}

export const StepCounter = ({ step }) => {
  return <span className='step-counter'>{step}</span>
}

export const StepStatus = ({ status }) => {
  const statusText = formStatus[status] || "Unknown Status"
  console.log("statusText", statusText)
  const icon = formStatusIcon[status] || "fa-question-circle"
  const backgroundColor = formStatusColors[status]

  return (
    <span
      className='step-status'
      style={{
        backgroundColor,
      }}
    >
      <i className={`fas ${icon}`} />
      {statusText}
    </span>
  )
}
