import React from "react"
import { Header, Tabs, Tab, Row, Col, Space, Item } from "uiComponents"
import { StatusChip } from "./QueryResolution"
import MessageCard from "./components/MessageCard"
import { parseDateTime, truncate } from "helpers"

const TicketDetails = ({ ticket, admin }) => {
  let assignee
  try {
    assignee = ticket?.assignee ? JSON.parse(ticket?.assignee) : ""
  } catch (error) {
    console.error("Failed to parse assignee data", error)
    assignee = null
  }

  return (
    <>
      <div style={{ display: "flex" }}>
        <Header sm bold>
          {ticket.name}
        </Header>
        <StatusChip
          accent
          status={ticket.status}
          style={{ marginLeft: "10px", width: "fit-content" }}
        />
      </div>
      <p>{ticket.description}</p>
      <Space lg />
      {ticket?.assignee && (
        <p>
          Your ticket is monitoring by <strong>{assignee?.value}</strong> (
          {assignee?.role})
        </p>
      )}
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} xl={8}>
          <Tabs>
            <Tab label='Conversation'>
              <Row>
                <Col
                  sm={12}
                  md={12}
                  style={{ position: "relative", height: "35vh" }}
                >
                  <div
                    style={{
                      position: "relative",
                      height: "100%",
                    }}
                  >
                    <div
                      style={{
                        height: "calc(100% - 7rem)",
                        overflowY: "auto",
                        scrollbarWidth: "none",
                        paddingBottom: "10px",
                        display: "flex",
                        flexDirection: "column",
                        borderRight: "0.5px solid var(--border-color)",
                      }}
                    >
                      <MessageCard ticketID={ticket?.id} />
                    </div>
                  </div>
                </Col>
              </Row>
            </Tab>
            <Tab label='History'></Tab>
          </Tabs>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={4}>
          <Tabs v2>
            <Tab label='Ticket Details'>
              <Space lg />
              <Item
                flexStart={"Created On"}
                flexEnd={parseDateTime(ticket?.createdAt)}
              />
              <Item
                flexStart={"Created By"}
                flexEnd={ticket?.created_by.user_name}
              />
            </Tab>
            <Tab v2 label='Attachments'>
              <Space lg />
              {ticket?.attachments && ticket.attachments.length > 0 && (
                <div className='attachments-wrapper'>
                  {ticket.attachments.map((attachment, index) => (
                    <div key={index} className='attachment-container'>
                      <img
                        src={attachment.url}
                        alt='Attachment'
                        className='attachment-image'
                      />
                      <div className='attachment-details'>
                        <p className='attachment-name'>
                          {truncate(attachment.name, 20)}
                        </p>
                        <a
                          href={attachment.url}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          <p className='attachment-view-link'>View</p>
                        </a>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </>
  )
}

export default TicketDetails
