import React from "react"
import { Col, Row, Card, Space, Header, Expander, Loader } from "uiComponents"
import { PageLayout } from "appComponents"
import getSVG from "../../../images/svgs/svg"
import { getTime, isMobile, parseDateTime } from "helpers"
import { FormMaker } from "../../Forms"
import {
  businessInformationsFields,
  personalInformationsFields,
  kycFields,
} from "./AccountFormFields"
import { useStateValue } from "state"
import { useGetUserMeta } from "firebaseUtils/firebaseOperations"
import { formStatus } from "shoppio-constants"

const AccountSetup = () => {
  const [{ user, userMeta }] = useStateValue()
  const { updateUserMeta } = useGetUserMeta()

  const personalInformationsSubmit = async personalInformations => {
    await updateUserMeta(user?.uid, {
      personalInfo: {
        ...personalInformations,
        status: formStatus.submitted,
        updatedAt: getTime(),
      },
    })
  }

  const businessInformationsSubmit = businessInformations => {
    updateUserMeta(user?.uid, {
      businessInfo: {
        ...businessInformations,
        status: formStatus.submitted,
        updatedAt: getTime(),
      },
    })
  }
  const kycInformationSubmit = kycInformations => {
    updateUserMeta(user?.uid, {
      kycInfo: {
        ...kycInformations,
        status: formStatus.submitted,
        updatedAt: getTime(),
      },
    })
  }

  let personalInitialValues = userMeta?.personalInfo
  let businessInitialValues = userMeta?.businessInfo
  let kycInitialValues = userMeta?.kycInfo

  const updatesOnPersonalInfo = userMeta?.personalInfo?.updatedAt
    ? parseDateTime(userMeta?.personalInfo?.updatedAt)
    : parseDateTime(userMeta?.personalInfo?.createdAt)

  const updatesOnBusinessInfo = userMeta?.businessInfo?.updatedAt
    ? parseDateTime(userMeta?.businessInfo?.updatedAt)
    : parseDateTime(userMeta?.businessInfo?.createdAt)

  const updatesOnKycInfo = userMeta?.kycInfo?.updatedAt
    ? parseDateTime(userMeta?.kycInfo?.updatedAt)
    : parseDateTime(userMeta?.kycInfo?.createdAt)

  const isPersonalFormCompleted = userMeta?.personalInfo?.status
  const isBusinessFormCompleted = userMeta?.businessInfo?.status
  const isKycFormCompleted = userMeta?.kycInfo?.status

  const disableSubmitForm =
    userMeta?.personalInfo?.status === "Validating" ||
    userMeta?.personalInfo?.status === "Submission Successful" ||
    userMeta?.personalInfo?.status === "Approved" ||
    userMeta?.businessInfo?.status === "Validating" ||
    userMeta?.businessInfo?.status === "Submission Successful" ||
    userMeta?.businessInfo?.status === "Approved" ||
    userMeta?.kycInfo?.status === "Validating" ||
    userMeta?.kycInfo?.status === "Submission Successful" ||
    userMeta?.kycInfo?.status === "Approved"
  return (
    <PageLayout
      mainContent={
        <Row>
          <Col sm={12} md={12} lg={12}>
            <Card
              notFull
              noXS
              svgIcon={getSVG(
                "Profile_Interface",
                { width: "100%", minHeight: "40rem" },
                null,
                true,
              )}
            >
              <Header sm bold>
                Account Setup
              </Header>
              <p>Complete your account setup to start selling Shoppio</p>
              <Space lg />
              {!userMeta ? (
                <Loader />
              ) : (
                <>
                  <Card>
                    <Expander
                      step={"1"}
                      lastUpdated={updatesOnPersonalInfo || undefined}
                      stepStatus={isPersonalFormCompleted}
                      title={"Personal Information"}
                    >
                      <Space lg />
                      <FormMaker
                        fields={personalInformationsFields}
                        initialValues={personalInitialValues}
                        validateOnMount
                        buttonFull={isMobile}
                        onSubmit={personalInformationsSubmit}
                        disableSubmit={disableSubmitForm}
                      />
                    </Expander>
                  </Card>
                  <Card>
                    <Expander
                      step={"2"}
                      lastUpdated={updatesOnBusinessInfo}
                      stepStatus={isBusinessFormCompleted}
                      title={"Business Information"}
                    >
                      <Space lg />
                      <FormMaker
                        validateOnMount
                        fields={businessInformationsFields}
                        initialValues={businessInitialValues}
                        buttonFull={isMobile}
                        onSubmit={businessInformationsSubmit}
                        disableSubmit={disableSubmitForm}
                      />
                    </Expander>
                  </Card>
                  <Card>
                    <Expander
                      step={"3"}
                      lastUpdated={updatesOnKycInfo}
                      stepStatus={isKycFormCompleted}
                      title={"KYC Verification"}
                      disableExpander={!isBusinessFormCompleted}
                    >
                      <Space lg />
                      <FormMaker
                        validateOnMount
                        fields={kycFields}
                        buttonFull={isMobile}
                        initialValues={kycInitialValues}
                        onSubmit={kycInformationSubmit}
                        disableSubmit={disableSubmitForm}
                      />
                    </Expander>
                  </Card>
                </>
              )}
            </Card>
          </Col>
        </Row>
      }
    />
  )
}

export default AccountSetup
