import React from "react"
import "./AutoImagePreview.css"
import useAddClickInImages from "./useAutoImagePreview"

export default function AutoImagePreview({
  config = {
    showOnlyLargeImages: true,
  },
}) {
  const { image, onClose } = useAddClickInImages(config)
  if (config?.showOnlyLargeImages && image?.naturalWidth < 500) return ""
  return image ? (
    <div className='image-preview-container'>
      <div className='image-preview-overlay' onClick={onClose} />
      <div className='image-preview-close' onClick={onClose}>
        <span className='fas fa-times' />
      </div>
      <img className='image' src={image?.src} alt='preview' />
    </div>
  ) : (
    ""
  )
}
