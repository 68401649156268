import React from "react"
import { Col } from "uiComponents"
import { isMobile } from "helpers"

export default function MainContent({ children }) {
  return (
    <Col
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={10}
      style={{ padding: isMobile && "0px" }}
    >
      {children}
    </Col>
  )
}
