import React, { useEffect, useState } from "react"
import "./Store.css"
import "../../../styles/theme.css"
import { useHistory, useParams } from "react-router-dom"
import {
  Grid,
  Row,
  Col,
  Header,
  Tabs,
  Tab,
  Loader,
  CompactCard,
  Busy,
} from "uiComponents"
import { useStateValue } from "state"
import { FormMaker } from "formComponents"
import { getDataAfterImageUpload, placeHolderPic } from "helpers"
import * as Yup from "yup"
import {
  getDocsFromDb,
  getDocsFromDbMultipleWheresWithLimit,
  DB,
} from "firebaseUtils"
import { getStoreData } from "../../../pages/Home/StoreHome"
import { sendEmail } from "emailer"
import { sendNotification, Footer } from "appComponents"
import { AppPaths } from "shoppio-constants"

export const categoryValidationSchema = () => {
  // console.log('llllll');
  return Yup.object().shape({
    category_name: Yup.string()
      .required("Please provide a category name")
      .min(3, "Too short")
      .max(100, "Too long"),
    desc: Yup.string().min(3, "Too short").max(100, "Too long"),
    category_pic: Yup.mixed().required("Please upload a banner picture"),
  })
}

const categoryFields = (store_logo, data, setError) => {
  const fetchOld = async categoryNew => {
    let docs = await getDocsFromDbMultipleWheresWithLimit(
      1,
      `user_stores/${data?.storeID}/categories/`,
      ["val", "==", categoryNew],
    )
    console.log("Docs", docs)
    setError(
      docs.length > 0
        ? [
            "Please choose a different category name as this category already exists in this store",
          ]
        : [],
    )
  }
  return [
    {
      name: "category_name",
      label: "Category Name",
      type: "text",
      onBlur: e => {
        console.log(e.target.value)
        if (e.target.value?.length > 3) {
          fetchOld(e.target.value)
        }
      },
      fullWidth: true,
    },
    {
      name: "desc",
      label: "Describe the category",
      type: "textarea",
      fullWidth: true,
    },
    {
      name: "category_pic",
      label: "Category Picture",
      info: "Upload a picture to showcase as banner",
      type: "pic",
      fullWidth: true,
    },
    {
      name: "store_logo",
      hidden: true,
      value: store_logo,
    },
  ]
}

function AddCategory({ update, categoryID, storeID, inDialog }) {
  const [{ user, userMeta, currentStore }, dispatch] = useStateValue()
  const [busy, setBusy] = useState()
  const [extraErrors, setErrors] = useState([])
  const history = useHistory()
  const params = useParams()
  const category_id = params?.category_id || categoryID
  const storeid = params?.storeid || storeID

  const [categoryData, setCategoryData] = useState()
  const afterAddPath = AppPaths.STORE_HOME + storeid

  const commonDBref = DB.collection(`store_categories`)
    .doc(storeid)
    .collection("data")

  const removeCategory = async () => {
    // Delete from user store ref
    setBusy(true)
    let subCategories = await getDocsFromDb(
      `store_categories/${storeid}/data/${category_id}/subcategories`,
    )
    // console.log('subCategories', subCategories)
    subCategories.forEach(
      async subCat =>
        await DB.collection("store_subcategories").doc(subCat?.id).delete(),
    )
    DB.collection(`user_stores`)
      .doc(storeid)
      .collection("categories")
      .doc(category_id)
      .delete()

    // Delete actual category from store_categories
    commonDBref
      .doc(category_id)
      .delete()
      .then(() => {
        setBusy()
        history.push(afterAddPath)
        dispatch({
          type: "SHOW_ALERT",
          open: true,
          message: "Category deleted successfully",
        })
      })
  }

  const addCategoryToStoreMeta = data => {
    DB.collection(`user_stores`)
      .doc(storeid)
      .collection("categories")
      .doc(data?.key)
      .set(data)
  }
  const addCategoryToDB = async categoryData => {
    setBusy(true)
    let data = await getDataAfterImageUpload(categoryData)
    console.log("Data After Upload", data)
    if (category_id) {
      commonDBref
        .doc(category_id)
        .update({
          updatedBy: user?.uid,
          ...data,
        })
        .then(() => {
          // console.log("Success");
          setBusy(false)
          history.push(afterAddPath)
        })
        .catch(error => {
          console.error("Error writing document: ", error)
          setBusy(false)
        })
    } else {
      commonDBref
        .add({
          addedBy: user?.uid,
          ownedBy: userMeta?.seller_name || "Seller Name",
          ...data,
        })
        .then(doc => {
          // console.log("Success");
          addCategoryToStoreMeta({
            key: doc.id,
            val: data?.category_name,
          })

          const emailData = {
            email: user?.email || userMeta?.email,
            from_name: "Shoppio",
            message: updating
              ? `Congratulations! Your '${data.category_name}' category details with Shoppio has been successfully updated.`
              : `Congratulations! Your ${currentStore} store with Shoppio has a new '${data.category_name}' category has been successfully added.`,
          }

          const template = "general_template"

          sendEmail(emailData, template)

          sendNotification({
            title: updating ? "Account Details Updated" : "Account Created",
            body: updating
              ? `Congratulations! Your '${data.category_name}' category details with Shoppio has been successfully updated.`
              : `Congratulations! Your ${currentStore} store with Shoppio has a new '${data.category_name}' category has been successfully added.`,
            toUserID: user?.uid,
          })
          setBusy(false)
          history.push(afterAddPath)
        })
        .catch(error => {
          console.error("Error writing document: ", error)
          setBusy(false)
        })
    }
  }
  const getUserCategoryData = (storeid, categoryId, dispatch) => {
    if (!dispatch || !categoryId || !storeid) return
    commonDBref
      .doc(categoryId)
      .get()
      .then(s => {
        setCategoryData(s.data())
        setBusy(false)
      })
      .catch(() => {
        setBusy(false)
      })
  }
  useEffect(() => {
    window.scrollTo(0, 0)
    if (user && storeid && !currentStore) {
      getStoreData(storeid, dispatch)
    }
    if (user && category_id && storeid) {
      setBusy(true)
      getUserCategoryData(storeid, category_id, dispatch)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, dispatch, category_id])
  const [values, setValues] = useState({})
  const initialValues = categoryData
  const updating = initialValues ? true : false
  const formContent = (
    <Row style={{ paddingTop: inDialog ? "5vh" : "" }}>
      <Col xs={12} sm={6} md={7}>
        <Header accent md bold>
          Add Category
        </Header>
        <p>Please fill the required details about your category</p>
        {currentStore ? (
          <FormMaker
            submitLabel={updating ? "Update Category" : "Add category"}
            fields={() =>
              categoryFields(
                currentStore?.store_logo,
                {
                  storeID: storeid,
                },
                setErrors,
              )
            }
            initialValues={initialValues}
            validation={categoryValidationSchema}
            handleChange={newVals => setValues({ ...values, ...newVals })}
            secAction={
              updating
                ? {
                    label: "Delete Category",
                    danger: true,
                    onClick: () => removeCategory(),
                  }
                : {
                    label: "Back",
                    onClick: () => history.goBack(),
                  }
            }
            extraErrors={extraErrors}
            onSubmit={formValues => {
              addCategoryToDB(formValues, updating)
            }}
          />
        ) : (
          <Loader />
        )}
      </Col>
      <Col xs={12} sm={6} md={4} mdOffset={1}>
        <Header sm bold>
          Preview
        </Header>
        <p>Category</p>
        <br />
        {[values]?.map(
          ({ category_name, category_pic, category_logo, desc }, key) => {
            return (
              <CompactCard
                notFull
                key={key}
                config={{
                  logo: category_logo,
                  version: "category-new",
                  title: category_name || "Toys",
                  point: "240+ products",
                  subtitle: desc,
                  backgroundImage: category_pic || placeHolderPic,
                  action: {
                    label: "View Category",
                  },
                }}
              />
            )
          },
        )}
      </Col>
    </Row>
  )
  const mainContent =
    !update && !inDialog ? (
      <Tabs>
        <Tab label={"Add Category"}>{formContent}</Tab>
        <Tab label={"View Categories"} to={`/${storeid}/view_categories`}></Tab>
      </Tabs>
    ) : (
      formContent
    )
  return (
    <div>
      <Busy busy={busy}>
        {inDialog ? (
          mainContent
        ) : (
          <Grid>
            {mainContent}
            <Footer />
          </Grid>
        )}
      </Busy>
    </div>
  )
}

export default AddCategory
