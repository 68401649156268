import React from "react"
import logo from "../../../images/Logo.png"
import { Col, Row, Space, Card, Divider, Padding } from "uiComponents"
import getSVG from "../../../images/svgs/svg"
import "./Footer.css"
import {
  FooterLinkSection,
  FooterLinkSectionMobile,
  SocialMediaButtons,
  footerData,
} from "./FooterData"
import { isMobile } from "helpers"
const FooterNew = () => {
  return (
    <>
      <Card themed rowStyle={{ paddingBottom: 0 }}>
        <Padding lg paddingTop={"0px"}>
          <div className='why-choose-header'>
            <div className='header-text themed'>
              <img
                src={logo}
                width={200}
                alt='logo'
                style={{ marginBottom: 10, filter: "invert(1)" }}
              />
            </div>
            <p>Enabling e-commerce through innovation</p>
          </div>
          <Space lg />
          <Space lg />
          <Space lg />
          <Row>
            {footerData?.map((data, index) => (
              <>
                {isMobile ? (
                  <FooterLinkSectionMobile
                    key={index}
                    title={data.title}
                    links={data.links}
                  />
                ) : (
                  <FooterLinkSection
                    key={index}
                    title={data.title}
                    links={data.links}
                  />
                )}
              </>
            ))}
            <Col sm={12} md={3}>
              {getSVG(
                "Get_in_touch",
                { width: 300 },
                null,
                isMobile ? null : true,
              )}
            </Col>
          </Row>
          {isMobile && (
            <>
              <Row center='xs'>
                <SocialMediaButtons />
              </Row>
              <Space lg />
            </>
          )}
        </Padding>
        <Divider />
        <Row
          between={`${isMobile ? "" : "xs"}`}
          center={`${isMobile ? "xs" : ""}`}
          style={{ padding: "10px" }}
        >
          <p className='copyright-text'>
            <i className='far fa-lg fa-copyright' />
            <span>2024 Shoppio. All rights reserved.</span>
          </p>
          {!isMobile && <SocialMediaButtons />}
        </Row>
      </Card>
    </>
  )
}

export default FooterNew
