import React from "react"
import "./ShippingLogistics.css"
import { Col, Row, Space, Card, Header } from "uiComponents"
import getSVG from "../../../images/svgs/svg"

const WhyChooseLogistics = () => {
  const whyChooseData = [
    {
      title: "Cost Efficiency",
      desc: "Lower shipping costs and reduced capital investment due to economies of scale and infrastructure provided by logistics experts.",
    },
    {
      title: "Economies of Scale",
      desc: "Lower shipping costs and reduced capital investment due to economies of scale and infrastructure provided by logistics experts.",
    },
    {
      title: "Technology and Infrastructure",
      desc: "Lower shipping costs and reduced capital investment due to economies of scale and infrastructure provided by logistics experts.",
    },
    {
      title: "Focus on Core Business",
      desc: "Lower shipping costs and reduced capital investment due to economies of scale and infrastructure provided by logistics experts.",
    },
    {
      title: "Customer Satisfaction",
      desc: "Lower shipping costs and reduced capital investment due to economies of scale and infrastructure provided by logistics experts.",
    },
  ]
  return (
    <Card>
      <Header sm bold>
        Why choose logistics providers?
      </Header>
      <Space lg />
      <Row>
        <Col sm={12} md={9}>
          {whyChooseData.map((item, index) => (
            <p className='li-check-circle'>
              <i className='fas fa-check-circle' />
              <strong className='span-check-circle'>{item.title}:</strong>
              {item.desc}
            </p>
          ))}
        </Col>
        <Col sm={12} md={3}>
          <div>{getSVG("Checking_boxes", { width: 250 }, null, true)}</div>
        </Col>
      </Row>
    </Card>
  )
}

export default WhyChooseLogistics
