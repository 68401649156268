import React, { Fragment } from "react"
import { Card, Space, Row, Col, Header, Icon } from "uiComponents"
import "./card.css"
import { isMobile } from "helpers"

const UserCard = ({ name, desc, img, actions, reviewCard, imgCol, small }) => {
  return (
    <Fragment>
      <Card horizontal>
        <Space md />
        <Row
          start='xs'
          middle={reviewCard ? undefined : "xs"}
          top={!reviewCard ? undefined : "xs"}
          style={{ margin: 0, width: "100%" }}
        >
          <Col
            xs={12}
            md={imgCol || 3}
            style={{
              textAlign: isMobile ? "left" : "center",
              padding: isMobile ? "" : 0,
              paddingTop: "1rem",
            }}
          >
            {!img ? (
              <Icon
                size={small ? "2.5rem" : "4rem"}
                inline
                iconClass={"far fa-user-circle"}
              />
            ) : (
              <img alt='alt-img' src={img} className='card-user-dp' />
            )}
          </Col>
          <Col xs={11} md={8}>
            {isMobile ? <Space lg /> : ""}
            {name && <Header lg={!reviewCard}>{name ? name : `Name`}</Header>}
            <p>{desc}</p>
            {actions && <Space />}
            {actions && <Space />}
            {actions}
          </Col>
        </Row>
        <Space md />
      </Card>
      <Space md />
    </Fragment>
  )
}

export default UserCard
