import React from "react"
import { Col, Row } from "uiComponents"
import {
  Logistics,
  GetYourApp,
  DashboardAccountSetup,
  PageLayout,
} from "appComponents"
import StoreManagement from "../StoreManagement/StoreManagement"

const SellerDashboard = () => {
  return (
    <PageLayout
      mainContent={
        <Row>
          <Col sm={12} md={12} lg={8}>
            <DashboardAccountSetup />
          </Col>
          <Col sm={12} md={12} lg={4}>
            <Logistics />
          </Col>
          <Col sm={12} md={12} lg={8}>
            <StoreManagement />
          </Col>
          <Col sm={12} lg={4} md={12}>
            <GetYourApp />
          </Col>
        </Row>
      }
    />
  )
}

export default SellerDashboard
