import { AppPaths } from "shoppio-constants"

export const DummyData = {
  store: {
    store_name: "Unicornia",
    store_tagline: "Authorised Apple Details",
    desc: "Macbook, iPhones, iPads & more",
    is_live: true,
    store_pic:
      "https://static.toiimg.com/photo/msid-78637906/78637906.jpg?resizemode=4&width=400",
    ownedBy: "Apple Authorized Seller",
  },
}

export const topPerformingsData = [
  {
    img: "https://images.ctfassets.net/u4vv676b8z52/5NYNFwJPtbocJ1fIwbSCyJ/48d77da8c115b622558d59393bd36bee/kids-sunglasses-hero.gif?fm=jpg&q=80",
    title: "Sunglass RB 802",
    desc: "A cool modern styled sunglasses design to cater Indian rough climate.",
    sales: "2.9K",
    rating: 4,
  },
  {
    img: "https://img.pikbest.com/png-images/landscape-nature-t-shirt-design-template-free-download_1586362.png!sw800",
    title: "Cupid black t-shirt",
    desc: "A cool modern styled sunglasses design to cater Indian rough climate.",
    sales: "3.1K",
    rating: 5,
  },
  {
    img: "https://img.freepik.com/free-photo/fedora-hats-arrangement-studio_23-2150737087.jpg?size=626&ext=jpg&ga=GA1.1.553209589.1715040000&semt=ais",
    title: "Class Top hat",
    desc: "A cool modern styled sunglasses design to cater Indian rough climate.",
    sales: "4.5K",
    rating: 5,
  },
  {
    img: "https://www.007.com/wp-content/uploads/2020/08/007_Omega-Watch_LANDSCAPE.png",
    title: "OMEGA Watch",
    desc: "A cool modern styled sunglasses design to cater Indian rough climate.",
    sales: "1.7K",
    rating: 4,
  },
]

export const worstPerformingsData = [
  {
    img: "https://images.ctfassets.net/u4vv676b8z52/5NYNFwJPtbocJ1fIwbSCyJ/48d77da8c115b622558d59393bd36bee/kids-sunglasses-hero.gif?fm=jpg&q=80",
    title: "Sunglass RB 802",
    desc: "A cool modern styled sunglasses design to cater Indian rough climate.",
    sales: "2.9K",
    rating: 1,
  },
  {
    img: "https://img.pikbest.com/png-images/landscape-nature-t-shirt-design-template-free-download_1586362.png!sw800",
    title: "Cupid black t-shirt",
    desc: "A cool modern styled sunglasses design to cater Indian rough climate.",
    sales: "3.1K",
    rating: 3,
  },
  {
    img: "https://img.freepik.com/free-photo/fedora-hats-arrangement-studio_23-2150737087.jpg?size=626&ext=jpg&ga=GA1.1.553209589.1715040000&semt=ais",
    title: "Class Top hat",
    desc: "A cool modern styled sunglasses design to cater Indian rough climate.",
    sales: "4.5K",
    rating: 2,
  },
  {
    img: "https://www.007.com/wp-content/uploads/2020/08/007_Omega-Watch_LANDSCAPE.png",
    title: "OMEGA Watch",
    desc: "A cool modern styled sunglasses design to cater Indian rough climate.",
    sales: "1.7K",
    rating: 3,
  },
]

export const reviewCardData = [
  {
    name: "John Doe",
    timestamp: "18/07/2024",
    reviewText: "A great product working perfectly and doing great",
    itemPurchase: "Purchased ‘Sunglasses 20 XL",
    rating: 5,
  },
  {
    name: "Jane Smith",
    timestamp: "12/06/2023",
    reviewText: "A great product working perfectly and doing great",
    itemPurchase: "Purchased ‘Sunglasses 10 XL",
    rating: 3,
  },
  {
    name: "Mary Johnson",
    timestamp: "09/05/2022",
    reviewText: "A great product working perfectly and doing great",
    itemPurchase: "Purchased ‘Sunglasses 15 XL",
    rating: 1,
  },
]

export const joinReasonsData = [
  {
    title: "Join Our Dynamic Team",
    description:
      "At our company, we're passionate about innovation and growth. We believe in nurturing talent and providing opportunities for professional development. If you're looking for a place to grow your career and make a significant impact, you've come to the  right place.",
    svg: "Team_goals",
  },
  {
    title: "Flexible Working Hours",
    description:
      "We understand the importance of work-life balance. Our flexible working hours allow you to manage your time effectively, ensuring you can meet both your personal and professional commitments without compromise.",
    svg: "Work_time",
    theme: true,
  },
  {
    title: "Generous Leave Policy",
    description:
      "We offer a generous leave policy to ensure our employees have ample time to rest, recharge, and take care of personal matters. Whether it's vacation, sick leave, or personal days, we've got you covered.",
    svg: "Trip",
  },
  {
    title: "Competitive Compensation",
    description:
      "Our compensation packages are designed to attract and retain top talent. We offer competitive salaries, performance bonuses, and other financial rewards to recognize and reward your hard work and dedication.",
    svg: "Payment Info",
    theme: true,
  },
  {
    title: "Collaborative Environment",
    description:
      "Our work environment is built on collaboration and mutual respect. We encourage open communication, teamwork, and the sharing of ideas to drive innovation and achieve our collective goals.",
    svg: "Team_work",
  },
  {
    title: "Opportunities for Growth",
    description:
      "We are committed to your professional development. With access to training programs, mentorship opportunities, and career advancement paths, we provide the tools and support you need to grow and succeed in your career.",
    svg: "Growing",
    theme: true,
  },
]

export const openPositionsData = [
  {
    job_title: "Sales Manager",
    job_desc:
      "We're seeking a Sales Manager to lead our sales team. You will develop strategies to drive sales growth, manage a team, and build strong client relationships. Required skills: leadership, communication, sales strategy, CRM proficiency. Enjoy flexible hours, generous leave, and competitive compensation. Apply now to lead our sales initiatives!",
    job_location: "Lucknow, Uttar Pradesh",
    job_type: "Onsite",
    job_link: "/join/sales/sales_manager",
  },
  {
    job_title: "Sales Executive",
    job_desc:
      "Join our team as a Sales Executive! You will identify new business opportunities, close sales deals, and maintain client relationships. Required skills: communication, negotiation, CRM proficiency, sales acumen. Enjoy flexible hours, generous leave, and competitive compensation. Apply now to drive our sales growth!",
    job_location: "Lucknow, Uttar Pradesh",
    job_type: "Onsite",
    job_link: "/join/sales/sales_executive",
  },
  {
    job_title: "DevOps Engineer",
    job_desc:
      "We're hiring a DevOps Engineer to streamline our deployment processes and manage infrastructure. Required skills: CI/CD, AWS/GCP, Docker/Kubernetes, scripting (Python/Bash). Strong problem-solving abilities and experience with automation are essential. Enjoy flexible hours, generous leave, and competitive compensation. Apply now to enhance our DevOps!",
    job_location: "Lucknow, Uttar Pradesh",
    job_type: "Onsite",
    job_link: "/join/engineering/devops_engineer",
  },
  {
    job_title: "UI/UX Designer",
    job_desc:
      "Join our team as a UI/UX Designer! You will design intuitive and engaging user interfaces and experiences. Required skills: Adobe XD/Figma, wireframing, prototyping, user research. A keen eye for detail and a passion for design are essential. Enjoy flexible hours, generous leave, and competitive compensation. Apply now to create amazing user experiences!",
    job_location: "Lucknow, Uttar Pradesh",
    job_type: "Onsite",
    job_link: "/join/design/ui_ux_designer",
  },
  {
    job_title: "Backend Lead",
    job_desc:
      "We're looking for a Backend Lead to oversee our server-side development. You will architect scalable solutions, manage a team, and ensure robust performance. Required skills: Node.js/Java/Python, SQL/NoSQL databases, API design, leadership. Enjoy flexible hours, generous leave, and competitive compensation. Apply now to lead our backend team!",
    job_location: "Lucknow, Uttar Pradesh",
    job_type: "Onsite",
    job_link: "/join/engineering/backend_lead",
  },
  {
    job_title: "Operations Manager",
    job_desc:
      "Join our team as an Operations Manager! You will oversee daily operations, ensure efficient processes, and manage logistics. Required skills: organizational skills, leadership, process optimization, communication. Enjoy flexible hours, generous leave, and competitive compensation. Apply now to streamline our operations!",
    job_location: "Lucknow, Uttar Pradesh",
    job_type: "Onsite",
    job_link: "/join/operations/operations_manager",
  },
]

export const loginCarouselData = [
  {
    svg: "logistics",
    title: "Shoppio’s Store Builder",
    desc: "Build amazing and unique fronts using Shoppio’s Store Builder.",
  },
  {
    svg: "Checking_boxes",
    title: "Shoppio’s Analytics Tools",
    desc: "Gain insights into your store’s performance with Shoppio’s comprehensive analytics tools.",
  },
  {
    svg: "Data_analysis",
    title: "Shoppio’s Marketing Suite",
    desc: "Boost your sales with Shoppio’s powerful marketing and advertising tools.",
  },
]

export const loginBottomLinks = [
  {
    title: "Shoppio Home",
    link: AppPaths.HOME_PAGE,
  },
  {
    title: "Features",
    link: AppPaths.POLICIES,
  },
  {
    title: "Contact Us",
    link: AppPaths.CONTACT_US,
  },
  {
    title: "Pricing",
    link: AppPaths.CANCELLATION_REFUND,
  },
]

export const engineeringSkillsSet = [
  { value: "react", label: "React" },
  { value: "node", label: "Node.js" },
  { value: "firebase", label: "Firebase" },
  { value: "figma", label: "Figma" },
  { value: "javascript", label: "JavaScript" },
  { value: "html", label: "HTML" },
  { value: "css", label: "CSS" },
  { value: "python", label: "Python" },
  { value: "java", label: "Java" },
  { value: "csharp", label: "C#" },
  { value: "ruby", label: "Ruby" },
  { value: "php", label: "PHP" },
  { value: "sql", label: "SQL" },
  { value: "typescript", label: "TypeScript" },
  { value: "swift", label: "Swift" },
  { value: "go", label: "Go" },
  { value: "docker", label: "Docker" },
  { value: "kubernetes", label: "Kubernetes" },
  { value: "git", label: "Git" },
  { value: "bash", label: "Bash" },
  { value: "aws", label: "AWS" },
  { value: "azure", label: "Azure" },
  { value: "gcp", label: "GCP" },
  { value: "graphql", label: "GraphQL" },
  { value: "mongodb", label: "MongoDB" },
  { value: "redis", label: "Redis" },
  { value: "elasticsearch", label: "Elasticsearch" },
  { value: "rabbitmq", label: "RabbitMQ" },
  { value: "nginx", label: "Nginx" },
  { value: "apache", label: "Apache" },
  { value: "tensorflow", label: "TensorFlow" },
  { value: "pytorch", label: "PyTorch" },
  { value: "jira", label: "Jira" },
  { value: "confluence", label: "Confluence" },
  { value: "webpack", label: "Webpack" },
  { value: "gulp", label: "Gulp" },
  { value: "sass", label: "Sass" },
  { value: "less", label: "Less" },
  { value: "bootstrap", label: "Bootstrap" },
  { value: "tailwind", label: "Tailwind CSS" },
  { value: "materialui", label: "Material UI" },
  { value: "storybook", label: "Storybook" },
  { value: "vscode", label: "Visual Studio Code" },
  { value: "intellij", label: "IntelliJ IDEA" },
  { value: "pycharm", label: "PyCharm" },
  { value: "eclipse", label: "Eclipse" },
  { value: "netbeans", label: "NetBeans" },
  { value: "sublime", label: "Sublime Text" },
  { value: "atom", label: "Atom" },
  { value: "vim", label: "Vim" },
  { value: "emacs", label: "Emacs" },
  { value: "xcode", label: "Xcode" },
  { value: "androidstudio", label: "Android Studio" },
]

export const joinInformationsFields = [
  {
    name: "name",
    label: "Full Name",
    dataTip: "Please tell us your good name",
    type: "text",
  },
  {
    name: "email",
    label: "Email Address",
    dataTip: "Please enter your email ID",
    type: "text",
  },
  {
    name: "dob",
    label: "Date of Birth",
    dataTip: "Please enter your phone number",
    type: "date",
  },

  {
    name: "gender",
    label: "Gender",
    type: "radio",
    dataTip: "Please choose your gender",
    inline: true,
    options: ["Male", "Female", "Other"].map(v => ({ label: v, value: v })),
  },
  {
    group: "Residential Address",
  },
  {
    name: "addressLine1",
    label: "Address line 1",
    type: "text",
    fullWidth: true,
  },
  {
    name: "house_number",
    label: "House Number, Lane Number",
    type: "text",
  },
  {
    name: "pinCode",
    label: "Pin Code",
    type: "text",
  },
  {
    name: "area_locality",
    label: "Area Locality",
    type: "text",
  },
  {
    name: "resume",
    label: "Upload CV",
    type: "file",
  },
  {
    group: "Social Connections",
  },
  {
    name: "linkedIn",
    label: "LinkedIn Profile",
    type: "text",
  },
  {
    name: "twitter",
    label: "Twitter Handle",
    type: "text",
  },
  {
    name: "facebook",
    label: "Facebook Profile",
    type: "text",
  },
  {
    name: "instagram",
    label: "Instagram Handle",
    type: "text",
  },
]

export const salesManagerFields = [
  {
    name: "name",
    label: "Full Name",
    dataTip: "Please tell us your good name",
    type: "text",
  },
  {
    name: "email",
    label: "Email Address",
    dataTip: "Please enter your email ID",
    type: "text",
  },
  {
    name: "dob",
    label: "Date of Birth",
    dataTip: "Please enter your date of birth",
    type: "date",
  },

  {
    name: "gender",
    label: "Gender",
    type: "radio",
    dataTip: "Please choose your gender",
    inline: true,
    options: ["Male", "Female", "Other"].map(v => ({ label: v, value: v })),
  },
  {
    group: "Residential Address",
  },
  {
    name: "address_line_1",
    label: "Address line 1",
    type: "text",
    fullWidth: true,
  },
  {
    name: "house_number",
    label: "House Number, Lane Number",
    type: "text",
  },
  {
    name: "pin_code",
    label: "Pin Code",
    type: "text",
  },
  {
    name: "area_locality",
    label: "Area Locality",
    type: "text",
  },
  {
    name: "resume",
    label: "Upload CV",
    type: "file",
  },
  {
    name: "year_of_experience",
    label: "Years of Experience in Sales",
    dataTip: "Please enter your experience in years",
    type: "number",
  },
  {
    name: "target_achievement",
    label: "Target Achievement Rate",
    dataTip: "Enter your target achievement percentage",
    type: "number",
  },
  {
    name: "linkedIn",
    label: "LinkedIn Profile",
    type: "text",
  },
  {
    name: "twitter",
    label: "Twitter Handle",
    type: "text",
  },
  {
    name: "facebook",
    label: "Facebook Profile",
    type: "text",
  },
  {
    name: "salesforce",
    label: "Salesforce Handle",
    type: "text",
  },
  {
    name: "photo",
    label: "Your Picture",
    type: "pic",
  },
]
export const designerFields = [
  {
    name: "name",
    label: "Full Name",
    dataTip: "Please tell us your good name",
    type: "text",
  },
  {
    name: "email",
    label: "Email Address",
    dataTip: "Please enter your email ID",
    type: "text",
  },
  {
    name: "dob",
    label: "Date of Birth",
    dataTip: "Please enter your date of birth",
    type: "date",
  },

  {
    name: "gender",
    label: "Gender",
    type: "radio",
    dataTip: "Please choose your gender",
    inline: true,
    options: ["Male", "Female", "Other"].map(v => ({ label: v, value: v })),
  },
  {
    group: "Residential Address",
  },
  {
    name: "address_line_1",
    label: "Address line 1",
    type: "text",
    fullWidth: true,
  },
  {
    name: "house_number",
    label: "House Number, Lane Number",
    type: "text",
  },
  {
    name: "pin_code",
    label: "Pin Code",
    type: "text",
  },
  {
    name: "area_locality",
    label: "Area Locality",
    type: "text",
  },
  {
    name: "resume",
    label: "Upload CV",
    type: "file",
  },
  {
    name: "portfolio_link",
    label: "Portfolio Link",
    dataTip: "Enter a link to your online portfolio",
    type: "url",
  },
  {
    name: "year_of_experience",
    label: "Year of Experience",
    dataTip: "Enter the number of years of experience",
    type: "text",
  },
  {
    name: "design_tools",
    label: "Design Tools",
    dataTip: "List the design tools you are proficient in",
    fullWidth: true,
    type: "multiselect",
    options: engineeringSkillsSet,
  },
  {
    name: "linkedIn",
    label: "LinkedIn Profile",
    type: "text",
  },
  {
    name: "twitter",
    label: "Twitter Handle",
    type: "text",
  },
  {
    name: "behance",
    label: "Behance Handle",
    type: "text",
  },
  {
    name: "dribbble",
    label: "Dribbble Handle",
    type: "text",
  },
  {
    name: "photo",
    label: "Your Picture",
    type: "pic",
  },
]

export const devOpsEngineerFields = [
  {
    name: "name",
    label: "Full Name",
    dataTip: "Please tell us your good name",
    type: "text",
  },
  {
    name: "email",
    label: "Email Address",
    dataTip: "Please enter your email ID",
    type: "text",
  },
  {
    name: "dob",
    label: "Date of Birth",
    dataTip: "Please enter your date of birth",
    type: "date",
  },

  {
    name: "gender",
    label: "Gender",
    type: "radio",
    dataTip: "Please choose your gender",
    inline: true,
    options: ["Male", "Female", "Other"].map(v => ({ label: v, value: v })),
  },
  {
    group: "Residential Address",
  },
  {
    name: "address_line_1",
    label: "Address line 1",
    type: "text",
    fullWidth: true,
  },
  {
    name: "house_number",
    label: "House Number, Lane Number",
    type: "text",
  },
  {
    name: "pin_code",
    label: "Pin Code",
    type: "text",
  },
  {
    name: "area_locality",
    label: "Area Locality",
    type: "text",
  },
  {
    name: "resume",
    label: "Upload CV",
    type: "file",
  },
  {
    name: "certifications",
    label: "Certifications",
    dataTip: "List any relevant certifications",
    type: "text",
    fullWidth: true,
  },
  // {
  //   name: "technologies",
  //   label: "Technologies",
  //   dataTip: "List technologies you are familiar with",
  //   type: "multiselect",
  //   children: engineeringSkillsSet,
  //   fullWidth: true,
  // },
  {
    name: "year_of_experience",
    label: "Year of Experience",
    dataTip: "Enter the number of years of experience",
    type: "text",
  },
  {
    name: "linkedIn",
    label: "LinkedIn Profile",
    type: "text",
  },
  {
    name: "twitter",
    label: "Twitter Handle",
    type: "text",
  },
  {
    name: "github",
    label: "Github Handle",
    type: "text",
  },
  {
    name: "reddit",
    label: "Reddit Handle",
    type: "text",
  },
  {
    name: "photo",
    label: "Your Picture",
    type: "pic",
  },
]

export const operationsManagerFields = [
  {
    name: "name",
    label: "Full Name",
    dataTip: "Please tell us your good name",
    type: "text",
  },
  {
    name: "email",
    label: "Email Address",
    dataTip: "Please enter your email ID",
    type: "text",
  },
  {
    name: "dob",
    label: "Date of Birth",
    dataTip: "Please enter your date of birth",
    type: "date",
  },

  {
    name: "gender",
    label: "Gender",
    type: "radio",
    dataTip: "Please choose your gender",
    inline: true,
    options: ["Male", "Female", "Other"].map(v => ({ label: v, value: v })),
  },
  {
    group: "Residential Address",
  },
  {
    name: "address_line_1",
    label: "Address line 1",
    type: "text",
    fullWidth: true,
  },
  {
    name: "house_number",
    label: "House Number, Lane Number",
    type: "text",
  },
  {
    name: "pin_code",
    label: "Pin Code",
    type: "text",
  },
  {
    name: "area_locality",
    label: "Area Locality",
    type: "text",
  },
  {
    name: "resume",
    label: "Upload CV",
    type: "file",
  },
  {
    name: "leadership_experience",
    label: "Leadership Experience",
    dataTip: "Describe your leadership experience",
    type: "textarea",
    fullWidth: true,
  },
  {
    name: "operations_skills",
    label: "Operations Skills",
    dataTip: "List your key skills in operations",
    type: "text",
    fullWidth: true,
  },
  {
    name: "year_of_experience",
    label: "Year of Experience",
    dataTip: "Enter the number of years of experience",
    type: "text",
  },
  {
    name: "linkedIn",
    label: "LinkedIn Profile",
    type: "text",
  },
  {
    name: "twitter",
    label: "Twitter Handle",
    type: "text",
  },
  {
    name: "facebook",
    label: "Facebook Handle",
    type: "text",
  },
  {
    name: "indeed",
    label: "Indeed Handle",
    type: "text",
  },
  {
    name: "photo",
    label: "Your Picture",
    type: "pic",
  },
]

export const marketingSkillsSet = [
  // Sales related options
  { value: "crm", label: "CRM" },
  { value: "lead_generation", label: "Lead Generation" },
  { value: "salesforce", label: "Salesforce" },
  { value: "negotiation", label: "Negotiation" },
  { value: "sales_strategy", label: "Sales Strategy" },
  { value: "cold_calling", label: "Cold Calling" },
  { value: "account_management", label: "Account Management" },
  { value: "customer_relationships", label: "Customer Relationships" },
  { value: "pipeline_management", label: "Pipeline Management" },
  { value: "sales_analytics", label: "Sales Analytics" },

  // Finance related options
  { value: "accounting", label: "Accounting" },
  { value: "financial_analysis", label: "Financial Analysis" },
  { value: "budgeting", label: "Budgeting" },
  { value: "forecasting", label: "Forecasting" },
  { value: "investment_management", label: "Investment Management" },
  { value: "risk_management", label: "Risk Management" },
  { value: "taxation", label: "Taxation" },
  { value: "financial_reporting", label: "Financial Reporting" },
  { value: "auditing", label: "Auditing" },
  { value: "payroll", label: "Payroll" },

  // Marketing related options
  { value: "seo", label: "SEO" },
  { value: "content_marketing", label: "Content Marketing" },
  { value: "social_media", label: "Social Media" },
  { value: "email_marketing", label: "Email Marketing" },
  { value: "advertising", label: "Advertising" },
  { value: "market_research", label: "Market Research" },
  { value: "branding", label: "Branding" },
  { value: "product_marketing", label: "Product Marketing" },
  { value: "digital_marketing", label: "Digital Marketing" },
  { value: "influencer_marketing", label: "Influencer Marketing" },

  // Human Resources related options
  { value: "recruitment", label: "Recruitment" },
  { value: "employee_relations", label: "Employee Relations" },
  { value: "training_and_development", label: "Training and Development" },
  { value: "performance_management", label: "Performance Management" },
  { value: "compensation_and_benefits", label: "Compensation and Benefits" },
  { value: "hr_compliance", label: "HR Compliance" },
  { value: "talent_management", label: "Talent Management" },
  { value: "hr_analytics", label: "HR Analytics" },
  { value: "organizational_development", label: "Organizational Development" },
  { value: "employee_engagement", label: "Employee Engagement" },
]

export const salesTableData = [
  {
    id: 1,
    name: "John Doe",
    phoneNumber: "123-456-7890",
    purchasedOn: "2024-08-01",
    planPurchased: "Pro",
    storeName: "Store A",
  },
  {
    id: 2,
    name: "Jane Smith",
    phoneNumber: "987-654-3210",
    purchasedOn: "2024-08-05",
    planPurchased: "Elite",
    storeName: "Store B",
  },
  {
    id: 3,
    name: "Michael Johnson",
    phoneNumber: "555-555-5555",
    purchasedOn: "2024-08-08",
    planPurchased: "Basic",
    storeName: "Store C",
  },
  {
    id: 4,
    name: "Emily Davis",
    phoneNumber: "777-777-7777",
    purchasedOn: "2024-08-10",
    planPurchased: "Standard",
    storeName: "Store D",
  },
]

export const leadsTableData = [
  {
    id: 1,
    name: "John Doe",
    phoneNumber: "123-456-7890",
    purchasedOn: "2024-08-01",
    source: "Facebook",
    storeName: "Store A",
  },
  {
    id: 2,
    name: "Jane Smith",
    phoneNumber: "987-654-3210",
    purchasedOn: "2024-08-05",
    storeName: "Store B",
    source: "Instagram",
  },
  {
    id: 3,
    name: "Michael Johnson",
    phoneNumber: "555-555-5555",
    purchasedOn: "2024-08-08",
    storeName: "Store C",
    source: "Twitter",
  },
  {
    id: 4,
    name: "Emily Davis",
    phoneNumber: "777-777-7777",
    purchasedOn: "2024-08-10",
    storeName: "Store D",
    source: "LinkedIn",
  },
]

export const assignee = [
  {
    name: "assignee_name",
    value: "John Doe",
    email: "john@doe.com",
    role: "FE",
  },
  {
    name: "assignee_name",
    value: "Jane Smith",
    email: "jane@smith.com",
    role: "BE",
  },
  {
    name: "assignee_name",
    value: "Michael Johnson",
    email: "michael@johnson.com",
    role: "Other",
  },
  {
    name: "assignee_name",
    value: "Emily Davis",
    email: "emily@davis.com",
    role: "BE",
  },
]

export const colorMap = {
  priority: {
    high: "#FF6347",
    urgent: "#FF4500",
    medium: "#FFD700",
    low: "#32CD32",
  },
  status: {
    "not picked": "#AF3A3A",
    "on hold": "#FFA500",
    delayed: "#FF4500",
    "work in progress": "#5565F6",
    completed: "#2B8D52",
  },
  complexity: {
    "Very Easy": "#2B8D52",
    Easy: "#5CB85C",
    Medium: "#F0AD4E",
    Hard: "#D9534F",
    "Very Hard": "#AF3A3A",
  },
  ticketStatus: {
    Open: "#a93226",
    Closed: "#1e8449",
    "In Progress": "#d4ac0d",
    Completed: "#3498db",
  },
  ticketType: {
    bug: "#d9534f",
    "feature request": "#5cb85c",
    "technical support": "#3498db",
    other: "#8a6d3b",
  },
}

export const complexities = [
  { level: 1, label: "Very Easy", points: 10 },
  { level: 2, label: "Easy", points: 20 },
  { level: 3, label: "Medium", points: 30 },
  { level: 4, label: "Hard", points: 40 },
  { level: 5, label: "Very Hard", points: 50 },
]
export const salesData = [
  {
    id: 1,
    icon: "thumbs-up",
    title: "Finished",
    taskCount: 27,
    taskChange: "+5 tasks",
    indicator: "up",
    color: "var(--success)",
  },
  {
    id: 2,
    icon: "stopwatch",
    title: "Tracked",
    taskCount: "12 h",
    taskChange: "-2 hours",
    indicator: "down",
    color: "var(--error)",
  },
  {
    id: 3,
    icon: "chart-line",
    title: "Efficiency",
    taskCount: "80%",
    taskChange: "+3%",
    indicator: "up",
    color: "var(--success)",
  },
]

export const superUserData = [
  {
    id: 1,
    icon: "thumbs-up",
    title: "Finished",
    taskCount: 27,
    taskChange: "+5 tasks",
    indicator: "up",
    color: "var(--success)",
  },
  {
    id: 2,
    icon: "stopwatch",
    title: "Tracked",
    taskCount: "12 h",
    taskChange: "-2 hours",
    indicator: "down",
    color: "var(--error)",
  },
  {
    id: 3,
    icon: "chart-line",
    title: "Efficiency",
    taskCount: "80%",
    taskChange: "+3%",
    indicator: "up",
    color: "var(--success)",
  },
]

export const tasksFields = [
  { group: "Tasks Details", desc: "Details related to the tasks" },
  {
    name: "name",
    label: "Task Name",
    dataTip: "Task name or title",
    type: "text",
    fullWidth: true,
  },
  {
    name: "description",
    label: "Description",
    dataTip: "Detailed description of the task",
    type: "textarea",
    fullWidth: true,
  },
  {
    name: "due_date",
    label: "Due Date",
    dataTip: "Deadline for task completion",
    type: "datetime",
    fullWidth: true,
  },
  {
    name: "priority",
    label: "Priority",
    dataTip: "Task priority level",
    type: "select",
    children: ["high", "urgent", "medium", "low"].map(op => (
      <option value={op}>{op}</option>
    )),
    fullWidth: true,
  },
  {
    name: "type",
    label: "Role Type",
    dataTip: "Task category/type",
    type: "select",
    children: ["FE", "BE", "Other"].map(op => <option value={op}>{op}</option>),
    fullWidth: true,
  },
  {
    name: "status",
    label: "Status",
    dataTip: "Current status of the task",
    type: "select",
    children: [
      "not picked",
      "on hold",
      "delayed",
      "work in progress",
      "completed",
    ].map(op => <option value={op}>{op}</option>),
    fullWidth: true,
  },
  { group: "Assigned To", desc: "Person assigned to the task" },
  {
    name: "assignee",
    label: "Assignee",
    type: "select",
    children: assignee.map(op => (
      <option key={op.value} value={JSON.stringify(op)}>
        {op.value}
      </option>
    )),
    fullWidth: true,
  },
  {
    name: "assignee_id",
    label: "Assignee ID",
    hidden: true,
  },
  {
    name: "task_complexity",
    label: "Task Complexity",
    dataTip: "Complexity of the task",
    type: "select",
    children: complexities.map(op => (
      <option value={op.label}>{op.label}</option>
    )),
    fullWidth: true,
  },
  {
    name: "attachments",
    label: "Attachments",
    dataTip: "Files related to the task",
    type: "file",
    fullWidth: true,
  },
  {
    name: "comments",
    label: "Comments",
    dataTip: "Comments on the task",
    type: "textarea",
    fullWidth: true,
  },
]
