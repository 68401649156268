import React from "react"
import { Space, Card, Header, ReadMore, InfiniteCarousel } from "uiComponents"
import "./SuccessStory.css"

const user1 = "https://avatars.githubusercontent.com/u/33944584?v=4"
const user2 = "https://avatars.githubusercontent.com/u/41429696?v=4"
const user3 =
  "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS4gg_D8uFRFRFiSilx1eTouqbrEpxHPKpOgg&s"

const SuccessStory = () => {
  const cards = [
    {
      title: "Improved Customer Engagement",
      content:
        "Our sales have significantly increased thanks to the custom storefront and CRM features. The platform has made it easy to track customer interactions and tailor our services to their needs. It's been a fantastic investment for our business.",
      user: {
        avatar: user1,
        name: "Faraz Khan",
        type: "Founder & Owner of shoppio",
      },
    },
    {
      title: "Streamlined Operations and Growth",
      content:
        "Shoppio's inventory management and analytics tools have streamlined our operations, allowing us to focus on growth. The insights we gain are invaluable.",
      user: {
        avatar: user2,
        name: "Amit Rawat",
        type: "Store Owner",
      },
    },
    {
      title: "Enhanced Customer Satisfaction",
      content:
        "With Shoppio, our customer satisfaction rates have soared. The easy-to-use interface and robust features make managing customer relationships a breeze.",
      user: {
        avatar: user3,
        name: "Jonny Depp",
        type: "Store Owner",
      },
    },
  ]

  return (
    <Card themed>
      <div className='why-choose-header'>
        <div className='header-text themed'>
          <span>Success</span>
          <span> </span>Stories
        </div>
        <p>
          Hear some truth from our users who are using Shoppio for their
          business needs
        </p>
      </div>
      <Space lg />
      <Space lg />

      <InfiniteCarousel flex rtl>
        {cards.map((card, index) => (
          <SuccessStoryCard
            key={index}
            title={card.title}
            content={card.content}
            name={card.user.name}
            avatar={card.user.avatar}
            type={card.user.type}
          />
        ))}
      </InfiniteCarousel>
    </Card>
  )
}

export default SuccessStory

export const SuccessStoryCard = ({
  title,
  content,
  children,
  avatar,
  name,
  type,
}) => {
  return (
    <Card style={{ marginRight: "20px" }} className='success-story-card'>
      <Space md />
      <Header sm bold>
        "{title}"
      </Header>
      <Space />
      <ReadMore text={content} maxWords={20} />
      <Space lg />
      {children}
      <div className='card-content'>
        <div className='card-avatar'>
          <img src={avatar} alt='' />
        </div>
        <div className='card-info'>
          <Header bold>{name}</Header>
          <p>{type}</p>
        </div>
      </div>
    </Card>
  )
}
