export const messageFields = [
  {
    name: "assigned_to",
    label: "Assigned to",
    type: "select",
    children: [
      "John Doe",
      "Jane Smith",
      "Michael Johnson",
      "Emily Davis",
      "Robert Williams",
    ].map(op => <option value={op}>{op}</option>),
    fullWidth: true,
  },
  {
    name: "customer_segment",
    label: "Customer Segment",
    type: "select",
    children: ["High", "Medium", "Low"].map(op => (
      <option value={op}>{op}</option>
    )),
    fullWidth: true,
  },
  {
    name: "customer_since",
    label: "Customer since",
    type: "text",
    value: "01 July 2024",
    fullWidth: true,
  },
]
export const messageData = [
  {
    username: "John Doe",
    textMessage: "Hello, how are you?",
    timestamp: "10:00 AM",
    unreadMessage: 1,
    vip: true,
    messages: [
      {
        text: "Hello, how are you?",
        sender: "John Doe",
        timestamp: "10:00 AM",
      },
    ],
  },
  {
    username: "Jane Smith",
    textMessage: "Hi, I'm doing well. How about you?",
    timestamp: "1 day ago",
    unreadMessage: 0,
    vip: false,
    messages: [
      {
        text: "Hi, I'm doing well. How about you?",
        sender: "Jane Smith",
        timestamp: "1 day ago",
      },
    ],
  },
  {
    username: "Michael Johnson",
    textMessage: "I'm excited to talk with you. What's your name?",
    timestamp: "5 days ago",
    unreadMessage: 5,
    vip: false,
    messages: [
      {
        text: "I'm excited to talk with you. What's your name?",
        sender: "Michael Johnson",
        timestamp: "5 days ago",
      },
    ],
  },
  {
    username: "Emily Davis",
    textMessage: "Good morning, I'm looking forward to our meeting.",
    timestamp: "yesterday",
    unreadMessage: 2,
    vip: true,
    messages: [
      {
        text: "Good morning, I'm looking forward to our meeting.",
        sender: "Emily Davis",
        timestamp: "yesterday",
      },
    ],
  },
  {
    username: "Robert Williams",
    textMessage:
      "Hi, I've been working on a new project. Do you need help with it?",
    timestamp: "yesterday",
    unreadMessage: 0,
    vip: false,
    messages: [
      {
        text: "Hi, I've been working on a new project. Do you need help with it?",
        sender: "Robert Williams",
        timestamp: "yesterday",
      },
    ],
  },
  {
    username: "David Brown",
    textMessage:
      "Hello, I just received an important document. Can you help me review it?",
    timestamp: "2 weeks ago",
    unreadMessage: 1,
    vip: true,
    messages: [
      {
        text: "Hello, I just received an important document. Can you help me review it?",
        sender: "David Brown",
        timestamp: "2 weeks ago",
      },
    ],
  },
  {
    username: "Sarah Clark",
    textMessage:
      "I'm excited to announce that we've won a huge prize! Can you help me find out more?",
    timestamp: "2 months ago",
    unreadMessage: 10,
    vip: false,
    messages: [
      {
        text: "I'm excited to announce that we've won a huge prize! Can you help me find out more? I'm excited to announce that we've won a huge prize! Can you help me find out more? I'm excited to announce that we've won a huge prize! Can you help me find out more?",
        sender: "Sarah Clark",
        timestamp: "2 months ago",
      },
    ],
  },
  {
    username: "Michael Johnson",
    textMessage:
      "I've sent you an email about the project. Let's discuss it tomorrow.",
    timestamp: "2 months ago",
    unreadMessage: 0,
    vip: false,
    messages: [
      {
        text: "I've sent you an email about the project. Let's discuss it tomorrow.",
        sender: "Michael Johnson",
        timestamp: "2 months ago",
      },
    ],
  },
  {
    username: "John Doe",
    textMessage: "Hello, how are you?",
    timestamp: "10:00 AM",
    unreadMessage: 1,
    vip: true,
    messages: [
      {
        text: "Hello, how are you?",
        sender: "John Doe",
        timestamp: "10:00 AM",
      },
    ],
  },
  {
    username: "Jane Smith",
    textMessage: "Hi, I'm doing well. How about you?",
    timestamp: "1 day ago",
    unreadMessage: 0,
    vip: false,
    messages: [
      {
        text: "Hi, I'm doing well. How about you?",
        sender: "Jane Smith",
        timestamp: "1 day ago",
      },
    ],
  },
  {
    username: "Michael Johnson",
    textMessage: "I'm excited to talk with you. What's your name?",
    timestamp: "5 days ago",
    unreadMessage: 5,
    vip: false,
    messages: [
      {
        text: "I'm excited to talk with you. What's your name?",
        sender: "Michael Johnson",
        timestamp: "5 days ago",
      },
    ],
  },
  {
    username: "Emily Davis",
    textMessage: "Good morning, I'm looking forward to our meeting.",
    timestamp: "yesterday",
    unreadMessage: 2,
    vip: true,
    messages: [
      {
        text: "Good morning, I'm looking forward to our meeting.",
        sender: "Emily Davis",
        timestamp: "yesterday",
      },
    ],
  },
  {
    username: "Robert Williams",
    textMessage:
      "Hi, I've been working on a new project. Do you need help with it?",
    timestamp: "yesterday",
    unreadMessage: 0,
    vip: false,
    messages: [
      {
        text: "Hi, I've been working on a new project. Do you need help with it?",
        sender: "Robert Williams",
        timestamp: "yesterday",
      },
    ],
  },
  {
    username: "David Brown",
    textMessage:
      "Hello, I just received an important document. Can you help me review it?",
    timestamp: "2 weeks ago",
    unreadMessage: 1,
    vip: true,
    messages: [
      {
        text: "Hello, I just received an important document. Can you help me review it?",
        sender: "David Brown",
        timestamp: "2 weeks ago",
      },
    ],
  },
  {
    username: "Sarah Clark",
    textMessage:
      "I'm excited to announce that we've won a huge prize! Can you help me find out more?",
    timestamp: "2 months ago",
    unreadMessage: 10,
    vip: false,
    messages: [
      {
        text: "I'm excited to announce that we've won a huge prize! Can you help me find out more? I'm excited to announce that we've won a huge prize! Can you help me find out more? I'm excited to announce that we've won a huge prize! Can you help me find out more?",
        sender: "Sarah Clark",
        timestamp: "2 months ago",
      },
    ],
  },
  {
    username: "Michael Johnson",
    textMessage:
      "I've sent you an email about the project. Let's discuss it tomorrow.",
    timestamp: "2 months ago",
    unreadMessage: 0,
    vip: false,
    messages: [
      {
        text: "I've sent you an email about the project. Let's discuss it tomorrow.",
        sender: "Michael Johnson",
        timestamp: "2 months ago",
      },
    ],
  },
  {
    username: "John Doe",
    textMessage: "Hello, how are you?",
    timestamp: "10:00 AM",
    unreadMessage: 1,
    vip: true,
    messages: [
      {
        text: "Hello, how are you?",
        sender: "John Doe",
        timestamp: "10:00 AM",
      },
    ],
  },
  {
    username: "Jane Smith",
    textMessage: "Hi, I'm doing well. How about you?",
    timestamp: "1 day ago",
    unreadMessage: 0,
    vip: false,
    messages: [
      {
        text: "Hi, I'm doing well. How about you?",
        sender: "Jane Smith",
        timestamp: "1 day ago",
      },
    ],
  },
  {
    username: "Michael Johnson",
    textMessage: "I'm excited to talk with you. What's your name?",
    timestamp: "5 days ago",
    unreadMessage: 5,
    vip: false,
    messages: [
      {
        text: "I'm excited to talk with you. What's your name?",
        sender: "Michael Johnson",
        timestamp: "5 days ago",
      },
    ],
  },
  {
    username: "Emily Davis",
    textMessage: "Good morning, I'm looking forward to our meeting.",
    timestamp: "yesterday",
    unreadMessage: 2,
    vip: true,
    messages: [
      {
        text: "Good morning, I'm looking forward to our meeting.",
        sender: "Emily Davis",
        timestamp: "yesterday",
      },
    ],
  },
  {
    username: "Robert Williams",
    textMessage:
      "Hi, I've been working on a new project. Do you need help with it?",
    timestamp: "yesterday",
    unreadMessage: 0,
    vip: false,
    messages: [
      {
        text: "Hi, I've been working on a new project. Do you need help with it?",
        sender: "Robert Williams",
        timestamp: "yesterday",
      },
    ],
  },
  {
    username: "David Brown",
    textMessage:
      "Hello, I just received an important document. Can you help me review it?",
    timestamp: "2 weeks ago",
    unreadMessage: 1,
    vip: true,
    messages: [
      {
        text: "Hello, I just received an important document. Can you help me review it?",
        sender: "David Brown",
        timestamp: "2 weeks ago",
      },
    ],
  },
  {
    username: "Sarah Clark",
    textMessage:
      "I'm excited to announce that we've won a huge prize! Can you help me find out more?",
    timestamp: "2 months ago",
    unreadMessage: 10,
    vip: false,
    messages: [
      {
        text: "I'm excited to announce that we've won a huge prize! Can you help me find out more? I'm excited to announce that we've won a huge prize! Can you help me find out more? I'm excited to announce that we've won a huge prize! Can you help me find out more?",
        sender: "Sarah Clark",
        timestamp: "2 months ago",
      },
    ],
  },
  {
    username: "Michael Johnson",
    textMessage:
      "I've sent you an email about the project. Let's discuss it tomorrow.",
    timestamp: "2 months ago",
    unreadMessage: 0,
    vip: false,
    messages: [
      {
        text: "I've sent you an email about the project. Let's discuss it tomorrow.",
        sender: "Michael Johnson",
        timestamp: "2 months ago",
      },
    ],
  },
]
