import React, { useEffect, useState } from "react"
import { Button, Space } from "uiComponents"
import firebase from "firebase"
import { InputCheck, InputField, PhoneField } from "formComponents"
import { useResend } from "./Hooks"
import "./Login.css"
import { AppPaths } from "shoppio-constants"

const LoginAuth = ({
  phoneNumber,
  onChange,
  callback,
  callbackParams,
  errorText,
  onLoginSuccess,
  forJoinPage,
  forSeller,
  loading,
}) => {
  const [agreed, setAgreed] = useState(false)
  const [password, setPassword] = useState("")
  const [error, setError] = useState("")
  const [phoneError, setPhoneError] = useState("")
  const [buttonLoading, setButtonLoading] = useState(false)

  const {
    confirm,
    setResend,
    setPhone,
    time,
    busy,
    setBusy,
    setTime,
    setConfirm,
    phone,
  } = useResend({
    phoneNumber,
  })

  const validatePhoneNumber = phone => {
    const phoneRegex = /^[+]?[(]?[0-9]{1,4}[)]?[-\s./0-9]*$/
    return phoneRegex.test(phone)
  }

  const sendOTP = async event => {
    event.preventDefault()

    if (!validatePhoneNumber(phone)) {
      setPhoneError("Invalid phone number format")
      return
    }

    setPhoneError("")
    setBusy(true)
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "recaptcha-container",
    )
    const appVerifier = window.recaptchaVerifier
    const confirmationResult = await firebase
      .auth()
      .signInWithPhoneNumber(phone, appVerifier)
    setConfirm(confirmationResult)
    setBusy(false)
    setTime(10)
  }

  const resendOTP = event => {
    setConfirm(false)
    event.preventDefault()
    setResend(true)
  }

  useEffect(() => {
    if (time > 0) {
      setTimeout(() => {
        setTime(time - 1)
      }, 1000)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time])

  const signInWithPhone = async event => {
    try {
      setBusy(true)
      const result = await confirm.confirm(password)
      if (callback) {
        callbackParams ? await callback(callbackParams) : await callback(result)
      }
      setButtonLoading(true)
      setTimeout(() => {
        setButtonLoading(false)
        onLoginSuccess()
        forJoinPage
          ? (window.location.href = "#")
          : forSeller
          ? (window.location.href = AppPaths.ACCOUNT_SETUP)
          : (window.location.href = AppPaths.HOME_PAGE)
      }, 2000)
    } catch (error) {
      setError(error?.message)
    } finally {
      setBusy(false)
    }
  }

  useEffect(() => {
    if (onChange) onChange(phone)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phone])

  return (
    <>
      <PhoneField
        hideCaptcha={confirm}
        errorText={phoneError || errorText}
        field={{
          name: "phone",
          onChange: e => setPhone(e.target.value),
          value: phone,
          error,
        }}
        disabled={!!confirm}
        label='Enter phone number'
      />
      <Space />
      {confirm && (
        <div className='verification-container'>
          <InputField
            field={{ name: "" }}
            label='Verification Code'
            errorText={error}
            onChange={e => {
              if (password === "") setError("")
              setPassword(e.target.value)
            }}
          />
          <Button
            accent
            disabled={Number(time) > 0 || busy}
            onClick={resendOTP}
            className='resend-button'
            // style={{ backgroundColor: buttonColor, color: textColor }}
          >
            {Number(time) > 0 ? `Resend OTP after ${time}s` : "Resend OTP"}
          </Button>
        </div>
      )}
      {error.length <= 80 ? (
        <>
          <Space lg={5} />
        </>
      ) : error.length > 80 ? (
        <>
          <Space lg={5} />
          <Space lg={5} />
        </>
      ) : (
        <Space />
      )}
      {confirm && (
        <InputCheck
          label={
            <p>
              By signing-in or creating your account you agree to Shoppio{" "}
              <span
                onClick={() => window.open(AppPaths.TERMS, "_blank")}
                style={{
                  cursor: "pointer",
                  color: "var(--theme-color)",
                  fontWeight: "bold",
                  textDecoration: "underline",
                }}
              >
                terms
              </span>{" "}
              of use and{" "}
              <span
                onClick={() => window.open(AppPaths.POLICIES, "_blank")}
                style={{
                  cursor: "pointer",
                  color: "var(--theme-color)",
                  fontWeight: "bold",
                  textDecoration: "underline",
                }}
              >
                privacy policy
              </span>
              .
            </p>
          }
          checked={agreed}
          onChange={e => setAgreed(e.target.checked)}
        />
      )}
      <Space />
      <Button
        loading={buttonLoading || busy || loading}
        fullWidth
        disabled={confirm && (!agreed || !password)}
        onClick={confirm ? signInWithPhone : sendOTP}
        colored
      >
        {confirm ? (buttonLoading ? "Signing in..." : "Sign in") : "Get OTP"}
      </Button>
    </>
  )
}

export default LoginAuth
