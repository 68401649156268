import React from "react"
import {
  Col,
  Row,
  Space,
  Card,
  Header,
  IconButtonCircle,
  Divider,
} from "uiComponents"
import { PageLayout } from "appComponents"
import SpaceBetween from "../../UI/Space/SpaceBetween"
import "./ReviewRating.css"
import BestSellers, {
  BestSellersCategory,
  getStarColor,
} from "../StoreAnalytics/BestSellers"
import {
  reviewCardData,
  topPerformingsData,
  worstPerformingsData,
} from "dummyData"
import { isMobile } from "helpers"
let overallRating = 5

const ReviewRating = () => {
  return (
    <PageLayout
      mainContent={
        <Row>
          <Col sm={12} md={12} lg={12} xl={3}>
            <StoreCard />
          </Col>
          <Col sm={12} md={12} lg={12} xl={9}>
            <Card noXS>
              <Header sm bold>
                Reviews & Ratings
              </Header>
              <p>
                Find out about the reviews and ratings this store is getting
                recently
              </p>
              <Space lg />
              <OverallRating />
              <Space lg />
              <Divider fullWidth />
              <Space lg />
              <Header sm bold>
                Top Performing Products
              </Header>
              <Row>
                {topPerformingsData.map(data => (
                  <Col sm={6} md={12} lg={3} xl={3}>
                    <BestSellers
                      accent
                      img={data.img}
                      title={data.title}
                      desc={data.desc}
                      sales={data.sales}
                      rating={data.rating}
                    />
                  </Col>
                ))}
              </Row>
              <Space lg />
              <Divider fullWidth />
              <Space lg />
              <Header sm bold>
                Worst Performing Products
              </Header>
              <Row>
                {worstPerformingsData.map(data => (
                  <Col sm={6} md={3} lg={3}>
                    <BestSellers
                      accent
                      img={data.img}
                      title={data.title}
                      desc={data.desc}
                      sales={data.sales}
                      rating={data.rating}
                    />
                  </Col>
                ))}
              </Row>
              <Space lg />
              <Divider fullWidth />
              <Space lg />
              <Header sm bold>
                Top Performing Categories
              </Header>
              <Space lg />
              <Row>
                {topPerformingsData.map(data => (
                  <Col sm={6} md={4} lg={4}>
                    <BestSellersCategory
                      accent
                      img={data.img}
                      title={data.title}
                      desc={data.desc}
                      sales={data.sales}
                      rating={data.rating}
                    />
                  </Col>
                ))}
              </Row>
              <Space lg />
              <Divider fullWidth />
              <Space lg />
              <Header sm bold>
                Worst Performing Categories
              </Header>
              <Space lg />
              <Row>
                {worstPerformingsData.map(data => (
                  <Col sm={6} md={4} lg={4}>
                    <BestSellersCategory
                      accent
                      img={data.img}
                      title={data.title}
                      desc={data.desc}
                      sales={data.sales}
                      rating={data.rating}
                    />
                  </Col>
                ))}
              </Row>
              <Divider fullWidth />
              <Space lg />
              <Header sm bold>
                Top Performing Subcategories
              </Header>
              <Space lg />
              <Row>
                {topPerformingsData.map(data => (
                  <Col sm={6} md={4} lg={4}>
                    <BestSellersCategory
                      accent
                      img={data.img}
                      title={data.title}
                      desc={data.desc}
                      sales={data.sales}
                      rating={data.rating}
                    />
                  </Col>
                ))}
              </Row>
              <Divider fullWidth />
              <Space lg />
              <Header sm bold>
                Worst Performing Subcategories
              </Header>
              <Space lg />
              <Row>
                {worstPerformingsData.map(data => (
                  <Col sm={6} md={4} lg={4}>
                    <BestSellersCategory
                      accent
                      img={data.img}
                      title={data.title}
                      desc={data.desc}
                      sales={data.sales}
                      rating={data.rating}
                    />
                  </Col>
                ))}
              </Row>
              <Divider fullWidth />
              <Space lg />
              <Header sm bold>
                Recent Reviews
              </Header>
              <Space lg />
              <Row>
                {reviewCardData.map(data => (
                  <Col sm={12} md={4}>
                    <ReviewsCard
                      name={data.name}
                      timestamp={data.timestamp}
                      reviewText={data.reviewText}
                      itemPurchase={data.itemPurchase}
                      rating={data.rating}
                    />
                  </Col>
                ))}
              </Row>
            </Card>
          </Col>
        </Row>
      }
    />
  )
}

export default ReviewRating

const StoreCard = () => {
  return (
    <Card notFull lessHorPad>
      <div>
        <img
          src='https://www.thomasforsyth.co.uk/site/assets/files/1495/toy-maker-london.jpg'
          alt='Hamlos'
          className='store-cover'
        />
      </div>
      <Space lg />
      <Header lg bold>
        Hamlos
      </Header>
      <p>All types of toys and much more, happiness</p>
      <Space lg />
      <SpaceBetween>
        <div className='store-logo-circle'>H</div>
        <IconButtonCircle icon='chevron-right' />
      </SpaceBetween>
    </Card>
  )
}

const ReviewsCard = ({ name, timestamp, reviewText, itemPurchase, rating }) => {
  return (
    <div
      style={{
        backgroundColor: "var(--background)",
        padding: "15px",
        borderRadius: "var(--radius)",
        marginBottom: "10px",
      }}
    >
      <Row>
        <Col sm={2} md={2}>
          <div className='store-logo-circle'>J</div>
        </Col>
        <Col sm={10} md={10}>
          <SpaceBetween>
            <Header bold>{name}</Header>
            <small>{timestamp}</small>
          </SpaceBetween>
          <p style={{ color: "var(--header-color)" }}>“{reviewText}”</p>
          <Space />
          <p>{itemPurchase}</p>
          <Space md />
          <div style={{ display: "flex", alignItems: "center" }}>
            {[...Array(5)].map((_, i) => (
              <i
                key={i}
                className='fas fa-star'
                style={{
                  fontSize: "15px",
                  color: i < rating ? getStarColor(rating) : "lightgray",
                }}
              />
            ))}
          </div>
        </Col>
      </Row>
    </div>
  )
}

const OverallRating = () => {
  return (
    <div
      style={{
        width: "100%",
        maxWidth: "fit-content",
        boxShadow: "0 4px 20px rgba(0, 0, 0, 0.05)",
        padding: "15px 20px",
        borderRadius: "5px",
      }}
    >
      <Header bold>Overall Rating</Header>
      <Space />
      <Row>
        <Col sm={12} md={6}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginRight: "15px",
            }}
          >
            {[...Array(5)].map((_, i) => (
              <i
                key={i}
                className='fas fa-star'
                style={{
                  fontSize: "25px",
                  color:
                    i < overallRating
                      ? getStarColor(overallRating)
                      : "lightgray",
                }}
              />
            ))}
            <p
              style={{
                marginLeft: "10px",
                color: "var(--theme-color)",
              }}
            >
              (332)
            </p>
          </div>
        </Col>
        <Col sm={12} md={6}>
          <div
            style={{
              display: "flex",
              borderLeft: !isMobile && "1px solid var(--border-color)",
              alignItems: "center",
              marginTop: isMobile && "5rem",
            }}
          >
            <span
              className='fas fa-smile'
              style={{
                fontSize: "25px",
                color: "var(--green)",
                marginLeft: "15px",
              }}
            />
            <Header
              style={{
                marginLeft: "10px",
              }}
              bold
            >
              Mostly Positive
            </Header>
          </div>
        </Col>
      </Row>
    </div>
  )
}
