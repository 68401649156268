import React from "react"
import { Col } from "uiComponents"
import { isMobile } from "helpers"

export default function SideContent({ children }) {
  return (
    <Col
      hidden-xs='true'
      hidden-sm='true'
      hidden-md='true'
      hidden-lg='true'
      xl
      style={{ padding: isMobile && "0px" }}
    >
      {children}
    </Col>
  )
}
