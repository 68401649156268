import React from "react"
import { Card, Row, Col, Button, Header, Space } from "uiComponents"

export const AdBanner = ({ data, small, active }) => {
  return (
    <>
      <Card>
        <Row>
          <Col sm={12} md={1}>
            {active && <p>ID: #892720</p>}
            <div>
              <img
                className={`profile-img ${small ? "small-size" : ""}`}
                src='https://i.pinimg.com/originals/e0/a8/c8/e0a8c89f347b36f1fb39708a04b6df6b.png'
                alt=''
              />
            </div>
          </Col>
          <Col sm={12} md={8}>
            <Header
              md={small ? false : true}
              sm={small ? true : false}
              bold
              accent
            >
              Cusine Crafter
            </Header>
            <Header xl={small ? false : true} md={small ? true : false} bold>
              {data.headline}
            </Header>
            <p className={`description-text ${small ? "small-size" : ""}`}>
              {data.description}
            </p>
            {active && (
              <>
                <Space lg />
                <Space lg />
                <div>
                  <Button noMar fullRound extraPad>
                    Edit Ad
                  </Button>
                  <Button noMar fullRound extraPad alt>
                    View Analytics
                  </Button>
                </div>
              </>
            )}
          </Col>
          <Col sm={12} md={3}>
            <div className={`image-container ${small ? "small-size" : ""}`}>
              <img
                className={`ad-img ${small ? "small-size" : ""}`}
                src={data.banner}
                alt='Logo'
              />
            </div>
            <Space lg />
            <Button noMar small={true && small} fullRound fullWidth>
              {data.button_text}
            </Button>
          </Col>
        </Row>
      </Card>
    </>
  )
}

export const AdBannerMobile = ({ data, small, active }) => {
  return (
    <>
      <Card notFull>
        <Row>
          <Col sm={12} md={12}>
            <p>ID: #892720</p>
            <div className={`image-container ${small ? "small-size" : ""}`}>
              <img
                className={`ad-img ${small ? "small-size" : ""}`}
                src={data.banner}
                alt='Logo'
              />
            </div>
          </Col>
          <Space lg />
          <Col sm={12} md={12}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                className={`profile-img ${small ? "small-size" : ""}`}
                src='https://i.pinimg.com/originals/e0/a8/c8/e0a8c89f347b36f1fb39708a04b6df6b.png'
                alt=''
              />
              <Header sm bold accent>
                Cusine Crafter
              </Header>
            </div>
            <Header md bold>
              {data.headline}
            </Header>
            <p className={`description-text ${small ? "small-size" : ""}`}>
              {data.description}
            </p>
            {active && (
              <>
                <Space lg />
                <Space lg />
                <div>
                  <Button noMar fullRound extraPad>
                    Edit Ad
                  </Button>
                  <Button noMar fullRound extraPad alt>
                    View Analytics
                  </Button>
                </div>
              </>
            )}
          </Col>
          <Col sm={12} md={12}>
            <Space lg />
            <Button small noMar fullRound fullWidth>
              {data.button_text}
            </Button>
            <Space lg />
          </Col>
        </Row>
      </Card>
    </>
  )
}
