import { addDataToDb, DB } from "firebaseUtils"
import { FormMaker } from "formComponents"
import { getSVG } from "getSVG"
import React, { useState } from "react"
import { Link } from "react-router-dom/cjs/react-router-dom.min"
import { AppPaths } from "shoppio-constants"
import { useStateValue } from "state"
import { Button, Header, Space } from "uiComponents"

const UserTicketForm = ({ setTicketDialog }) => {
  const [{ currentStore, user, userMeta }, dispatch] = useStateValue()
  const [busy, setBusy] = useState(false)
  const [success, setSuccess] = useState()
  console.log("Current store........", currentStore)
  const storeID = currentStore.id
  const storeName = currentStore.store_name

  const addToUserMeta = doc => {
    // console.log("Docs", doc);
    DB.collection(`users_meta`).doc(user?.uid).collection("tickets").add(doc)
  }

  const handleSubmit = async values => {
    const url = "store_tickets"
    console.log("Form submitted:", values)
    try {
      setBusy(true)
      const result = await addDataToDb(
        url,
        {
          ...values,
          status: "Open",
        },
        dispatch,
        "Ticket added successfully!",
        doc => {
          console.log("Document saved successfully:", doc)
          addToUserMeta(
            { key: doc.id, value: values.name, type: "store" },
            "tickets",
          )
        },
      )

      if (result === "success") {
        console.log("Ticket added successfully!")
      }

      setBusy(false)
      setSuccess(true)
    } catch (error) {
      console.error("Error adding ticket:", error)
    }
  }

  return (
    <>
      {!success ? (
        <div>
          <Header md bold>
            Need help, raise a query
          </Header>
          <p>
            Do you need help, or stuck or have a suggestion, we’ll get back to
            you as soon as possible
          </p>
          <Space lg />
          <FormMaker
            fields={[
              {
                name: "name",
                type: "text",
                label: "Ticket Name",
                fullWidth: true,
              },
              {
                name: "description",
                type: "textarea",
                label: "Description",
                fullWidth: true,
                rows: 5,
              },
              {
                name: "store_id",
                type: "text",
                hidden: true,
                value: storeID,
              },
              {
                name: "store_name",
                type: "text",
                hidden: true,
                value: storeName,
              },
              {
                name: "created_by",
                type: "text",
                hidden: true,
                value: userMeta?.name,
              },
            ]}
            submitLabel='Create Ticket'
            onSubmit={handleSubmit}
            busy={busy}
          />
        </div>
      ) : (
        <>
          <Space lg />
          <Space lg />
          <Space lg />
          <Header md bold>
            Thank you for your ticket.
          </Header>
          <p>We will get back to you as soon as possible.</p>
          <Space lg />
          <Link to={`${AppPaths.QUERY_RESOLUTION}#store`}>
            <Button onClick={() => setTicketDialog(false)}>View Ticket</Button>
          </Link>
          <div>
            {getSVG(
              "Confirmed-cuate",
              { width: "100%", height: "25%" },
              null,
              true,
            )}
          </div>
        </>
      )}
    </>
  )
}

export default UserTicketForm
