import { Field, Form, Formik } from "formik"
import React, { useEffect, useState } from "react"
import ReactDOM from "react-dom"
import {
  Col,
  Row,
  Space,
  Button,
  Header,
  DynamicFields,
  Highlight,
  LocationMapper,
} from "uiComponents"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { DummyData } from "dummyData"
import { deepCopy, env, getTime, isDev } from "helpers"
import { ImageUploader } from "./ImageUploader/ImageUploader"
import {
  CheckboxInput,
  DateTimePicker,
  DurationInput,
  PriceInput,
  InputField,
  NumberField,
  PhoneField,
  Radios,
  Range,
  SelectField,
  TextArea,
  DatePicker,
  FileUploader,
  TextWithAttach,
  VideoUploader,
  StandaloneImageUploader,
} from "formComponents"
import { getSVG } from "getSVG"
import { MultiSelectChips } from "./FormComponents"

export const FormMaker = ({
  initialValues,
  onSubmit,
  submitDisabled,
  submitLabel,
  buttonFull,
  formName,
  handleChange,
  onChange,
  validation,
  callback = () => null,
  onCancel,
  autoFill,
  secAction,
  validateOnMount,
  showBack,
  desc,
  svgImage,
  disableSubmit,
  extraErrors,
  extraComponent,
  mergeHiddenFields,
  busy,
  preview,
  hideSubmit,
  inPortalElement,
  noMar,
  ...props
}) => {
  // console.log("formName", formName);
  const [oldValues, setOldValues] = useState({})
  const fields =
    (typeof props.fields === "function"
      ? props.fields(oldValues)
      : props.fields) || []

  const history = useHistory()
  const getComponentFromType = t => {
    if (t === "checkbox") {
      return CheckboxInput
    } else if (t === "radio") {
      return Radios
    } else if (t === "text") {
      return InputField
    } else if (t === "number") {
      return NumberField
    } else if (t === "range") {
      return Range
    } else if (t === "select") {
      return SelectField
    } else if (t === "multiselect") {
      return MultiSelectChips
    } else if (t === "textarea") {
      return TextArea
    } else if (t === "phoneNumber") {
      return PhoneField
    } else if (t === "datetime") {
      return DateTimePicker
    } else if (t === "duration") {
      return DurationInput
    } else if (t === "date") {
      return DatePicker
    } else if (t === "file") {
      return FileUploader
    } else if (t === "price") {
      return PriceInput
    } else if (t === "message") {
      return TextWithAttach
    } else {
      return InputField
    }
  }

  useEffect(() => {
    if (env === "production") {
      window.addEventListener("beforeunload", handleunload)
    }

    return () => window.removeEventListener("beforeunload", handleunload)
  }, [])

  const handleunload = e => {
    // Cancel the event
    e.preventDefault()
    // Standard-compliant browsers
    e.returnValue = ""
    // Older browsers
    return ""
  }

  // console.log('initialValues', initialValues)

  return !fields ? (
    <div />
  ) : (
    <Formik
      initialTouched={{
        field: true,
      }}
      enableReinitialize
      initialValues={
        initialValues
          ? initialValues
          : isDev && autoFill
          ? DummyData[autoFill]
          : {}
      }
      validateOnMount={validateOnMount}
      validationSchema={
        validation ? validation(props, fields, oldValues) : null
      }
      onSubmit={(values, { setSubmitting, resetForm }) => {
        let extraData = {}
        if (mergeHiddenFields || fields?.filter(f => f.hidden)?.length) {
          const hiddenFields = fields && fields.filter(f => f.hidden)
          hiddenFields.forEach(f => {
            extraData[f.name] = f.value
          })
        }
        console.log("LOLOLL", values)
        let time = initialValues
          ? {
              updatedAt: getTime(),
            }
          : {
              createdAt: getTime(),
            }
        let formValuesFinal = {
          ...values,
          ...time,
          ...extraData,
        }

        if (onSubmit) onSubmit(formValuesFinal)
        setSubmitting(false)
        if (callback) callback()
        resetForm({ values: initialValues })
      }}
    >
      {formProps => {
        if (
          JSON.stringify(oldValues) !== JSON.stringify(formProps.values) &&
          handleChange
        ) {
          handleChange({ ...formProps.values })
          setOldValues(deepCopy(formProps.values))
        }
        const formContent = (
          <Row style={{ width: "100%" }}>
            <Col xs={12} sm={12} md={preview ? 8 : 12} lg={preview ? 8 : 12}>
              <Form onChange={onChange}>
                {formName && (
                  <Row middle='xs'>
                    <Col xs={12} md={10}>
                      <Header lg bold accent>
                        {formName}
                      </Header>
                      <Header>{desc}</Header>
                    </Col>

                    {desc ? <Space lg /> : ""}
                    <Col xs={12} md={2}>
                      {svgImage && getSVG(svgImage, null, null, true)}
                    </Col>
                  </Row>
                )}

                <Row style={{ paddingTop: !noMar && "2rem" }}>
                  {fields &&
                    fields.map((f, k) => {
                      return f.type === "dynamic" ? (
                        <Col key={k} xs={12} sm={12} md={12}>
                          <Space />
                          <DynamicFields
                            onChange={data => {
                              // console.log("Change ata")
                              formProps.setFieldValue(f?.name, data)
                              if (f?.onChange) f?.onChange(data)
                            }}
                            info={f.info}
                            label={f.label}
                            desc={f.desc}
                            value={
                              formProps?.values && formProps?.values[f.name]
                            }
                            errorText={
                              formProps?.values && formProps?.errors[f.name]
                            }
                          />
                          <Space />
                          <Space />
                          <Space />
                          <Space />
                          <Space />
                        </Col>
                      ) : f.type === "location" ? (
                        <Col
                          key={k}
                          xs={12}
                          sm={12}
                          md={f.fullWidth ? 12 : f.small ? 4 : 6}
                        >
                          {console.log("formProps?.values", formProps?.values)}
                          <LocationMapper
                            onChange={place => {
                              formProps.setFieldValue(f?.name, place)
                            }}
                            info={f.info}
                            label={f.label}
                            value={
                              formProps?.values && formProps?.values[f.name]
                            }
                            errorText={
                              formProps?.values && formProps?.errors[f.name]
                            }
                          />
                          <Space />
                          <Space />
                          <Space />
                          <Space />
                          <Space />
                        </Col>
                      ) : f.type === "video" ? (
                        <Col key={k} xs={12} sm={12} md={f.fullWidth ? 12 : 6}>
                          <Space />
                          <VideoUploader
                            onUpdate={([video]) => {
                              // formProps.setFieldValue(
                              //   f?.name,
                              //   video ? video["data_url"] : null,
                              // )
                              // formProps.setFieldValue(
                              //   f?.name + "_file",
                              //   image ? image["file"] : null,
                              // )
                            }}
                            onDelete={img =>
                              formProps.setFieldValue(f?.name, null)
                            }
                            pathToUpload={"/"}
                            maxNumber={1}
                            info={f.info}
                            label={f.label}
                            value={
                              formProps?.values && formProps?.values[f.name]
                            }
                            errorText={
                              formProps?.values && formProps?.errors[f.name]
                            }
                          />
                          <Space />
                          <Space />
                          <Space />
                          <Space />
                          <Space />
                        </Col>
                      ) : f.type === "pic" ? (
                        <Col key={k} xs={12} sm={12} md={f.fullWidth ? 12 : 6}>
                          <Space />
                          <StandaloneImageUploader
                            showUploadButton
                            onUpload={([imagePath]) => {
                              formProps.setFieldValue(f?.name, imagePath)
                            }}
                            onDelete={img =>
                              formProps.setFieldValue(f?.name, null)
                            }
                            pathToUpload={"/"}
                            maxNumber={1}
                            info={f.info}
                            label={f.label}
                            value={
                              formProps?.values && formProps?.values[f.name]
                            }
                            errorText={
                              formProps?.values && formProps?.errors[f.name]
                            }
                          />
                          <Space />
                          <Space />
                          <Space />
                          <Space />
                          <Space />
                        </Col>
                      ) : f.type === "pics" ? (
                        <Col key={k} xs={12} sm={12} md={f.fullWidth ? 12 : 6}>
                          <Space />
                          <ImageUploader
                            onUpdate={images => {
                              console.log("onUpdate", images)
                              formProps.setFieldValue(f?.name, images)
                            }}
                            onDelete={images => {
                              f?.onDelete && f?.onDelete(images)
                              formProps.setFieldValue(f?.name, images)
                            }}
                            info={f.info}
                            label={f.label}
                            value={
                              formProps?.values && formProps?.values[f.name]
                            }
                            errorText={
                              formProps?.values && formProps?.errors[f.name]
                            }
                          />
                          <Space />
                          <Space />
                          <Space />
                          <Space />
                          <Space />
                        </Col>
                      ) : f.type === "handle" ? (
                        <></>
                      ) : f.group ? (
                        <Col key={k} xs={12}>
                          <Space />
                          <Space />
                          <Header sm bold>
                            {f.group}
                          </Header>
                          {f.desc}
                          <Space />
                          {f.desc ? <Space lg /> : ""}
                        </Col>
                      ) : (
                        <Col
                          key={k}
                          xs={12}
                          sm={12}
                          md={f.fullWidth ? 12 : f.small ? 4 : 6}
                        >
                          {!f.hidden && (
                            <>
                              <Space />
                              {f.name ? (
                                <Field
                                  {...f}
                                  name={f.name}
                                  label={f.label}
                                  dataTip={f.dataTip || f.info}
                                  desc={f.desc}
                                  loading={f?.loading || f?.busy}
                                  disabled={
                                    f?.loading || f?.busy || f?.disabled
                                  }
                                  onChange={f?.onChange}
                                  onBlur={f?.onBlur}
                                  component={getComponentFromType(f.type)}
                                  max={f.max}
                                  min={f.min}
                                  step={f.step}
                                  value={f?.value}
                                  initialValue={f?.initialValue}
                                  options={f.options}
                                  inline={f.inline}
                                  row={f.row}
                                  isValidDate={f.isValidDate}
                                  withButton={f.withButton}
                                  small={f.small}
                                  noMar={f.noMar}
                                  onFilesChange={f.onFilesChange}
                                >
                                  {f.children}
                                </Field>
                              ) : (
                                ""
                              )}
                              {f.extra}
                              <Space />
                              <Space />
                            </>
                          )}
                        </Col>
                      )
                    })}
                  {!inPortalElement ? <Space /> : ""}
                </Row>
                <Row>
                  {extraErrors?.length > 0 ? (
                    <Col xs={12}>
                      <Highlight type='danger'>
                        <ul>
                          {extraErrors.map((s, i) => (
                            <li key={i}>{s}</li>
                          ))}
                        </ul>
                      </Highlight>
                      <Space lg />
                    </Col>
                  ) : (
                    " "
                  )}
                  {/* {console.log(
                      "Disabled Condition",
                      !formProps?.isValid,
                      formProps,
                      disableSubmit,
                      extraErrors?.length > 0,
                    )} */}
                  {extraComponent && extraComponent(formProps)}
                  <Col xs={12}>
                    {!hideSubmit ? (
                      <Button
                        fullWidth={buttonFull && true}
                        submit
                        marRight
                        loading={formProps.isSubmitting || busy}
                        disabled={
                          !formProps?.isValid ||
                          disableSubmit ||
                          extraErrors?.length > 0
                        }
                        colored
                      >
                        {submitLabel || "Submit"}
                      </Button>
                    ) : (
                      ""
                    )}
                    {onCancel && (
                      <Button accent onClick={onCancel}>
                        Cancel
                      </Button>
                    )}
                    {secAction && (
                      <Button
                        accent
                        danger={secAction.danger}
                        warn={secAction.danger}
                        onClick={secAction.onClick}
                        disabled={secAction.disabled}
                      >
                        {secAction.label}
                      </Button>
                    )}
                    {showBack && (
                      <Button accent onClick={() => history.goBack()}>
                        Back
                      </Button>
                    )}
                  </Col>
                </Row>
                {!inPortalElement ? <Space /> : ""}
                {!inPortalElement ? <Space /> : ""}
                {!inPortalElement ? <Space /> : ""}
              </Form>
            </Col>
            <Col xs={12} sm={12} md={4} lg={3} lgOffset={1}>
              {preview ? preview(formProps?.values) : ""}
            </Col>
          </Row>
        )
        return inPortalElement
          ? ReactDOM.createPortal(formContent, inPortalElement)
          : formContent
      }}
    </Formik>
  )
}
