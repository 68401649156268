import { useEffect, useState } from "react"
import { getOrCreateMeta } from "../Routes"
import { useStateValue } from "state"
import { requestNotificationPermission, StoreSelector } from "appComponents"
import { auth } from "firebaseUtils"
import { afterLogout, isDev, isMobile } from "helpers"
import { ShoppioUserTypes } from "config"
import { AppPaths, getPath } from "shoppio-constants"

export const useSetAuth = () => {
  const [{ envs }, dispatch] = useStateValue()
  useEffect(() => {
    dispatch({
      type: "GENERAL",
      data: {
        checkingAuth: true,
      },
    })
    auth.onAuthStateChanged(authUser => {
      if (authUser) {
        if (authUser.email) {
          const isSuperAdmin =
            process.env.REACT_APP_ADMIN_EMAIL &&
            process.env.REACT_APP_ADMIN_EMAIL.split(",").includes(
              authUser?.email,
            )
          dispatch({
            type: "SET_SUPER_ADMIN",
            isSuperAdmin: isSuperAdmin,
          })
        }
        if (authUser.phoneNumber) {
          const isSuperAdmin = isDev
            ? process.env.REACT_APP_ADMIN_PHONE &&
              process.env.REACT_APP_ADMIN_PHONE.split(",").includes(
                authUser?.phoneNumber,
              )
            : envs?.REACT_APP_ADMIN_PHONE.split(",").includes(
                authUser?.phoneNumber,
              )
          dispatch({
            type: "SET_SUPER_ADMIN",
            isSuperAdmin: isSuperAdmin,
          })
        }
        // console.log("Authenticated user", authUser)
        getOrCreateMeta(authUser.uid, dispatch)
        requestNotificationPermission()
        // the user just logged in / the user was logged in
        dispatch({
          type: "SET_USER",
          user: authUser,
        })
        dispatch({
          type: "UNSET_BUSY",
        })
      } else {
        // the user is logged out
        dispatch({
          type: "SET_USER",
          user: null,
        })
        dispatch({
          type: "UNSET_BUSY",
        })
        dispatch({
          type: "GENERAL",
          data: {
            checkingAuth: false,
          },
        })
        afterLogout()
      }
    })
  }, [dispatch, envs?.REACT_APP_ADMIN_PHONE])
}

export const useIsSeller = () => {
  const [{ userMeta }] = useStateValue()
  const [isSeller, setIsSeller] = useState(false)
  useEffect(() => {
    setIsSeller(userMeta?.type === ShoppioUserTypes.SELLER || userMeta?.seller)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userMeta])

  return isSeller
}

export const useMenuItems = () => {
  const [{ busyProcesses, ...state }] = useStateValue()
  const [busy, setBusy] = useState(true)
  const [menuItems, setMenuItems] = useState([])
  const isSeller = useIsSeller()
  useEffect(() => {
    setBusy(true)

    if (isSeller) {
      let menuItems = [
        {
          label: "Dashboard",
          url: AppPaths.SELLER_DASHBOARD,
          iconClass: "fas fa-home",
        },
        {
          label: "Analytics",
          url: AppPaths.STORE_ANALYTICS,
          iconClass: "fas fa-chart-pie",
        },
        {
          label: "Account",
          url: AppPaths.ACCOUNT_SETUP,
          iconClass: "fas fa-user",
        },
        {
          label: "KYC status",
          url: AppPaths.KYC_STATUS,
          iconClass: "fas fa-user-check",
        },
        {
          label: "Customers",
          url: AppPaths.CUSTOMERS_PAGE,
          iconClass: "fas fa-users",
        },
        {
          label: "Messages",
          url: AppPaths.CUSTOMER_CHAT,
          iconClass: "fas fa-comments",
        },
        {
          label: "Membership",
          url: AppPaths.PLANS,
          iconClass: "fas fa-award",
        },

        {
          label: "Logistics",
          url: AppPaths.SHIPPING_LOGISTICS,
          iconClass: "fas fa-truck",
        },
        {
          label: "Integrations",
          url: AppPaths.INTEGRATIONS,
          iconClass: "fas fa-code-branch",
        },
        {
          label: "Pricing",
          url: AppPaths.PRICING_BILLING,
          iconClass: "fas fa-rupee-sign",
        },

        {
          label: "Store",
          group: true,
          component: (
            <>
              <StoreSelector />
            </>
          ),
        },
        {
          label: "Store",
          url: getPath(AppPaths.STORE_MANAGEMENT, {
            storeid: state.currentStoreID,
          }),
          scopedOn: "currentStoreID",
          iconClass: "fas fa-store",
        },
        {
          label: "Theme",
          url: AppPaths.THEME,
          scopedOn: "currentStoreID",
          iconClass: "fas fa-paint-brush",
        },

        {
          label: "Native Apps",
          url: AppPaths.NATIVE_APPS,
          scopedOn: "currentStoreID",

          iconClass: "fas fa-mobile-alt",
        },

        {
          label: "Marketing",
          url: AppPaths.MARKETING,
          scopedOn: "currentStoreID",
          iconClass: "fas fa-poll",
        },

        {
          label: "Rate & Review",
          url: AppPaths.REVIEW_RATING,
          scopedOn: "currentStoreID",
          iconClass: "fas fa-star-half-alt",
        },
      ].map((item, i) => {
        return {
          ...item,
          disabled: item.scopedOn && !state[item.scopedOn],
          url:
            item.url +
            (item.scopedOn && state[item.scopedOn]
              ? `/${state[item.scopedOn]}`
              : ""),
        }
      })

      const mobileData = [
        {
          label: "Account",
          url: AppPaths.HOME_PAGE,
          iconClass: "fas fa-user",
        },
        //  {
        //    label: themeLabel,
        //    url: "#",
        //    iconClass: themeIcon,
        //    onClick: toggleTheme,
        //  },
      ]

      if (isMobile) {
        menuItems.push(...mobileData)
      }
      setMenuItems(menuItems)
      setBusy(false)
    } else {
      setBusy(false)
      setMenuItems([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSeller, state["currentStoreID"]])
  return { menuItems, busy }
}
