import { AvatarChip } from "appComponents/QueryResolution/QueryResolution"
import { assignee } from "dummyData"
import { updateDataToDb } from "firebaseUtils"
import { FormMaker } from "formComponents"
import { getAvatarColor } from "helpers"
import { useAlerter } from "hooks"
import React, { useState } from "react"
import { useStateValue } from "state"
import { Button, Card, Col, Header, Row, Space } from "uiComponents"

const TaskDetails = ({ taskData, id, setTaskDialog, refreshDoc }) => {
  const [showImage, setShowImage] = useState(false)
  const [busy, setBusy] = useState(false)
  const [, dispatch] = useStateValue()
  const { alerter } = useAlerter()

  const handleClick = () => {
    setShowImage(!showImage)
  }
  const taskImgs = [
    {
      id: 1,
      src: "https://picsum.photos/500/300?grayscale",
    },
    {
      id: 2,
      src: "https://picsum.photos/500/300?grayscale",
    },
    {
      id: 3,
      src: "https://picsum.photos/500/300?grayscale",
    },
    {
      id: 4,
      src: "https://picsum.photos/500/300?grayscale",
    },
    {
      id: 5,
      src: "https://picsum.photos/500/300?grayscale",
    },
  ]
  const handleSubmit = async newData => {
    console.log("Submitted Data", newData)
    try {
      setBusy(true)
      await updateDataToDb(
        "tasks",
        id,
        newData,
        dispatch,
        "Task updated successfully",
        () => {
          refreshDoc()
        },
      )
      setBusy(false)
      setTaskDialog(false)
    } catch (error) {
      console.error("Error updating data:", error)
      alerter("Task update failed", "error")
      setBusy(false)
    }
  }
  return (
    <>
      <div style={{ display: "flex", gap: "10px" }}>
        <AvatarChip large label={"AR"} color={getAvatarColor("Amit")} />
        <div>
          <Header bold sm>
            {taskData.name}
          </Header>
          <p>{taskData.description}</p>
        </div>
      </div>
      <Space lg />
      <Space lg />
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} xl={3}>
          <Header bold>Task Details</Header>
          <Space lg />
          <FormMaker
            initialValues={taskData}
            onSubmit={handleSubmit}
            busy={busy}
            submitLabel={"Update Task"}
            fields={[
              {
                name: "priority",
                label: "Priority",
                type: "select",
                children: ["high", "urgent", "medium", "low"].map(op => (
                  <option key={op} value={op}>
                    {op}
                  </option>
                )),
                fullWidth: true,
              },
              {
                name: "status",
                label: "Status",
                type: "select",
                children: [
                  "not picked",
                  "on hold",
                  "delayed",
                  "work in progress",
                  "completed",
                ].map(op => <option value={op}>{op}</option>),
                fullWidth: true,
              },
              {
                name: "due_date",
                label: "Due Date",
                type: "datetime",
                fullWidth: true,
              },
              {
                name: "assignee",
                label: "Assignee",
                type: "select",
                children: assignee.map(op => (
                  <option key={op.email} value={op.email}>
                    {op.value} ({op.role})
                  </option>
                )),
                fullWidth: true,
              },
              {
                name: "task_complexity",
                label: "Task Complexity",
                type: "select",
                children: [
                  "Very Easy",
                  "Easy",
                  "Medium",
                  "Hard",
                  "Very Hard",
                ].map(op => (
                  <option key={op} value={op}>
                    {op}
                  </option>
                )),
                fullWidth: true,
              },
            ]}
          />
        </Col>
        <Col
          xs={12}
          sm={6}
          md={12}
          lg={12}
          xl={9}
          style={{
            borderLeft: "1px solid var(--border-color)",
            position: "relative",
            height: "75vh",
          }}
        >
          <Header bold>Task Comments</Header>

          <Card lessHorPad noXS notFull>
            <div style={{ display: "flex" }}>
              <AvatarChip
                large
                label={"AR"}
                style={{ marginRight: "10px" }}
                color='#B93E2E'
              />
              <div>
                <p style={{ marginRight: "5rem" }}>
                  Vorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
                </p>
                {showImage && (
                  <div className='tasks-messages-container-image'>
                    {taskImgs.map(img => (
                      <img
                        key={img.id}
                        src={img.src}
                        alt=''
                        className='tasks-messages-image'
                      />
                    ))}
                  </div>
                )}
              </div>
              <span
                onClick={handleClick}
                className='fas fa-paperclip attach-icon'
              />
            </div>
          </Card>
          <div className='task-message-input-container'>
            <div
              className='message-input-container-subchildren'
              style={{
                backgroundColor: "var(--foreground)",
                height: "7rem",
                marginLeft: "10px",
              }}
            >
              <input
                type='text'
                className='task-message-input'
                placeholder='Type your message...'
              />
            </div>
            <Button noMar extraPad onClick={{}}>
              Send
            </Button>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default TaskDetails
