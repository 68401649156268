import { Col, Button, Header, Expander } from "uiComponents"
import { Link } from "react-router-dom/cjs/react-router-dom.min"
import { AppPaths } from "shoppio-constants"

const socialMediaData = [
  {
    icon: "fab fa-lg fa-facebook-f",
  },
  {
    icon: "fab fa-lg fa-youtube",
  },
  {
    icon: "fab fa-lg fa-instagram",
  },
]

export const FooterLinkSection = ({ title, links }) => (
  <Col sm={12} md={3}>
    <Header md themed>
      {title}
    </Header>
    {links.map((link, index) => (
      <p className='footer-link' key={index}>
        <Link to={link.link}>{link.title}</Link>
      </p>
    ))}
  </Col>
)

export const FooterLinkSectionMobile = ({ title, links }) => (
  <Col sm={12} md={3} style={{ marginBottom: "10px" }}>
    <Expander showOnMobile themed title={title}>
      <div>
        {links.map((link, index) => (
          <p className='footer-link' key={index}>
            <Link to={link.link}>{link.title}</Link>
          </p>
        ))}
      </div>
    </Expander>
  </Col>
)

export const SocialMediaButtons = () => (
  <>
    <div>
      {socialMediaData.map((item, index) => (
        <Button
          key={index}
          style={{
            backgroundColor: "var(--font-color)",
          }}
          circle
        >
          <i
            style={{
              fontSize: "20px",
              color: "var(--background)",
            }}
            className={item.icon}
          ></i>
        </Button>
      ))}
    </div>
  </>
)

export const footerData = [
  {
    title: "Company",
    links: [
      { title: "About Us", link: AppPaths.ABOUT_US },
      { title: "Contact Us", link: AppPaths.CONTACT_US },
      { title: "Policies", link: AppPaths.POLICIES },
      { title: "Terms", link: AppPaths.TERMS },
      { title: "Cancellation & Refund", link: AppPaths.CANCELLATION_REFUND },
      { title: "Shipping & Delivery", link: AppPaths.SHIPPING_DELIVERY },
    ],
  },
  {
    title: "Help",
    links: [
      { title: "Queries", link: AppPaths.QUERIES },
      { title: "FAQs", link: AppPaths.FAQS },
    ],
  },
  {
    title: "Account",
    links: [
      { title: "Sign Up", link: AppPaths.SIGNUP },
      { title: "Plans", link: AppPaths.PLANS },
      { title: "Shipping Logistics", link: AppPaths.SHIPPING_LOGISTICS },
      { title: "Account Setup", link: AppPaths.ACCOUNT_SETUP },
    ],
  },
]
