import React from "react"
import { Col, Row, Space, Button, Card, Header } from "uiComponents"
import { isMobile } from "helpers"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"

export const CardSection = ({ header, content, buttons, svg, themed }) => {
  const history = useHistory()
  return (
    <Col sm={12} md={12}>
      <Card themed={themed ? true : false}>
        <Row middle='xs'>
          <Col sm={12} md={8}>
            <div>{isMobile && svg}</div>

            <Header themed={themed ? true : false} lg bold>
              {header}
            </Header>
            <Space lg />
            <Space />
            <p style={{ fontSize: "15px", fontWeight: "500" }}>{content}</p>
            <Space lg />
            <Space lg />
            {buttons.map((button, index) => (
              <Button
                fullWidth={isMobile}
                key={index}
                extraPad
                fullRound
                alt={button.alt}
                onClick={() => history.push(button.onClick)}
              >
                {button.label}
              </Button>
            ))}
          </Col>
          {!isMobile && svg && (
            <Col sm={12} md={4}>
              <div>{svg}</div>
            </Col>
          )}
        </Row>
      </Card>
    </Col>
  )
}

export const CardSectionSecound = ({
  header,
  content,
  buttons,
  svg,
  themed,
}) => {
  const history = useHistory()

  return (
    <Col sm={12} md={12}>
      <Card themed={themed ? true : false}>
        <Row middle='xs'>
          {svg && (
            <Col sm={12} md={4}>
              <div>{svg}</div>
            </Col>
          )}
          <Col sm={12} md={8}>
            <Header themed={themed ? true : false} lg bold>
              {header}
            </Header>
            <Space lg />
            <Space />
            <p style={{ fontSize: "15px", fontWeight: "500" }}>{content}</p>
            <Space lg />
            <Space lg />
            {buttons.map((button, index) => (
              <Button
                fullWidth={isMobile}
                key={index}
                extraPad
                fullRound
                alt={button.alt}
                onClick={() => history.push(button.onClick)}
              >
                {button.label}
              </Button>
            ))}
          </Col>
        </Row>
      </Card>
    </Col>
  )
}

export const CardWithSVG = ({ header, content, buttonLabel, svg }) => {
  return (
    <Col sm={12} md={4}>
      <Card>
        <div>{isMobile && svg}</div>
        <Header lg bold>
          {header}
        </Header>
        <Space lg />
        <p style={{ fontSize: "15px", fontWeight: "500" }}>{content}</p>
        <Space lg />
        <Button extraPad fullWidth={isMobile} fullRound alt>
          {buttonLabel}
        </Button>
        <div>{!isMobile && svg}</div>
      </Card>
    </Col>
  )
}
