import React from "react"
import "./CheckoutProduct.css"
import { useStateValue } from "state"
import { CurrencySign } from "config"
import { Row, Col, Tag } from "uiComponents"
import {
  currencyformatter,
  getDateAfterNDays,
  getHoursAndMinutesFromMinutes,
  getThumbnailFromProduct,
  parseDate,
  parseDateTime,
} from "helpers"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { getSingularFromPlural } from "dictionary"
import { AppPaths, getPath } from "shoppio-constants"

export const SimpleIcon = ({ icon }) => (
  <span className={"simple-icon lnr " + icon}></span>
)

function CheckoutProduct(product) {
  const {
    id,
    productName,
    name,
    price,
    brand,
    discount,
    hideButton,
    quantity,
    fromProfile,
    size,
    storeID,
    productId,
    type,
    serviceTime,
    duration,
    variant,
    deliveryTime,
    seller,
  } = product || {}
  const isService = type === "Service"
  const [, dispatch] = useStateValue()
  const history = useHistory()
  let maxDaysToDelivery = isService
    ? 0
    : deliveryTime
    ? getDateAfterNDays(new Date(), deliveryTime)
    : 0

  const removeFromBasket = () => {
    //remove from the basket
    dispatch({
      type: "REMOVE_FROM_BASKET",
      id: id,
    })
  }

  const updateQuantity = sign => {
    // console.log(sign, maxQuantity, 'SIGN');
    if (sign === "minus" && quantity === 1) {
      removeFromBasket()
    } else {
      dispatch({
        type: "UPDATE_PRODUCT",
        sign: sign,
        id: id,
      })
    }
  }
  let santisePrice = price
  const sellingPrice = santisePrice - (discount / 100) * santisePrice
  return (
    <div
      className={"checkoutProduct" + (fromProfile ? " from-profile" : "")}
      style={{
        margin: fromProfile ? "1rem 0" : "",
        padding: fromProfile ? "2rem" : "",
      }}
    >
      <Row middle='xs' style={{ width: "100%" }}>
        <Col xs={3} sm={4} md={4} lg={3}>
          <img
            draggable={false}
            className={
              fromProfile
                ? "checkoutProduct__image checkoutProduct__image_profile"
                : "checkoutProduct__image"
            }
            src={getThumbnailFromProduct(product)}
            alt={name || productName}
            onClick={() =>
              history.push(getPath(AppPaths.PRODUCT_DETAILS), {
                storeid: storeID,
                id: productId,
              })
            }
          />
        </Col>
        <Col xs={8} smOffset={0} sm={8} md={8} lg={9}>
          <div className='checkoutProduct__info'>
            <p
              className='checkoutProduct__title'
              style={{ fontSize: fromProfile ? "2rem" : "" }}
            >
              {name || productName}
            </p>
            <p className='checkoutProduct__brand'>{brand}</p>

            <span className={"product-type " + type}>{type || "Product"}</span>

            <div className='checkoutProduct__extradetails'>
              {variant &&
                Object.keys(variant).map((key, index) => {
                  return (
                    <Tag
                      index={index}
                      hideOn={!variant[key]}
                      dotted
                      text={
                        <span>
                          <span style={{ textTransform: "capitalize" }}>
                            {getSingularFromPlural(key)}
                          </span>
                          : {variant[key]?.name}
                        </span>
                      }
                    />
                  )
                })}
              <Tag
                hideOn={isService || !maxDaysToDelivery}
                dotted
                bold={seller}
                text={<span>Get it by: {parseDate(maxDaysToDelivery)}</span>}
              />

              <Tag
                hideOn={!price}
                bold
                abs={"bottom-right"}
                text={
                  <span>{CurrencySign + currencyformatter(sellingPrice)}</span>
                }
              />

              <Tag
                hideOn={!duration}
                dotted
                text={`Duration: ${getHoursAndMinutesFromMinutes(
                  duration,
                  true,
                )}`}
              />
              <Tag
                hideOn={!serviceTime}
                dotted
                bold={seller}
                text={`Scheduled for: ${parseDateTime(serviceTime)}`}
              />
              <Tag hideOn={!size} text={`Size: ${size}`} />
            </div>
            {!hideButton && (
              <p className='quantity-options'>
                <button
                  onClick={() => {
                    updateQuantity("plus")
                  }}
                >
                  <SimpleIcon icon={"fas fa-plus"} />
                </button>
                {quantity}{" "}
                <button
                  style={{
                    backgroundColor: Number(quantity) > 1 ? "" : "var(--red)",
                  }}
                  onClick={() => {
                    updateQuantity("minus")
                  }}
                >
                  {Number(quantity) > 1 ? (
                    <SimpleIcon icon={"fas fa-minus"} />
                  ) : (
                    <SimpleIcon icon={"fas fa-trash"} />
                  )}
                </button>
              </p>
            )}
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default CheckoutProduct
