import { CollectionRenderer, DocRenderer } from "firestoreComponents"
import React, { useCallback } from "react"
import { Card, Header, Tab, Tabs } from "uiComponents"
import { TicketCard } from "../QueryResolution"
import { useStateValue } from "state"

const UserTickets = ({ setSelectedTicket, setTicketID }) => {
  const [{ user }] = useStateValue()
  const RenderCollection = useCallback(({ filters, docCollection }) => {
    return (
      <CollectionRenderer
        collectionPath={`users_meta/${user?.uid}/tickets`}
        viewType={"custom"}
        filters={filters}
        loaderType={"gen"}
        noDocMessage={"No tickets created yet"}
        renderDoc={({ id, data }) => {
          return (
            <DocRenderer
              key={id}
              collectionPath={docCollection}
              docID={data.key}
              insideCollection
              noDocMessage={"No general tickets created yet"}
              renderDoc={ticketData => {
                return (
                  <TicketCard
                    key={id}
                    ticket={ticketData}
                    onClick={() => {
                      console.log("Ticket clicked:", ticketData)
                      setSelectedTicket({ ...ticketData, id: id })
                    }}
                  />
                )
              }}
            />
          )
        }}
      />
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <>
      <Card lessHorPad noXS>
        <Header md bold>
          Your Tickets
        </Header>
        <Tabs>
          <Tab label='General'>
            <RenderCollection
              docCollection={"tickets"}
              filters={[{ property: "type", operator: "==", value: "general" }]}
            />
          </Tab>
          <Tab label='Store'>
            <RenderCollection
              docCollection={"store_tickets"}
              filters={[{ property: "type", operator: "==", value: "store" }]}
            />
          </Tab>
        </Tabs>
      </Card>
    </>
  )
}

export default UserTickets
