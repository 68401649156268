import Popover from "@mui/material/Popover"
import React, { useEffect, useState } from "react"
import { Col, Grid, Row, Space, Button, Header, NoRecord } from "uiComponents"
import { useHistory } from "react-router-dom"
import { getText } from "dictionary"
import { isMobile } from "helpers"
import { CollectionRenderer } from "firestoreComponents"

import Product from "../Product/Product"
import { useStateValue } from "state"
import "../../styles/theme.css"
import Filters from "./Filters"
import { AppPaths, getPath } from "shoppio-constants"

const openDetails = (data, id, storeID, history) => {
  history.push({
    pathname: getPath(AppPaths.PRODUCT_DETAILS, { storeid: storeID, id: id }),
  })
}

const initPrice = {
  min: 0,
  max: 10000,
}

const ProductList = ({ isAdmin, storeID, categoryID, subcategoryID }) => {
  const [filterOpen, setFilterOpen] = useState(null)
  const [state] = useStateValue()
  // const { storeProducts } = state || {};
  const [storeProducts, setStoreProducts] = useState(state?.storeProducts || [])
  const history = useHistory()
  const [busy] = useState()

  //Filter states
  const [price, setPrice] = useState(initPrice)
  const [rating, setRating] = useState(0)
  const [discount, setDiscount] = useState(0)
  const [priceFilterChanged, setPriceFilterChanged] = useState(false)
  const [filterOptions, setFilterOptions] = useState([])

  // console.log("Store", id);
  const onFilterClick = async () => {
    if (priceFilterChanged) {
      setFilterOptions([
        {
          property: "price",
          value: price?.max,
          operator: "<=",
        },
        {
          property: "price",
          value: price?.min,
          operator: ">=",
        },
      ])
    }
    if (rating) {
      setFilterOptions([
        {
          property: "rating",
          value: rating,
          operator: ">=",
        },
      ])
    }
    if (discount) {
      setFilterOptions([
        {
          property: "discount",
          value: discount,
          operator: ">=",
        },
      ])
    }
    setFilterOpen(null)
  }

  const onFilterClose = () => setFilterOpen(null)

  useEffect(() => {
    state?.storeProducts && setStoreProducts(state?.storeProducts)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.storeProducts, filterOptions])

  const onClearClick = () => {
    setPrice(initPrice)
    setRating(0)
    setDiscount(0)
    setFilterOpen(null)
    setFilterOptions([])
    fetch(true)
  }

  const statusFilter = isAdmin
    ? []
    : [
        {
          property: "status",
          value: "listed",
          operator: "==",
        },
      ]

  const categoryFilter = !categoryID
    ? []
    : [
        {
          property: "category",
          value: categoryID,
          operator: "==",
        },
      ]
  const subcategoryFilter = !subcategoryID
    ? []
    : [
        {
          property: "subcategory",
          value: subcategoryID,
          operator: "==",
        },
      ]
  const filterValues = filterOptions ? filterOptions.map(f => f?.value) : []
  console.log("Filters", filterValues)
  const list = () => (
    <CollectionRenderer
      collectionPath={`products/${storeID}/store_products`}
      variable={
        subcategoryID
          ? `storeProducts_${storeID}_${categoryID}_${subcategoryID}`
          : categoryID
          ? `storeProducts_${storeID}_${categoryID}`
          : "storeProducts_" + storeID
      }
      loaderType={"product"}
      limit={4}
      viewType={"custom"}
      filters={[
        ...statusFilter,
        ...categoryFilter,
        ...subcategoryFilter,
        ...filterOptions,
      ]}
      noDocComponent={
        <div style={{ padding: "0 1rem" }}>
          <Space />
          {!busy &&
            (storeProducts?.length === 0 || !storeProducts) &&
            isAdmin && (
              <NoRecord type={"general"} svg={"product"}>
                <Header md bold>
                  Add A Product/Service
                </Header>
                {getText("noProduct")}
                <Button
                  onClick={() =>
                    history.push(
                      getPath(AppPaths.ADD_PRODUCT, { storeid: storeID }),
                    )
                  }
                >
                  Add Now
                </Button>
              </NoRecord>
            )}
          {!busy &&
            (storeProducts?.length === 0 || !storeProducts) &&
            !isAdmin && (
              <NoRecord type={"general"} svg={"product"}>
                <Header md bold>
                  We're sorry
                </Header>
                {getText("noProductCustomer")}
                <Button onClick={() => history.push(`/`)}>
                  Back to Shopping
                </Button>
              </NoRecord>
            )}
        </div>
      }
      renderDoc={({ id, data }, refresh) => (
        <Product
          filterOpen={filterOpen && !isMobile}
          key={id}
          onView={() => {
            openDetails(data, id, storeID, history)
          }}
          id={id}
          refresh={refresh}
          productId={id}
          storeId={storeID}
          title={data.name}
          image={data.images[0]}
          {...data}
        />
      )}
    />
  )
  // console.log("store Product", storeProducts)

  return (
    <div className='Home'>
      <div className='home__container'>
        <div className='home-section'>
          <Grid>
            <Space lg />
            <div className='flex-between'>
              <Header accent uppercase sm bold>
                {subcategoryID || categoryID ? "Products" : "Latest Arrival"}
              </Header>

              {filterOpen ? (
                <span />
              ) : (
                <Button
                  colored
                  circle={isMobile}
                  noMar
                  onClick={() => setFilterOpen(true)}
                >
                  <span>
                    <span
                      className={"fas fa-filter"}
                      style={{ marginRight: isMobile ? 0 : "1rem" }}
                    />
                    {!isMobile && "Filter"}
                  </span>
                </Button>
              )}
            </div>

            <Space lg />
            <Row>
              <Col xs={12} md={filterOpen && !isMobile ? 8 : 12}>
                <Row start='xs'>{list()}</Row>
              </Col>
              {filterOpen && !isMobile ? (
                <Col xs={12} md={4}>
                  <Filters
                    price={price}
                    setPrice={setPrice}
                    rating={rating}
                    setRating={setRating}
                    discount={discount}
                    setDiscount={setDiscount}
                    onFilterClick={onFilterClick}
                    onClearClick={onClearClick}
                    priceFilterChanged={priceFilterChanged}
                    setPriceFilterChanged={setPriceFilterChanged}
                    initPrice={initPrice}
                  />
                </Col>
              ) : (
                ""
              )}
            </Row>
          </Grid>
        </div>
        {isMobile ? (
          <Popover
            anchorEl={filterOpen}
            open={!!filterOpen}
            onClose={onFilterClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Filters
              price={price}
              setPrice={setPrice}
              rating={rating}
              setRating={setRating}
              discount={discount}
              setDiscount={setDiscount}
              onFilterClick={onFilterClick}
              onClearClick={onClearClick}
              priceFilterChanged={priceFilterChanged}
              setPriceFilterChanged={setPriceFilterChanged}
              initPrice={initPrice}
            />
          </Popover>
        ) : (
          ""
        )}
      </div>
    </div>
  )
}

export default ProductList
