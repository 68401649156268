export const rowOneButtonData = [
  {
    id: 1,
    icon: "volleyball-ball",
    text: "Sport",
  },
  {
    id: 2,
    icon: "mobile-alt",
    text: "Cell Phone Store",
  },
  {
    id: 3,
    icon: "book",
    text: "Bookstore",
  },
  {
    id: 4,
    icon: "music",
    text: "Music Store",
  },
  {
    id: 5,
    icon: "store",
    text: "General Store",
  },
  {
    id: 6,
    icon: "toolbox",
    text: "Hardware Store",
  },
  {
    id: 7,
    icon: "syringe",
    text: "Pharmacy",
  },
  {
    id: 8,
    icon: "globe",
    text: "Travel Agency",
  },
  {
    id: 9,
    icon: "dog",
    text: "Pet Store",
  },
]

export const rowTwoButtonData = [
  {
    id: 10,
    icon: "chair",
    text: "Furniture brand",
  },
  {
    id: 11,
    icon: "tshirt",
    text: "Clothing Store",
  },
  {
    id: 12,
    icon: "camera",
    text: "Photo Studio",
  },
  {
    id: 13,
    icon: "utensils",
    text: "Wedding Organiser",
  },
  {
    id: 14,
    icon: "fan",
    text: "Electrical Store",
  },
  {
    id: 15,
    icon: "shoe-prints",
    text: "Footware",
  },
  {
    id: 16,
    icon: "bicycle",
    text: "Bicycle Shop",
  },
  {
    id: 17,
    icon: "palette",
    text: "Art Supplies Shop",
  },
]

export const shoppioBenefitsCardData = [
  {
    step: "1",
    title: "Open your digital studio",
    desc: "Create your digital studio with Shoppio and tell your customers",
    svg: "logistics",
  },
  {
    step: "2",
    title: "Receive & manage bookings",
    desc: "Receive bookings, schedule meetings and fulfil bookings",
    svg: "logistics",
  },
  {
    step: "3",
    title: "Generate revenue",
    desc: "Create your digital studio with Shoppio and tell your customers",
    svg: "logistics",
  },
]
export const buttonRelatedCardsData = {
  1: [
    {
      step: 1,
      title: "Popular Games",
      desc: "Find the latest and greatest games available.",
      svg: "Cricket",
    },
    {
      step: 2,
      title: "Accessories",
      desc: "Browse gaming accessories to enhance your experience.",
      svg: "Basketball",
    },
    {
      step: 3,
      title: "Game Deals",
      desc: "Check out the hottest deals on top games.",
      svg: "Dumbbell_exercise",
    },
  ],
  2: [
    {
      step: 1,
      title: "Latest Smartphones",
      desc: "Explore the newest smartphones on the market.",
      svg: "Mobile_user",
    },
    {
      step: 2,
      title: "Phone Accessories",
      desc: "Find cases, chargers, and other phone accessories.",
      svg: "Mobile_life",
    },
    {
      step: 3,
      title: "Best Deals",
      desc: "Get the best deals on phones and plans.",
      svg: "Discounts",
    },
  ],
  3: [
    {
      step: 1,
      title: "New Arrivals",
      desc: "Discover the latest books to hit the shelves.",
      svg: "Bibliophile",
    },
    {
      step: 2,
      title: "Top Sellers",
      desc: "Check out the best-selling books.",
      svg: "Bookshop",
    },
    {
      step: 3,
      title: "Book Reviews",
      desc: "Read reviews on popular books.",
      svg: "Reading_glasses",
    },
  ],
  4: [
    {
      step: 1,
      title: "Headphones",
      desc: "Explore a range of high-quality headphones for the best audio experience.",
      svg: "Headphone",
    },
    {
      step: 2,
      title: "Top Charts",
      desc: "Find out what's topping the music charts.",
      svg: "Playing_Music",
    },
    {
      step: 3,
      title: "Music Accessories",
      desc: "Browse accessories for your music needs.",
      svg: "jazz_piano",
    },
  ],
  5: [
    {
      step: 1,
      title: "Daily Essentials",
      desc: "Shop for daily necessities at great prices.",
      svg: "Online_Groceries",
    },
    {
      step: 2,
      title: "Seasonal Items",
      desc: "Find seasonal products and special offers.",
      svg: "Watermelon",
    },
    {
      step: 3,
      title: "Home Goods",
      desc: "Explore a variety of home goods.",
      svg: "cake_shop",
    },
  ],
  6: [
    {
      step: 1,
      title: "Tools",
      desc: "Get the right tools for any job.",
      svg: "Maintenance_tool",
    },
    {
      step: 2,
      title: "Hardware Supplies",
      desc: "Browse our selection of hardware supplies.",
      svg: "Electrician",
    },
    {
      step: 3,
      title: "DIY Projects",
      desc: "Find inspiration and supplies for your next project.",
      svg: "Woodworker",
    },
  ],
  7: [
    {
      step: 1,
      title: "Prescriptions",
      desc: "Manage your prescriptions easily.",
      svg: "Contraception_methods",
    },
    {
      step: 2,
      title: "Health Products",
      desc: "Shop for health and wellness products.",
      svg: "Eating_disorder",
    },
    {
      step: 3,
      title: "Consultations",
      desc: "Get consultations with pharmacists.",
      svg: "Medicine",
    },
  ],
  8: [
    {
      step: 1,
      title: "Vacation Packages",
      desc: "Find the best vacation packages.",
      svg: "Flying_around",
    },
    {
      step: 2,
      title: "Travel Guides",
      desc: "Get travel guides and tips.",
      svg: "Travel_insurance",
    },
    {
      step: 3,
      title: "Flight Deals",
      desc: "Discover great deals on flights.",
      svg: "Trip",
    },
  ],
  9: [
    {
      step: 1,
      title: "Pet Food",
      desc: "Shop for high-quality pet food.",
      svg: "pet_food",
    },
    {
      step: 2,
      title: "Pet Accessories",
      desc: "Find accessories for your pets.",
      svg: "pet_care",
    },
    {
      step: 3,
      title: "Veterinary Services",
      desc: "Get veterinary services and advice.",
      svg: "Veterinary",
    },
  ],
  10: [
    {
      step: 1,
      title: "Living Room Furniture",
      desc: "Explore stylish and comfortable furniture for your living room.",
      svg: "House_restyling",
    },
    {
      step: 2,
      title: "Bedroom Furniture",
      desc: "Discover our collection of bedroom furniture.",
      svg: "Hospital_room",
    },
    {
      step: 3,
      title: "Office Furniture",
      desc: "Find the perfect furniture for your home office.",
      svg: "Writing_room",
    },
  ],
  11: [
    {
      step: 1,
      title: "New Arrivals",
      desc: "Check out the latest trends in clothing.",
      svg: "Window_Shopping",
    },
    {
      step: 2,
      title: "Seasonal Collections",
      desc: "Shop for clothing by season.",
      svg: "Choosing_clothes",
    },
    {
      step: 3,
      title: "Best Sellers",
      desc: "Discover our most popular clothing items.",
      svg: "fashion_shop",
    },
  ],
  12: [
    {
      step: 1,
      title: "Portrait Photography",
      desc: "Book a session for professional portrait photography.",
      svg: "Studio_photographer",
    },
    {
      step: 2,
      title: "Event Photography",
      desc: "Capture your special moments with our event photography services.",
      svg: "outdoor_photoshoot",
    },
    {
      step: 3,
      title: "Photo Printing",
      desc: "Print your favorite photos with us.",
      svg: "Printing_invoices",
    },
  ],
  13: [
    {
      step: 1,
      title: "Wedding Planning",
      desc: "Get help planning your perfect wedding.",
      svg: "Wedding",
    },
    {
      step: 2,
      title: "Catering Services",
      desc: "Find top-notch catering for your wedding.",
      svg: "Chef_catering",
    },
    {
      step: 3,
      title: "Decoration Ideas",
      desc: "Explore beautiful decoration ideas for your wedding.",
      svg: "House_decorations",
    },
  ],
  14: [
    {
      step: 1,
      title: "Home Appliances",
      desc: "Shop for essential home appliances.",
      svg: "moving_tv",
    },
    {
      step: 2,
      title: "Electrical Components",
      desc: "Find the electrical components you need.",
      svg: "Smart_home",
    },
    {
      step: 3,
      title: "Energy Efficient Solutions",
      desc: "Discover energy-efficient solutions for your home.",
      svg: "brilliant_ideas",
    },
  ],
  15: [
    {
      step: 1,
      title: "New Arrivals",
      desc: "Check out the latest in footwear fashion.",
      svg: "Catalogue_shoe",
    },
    {
      step: 2,
      title: "Sports Shoes",
      desc: "Explore our range of sports and athletic shoes.",
      svg: "Cricket",
    },
    {
      step: 3,
      title: "Footwear Accessories",
      desc: "Find accessories to complement your footwear.",
      svg: "Baby_shoes",
    },
  ],
  16: [
    {
      step: 1,
      title: "Bikes",
      desc: "Explore a variety of bicycles for different terrains and purposes.",
      svg: "Track_cycling",
    },
    {
      step: 2,
      title: "Accessories",
      desc: "Find accessories like helmets, locks, and lights to enhance your cycling experience.",
      svg: "Bicycle_shop",
    },
    {
      step: 3,
      title: "Maintenance",
      desc: "Discover tools and products for bike maintenance and repair.",
      svg: "Bicycle_workshop",
    },
  ],
  17: [
    {
      step: 1,
      title: "Art Supplies",
      desc: "Shop for high-quality art supplies.",
      svg: "Images_Gallery",
    },
    {
      step: 2,
      title: "Canvas and Paper",
      desc: "Find the perfect canvas and paper for your projects.",
      svg: "Art_lover",
    },
    {
      step: 3,
      title: "Painting Tools",
      desc: "Discover a variety of painting tools.",
      svg: "Artist_tools",
    },
  ],
}
