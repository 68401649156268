import React, { useState } from "react"
import "./ViewBy.css"

const ViewBy = () => {
  const [selectedView, setSelectedView] = useState()
  const options = [
    { value: "all", label: "All" },
    { value: "today", label: "Today" },
    { value: "week", label: "This Week" },
    { value: "month", label: "This Month" },
  ]

  const handleChange = event => {
    setSelectedView(event.target.value)
  }

  return (
    <div className='view-by-dropdown'>
      <select id='viewBy' value={selectedView} onChange={handleChange}>
        {options.map(op => (
          <option key={op.value} value={op.value}>
            {op.label}
          </option>
        ))}
      </select>
    </div>
  )
}

export default ViewBy
