import React from "react"
import Storage from "./Storage"
import "./Notification.css"
import PushNotification from "./PushNotification"

class Notifications extends React.Component {
  state = {
    value: [],
  }

  componentDidMount() {
    Storage.addListener(v => this.setState({ value: v }))
  }

  render() {
    const { position } = this.props
    const classN = `rpn-notification-holder ${
      position || "top-middle"
    } supertest`
    return (
      <div className={classN}>
        {this.state.value.map((note, i) => (
          <PushNotification
            key={i}
            closeNotification={Storage.popAndPush}
            onClick={note.onClick}
            id={note.id}
            theme={note.theme}
            title={note.title}
            subtitle={note.subtitle}
            closeButton={note.closeButton}
            message={note.message}
            styling={note.styling}
          />
        ))}
      </div>
    )
  }
}

export default Notifications
