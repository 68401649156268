import React from "react"
import { Col, Row, Space, Button, Header, Expander } from "uiComponents"
import InputRange from "rc-slider"
import "./Filter.css"
import { currencyformatter, isMobile } from "helpers"

const ratingList = [3, 4]
const discountList = [5, 10, 20, 30, 50]

export default function Filters({
  price,
  setPrice,
  rating,
  discount,
  setRating,
  setDiscount,
  onFilterClick,
  onClearClick,
  setPriceFilterChanged,
  priceFilterChanged,
  initPrice,
}) {
  return (
    <div className='sticky-filter'>
      <Row>
        <Col xs={12} style={{ padding: "1rem 4rem", width: "50rem" }}>
          {isMobile ? (
            <Header sm bold>
              Filters
            </Header>
          ) : (
            ""
          )}
          <Expander
            initiallyOpen
            title='Price'
            onClearShow={priceFilterChanged}
            onClearClick={() => {
              setPrice(initPrice)
              setPriceFilterChanged(false)
            }}
          >
            <InputRange
              step={10}
              maxValue={10000}
              minValue={0}
              value={price}
              formatLabel={v => currencyformatter(v, true)}
              onChangeComplete={value => {
                // console.log("Range value", value)
                setPrice(value)
                setPriceFilterChanged(true)
              }}
              onChange={value => setPrice(value)}
            />
          </Expander>
          <Expander title='Rating' initiallyOpen>
            {ratingList?.map((rat, index) => (
              <Button
                accent={rating !== rat}
                key={index}
                small
                onClick={e => setRating(rating === rat ? 0 : rat)}
              >
                {rat}
                <span className='fa fa-star' style={{ fontSize: "1.2rem" }} />+
              </Button>
            ))}
          </Expander>
          <Expander title='Discount' initiallyOpen>
            {discountList?.map((disc, index) => (
              <Button
                key={index}
                small
                accent={disc !== discount}
                onClick={e => setDiscount(disc === discount ? 0 : disc)}
              >{`${disc}% +`}</Button>
            ))}
          </Expander>
          <Space />
          <Space />
          <Row>
            <Col xs={6}>
              <Button small accent fullWidth onClick={onClearClick}>
                Clear All
              </Button>
            </Col>
            <Col xs={6}>
              <Button small fullWidth onClick={onFilterClick}>
                Apply
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
}
