import React, { useState } from "react"
import {
  Grid,
  Row,
  Card,
  Col,
  Header,
  Expander,
  Space,
  Button,
} from "uiComponents"
import { getSVG } from "getSVG"
import { FormMaker } from "formComponents"
import LoginAuth from "../Login/LoginAuth"
import { useLocation } from "react-router-dom"
import { DocRenderer } from "firestoreComponents"
import {
  joinInformationsFields,
  designerFields,
  devOpsEngineerFields,
  operationsManagerFields,
  salesManagerFields,
} from "dummyData"
import { DB } from "firebaseUtils"
import "./Join.css"
import { setDataToDb } from "firebaseUtils"
import { useStateValue } from "state"
import {
  designerValidationSchema,
  devOpsEngineerValidationSchema,
  joinInformationsValidationSchema,
  operationsManagerValidationSchema,
  salesManagerValidationSchema,
} from "../../Forms/Validations"
import { openPositionsData } from "dummyData"

const Join = props => {
  const location = useLocation()
  const { onPhoneChange, phoneError } = props
  const [loginSuccess, setLoginSuccess] = useState("")
  const [{ user }] = useStateValue()
  const [openExpander, setOpenExpander] = useState()
  const [formValid, setFormValid] = useState(false)
  const [localData, setLocalData] = useState()

  // Get fields and validation based on path
  const getFieldsBasedOnPath = () => {
    const path = location.pathname
    if (path.includes("/sales")) {
      return salesManagerFields
    } else if (path.includes("/design")) {
      return designerFields
    } else if (path.includes("/engineering")) {
      return devOpsEngineerFields
    } else if (path.includes("/operations")) {
      return operationsManagerFields
    }
    return joinInformationsFields
  }

  const fields = getFieldsBasedOnPath()

  const getValidationsBasedOnPath = () => {
    const path = location.pathname
    if (path.includes("/sales")) {
      return salesManagerValidationSchema
    } else if (path.includes("/design")) {
      return designerValidationSchema
    } else if (path.includes("/engineering")) {
      return devOpsEngineerValidationSchema
    } else if (path.includes("/operations")) {
      return operationsManagerValidationSchema
    }
    return joinInformationsValidationSchema
  }

  const validations = getValidationsBasedOnPath()

  // Find job data based on path
  const getJobDataBasedOnPath = () => {
    const path = location.pathname
    const jobTitleMapping = {
      "/sales": "Sales Manager",
      "/design": "Designer",
      "/engineering": "DevOps Engineer",
      "/operations": "Operations Manager",
    }
    const jobTitle = Object.keys(jobTitleMapping).find(key =>
      path.includes(key),
    )
    const job = openPositionsData.find(
      pos => pos.job_title === jobTitleMapping[jobTitle],
    )
    return job || {}
  }

  const { job_title } = getJobDataBasedOnPath()

  const checkIfUserExists = async result => {
    const { uid, phoneNumber } = result.user

    if (uid) {
      let res = await DB.collection(`users_meta`).doc(uid).get()

      if (res.exists) {
        setLoginSuccess("Welcome")
      } else {
        await DB.collection("users_meta").doc(uid).set({
          phoneNumber,
        })

        setLoginSuccess("User added successfully")
      }
    }
  }

  const addInternalUserToDB = async (data, dispatch, cb) => {
    const url = "internal_users"
    const path = location.pathname

    let role

    if (path.includes("/sales")) {
      role = "Sales Manager"
    } else if (path.includes("/design")) {
      role = "Designer"
    } else if (path.includes("/engineering")) {
      role = "DevOps Engineer"
    } else if (path.includes("/operations")) {
      role = "Operations Manager"
    } else {
      role = "General"
    }

    try {
      const result = await setDataToDb(
        url,
        user?.uid,
        { ...data, createdAt: new Date().toISOString(), role },
        dispatch,
        "Internal User added successfully!",
        cb,
      )
      if (result === "success") {
        console.log("Internal User added successfully!")
      }
    } catch (error) {
      console.error("Error adding user:", error)
    }
  }

  const handleFormSubmit = formData => {
    addInternalUserToDB({
      ...formData,
      statusData: {
        user_status: "Submitted",
      },
    })
    console.log("Form data submitted:", formData)
    handleFormValidityChange(formData)
    setOpenExpander(3)
  }

  const handleFormValidityChange = isValid => {
    setFormValid(isValid)
  }

  const handleLoginSuccess = () => {
    if (!user) {
      setLoginSuccess("Login Successful")
    }
    setOpenExpander(2)
  }

  return (
    <Grid>
      <Row>
        <Col sm={12} md={12}>
          <Card
            noXS
            svgIcon={getSVG(
              "Profile_Interface",
              { width: "100%", minHeight: "40rem" },
              null,
              true,
            )}
          >
            <Header sm bold>
              For join as {job_title || "Informations and Verification"}
            </Header>
            <p>Complete your account setup to join Shoppio</p>
            <Space lg />
            <Card>
              <Expander
                initiallyOpen={openExpander === 1 && !user}
                step={"1"}
                stepStatus={user}
                title={"Mobile Verifications"}
              >
                <div style={{ padding: "5px" }}>
                  <Space lg />
                  <Space lg />
                  <Row>
                    <Col sm={12} md={6}>
                      {user && loginSuccess ? (
                        <div style={{ textAlign: "center" }}>
                          <i
                            className='fas fa-check-circle'
                            style={{
                              fontSize: "10rem",
                              color: "green",
                              marginBottom: "8rem",
                            }}
                          />
                          <Header center lg bold>
                            Welcome to Shoppio
                          </Header>
                          <Space lg />
                          <p>Welcome to Shoppio...</p>
                        </div>
                      ) : (
                        <LoginAuth
                          onChange={onPhoneChange}
                          errorText={phoneError}
                          callback={checkIfUserExists}
                          onLoginSuccess={handleLoginSuccess}
                          forJoinPage
                        />
                      )}
                    </Col>
                    <Col sm={12} md={6}>
                      {getSVG("Mobile_login", null, true)}
                    </Col>
                  </Row>
                </div>
              </Expander>
            </Card>
            <Card>
              <Expander
                disableExpander={
                  localData?.statusData?.user_status === "Submitted"
                }
                step={"2"}
                stepStatus={localData?.statusData?.user_status === "Submitted"}
                title={"Personal Information"}
                initiallyOpen={openExpander === 2}
              >
                <Space lg />
                <Space lg />
                <FormMaker
                  fields={fields}
                  validateOnMount
                  onSubmit={handleFormSubmit}
                  validation={validations}
                />
              </Expander>
            </Card>
            <DocRenderer
              collectionPath={"internal_users"}
              setLocalData={setLocalData}
              viewType={"custom"}
              docID={user?.uid}
              loaderType={"text"}
              renderDoc={({ id, data }) => (
                <>
                  <Card>
                    <Expander
                      step='3'
                      title='Verification'
                      stepStatus={
                        data?.statusData?.user_status === "Submitted" &&
                        formValid
                      }
                      disableExpander={
                        localData?.statusData?.user_status !== "Submitted"
                      }
                      initiallyOpen={openExpander === 3}
                    >
                      <Row>
                        <Col sm={12} md={4}>
                          {getSVG(
                            data?.statusData?.user_status === "Approve"
                              ? "Confirmed-cuate"
                              : "team_checklist",
                            { width: "100%" },
                            null,
                            true,
                          )}
                        </Col>
                        <Col
                          sm={12}
                          md={8}
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <div>
                            <span
                              className={`fas
                              ${
                                data?.statusData?.user_status === "Approve"
                                  ? " fa-check approve-icon"
                                  : " fa-hourglass-start pending-icon"
                              }`}
                            />
                            <Header bold lg>
                              {data?.statusData?.user_status === "Approve"
                                ? "Verification Completed"
                                : "Verification in progress"}
                            </Header>
                            {data?.statusData?.user_status === "Approve" ? (
                              <p style={{ fontSize: "16px" }}>
                                Your application has been successfully verified.
                                <br /> Thank you for your patience.
                              </p>
                            ) : (
                              <p style={{ fontSize: "16px" }}>
                                Please wait while we perform our checks and
                                review
                                <br /> your application.
                              </p>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </Expander>
                  </Card>
                  <Card>
                    <Expander
                      disableExpander={
                        // data?.statusData?.user_status !== "Approve"
                        true
                      }
                      step={"4"}
                      stepStatus={
                        data?.statusData?.user_status === "Approve" && formValid
                      }
                      title={"On Boarding"}
                    >
                      <Row>
                        <Col sm={12} md={4}>
                          {getSVG("Welcome", { width: "100%" }, null, true)}
                        </Col>
                        <Col
                          sm={12}
                          md={8}
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <div style={{}}>
                            <span
                              className='fas fa-check-circle'
                              style={{
                                fontSize: "40px",
                                color: "var(--success)",
                                marginBottom: "10px",
                              }}
                            />
                            <Header bold lg>
                              Congratulations
                            </Header>
                            <p style={{ fontSize: "16px" }}>
                              You have successfully verified the details and can
                              now explore.
                            </p>
                            <Space lg />
                            <Button>Get Started</Button>
                          </div>
                        </Col>
                      </Row>
                    </Expander>
                  </Card>
                </>
              )}
            />
          </Card>
        </Col>
      </Row>
    </Grid>
  )
}

export default Join
