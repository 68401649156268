import React, { useEffect, useState } from "react"
import "./Store.css"
import "../../../styles/theme.css"
import { useHistory, useParams } from "react-router-dom"
import {
  Grid,
  Row,
  Col,
  Header,
  Tabs,
  Tab,
  CompactCard,
  Busy,
} from "uiComponents"
import { Footer, sendNotification } from "appComponents"
import { DB } from "firebaseUtils"
import { useStateValue } from "state"
import { FormMaker } from "formComponents"
import { getDataAfterImageUpload } from "helpers"
import * as Yup from "yup"
import { states } from "dictionary"
import { productCategories, serviceCategories } from "config"
import { sendEmail } from "emailer"
import { AppPaths } from "shoppio-constants"

export const storeValidationSchema = (props, fiels, values) => {
  const { department } = values || {}
  const customDepartmentValidation =
    department === "Other"
      ? {
          custom_department: Yup.string().required(
            "Please name the department",
          ),
        }
      : {}
  return Yup.object().shape({
    seller_agreement: Yup.mixed().required(
      "Please read and check the seller terms & policies",
    ),
    type_of_store: Yup.string().required("Please provide an answer"),
    department: Yup.string().required("Please choose a type"),
    ...customDepartmentValidation,
    store_name: Yup.string()
      .required("Please provide a store name")
      .min(3, "Too short")
      .max(100, "Too long"),
    store_tagline: Yup.string()
      .required("Please provide a store tagline")
      .min(3, "Too short")
      .max(100, "Too long"),
    desc: Yup.string()
      .required("Please provide a store description")
      .min(3, "Too short")
      .max(100, "Too long"),
    greeting: Yup.string().min(3, "Too short").max(100, "Too long"),
    store_pic: Yup.mixed().required("Please upload a banner picture"),
    store_logo: Yup.mixed().required("Please upload your brand/store logo"),
    business_pan: Yup.string()
      .required("Please provide your business PAN")
      .matches(/^[A-Z]{5}[0-9]{4}[A-Z]$/, "Invalid PAN format"),
    house_number: Yup.string()
      .required("Please provide a house/buildling number of your store")
      .min(3, "Too short")
      .max(20, "Too long"),
    address_line_1: Yup.string()
      .required("Please provide a street address of your store")
      .min(3, "Too short")
      .max(200, "Too long"),
    locality: Yup.string()
      .required("Please provide a street address of your store")
      .min(3, "Too short")
      .max(100, "Too long"),
    city: Yup.string()
      .required("Please provide a street address of your store")
      .min(3, "Too short")
      .max(100, "Too long"),
    state: Yup.string()
      .required("Please provide a street address of your store")
      .min(3, "Too short")
      .max(100, "Too long"),
  })
}

const storeFields = formValues => {
  const { type_of_store, department } = formValues || {}
  // console.log('formValues', formValues)
  const departmentArray =
    type_of_store === "Services"
      ? [...serviceCategories, "Other"]
      : type_of_store === "Both"
      ? [...productCategories, ...serviceCategories, "Other"]
      : [...productCategories, "Other"]
  const customDepartment =
    department === "Other"
      ? [
          {
            name: "custom_department",
            label: "Name the type of store/service",
            type: "text",
            fullWidth: true,
          },
        ]
      : []
  return [
    { group: "Business Information" },
    {
      name: "type_of_store",
      label: "Do you sell products or provide services?",
      type: "select",
      fullWidth: true,
      children: ["Products", "Services", "Both"].map(op => (
        <option value={op}>{op}</option>
      )),
    },
    {
      name: "department",
      label:
        type_of_store === "Services"
          ? "Please select the type of services?"
          : "Please select the type of store?",
      type: "select",
      fullWidth: true,
      children: departmentArray.map(op => <option value={op}>{op}</option>),
    },
    ...customDepartment,
    {
      name: "desc",
      label:
        type_of_store === "Services"
          ? "A describe your services in detail"
          : "A describe your products in detail",
      type: "textarea",
      fullWidth: true,
      row: 2,
    },
    {
      name: "business_pan",
      label: "Please enter Business PAN",
      dataTip:
        "If you don't have a business pan you can enter your personal PAN Number",
      type: "text",
      fullWidth: true,
    },
    {
      name: "gstin",
      label: "Please enter GSTIN of your business",
      type: "text",
      fullWidth: true,
    },
    { group: "Branding" },
    {
      name: "store_name",
      label: "Store Name",
      desc: "Name of your online shop",
      fullWidth: true,
      type: "text",
    },
    {
      name: "store_pic",
      label: "Banner Picture",
      info: "Upload a picture to showcase as banner",
      type: "pic",
      fullWidth: true,
    },
    {
      name: "store_logo",
      label: "Store Logo",
      info: "Your store name will be used if logo is not provided",
      type: "pic",
      fullWidth: true,
    },
    {
      name: "store_tagline",
      label: "Store tagline",
      fullWidth: true,
      type: "text",
    },
    {
      name: "greeting",
      label: "Greeting",
      fullWidth: true,
      type: "text",
    },
    { group: "Store Location" },
    {
      name: "house_number",
      label: "Flat No./Apartment No./House No.",
      type: "text",
      fullWidth: true,
    },
    {
      name: "address_line_1",
      label: "Address Line 1",
      type: "text",
      fullWidth: true,
    },
    {
      name: "locality",
      label: "Locality",
      type: "text",
    },
    {
      name: "city",
      label: "City",
      type: "text",
    },
    {
      name: "state",
      label: "State",
      type: "select",
      children: states.map(op => <option value={op.name}>{op.name}</option>),
      fullWidth: true,
    },
    {
      name: "store_location",
      label: "Pincode & Map",
      desc: "Please use the autocomplete to search for the location of your store and use the map to pin point the location",
      fullWidth: true,
      type: "location",
    },
    {
      name: "seller_agreement",
      label: (
        <span>
          By creating a store on Shoppio you are agreeing to{" "}
          <a
            href={AppPaths.SELLER_TERMS_POLICIES}
            target='_blank'
            rel='noreferrer'
          >
            seller terms & conditions
          </a>
        </span>
      ),
      fullWidth: true,
      type: "checkbox",
    },

    // {
    //   group: 'Sale',
    // },
    // {
    //   name: 'is_sale_on',
    //   label: 'Is Sale on?',
    //   type: 'select',
    //   children: ['Sale Active', 'No Sale'].map(op => (
    //     <option value={op === 'Sale Active'}>{op}</option>
    //   )),
    // },
    // ...saleOnFields,
  ]
}

function AddStore() {
  const [{ user, userMeta }, dispatch] = useStateValue()
  const [busy, setBusy] = useState()
  const history = useHistory()
  const { storeid } = useParams()
  const [storeData, setStoreData] = useState()
  const removeStore = () => {
    DB.collection(`user_stores`)
      .doc(storeid)
      .delete()
      .then(doc => {
        DB.collection(`users_meta`)
          .doc(user?.uid)
          .collection("stores")
          .doc(storeid)
          .delete()
          .then(() => {
            history.push(AppPaths.SELLER_PROFILE)
            dispatch({
              type: "SHOW_ALERT",
              open: true,
              message: "Store deleted successfully",
            })
          })
          .catch(() => {
            // console.log("Store ID cant be removed from user meta");
            history.push(AppPaths.SELLER_PROFILE)
            dispatch({
              type: "SHOW_ALERT",
              open: true,
              message: "Store deleted successfully",
            })
          })
      })
  }
  const addToUserMeta = doc => {
    // console.log("Docs", doc);
    DB.collection(`users_meta`)
      .doc(user?.uid)
      .collection("stores")
      .doc(doc.key)
      .set(doc)
  }
  const addStoreToDB = async storeData => {
    setBusy(true)
    console.log("1")
    let data = await getDataAfterImageUpload(storeData)
    console.log("Data After Upload", data)
    if (storeid) {
      DB.collection(`user_stores`)
        .doc(storeid)
        .update({
          updatedBy: user?.uid,
          ...data,
        })
        .then(doc => {
          console.log("Success")
          setBusy(false)
          history.push(AppPaths.SELLER_PROFILE)
        })
        .catch(error => {
          console.error("Error writing document: ", error)
          setBusy(false)
        })
    } else {
      DB.collection(`user_stores`)
        .add({
          ...data,
          addedBy: user?.uid,
          ownedBy: userMeta?.seller_name || "Seller Name",
          storeApproval: "Unapproved",
        })
        .then(doc => {
          console.log("Success Store Added", doc)
          addToUserMeta({
            key: doc.id,
            value: data?.store_name,
          })
          setBusy(false)
          history.push(`${AppPaths.SELLER_PROFILE}/#stores`)
        })
        .catch(error => {
          console.error("Error writing document: ", error)
          setBusy(false)
        })
    }
  }
  const getUserStoreData = (storeId, dispatch) => {
    if (!dispatch || !storeId) return
    DB.collection("user_stores")
      .doc(storeId)
      .get()
      .then(s => {
        setStoreData(s.data())
        setBusy(false)
      })
      .catch(error => {
        setBusy(false)
      })
  }
  useEffect(() => {
    window.scrollTo(0, 0)
    if (user && storeid) {
      setBusy(true)
      getUserStoreData(storeid, dispatch)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, dispatch, storeid])
  const [values, setValues] = useState({})
  const initialValues = storeData
  const updating = initialValues ? true : false
  // console.log(user?.id)
  return (
    <div className='Home'>
      <div className='home__container'>
        <div className='home-section'>
          <Busy busy={busy}>
            <Grid>
              <Tabs>
                <Tab label='Store Details'>
                  <Row>
                    <Col xs={12} sm={6} md={6}>
                      <Header accent lg bold>
                        {updating ? "Update Store" : "Create a Store"}
                      </Header>
                      <p>Please fill the required details about your store</p>
                      <FormMaker
                        submitLabel={
                          updating ? "Update Store" : "Make my store"
                        }
                        // formName=""
                        fields={storeFields}
                        initialValues={initialValues}
                        validation={storeValidationSchema}
                        handleChange={newVals =>
                          setValues({ ...values, ...newVals })
                        }
                        secAction={
                          updating &&
                          initialValues?.storeApproval !== "Approved"
                            ? {
                                label: "Delete Store",
                                danger: true,
                                onClick: () => removeStore(),
                              }
                            : ""
                        }
                        onSubmit={formValues => {
                          addStoreToDB(formValues, updating)
                          let updateMessage =
                            "The store information has been successfully updated."

                          let createMessage =
                            "The store was created and submitted for review and approval."

                          sendNotification({
                            title: updating ? "Store Updated" : "Store Created",
                            body: updating ? updateMessage : createMessage,
                            toUserID: user?.uid,
                          })
                          const emailData = {
                            email: user?.email,
                            from_name: "Shoppio",
                            message: updating ? updateMessage : createMessage,
                          }

                          const template = "general_template"

                          sendEmail(emailData, template)
                        }}
                        showBack
                      />
                    </Col>
                    <Col xs={12} sm={6} md={6}>
                      <Header sm bold>
                        Preview
                      </Header>
                      <p>Store</p>
                      <br />
                      {[values]?.map(
                        (
                          {
                            id,
                            store_name,
                            store_pic,
                            store_tagline,
                            store_logo,
                            is_sale_on,
                            greeting,
                            ownedBy,
                          },
                          key,
                        ) => {
                          return (
                            <CompactCard
                              notFull
                              key={key}
                              config={{
                                logo: store_logo,
                                version: "store",
                                title: store_name,
                                subtitle: store_tagline,
                                backgroundImage: store_pic,
                                greeting: greeting,
                                tag:
                                  String(is_sale_on) === "true"
                                    ? "Sale On"
                                    : null,
                                ownedBy: ownedBy,
                                action: {
                                  label: "View Store",
                                },
                              }}
                            />
                          )
                        },
                      )}
                    </Col>
                  </Row>
                </Tab>
              </Tabs>
              <Footer />
            </Grid>
          </Busy>
        </div>
      </div>
    </div>
  )
}

export default AddStore
