import React from "react"
import "../ImageUploader/ImageUploader.css"
import { Dropzone, FileMosaic, FullScreen, VideoPreview } from "@files-ui/react"

const BASE_URL = "https://www.myserver.com"

export function VideoUploader(props) {
  const { errorText } = props || {}
  const [extFiles, setExtFiles] = React.useState([])
  const [, setImageSrc] = React.useState(undefined)
  const [videoSrc, setVideoSrc] = React.useState(undefined)

  const updateFiles = incommingFiles => {
    console.log("incomming files", incommingFiles)
    setExtFiles(incommingFiles)
  }
  const onDelete = id => {
    setExtFiles(extFiles.filter(x => x.id !== id))
  }
  const handleSee = imageSource => {
    setImageSrc(imageSource)
  }
  const handleWatch = videoSource => {
    setVideoSrc(videoSource)
  }
  const handleStart = filesToUpload => {
    console.log("advanced demo start upload", filesToUpload)
  }
  const handleFinish = uploadedFiles => {
    console.log("advanced demo finish upload", uploadedFiles)
  }
  const handleAbort = id => {
    setExtFiles(
      extFiles.map(ef => {
        if (ef.id === id) {
          return { ...ef, uploadStatus: "aborted" }
        } else return { ...ef }
      }),
    )
  }
  const handleCancel = id => {
    setExtFiles(
      extFiles.map(ef => {
        if (ef.id === id) {
          return { ...ef, uploadStatus: undefined }
        } else return { ...ef }
      }),
    )
  }

  return (
    <div
      style={{
        padding: "2rem",
        position: "relative",
        borderRadius: "var(--radius)",
        border: "1px solid #ddd",
      }}
    >
      {errorText && <div className='error'>{errorText}</div>}
      <Dropzone
        onChange={updateFiles}
        minHeight='195px'
        value={extFiles}
        accept='video/*'
        maxFiles={3}
        maxFileSize={100 * 1024 * 1024}
        label="Drag'n drop files here or click to browse"
        uploadConfig={{
          // autoUpload: true
          url: BASE_URL + "/file/28048465460",
          cleanOnUpload: true,
        }}
        onUploadStart={handleStart}
        onUploadFinish={handleFinish}
        //fakeUpload
        actionButtons={{
          position: "after",
          abortButton: {},
          deleteButton: {},
          uploadButton: {},
        }}
      >
        {extFiles.map(file => (
          <FileMosaic
            {...file}
            key={file.id}
            onDelete={onDelete}
            onSee={handleSee}
            onWatch={handleWatch}
            onAbort={handleAbort}
            onCancel={handleCancel}
            resultOnTooltip
            preview
            info
          />
        ))}
      </Dropzone>
      <FullScreen
        open={videoSrc !== undefined}
        onClose={() => setVideoSrc(undefined)}
      >
        <VideoPreview src={videoSrc} autoPlay controls />
      </FullScreen>
    </div>
  )
}
