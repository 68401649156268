import React from "react"
import { Col, Grid, Row, Space, Header } from "uiComponents"
import { Link } from "react-router-dom"
import logo from "../../../images/Logo.png"
import "./footer.css"
import PWAinstall from "../../UI/PWA/PWAinstall"
import { SellerSmallBanner } from "appComponents"
import { AppPaths } from "shoppio-constants"

export const CompactFooter = ({ color }) => {
  return (
    <div className='compact-footer'>
      <div style={{ marginBottom: "-4px", marginRight: "1rem" }}></div>
      <div className='chrome'>
        This portfolio was created on Foleoz.{" "}
        <a
          target='_blank'
          rel='noopener noreferrer'
          href='https://foleoz.com'
          style={{ color: color }}
        >
          Learn More
        </a>
      </div>
    </div>
  )
}
export const SocialLinks = () => {
  return (
    <div>
      <Header xs>Follow us on.</Header>
      <div className='social-links'>
        <Link to=''>
          <span className='fab fa-instagram' />
        </Link>
        <Link to=''>
          <span className='fab fa-youtube' />
        </Link>
      </div>
    </div>
  )
}

const Footer = () => {
  return (
    <Grid>
      <Row center='xs' style={{ textAlign: "left" }}>
        <Col xs={12} md={12}>
          <div className='footer smooth'>
            <Row center='xs' middle='xs'>
              <Col xs={12} sm={4} className='footer-col'>
                <Space lg />
                <Link to='/' onClick={() => window.scrollTo(0, 0)}>
                  <img
                    style={{ filter: "invert(1)" }}
                    alt=''
                    width={window.innerWidth < 766 ? 100 : 140}
                    src={logo}
                  />
                </Link>
                <Space lg />
                {/* <SocialLinks /> */}
              </Col>
              <Col
                xs={12}
                sm={4}
                style={{ textAlign: "start" }}
                className='footer-col links'
              >
                <Link to={AppPaths.CONTACT_US}>Contact Us</Link>
                <Link to={AppPaths.ABOUT_US}>About Us</Link>
                <Link to={AppPaths.POLICIES}>Policies</Link>
                <Link to={AppPaths.TERMS}>Terms</Link>
                <Link to={AppPaths.CANCELLATION_REFUND}>
                  Cancellation & Refund
                </Link>
                <Link to={AppPaths.SHIPPING_DELIVERY}>Shipping & Delivery</Link>

                {/* {mailingList && (
                <div>
                  <Header sm bold>
                    Subscribe.
                  </Header>
                  <Space />
                  {renderMailingList(this.props)}
                </div>
              )} */}
                {/* <div>
                <p>
                  Made with{" "}
                  <span className="fas fa-heart" style={{ color: Colors }} /> by{" "}
                  <a href="https://github.com/ferrousdesigner">
                    @ferrousdesigner
                  </a>
                </p>
              </div> */}
              </Col>
              <Col xs={12} sm={4}>
                <SellerSmallBanner />
                <PWAinstall type='footer' />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Grid>
  )
}

export default Footer
