import { useRef, useState, useEffect, createElement, Fragment } from "react"

var isLoaded = false

export default function GoogleFontFetcher(props) {
  var href = props.href,
    hasLoadedRef = useRef(false),
    setState = useState(false)[1]

  useEffect(function () {
    if (!hasLoadedRef.current) {
      isLoaded = true
      hasLoadedRef.current = true
      setState(true)
    }
    //eslint-disable-next-line
  }, [])

  return createElement(
    Fragment,
    null,
    createElement("link", {
      rel: "preconnect",
      href: "https://fonts.gstatic.com",
      crossOrigin: "anonymous",
    }),
    createElement("link", { rel: "preload", as: "style", href: href }),
    createElement("link", {
      href: href,
      rel: "stylesheet",
      media: isLoaded ? "all" : "print",
    }),
  )
}
