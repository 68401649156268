import Breadcrumbs from "@mui/material/Breadcrumbs"
import React, { Fragment, useEffect, useState } from "react"
import { Col, Grid, Row, Space, Button, Header } from "uiComponents"
import ImageGallery from "react-image-gallery"
import { Link } from "react-router-dom/cjs/react-router-dom.min"
import { CurrencySign } from "config"
import { CollectionRenderer, DocRenderer } from "firestoreComponents"
import {
  currencyformatter,
  getHoursAndMinutesFromMinutes,
  isMobile,
} from "helpers"
import { useStateValue } from "state"
import { DB } from "firebaseUtils"
import { Footer, hasAdminAccessToStore } from "appComponents"
import { InputField, SelectField } from "formComponents"
import { RenderReview } from "../Rating/RateReview"
import Rater from "../Rating/Rater"
import { AvailableColors, Highlight } from "uiComponents"
import "./ProductPage.css"
import RenderVariants from "./RenderVariants"
import moment from "moment"
import ReactDatetime from "react-datetime"
import { AppPaths, getPath } from "shoppio-constants"

export const isServiceValidDate = current => {
  var yesterday = moment().subtract(1, "day")
  return current.isAfter(yesterday)
}
export const ProductImages = ({ images, big }) => {
  console.log("images", images)
  const imagesGallery = images
    ?.filter(i => i)
    .map(image => ({
      original: image,
      thumbnail: image,
    }))
  return (
    <Col
      xs={12}
      md={big ? 12 : 5}
      className={"product-page-img-container " + (big ? " big-images" : "")}
    >
      {imagesGallery?.length ? (
        <ImageGallery autoPlay infinite items={imagesGallery} />
      ) : (
        ""
      )}
    </Col>
  )
}

const fetchStorePincodes = (storeID, dispatch) => {
  // console.log("Clled");
  if (!storeID) return
  DB.collection("delivery_cities")
    .doc(storeID)
    .collection("data")
    .get()
    .then(snap => {
      if (snap.docs.length === 0) {
        // console.log("No data");
        return
      }
      let citiesPins = []
      snap.forEach(citySnap => citiesPins.push(citySnap.data().pincodes))
      citiesPins = citiesPins.flat()
      // console.log("citiesPins", citiesPins);
      dispatch({
        type: "SET_STORE_PINCODE",
        storePincodes: citiesPins,
      })
    })
}

const DeliveryCheck = ({ storeID, onUpdate, deliveryTime, isService }) => {
  const [pinCode, setPenCode] = useState()
  const [deliveryStatus, setDeliveryStatus] = useState()
  const [{ userPincode, storePincodes }, dispatch] = useStateValue()
  const handlePincodeCheck = setDeliveryStatus => {
    dispatch({
      type: "SET_USER_PINCODE",
      userPincode: pinCode,
    })
    if (storePincodes?.length > 0 && storePincodes?.includes(pinCode)) {
      return setDeliveryStatus("available")
    } else {
      return setDeliveryStatus("no")
    }
  }
  useEffect(() => {
    if (!pinCode && userPincode) {
      setPenCode(userPincode)
    }
    if (storeID) fetchStorePincodes(storeID, dispatch)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeID])

  useEffect(() => {
    onUpdate && onUpdate(deliveryStatus)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deliveryStatus])
  // console.log(">storePincodesstorePincodesstorePincodes", [deliveryStatus])
  return (
    <Col xs={12}>
      <Space md />
      <InputField
        form={{
          touched: {
            search: true,
          },
        }}
        field={{
          name: "search",
          value: pinCode,
          onChange: e => {
            setPenCode(e.target.value)
            setDeliveryStatus()
          },
        }}
        label={isService ? "Check availability" : "Check delivery"}
        withButton
        buttonDisabled={!pinCode}
        hint={"Enter your pincode"}
        buttonText={<span className='fas fa-map-marker-alt' />}
        onButtonClick={() => handlePincodeCheck(setDeliveryStatus)}
      />
      {deliveryStatus === "no" && (
        <Highlight type='danger'>
          {isService
            ? "This service is currently not available in this area, please try a different location"
            : "This product cannot be delivered to this location."}
        </Highlight>
      )}
      {deliveryStatus === "available" && (
        <Highlight type='success'>
          Great! This {isService ? "service" : "product"} is available at{" "}
          {pinCode}.
        </Highlight>
      )}
    </Col>
  )
}
const ExtraActions = ({ config, isService }) => {
  const { desc, features, instructions, storeID, totalReviews, id } =
    config || {}
  return (
    <Col xs={12} className='product-page-extra-action'>
      <Row>
        <Col xs={12} sm={12} style={{ textAlign: "left" }}>
          <Row start='xs'>
            {features && (
              <Col xs={12} sm={6}>
                <Col xs={12}>
                  <Header accent>Features</Header>
                  {features && <p>{features}</p>}
                </Col>
              </Col>
            )}

            {desc && (
              <Col xs={12} sm={isService ? 12 : 6}>
                <Col xs={12}>
                  <Header accent>Description</Header>
                  <p>{desc}</p>
                </Col>
              </Col>
            )}
          </Row>
          <Row>
            {instructions && (
              <Col xs={12}>
                <Header accent>Instructions</Header>
                <div>{instructions}</div>
              </Col>
            )}
          </Row>
        </Col>
        <Col xs={12} sm={6}>
          <Col xs={12}>
            <Space lg />
            <Header accent>Reviews ({totalReviews || 0})</Header>
            <CollectionRenderer
              noReload
              loaderType={"review"}
              collectionPath={`products/${storeID}/store_products/${
                id || "NO ID"
              }/reviews`}
              variable={`productReviews.${id}`}
              viewType={"custom"}
              noDocMessage={"No reviews yet."}
              renderDoc={({ id, data }) => <RenderReview key={id} {...data} />}
            />
          </Col>
        </Col>
      </Row>
    </Col>
  )
}
export default function ProductPage({ config }) {
  const [{ userMeta }] = useStateValue()
  const [tempOrder, setTempOrder] = useState({})
  const [variantSelected, setVariantSelected] = useState({})

  const setOrder = (type, v) => {
    let temp = { ...tempOrder }
    temp[type] = v
    setTempOrder(temp)
    config?.onOrderChange(temp)
  }
  useEffect(() => {
    // console.log("etststsst", variantSelected)
    if (variantSelected) {
      setOrder("variant", variantSelected)
    } else {
      setOrder("variant", {})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(variantSelected)])

  const {
    id,
    backgroundImages,
    brand,
    name,
    price,
    discount,
    desc,
    primaryAction,
    secAction,
    colors,
    size,
    pattern,
    rating,
    totalRatings,
    stock,
    storeID,
    storeName,
    variants,
    subcategory,
    category,
    setDeliveryStatus,
    type,
    duration,
    setServiceTime,
    serviceTime,
  } = config || {}
  const isService = config?.type === "Service"
  const ownsThisStore = hasAdminAccessToStore(
    userMeta,
    storeID,
    "update_product",
  )
  // const [servicingDateTime, setServicingDateTime] = useState(null)

  useEffect(() => {
    if (serviceTime) {
      setOrder("serviceTime", serviceTime.toDate())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceTime])
  const sellingPrice = price - (discount / 100) * price
  const Head = ({ children }) => (
    <h1 className='product-page-head'>{children}</h1>
  )
  const AvailableSizes = ({ sizes, onSelectSize, value }) => (
    <div>
      <Space />
      <Head>Sizes</Head>
      {sizes?.map(s => (
        <Button
          colored={value === s}
          accent={value !== s}
          onClick={() => onSelectSize(s)}
        >
          {s}
        </Button>
      ))}
    </div>
  )
  const Patterns = ({ patterns, onSelectPattern, value }) => (
    <div>
      {/* {console.log('>>>', patterns)} */}
      <Head>Patterns</Head>
      <SelectField
        form={{ errors: {} }}
        field={{ name: "pattern", value }}
        onChange={v => onSelectPattern(v.target.value)}
      >
        {patterns && patterns.map ? (
          patterns?.map(s => <option value={s}>{s}</option>)
        ) : (
          <option value={patterns}>{patterns}</option>
        )}
      </SelectField>
    </div>
  )
  const Description = () => (
    <Col xs={12}>
      <span className={"product-type " + type}>{type || "Product"}</span>
      <h1 className='product-name'>{name} </h1>
      <h2 className='product-brand'>By {brand ? brand : storeName} </h2>
      <p className='product-desc'>{desc?.substring(0, 200)}</p>
      <h3 className='product-price'>
        <span className='currency-sign'>{CurrencySign}</span>
        {sellingPrice && currencyformatter(sellingPrice)}
        <span className='discount-sign'>-{discount}%</span>
      </h3>
      {price && (
        <p className='mrp crossed'>
          MRP: {CurrencySign}
          {price}
        </p>
      )}
      <div className='rating' style={{ marginBottom: "4rem" }}>
        <Rater
          readonly
          style={{ fontSize: isMobile ? 24 : 36 }}
          rating={rating || 0}
          noHead
        />
        {totalRatings && <span>({totalRatings})</span>}
        {/* {console.log(
          "🚀 ~ file: ProductPage.js:199 ~ ProductPage ~ totalRatings:",
          totalRatings
        )} */}
      </div>
    </Col>
  )

  const ActionsStock = () => {
    return (
      <Fragment>
        <div className='actions'>
          {primaryAction && (
            <button
              onClick={primaryAction.disabled ? null : primaryAction?.onClick}
              type='button'
            >
              {primaryAction?.icon}
            </button>
          )}
          {secAction && (
            <button
              className={secAction?.disabled ? "btn-disabled" : ""}
              disabled={secAction?.disabled}
              onClick={secAction?.disabled ? null : secAction?.onClick}
              type='button'
            >
              {secAction?.icon} {secAction?.label}
            </button>
          )}
        </div>
        <Space lg />
        {ownsThisStore ? (
          <Link
            to={
              (getPath(AppPaths.UPDATE_PRODUCT),
              {
                storeid: storeID,
                product_id: id,
              })
            }
          >
            <Button fullWidth accent>
              Update Product
            </Button>
          </Link>
        ) : (
          ""
        )}

        {!isService ? (
          <div
            style={{ paddingTop: "2rem" }}
            className={Number(stock) > 0 ? "in-stock" : "out-stock"}
          >
            {Number(stock) > 0 ? "In Stock" : "Out of Stock"}
          </div>
        ) : (
          ""
        )}
      </Fragment>
    )
  }
  const RenderBreadcrumbs = React.useCallback(() => {
    return subcategory ? (
      <Breadcrumbs
        style={{ color: "var(--font-color)", textAlign: "center" }}
        aria-label='breadcrumb'
      >
        <Link to={`/`}>
          <span className='fas fa-home' />
        </Link>
        <Link
          to={
            (getPath(AppPaths.STORE_HOME),
            {
              id: storeID,
            })
          }
        >
          {storeName || "Store"}
        </Link>
        <Link
          to={
            (getPath(AppPaths.CATEGORY),
            {
              storeid: storeID,
              category_id: category,
            })
          }
        >
          {" "}
          <DocRenderer
            loaderType={"text"}
            collectionPath={`store_categories/${storeID}/data`}
            docID={category}
            renderDoc={({ category_name }) => category_name}
          />
        </Link>
        <Link
          to={
            (getPath(AppPaths.SUBCATEGORY_HOME),
            {
              storeid: storeID,
              category_id: category,
              subcategory_id: subcategory,
            })
          }
        >
          <DocRenderer
            loaderType={"text"}
            collectionPath={`store_subcategories/${storeID}/data/${category}/data`}
            docID={subcategory}
            renderDoc={({ subcategory_name }) => subcategory_name}
          />
        </Link>
      </Breadcrumbs>
    ) : category ? (
      <Breadcrumbs
        style={{ color: "var(--font-color)", textAlign: "center" }}
        aria-label='breadcrumb'
      >
        <Link to={`/`}>
          <span className='fas fa-home' />
        </Link>
        <Link
          to={
            (getPath(AppPaths.STORE_HOME),
            {
              id: storeID,
            })
          }
        >
          {storeName || "Store"}
        </Link>
        <Link
          to={
            (getPath(AppPaths.CATEGORY),
            {
              storeid: storeID,
              category_id: category,
            })
          }
        >
          <DocRenderer
            collectionPath={`store_categories/${storeID}/data`}
            docID={category}
            loaderType={"text"}
            renderDoc={data => {
              const { category_name } = data || {}
              return category_name
            }}
          />
        </Link>
      </Breadcrumbs>
    ) : (
      <Breadcrumbs
        style={{ color: "var(--font-color)", textAlign: "center" }}
        aria-label='breadcrumb'
      >
        <Link to={`/`}>
          <span className='fas fa-home' />
        </Link>
        <Link
          to={
            (getPath(AppPaths.STORE_HOME),
            {
              id: storeID,
            })
          }
        >
          {storeName || "Store"}
        </Link>
      </Breadcrumbs>
    )
  }, [subcategory, category, storeID, storeName])
  const ServiceDuration = () => (
    <div>
      <Header>Duration</Header>
      <Header bold md>
        {Number(duration) > 60
          ? `${getHoursAndMinutesFromMinutes(duration).hours} Hours ${
              getHoursAndMinutesFromMinutes(duration).minutes
            } Minutes`
          : `${duration} minutes`}
      </Header>
    </div>
  )
  const Variants = () => (
    <div>
      {variants ? (
        <RenderVariants
          variants={variants}
          variantSelected={variantSelected}
          onSelect={(key, value) => {
            let temp = { ...variantSelected }
            if (value) {
              temp[key] = value
            } else {
              delete temp[key]
            }
            setVariantSelected(temp)
          }}
        />
      ) : (
        <Fragment>
          {colors && colors.length > 0 && (
            <div>
              <Head>Colors</Head>
              <AvailableColors
                value={tempOrder?.color}
                colors={colors}
                onSelect={c => setOrder("color", c)}
              />
            </div>
          )}
          {size?.length > 0 && (
            <div>
              <AvailableSizes
                sizes={size}
                value={tempOrder?.size}
                onSelectSize={s => setOrder("size", s)}
              />
            </div>
          )}
          {pattern?.length > 0 && (
            <div>
              <Patterns
                value={tempOrder?.pattern}
                patterns={pattern}
                onSelectPattern={s => setOrder("pattern", s)}
              />
            </div>
          )}
        </Fragment>
      )}
    </div>
  )

  const RenderDescriptionTwo = React.useCallback(() => {
    return <ExtraActions isService={isService} config={config} />
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config])

  const Timepicker = () => {
    return (
      <Col xs={12}>
        <Space lg />
        <div className='input-field'>
          <label>Date & time of service</label>
          <ReactDatetime
            className='date-time'
            value={serviceTime}
            isValidDate={isServiceValidDate}
            onChange={newValue => {
              setServiceTime(newValue)
            }}
          />
        </div>
      </Col>
    )
  }

  console.log(">Product", config)
  return (
    <div className='v2-product-page-container'>
      <div className='v2-product-page-inner'>
        <Grid>
          <Row top='xs'>
            <Col xs={12} style={{ textAlign: "center" }}>
              <div
                style={{
                  flex: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <RenderBreadcrumbs />
              </div>
              <Space lg />
            </Col>
            <ProductImages images={backgroundImages} />
            <Col xs={12} md={6} mdOffset={1}>
              <Description />
              <Col xs={12}>
                {isService ? <ServiceDuration /> : <Variants />}
              </Col>

              <DeliveryCheck
                deliveryTime={config?.deliveryTime}
                storeID={storeID}
                isService={isService}
                onUpdate={status =>
                  setDeliveryStatus(status === "available" ? true : false)
                }
              />
              {isService ? <Timepicker /> : ""}
              <Col xs={12}>
                <ActionsStock />
              </Col>
            </Col>
            <RenderDescriptionTwo />
          </Row>
        </Grid>
      </div>
      <Footer />
    </div>
  )
}
