import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import moment from "moment"
import { Dialog, DevPanel, PWAinstall, IconButton, Button } from "uiComponents"
import { UserNotifications, Jumbotron } from "appComponents"
import { isDev } from "helpers"
import PrimaryMenu from "../../../UI/PrimaryMenu/PrimaryMenu"
import { isMobile } from "helpers"
import "./Drawer.css"
import { AppPaths } from "shoppio-constants"

export default function Drawer({
  setMenuOpen,
  menuOpen,
  logo,
  user,
  openDialog,
  dispatch,
  history,
}) {
  const [themeIcon, setIcon] = useState(
    localStorage.getItem("theme") === "dark" ? "moon" : "sun",
  )
  // console.log("isDeb", isDev)
  const themeSetter = theme => {
    let body = document.getElementsByClassName("default")[0]
    if (theme === "light") {
      setIcon("fas fa-moon")
      body.classList.remove("theme-dark")
      body.classList.add("theme-light")
      localStorage.setItem("theme", "light")
    } else {
      setIcon("fas fa-sun")
      body.classList.remove("theme-light")
      body.classList.add("theme-dark")
      localStorage.setItem("theme", "dark")
    }
  }

  useEffect(() => {
    const savedTheme = localStorage.getItem("theme") || "light"
    themeSetter(savedTheme)
  }, [])

  const toggleTheme = () => {
    const currentTheme =
      localStorage.getItem("theme") === "dark" ? "light" : "dark"
    return currentTheme
  }

  const toggleDialog = () => {
    dispatch({
      type: "GENERAL",
      data: {
        openDialog: !openDialog,
      },
    })
    dispatch({ type: "OPEN_PRIMARY_MENU" })
  }

  return (
    <div>
      <div
        id='nav_drawer'
        onBlur={event => event.target.id !== "nav_drawer" && setMenuOpen()}
        className={
          (menuOpen ? "nav-menu-pane menu-open" : "nav-menu-pane") +
          (isMobile ? " nav-menu-mobile" : "")
        }
      >
        <div
          style={{
            display: isMobile && "flex",
            justifyContent: isMobile && "center",
            fontSize: isMobile && "30px",
          }}
          className={!isMobile ? "icon-flex" : "icon-flex-mobile"}
        >
          <Link
            to='/'
            style={{ marginLeft: 0, backgroundColor: "transparent" }}
          >
            <img className='header__logo' alt='logo' src={logo} />
          </Link>
          <Button
            circle
            accent
            rel='noopener noreferrer'
            className={!isMobile ? "search-toggle" : "search-toggle-mobile"}
            onClick={() => setMenuOpen()}
          >
            <span className='fas fa-times' style={{ fontSize: "25px" }}></span>
          </Button>
        </div>
        {isMobile && <PrimaryMenu setMenuOpen={setMenuOpen} isOpen />}{" "}
        <div className='nmp-links'>
          <IconButton
            onClick={() => history.push("/account")}
            icon={user ? "user-check" : "user"}
          >
            Account
          </IconButton>
          <IconButton
            onClick={() => themeSetter(toggleTheme())}
            icon={themeIcon}
          >
            {`${
              localStorage.getItem("theme") === "dark" ? "Light" : "Dark"
            } Mode`}
          </IconButton>
          <IconButton
            onClick={() => history.push(AppPaths.LOGOUT)}
            icon={user ? "sign-out-alt" : "sign-in-alt"}
          >
            {user ? "Logout" : "Login"}
          </IconButton>
        </div>
        {isDev ? <DevPanel /> : ""}
        <div style={{ textAlign: "center" }}>
          <p>
            <b>Version</b> <br /> {process.env.REACT_APP_VERSION}
          </p>
          <p>
            <b>Last updated at</b> <br />
            {moment(process.env.REACT_APP_UPDATED_AT).format(
              "DD-MMM-YYYY (HH:MM:SS)",
            )}
          </p>
        </div>
        <PWAinstall type='drawer' />
      </div>
      <Dialog right open={openDialog} onClose={() => toggleDialog()}>
        <Jumbotron icon={"lnr lnr-alarm"} title={"Notifications"} />
        <UserNotifications />
      </Dialog>
    </div>
  )
}
