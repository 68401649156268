import React from "react"
import {
  Card,
  Row,
  Col,
  Space,
  Header,
  IconButtonCircle,
  Expander,
} from "uiComponents"
import AnalyticsTile from "./AnalyticsTile"
import BestSellers from "./BestSellers"
import SpaceBetween from "../../UI/Space/SpaceBetween"

const cardsData = [
  { title: "Stock levels", value: "99 SKU", icon: "layer-group" },
  { title: "Total Stock Sold", value: "178 SKU", icon: "cubes" },
]

const bestSellersData = [
  {
    img: "https://images.ctfassets.net/u4vv676b8z52/5NYNFwJPtbocJ1fIwbSCyJ/48d77da8c115b622558d59393bd36bee/kids-sunglasses-hero.gif?fm=jpg&q=80",
    title: "Sunglass RB 802",
    desc: "A cool modern styled sunglasses design to cater Indian rough climate.",
    sales: "2.9K",
  },
  {
    img: "https://img.pikbest.com/png-images/landscape-nature-t-shirt-design-template-free-download_1586362.png!sw800",
    title: "Cupid black t-shirt",
    desc: "A cool modern styled sunglasses design to cater Indian rough climate.",
    sales: "3.1K",
  },
]
const InventoryStatus = () => {
  return (
    <Card>
      <Expander
        title={"Inventory Status"}
        subTitle={
          "Monitor stock levels, manage replenishments, and control product visibility."
        }
      >
        <Space lg />
        <Row>
          {cardsData.map(data => (
            <Col sm={12} md={12} lg={6}>
              <AnalyticsTile
                title={data.title}
                value={data.value}
                icon={data.icon}
                noIcon
                light
              />
            </Col>
          ))}
        </Row>
        <Space />
        <SpaceBetween>
          <Header sm bold>
            Low Inventory
          </Header>
          <IconButtonCircle accent icon={"chevron-right"} />
        </SpaceBetween>
        <Row>
          {bestSellersData.map(data => (
            <Col sm={6} md={6} lg={3}>
              <BestSellers
                img={data.img}
                title={data.title}
                desc={data.desc}
                sales={data.sales}
              />
            </Col>
          ))}
        </Row>
      </Expander>
    </Card>
  )
}

export default InventoryStatus
