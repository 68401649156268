import React, { useState, useEffect, Children, useCallback } from "react"
import "./SimpleCarousel.css"

const SimpleCarousel = ({ children, interval = 10000, style }) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const [exiting, setExiting] = useState(false)
  const totalChildren = Children.count(children)
  const handleNext = useCallback(() => {
    setExiting(true)
    setTimeout(() => {
      setExiting(false)
      setCurrentIndex(prevIndex => (prevIndex + 1) % totalChildren)
    }, 500)
  }, [totalChildren])

  useEffect(() => {
    const timer = setInterval(() => {
      handleNext()
    }, interval)

    return () => clearInterval(timer)
  }, [handleNext, interval])

  return (
    <div className='carousel-container' style={{ ...style }}>
      <div className={`carousel-content ${exiting ? "exiting" : ""}`}>
        {Children.toArray(children)[currentIndex]}
      </div>
      <div className='carousel-indicators'>
        {Array.from({ length: totalChildren }).map((_, index) => (
          <span
            key={index}
            className={`indicator ${index === currentIndex ? "active" : ""}`}
            onClick={() => {
              setExiting(true)
              setTimeout(() => {
                setExiting(false)
                setCurrentIndex(index)
              }, 500)
            }}
          />
        ))}
      </div>
    </div>
  )
}

export default SimpleCarousel
