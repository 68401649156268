// src/containers/Customers/index.js
import React, { useState } from "react"
import {
  Col,
  Row,
  Card,
  Header,
  Space,
  Button,
  IconButtonCircle,
  SpaceBetween,
} from "uiComponents"
import { PageLayout } from "appComponents"
import DataTable from "react-data-table-component"
import * as XLSX from "xlsx"
import { ImportDialog, ExportDialog } from "./DialogData"
import FilterData from "./FilterData"
import { useStateValue } from "state"
import { isMobile } from "helpers"

function convertArrayOfObjectsToExcel(array) {
  const worksheet = XLSX.utils.json_to_sheet(array)
  const workbook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(workbook, worksheet, "Customers")
  return XLSX.write(workbook, { bookType: "xlsx", type: "array" })
}

function downloadExcel(array) {
  const excelBuffer = convertArrayOfObjectsToExcel(array)
  const blob = new Blob([excelBuffer], { type: "application/octet-stream" })
  const link = document.createElement("a")
  const filename = "customers_export.xlsx"

  link.href = URL.createObjectURL(blob)
  link.setAttribute("download", filename)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

const Customers = () => {
  const [data, setData] = useState([])
  const [columns, setColumns] = useState([])
  const [importDialog, setImportDialog] = useState(false)
  const [exportDialog, setExportDialog] = useState(false)
  const [uploading, setUploading] = useState(false)
  const [selectedRows, setSelectedRows] = useState([])
  const [searchTerm, setSearchTerm] = useState("")
  const [{ isFilterMenuOpen }, dispatch] = useStateValue()

  const handleImportExcel = e => {
    const file = e.target.files[0]
    if (file) {
      setUploading(true)

      const reader = new FileReader()
      reader.onload = e => {
        const data = new Uint8Array(e.target.result)
        const workbook = XLSX.read(data, { type: "array" })
        const sheetName = workbook.SheetNames[0]
        const worksheet = workbook.Sheets[sheetName]
        const jsonData = XLSX.utils.sheet_to_json(worksheet)

        if (jsonData.length > 0) {
          const headers = Object.keys(jsonData[0])
          const columns = headers.map(header => ({
            name: header,
            selector: row => row[header],
            sortable: true,
          }))
          setColumns(columns)
        }

        setData(jsonData)
        setUploading(false)
      }

      reader.readAsArrayBuffer(file)
    }
  }

  const handleRowSelected = state => {
    setSelectedRows(state.selectedRows)
  }

  const filterData = (data, searchTerm) => {
    if (!searchTerm) return data
    return data.filter(row => {
      return Object.values(row).some(
        value =>
          String(value).toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1,
      )
    })
  }

  const handleFilterMenuToggle = () => {
    dispatch({
      type: !isFilterMenuOpen ? "OPEN_FILTER_MENU" : "CLOSE_FILTER_MENU",
    })
  }

  return (
    <PageLayout
      sideContent={
        isFilterMenuOpen && (
          <>
            <FilterData />
          </>
        )
      }
      mainContent={
        <>
          <Row>
            <Col sm={12} md={12}>
              <Card>
                <Header sm bold>
                  Customers
                </Header>
                <p>
                  You can manage your customer data, export and import customer
                  data
                </p>
                <Space lg />
                <div
                  style={{
                    backgroundColor: "var(--background)",
                    marginLeft: "5px",
                    borderRadius: "10px",
                    padding: "15px",
                  }}
                >
                  <SpaceBetween>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <input
                        type='text'
                        value={searchTerm}
                        placeholder='Search'
                        onChange={e => setSearchTerm(e.target.value)}
                        style={{
                          padding: "10px",
                          borderRadius: "10px",
                          border: "none",
                          placeholder: "Search",
                          maxWidth: isMobile && "150px",
                        }}
                      />

                      <IconButtonCircle
                        icon={
                          isFilterMenuOpen ? "fas fa-times" : "fas fa-filter"
                        }
                        color='var(--base-font-color)'
                        large
                        ml={isMobile ? "" : "10"}
                        size='20'
                        onClick={handleFilterMenuToggle}
                      />
                    </div>

                    <div style={{ display: "flex", alignItems: "center" }}>
                      <input
                        type='file'
                        accept='.xlsx'
                        onChange={handleImportExcel}
                        style={{ display: "none" }}
                        id='import-excel'
                      />
                      {isMobile ? (
                        <span
                          className='fas fa-file-import'
                          style={{ fontSize: "20px", marginRight: "10px" }}
                          onClick={() => setImportDialog(true)}
                        />
                      ) : (
                        <Button noMar onClick={() => setImportDialog(true)}>
                          Import
                        </Button>
                      )}

                      {isMobile ? (
                        <span
                          className='fas fa-file-export'
                          style={{ fontSize: "20px" }}
                          onClick={() => setExportDialog(true)}
                        />
                      ) : (
                        <Button
                          noMar
                          accent
                          onClick={() => setExportDialog(true)}
                        >
                          Export
                        </Button>
                      )}
                    </div>
                  </SpaceBetween>
                  <Space lg />
                  <div style={{ overflowX: "auto" }}>
                    {uploading ? (
                      <p>Uploading...</p>
                    ) : (
                      <DataTable
                        columns={columns}
                        data={filterData(data, searchTerm)}
                        selectableRows
                        onSelectedRowsChange={handleRowSelected}
                        pagination
                      />
                    )}
                  </div>
                </div>
              </Card>
            </Col>
          </Row>

          <ImportDialog
            open={importDialog}
            onClose={() => setImportDialog(false)}
            uploading={uploading}
            onChooseFile={() => {
              setImportDialog(false)
              document.getElementById("import-excel").click()
            }}
          />
          <ExportDialog
            open={exportDialog}
            onClose={() => setExportDialog(false)}
            selectedRows={selectedRows}
            onExport={() => {
              setExportDialog(false)
              downloadExcel(selectedRows)
            }}
          />
        </>
      }
    />
  )
}

export default Customers
