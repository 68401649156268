import React, { useState } from "react"
import { Row, Col, Card, Header, Space } from "uiComponents"
import { FormMaker } from "../../Forms"
import { isMobile } from "helpers"
import Submissions from "./Submissions"

const AppDetails = () => {
  const [viewChange, setViewChange] = useState(false)

  const handleViewChange = () => {
    setViewChange(!viewChange)
  }

  const fields = [
    { name: "app_name", label: "App Name", type: "text", fullWidth: true },
    {
      name: "app_desc",
      label: "App Description",
      type: "textarea",
      fullWidth: true,
    },
  ]

  return !viewChange ? (
    <Row>
      <Col sm={12} md={12}>
        <Card>
          <Header sm bold>
            App Details
          </Header>
          <p>Please fill the required application details</p>
          <Row>
            <Col sm={12} md={6}>
              <FormMaker
                submitLabel={"Proceed to Submission"}
                fields={fields}
                validateOnMount
                onSubmit={handleViewChange}
              />
            </Col>
            <Col
              sm={12}
              md={6}
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Row center='xs'>
                <div style={{ marginBottom: isMobile ? "2rem" : "" }}>
                  <Header bold>App Logo</Header>
                  <Space />
                  <AppLogoUpload />
                </div>
                <div style={{ marginLeft: "20px" }}>
                  <Header bold>App Splash Screen</Header>
                  <Space />
                  <AppLogoUpload large />
                </div>
              </Row>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  ) : (
    <Submissions />
  )
}
export default AppDetails

export const AppLogoUpload = ({ large }) => {
  return (
    <>
      <div
        style={{
          backgroundColor: "var(--background)",
          height: large ? "400px" : "120px",
          width: large ? "230px" : "110px",
          padding: "20px",
          borderRadius: "10px",
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          cursor: "pointer",
        }}
      >
        <i
          style={{
            fontSize: large ? "50px" : "30px",
            marginBottom: "10px",
          }}
          className='fas fa-upload'
        />
        <div>
          <p style={{ fontSize: "10px" }}>Max Size: 1MB</p>
          <p style={{ fontSize: "10px" }}>File Type: .PNG</p>
        </div>
      </div>
    </>
  )
}
