import React, { useState } from "react"
import { Row, Col, Card, Button, Space, Header } from "uiComponents"
import { PageLayout } from "appComponents"
import getSVG from "../../../images/svgs/svg"
import MarketingForm from "./MarketingForm"
import { isMobile } from "helpers"

const Marketing = () => {
  const [viewChange, setViewChange] = useState(false)

  const handleViewChange = () => {
    setViewChange(!viewChange)
  }
  return (
    <PageLayout
      mainContent={
        !viewChange ? (
          <Row>
            <Col sm={12} md={12}>
              <Card
                themed
                svgIcon={getSVG(
                  "App_data",
                  { width: "100%", height: "40rem" },
                  null,
                  true,
                )}
              >
                <Row>
                  <Col sm={12} md={12}>
                    <Button small color='var(--success)'>
                      Elite
                    </Button>
                    <Header themed xxl bold>
                      Run Ads, and Generate <br /> Revenue Effortlessly
                    </Header>
                    <p style={{ fontSize: "20px", fontWeight: "500" }}>
                      Unlock the potential of your business by creating <br />
                      your own marketing adds.
                    </p>
                    <Space lg />
                    <Space lg />
                    <div>
                      <Button
                        noMar
                        fullRound
                        extraPad
                        alt
                        fullWidth={isMobile}
                        onClick={handleViewChange}
                      >
                        Get Started
                      </Button>
                      <Button noMar fullRound extraPad fullWidth={isMobile}>
                        Learn More
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        ) : (
          <MarketingForm />
        )
      }
    />
  )
}

export default Marketing
