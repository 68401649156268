import React from "react"
import "./SalesDashboard.css"
import {
  Col,
  Row,
  Card,
  Header,
  IconButtonCircle,
  Space,
  SpaceBetween,
} from "uiComponents"
import { salesData } from "dummyData"

const SalesAnalytics = () => {
  return (
    <>
      <Card noXS notFull>
        <div style={{ display: "flex", gap: "10px" }}>
          <Header lg>Hello, </Header>
          <Header lg bold>
            John Doe
          </Header>
        </div>
        <p>
          Welcome to your sales analytics dashboard. Here, you can track your
          sales, performance, and trends.
        </p>
        <Space lg />
        <Space lg />
        <Row>
          {salesData.map(data => (
            <Col key={data.id} sm={12} md={6} lg={4} xl={4}>
              <AnalyticsCard analyticsData={[data]} />
            </Col>
          ))}
        </Row>
      </Card>
    </>
  )
}

export const AnalyticsCard = ({ analyticsData }) => {
  return (
    <>
      {analyticsData.map(data => (
        <div key={data.id} className='sales-analytics-card'>
          <div className='container-one'>
            <IconButtonCircle large icon={data.icon} size='20' />
          </div>
          <div className='container-two'>
            <Header noMar bold>
              {data.title}
            </Header>
            <Space sm />
            <SpaceBetween>
              <Header noMar sm bold>
                {data.taskCount}
              </Header>
              <p style={{ color: data.color }}>
                <i
                  className={`fas fa-lg fa-caret-${
                    data.indicator === "up" ? "up" : "down"
                  }`}
                />
                &nbsp; {data.taskChange}
              </p>
            </SpaceBetween>
          </div>
        </div>
      ))}
    </>
  )
}

export default SalesAnalytics
