import React from "react"
import { Card, Header, Space, Col } from "uiComponents"
import SalesOverview from "./SalesOverview"
import CustomerOverview from "./CustomerOverview"
import OrderAnalytics from "./OrderAnalytics"
import RevenueProfits from "./RevenueProfits"
import InventoryStatus from "./InventoryStatus"

const Analytics = () => {
  return (
    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
      <Card notFull>
        <Header md bold>
          Store Analytics
        </Header>
        <SalesOverview />
        <Space />
        <CustomerOverview />
        <Space />
        <OrderAnalytics />
        <Space />
        <RevenueProfits />
        <Space />
        <InventoryStatus />
      </Card>
    </Col>
  )
}

export default Analytics
