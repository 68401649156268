import React from "react"
import { Space, Row, Col, Card, Header, Icon } from "uiComponents"
import "./card.css"
import { isMobile } from "helpers"

const StatusCard = ({ title, desc, iconClass, actions }) => {
  return (
    <Card>
      <Space md />

      <Row start='xs' middle='xs' style={{ margin: 0 }}>
        <Col xs={12} md={1} style={{ textAlign: isMobile ? "left" : "center" }}>
          <Icon
            size='8rem'
            inline
            iconClass={iconClass || "lnr lnr-checkmark-circle"}
          />
          {isMobile ? <Space lg /> : ""}
        </Col>
        <Col xs={11} md={10}>
          <Header lg>{title ? title : `Title`}</Header>
          <p>{desc}</p>
          <Space />
          <Space />
          {actions}
        </Col>
      </Row>
      <Space md />
    </Card>
  )
}

export default StatusCard
