import React from "react"
import "./SalesDashboard.css"
import { Col, Row, Card, Grid } from "uiComponents"
import SalesAnalytics from "./SalesAnalytics"
import SalesManagement from "./SalesManagement"

const SalesDashboard = () => {
  return (
    <>
      <Grid fluid>
        {" "}
        <Row>
          <Col sm={12} md={12} lg={6} xl={2}>
            <Card noXS notFull></Card>
          </Col>
          <Col sm={12} md={12} lg={6} xl={7}>
            <SalesAnalytics />
            <SalesManagement />
          </Col>
          <Col sm={12} md={12} lg={6} xl={3}>
            <Card noXS notFull></Card>
          </Col>
        </Row>
      </Grid>
    </>
  )
}

export default SalesDashboard
