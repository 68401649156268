export const sellerFormFields = [
  {
    name: "name",
    label: "Full Name",
    dataTip: "Please tell us your good name",
    type: "text",
  },
  {
    name: "email",
    label: "Email Address",
    dataTip: "Please enter your email ID",
    type: "text",
  },
  {
    name: "phoneNumber",
    label: "Phone Number",
    dataTip: "Please enter your phone number",
    type: "phoneNumber",
  },
  {
    name: "dob",
    type: "date",
    label: "Date of Birth",
    dataTip: "Please enter your date of birth",
  },
  {
    name: "gender",
    label: "Gender",
    type: "radio",
    dataTip: "Please choose your gender",
    inline: true,
    options: ["Male", "Female", "Other"].map(v => ({ label: v, value: v })),
  },
]

export const sellerCarouselData = [
  {
    svg: "logistics",
    subTitle: "Store Management",
    desc: ["Add/edit/delete products/services", "Manage inventory"],
  },
  {
    svg: "Checking_boxes",
    subTitle: "Order Management",
    desc: [
      "View orders and order history",
      "Process orders and update order status",
    ],
  },
  {
    svg: "Data_analysis",
    subTitle: "Analytics",
    desc: ["Sales reports and analytics", "Customer insights"],
  },
]
