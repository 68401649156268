import React, { useState } from "react"
import { PageLayout } from "appComponents"
import {
  Row,
  Col,
  Card,
  Header,
  Space,
  IconButtonCircle,
  Button,
} from "uiComponents"
import "./ThemeModule.css"
import { FormMaker } from "formComponents"
import SpaceBetween from "../../UI/Space/SpaceBetween"
import builderAd from "../../../images/builderAdd.png"
import { BlockPicker } from "react-color"
import GoogleFontFetcher from "../../../theme/font"
import { hexToRgba, isTablet, isMobile } from "helpers"

const themeColors = [
  { type: "Primary", colorCode: "#4A67FF", blockColor: "#4A67FF" },
]

const utilityColors = [
  { type: "Danger", colorCode: "#BE2C2C", blockColor: "#BE2C2C" },
  { type: "Success", colorCode: "#128807", blockColor: "#128807" },
  { type: "Neutral", colorCode: "#6A73A7", blockColor: "#6A73A7" },
]

const ThemeModule = () => {
  const [selectedCard, setSelectedCard] = useState(0)
  const [primaryColor, setPrimaryColor] = useState("#4A67FF")
  const [dangerColor, setDangerColor] = useState("#BE2C2C")
  const [neutralColor, setNeutralColor] = useState("#6A73A7")
  const [successColor, setSuccessColor] = useState("#128807")

  const [primaryFont, setPrimaryFont] = useState("")
  const [secondaryFont, setSecondaryFont] = useState("")
  const [isRounded, setIsRounded] = useState(true)

  const handleSelect = index => {
    setSelectedCard(index)
    setIsRounded(index === 0)
  }

  const handleColorChange = (type, color) => {
    if (type === "Primary") {
      setPrimaryColor(color.hex)
    } else if (type === "Danger") {
      setDangerColor(color.hex)
    } else if (type === "Neutral") {
      setNeutralColor(color.hex)
    } else if (type === "Success") {
      setSuccessColor(color.hex)
    }
  }

  const fields = [
    {
      name: "primary_font",
      label: "Primary Font",
      type: "select",
      onChange: e => setPrimaryFont(e.target.value),
      children: [
        "Roboto",
        "Open Sans",
        "Montserrat",
        "Lato",
        "Poppins",
        "Source Sans Pro",
        "Raleway",
        "Noto Sans",
        "Inter",
        "Roboto Slab",
        "Merriweather",
        "Playfair Display",
      ].map(op => (
        <option key={op} value={op}>
          {op}
        </option>
      )),
    },
    {
      name: "secondary_font",
      label: "Secondary Font",
      type: "select",
      onChange: e => setSecondaryFont(e.target.value),
      children: [
        "Roboto",
        "Open Sans",
        "Montserrat",
        "Lato",
        "Poppins",
        "Source Sans Pro",
        "Raleway",
        "Noto Sans",
        "Inter",
        "Roboto Slab",
        "Merriweather",
        "Playfair Display",
      ].map(op => (
        <option key={op} value={op}>
          {op}
        </option>
      )),
    },
  ]

  return (
    <PageLayout
      mainContent={
        <Row>
          <Col sm={12} md={12}>
            <Card>
              <Row>
                <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                  <Header sm bold>
                    Theme & Customisation
                  </Header>
                  <p>Customize your account to start selling on Shoppio.</p>
                  <Space lg />
                  <Header accent sm>
                    Theme
                  </Header>
                  <Space lg />
                  {themeColors.map((color, index) => (
                    <React.Fragment key={index}>
                      <ColorCard
                        type={color.type}
                        colorCode={color.colorCode}
                        blockColor={color.blockColor}
                        onColorChange={handleColorChange}
                      />
                      <Space lg />
                    </React.Fragment>
                  ))}
                  <Header accent sm>
                    Utility
                  </Header>
                  <Space lg />
                  <Row style={{ padding: "0px 8px", gap: "30px" }}>
                    {utilityColors.map((color, index) => (
                      <ColorCard
                        key={index}
                        type={color.type}
                        colorCode={color.colorCode}
                        blockColor={color.blockColor}
                        onColorChange={handleColorChange}
                      />
                    ))}
                  </Row>
                  <Space lg />
                  <Header accent sm>
                    Font Family
                  </Header>
                  <Space />
                  <FormMaker fields={fields} hideSubmit />
                  <Header accent sm>
                    Corners
                  </Header>
                  <Space lg />
                  <Row style={{ padding: "0px 8px", gap: "30px" }}>
                    {[true, false].map((isRounded, index) => (
                      <CornersCard
                        key={index}
                        isRounded={isRounded}
                        isSelected={selectedCard === index}
                        onSelect={() => handleSelect(index)}
                      />
                    ))}
                  </Row>
                </Col>
                <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                  <Header sm bold>
                    Preview
                  </Header>
                  <Space lg />
                  <TemplatePreview
                    primaryColor={primaryColor}
                    dangerColor={dangerColor}
                    neutralColor={neutralColor}
                    successColor={successColor}
                    primaryFont={primaryFont}
                    secondaryFont={secondaryFont}
                    isRounded={isRounded}
                  />
                </Col>
              </Row>
              <Space lg />
              <StoreBuilderAd />
            </Card>
          </Col>
        </Row>
      }
    />
  )
}

export default ThemeModule

const ColorCard = ({ type, colorCode, blockColor, onColorChange }) => {
  const [showPicker, setShowPicker] = useState(false)
  const [selectedColor, setSelectedColor] = useState(blockColor)

  const handleClick = () => setShowPicker(!showPicker)

  const handleChange = color => {
    setSelectedColor(color.hex)
    onColorChange(type, color)
    setShowPicker(false)
  }

  return (
    <div style={{ position: "relative" }}>
      <Header bold>{type}</Header>
      <div className='color-card-container' onClick={handleClick}>
        <div
          className='color-block'
          style={{ backgroundColor: selectedColor }}
        />
        <div className='color-code'>{selectedColor}</div>
      </div>
      {showPicker && (
        <div style={{ position: "absolute", zIndex: "2", top: "100%" }}>
          <Space />
          <BlockPicker color={selectedColor} onChange={handleChange} />
        </div>
      )}
    </div>
  )
}

const CornersCard = ({ isRounded, isSelected, onSelect }) => (
  <div
    className={`corners-card-container ${isSelected ? "selected" : ""}`}
    onClick={onSelect}
  >
    <SpaceBetween>
      <Header sm>{isRounded ? "Rounded" : "Square"}</Header>
      <i
        className={`${
          isSelected ? "fas fa-check-circle" : "far fa-circle"
        } check-icon`}
      />
    </SpaceBetween>
    <div className={`corners-block ${isRounded ? "rounded" : "square"}`}></div>
  </div>
)

const TemplatePreview = ({
  primaryColor,
  dangerColor,
  successColor,
  neutralColor,
  primaryFont,
  secondaryFont,
  isRounded,
}) => {
  const backgroundColor = hexToRgba(neutralColor, 0.1)
  return (
    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
      <div className='template-preview-container'>
        <div
          className={`template-preview ${
            isRounded ? "rounded-corners" : "square-corners"
          }`}
        >
          <div className='template-preview-content'>
            <div className='template-image-container'>
              <img
                src='https://www.bobswatches.com/rolex-blog/wp-content/uploads/2017/01/Green-Gold-Rolex-GMT-Master.jpg'
                alt='Watch Preview'
                style={{ width: "100%", height: "auto" }}
                className={isRounded ? "rounded-corners-img" : "square-corners"}
              />
              <div
                className={`out-of-stock-label ${
                  isRounded ? "rounded-corners" : "square-corners"
                }`}
                style={{ color: dangerColor, fontFamily: secondaryFont }}
              >
                Out of Stock
              </div>
            </div>
            <Space lg />
            <div className='template-details-container'>
              <div>
                <SpaceBetween>
                  <Header lg bold style={{ fontFamily: primaryFont }}>
                    Rollex Grandior 2024
                  </Header>
                  <div
                    style={{
                      position: "relative",
                      display: "inline-block",
                      top: "-8px",
                    }}
                  >
                    <i
                      className='fas fa-certificate'
                      style={{ fontSize: "40px", color: successColor }}
                    ></i>
                    <span className='discount-percentage-text'>50% off</span>
                  </div>
                </SpaceBetween>
                <p style={{ fontSize: "16px", fontFamily: secondaryFont }}>
                  A great watch, stylish good very chrome black finish,
                  available for exchange...
                </p>
              </div>
              {primaryFont && (
                <GoogleFontFetcher
                  href={`https://fonts.googleapis.com/css2?family=${primaryFont.replace(
                    / /g,
                    "+",
                  )}&display=swap`}
                />
              )}
              {secondaryFont && (
                <GoogleFontFetcher
                  href={`https://fonts.googleapis.com/css2?family=${secondaryFont.replace(
                    / /g,
                    "+",
                  )}&display=swap`}
                />
              )}
              <Space lg />
              <div
                className={`category-tag ${
                  isRounded ? "rounded-corners" : "square-corners"
                }`}
                style={{
                  color: neutralColor,
                  backgroundColor: backgroundColor,
                }}
              >
                watch
              </div>
              <Space lg />
              <div>
                <SpaceBetween style={{ marginTop: "5px" }}>
                  <Header
                    noMar
                    lg
                    bold
                    style={{
                      color: primaryColor,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    ₹ 4,30,000
                  </Header>
                  <div className='template-actions-container'>
                    <IconButtonCircle large size='20' icon='heart' />
                    {!isTablet && (
                      <div
                        className={`add-to-cart-button ${
                          isRounded
                            ? "rounded-corners-button"
                            : "square-corners"
                        }`}
                        style={{
                          backgroundColor: primaryColor,
                          fontFamily: secondaryFont,
                        }}
                      >
                        Add to Cart
                      </div>
                    )}
                  </div>
                </SpaceBetween>
                {isTablet && (
                  <div
                    className={`add-to-cart-button ${
                      isRounded ? "rounded-corners-button" : "square-corners"
                    }`}
                    style={{
                      backgroundColor: primaryColor,
                      fontFamily: secondaryFont,
                    }}
                  >
                    Add to Cart
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Col>
  )
}

const StoreBuilderAd = () => (
  <Card extraPad notFull noXS>
    <Row>
      <Col sm={12} md={8}>
        <Header lg bold>
          Launching Shoppio Store Builder
        </Header>
        <p style={{ fontSize: "24px" }}>
          Make your store unique by changing the layout
          <br />
          using our powerful store builder
        </p>
        <Space lg />
        <Button fullWidth={isMobile}>Launch Builder</Button>
        <Button fullWidth={isMobile} accent>
          Learn More
        </Button>
      </Col>
      <Col
        sm={12}
        md={4}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img src={builderAd} alt='Store Builder Ad' />
      </Col>
    </Row>
  </Card>
)
