import React from "react"
import { Card, Row, Col, Space, Expander } from "uiComponents"
import AnalyticsTile from "./AnalyticsTile"

const RevenueProfits = () => {
  const cardsData = [
    { title: "Total Revenue", value: "129,982.9K", icon: "rupee-sign" },
    { title: "Net Profits", value: "78K", icon: "rupee-sign" },
    {
      title: "Average order value (AOV)",
      value: "367",
      icon: "coins",
    },
    {
      title: "Key details",
      value: "1090",
      icon: "key",
    },
  ]
  return (
    <Card>
      <Expander
        title={"Renvenue and Profits"}
        subTitle={
          "Track revenue and profit reports, assess profitability, and manage products and categories"
        }
      >
        <Space lg />
        <Row>
          {cardsData.map(data => (
            <Col sm={12} md={12} lg={6}>
              <AnalyticsTile
                title={data.title}
                value={data.value}
                icon={data.icon}
                noIcon
                light
              />
            </Col>
          ))}
        </Row>
      </Expander>
    </Card>
  )
}

export default RevenueProfits
