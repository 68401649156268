import React, { useEffect, useState } from "react"
import {
  Card,
  Button,
  Space,
  Divider,
  IconButtonCircle,
  Loader,
} from "uiComponents"
import { AvatarChip } from "../QueryResolution"
import { getAvatarColor, parseDateTime } from "helpers"
import { addDataToDb } from "firebaseUtils"
import { useStateValue } from "state"
import { CollectionRenderer } from "firestoreComponents"
import { getInitials } from "../../Messages/CustomerMessages"
import { FormMaker } from "formComponents"

const MessageCard = ({ ticketID }) => {
  const [showImage, setShowImage] = useState({})
  const [message, setMessage] = useState("")
  const [busy, setBusy] = useState(false)
  const [{ user, userMeta }, dispatch] = useStateValue()
  const [shouldReload, setShouldReload] = useState(false)
  const [attachments, setAttachments] = useState([])

  const handleClick = id => {
    setShowImage(prevState => ({
      ...prevState,
      [id]: !prevState[id],
    }))
  }

  const handleSubmit = async () => {
    const url = `tickets/${ticketID}/messages`

    const values = { message, attachments }

    try {
      setBusy(true)
      const result = await addDataToDb(
        url,
        {
          ...values,
          sender_name: userMeta?.name,
          sender_id: user?.uid,
          createdAt: new Date().toISOString(),
        },
        dispatch,
        "Message added successfully!",
        doc => {
          console.log("Document saved successfully:", doc)
        },
      )
      if (result === "success") {
        setMessage("")
        setAttachments([])
        setShouldReload(true)
        console.log("Message added successfully!")
      }
      setBusy(false)
    } catch (error) {
      console.error("Error adding message:", error)
      setBusy(false)
    }
  }

  useEffect(() => {
    if (shouldReload) {
      setShouldReload(false)
    }
  }, [shouldReload])

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          width: "100%",
        }}
      >
        <CollectionRenderer
          shouldReload={shouldReload}
          sorting={{ orderBy: "createdAt", orderByMethod: "asc" }}
          collectionPath={`tickets/${ticketID}/messages`}
          viewType={"custom"}
          noDocMessage={"Chat not started yet"}
          renderDoc={({ id, data }) => {
            const isSender = data.sender_id === user?.uid
            return (
              <div
                style={{
                  display: "flex",
                  justifyContent: isSender ? "flex-end" : "flex-start",
                  width: "100%",
                }}
              >
                <Card
                  lessHorPad
                  noXS
                  notFull
                  style={{
                    maxWidth: "450px",
                    marginRight: "10px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      {!isSender && (
                        <AvatarChip
                          large
                          label={getInitials(data.sender_name)}
                          style={{ marginRight: "10px" }}
                          color={getAvatarColor(data.sender_name)}
                        />
                      )}
                      <p style={{ marginRight: "5rem" }}>{data.message}</p>
                    </div>
                    <Space lg />
                    {isSender && (
                      <AvatarChip
                        large
                        label={getInitials(data.sender_name)}
                        color={getAvatarColor(data.sender_name)}
                      />
                    )}
                  </div>
                  <Space />
                  <Divider fullWidth />
                  <Space lg />
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Space lg />
                      {busy ? (
                        <Loader type='general' />
                      ) : (
                        data?.attachments.length > 0 && (
                          <IconButtonCircle
                            bgColor='var(--foreground)'
                            small
                            icon={showImage[id] ? "times" : "paperclip"}
                            size='10'
                            onClick={() => handleClick(id)}
                          />
                        )
                      )}
                    </div>

                    <p style={{ marginLeft: "5px" }}>
                      {parseDateTime(data.createdAt)}
                    </p>
                  </div>

                  {showImage[id] && (
                    <div className='tasks-messages-container-image'>
                      {data.attachments?.map((img, index) => (
                        <img
                          key={index}
                          src={img.url}
                          alt={img.name}
                          className='tasks-messages-image'
                        />
                      ))}
                    </div>
                  )}
                </Card>
              </div>
            )
          }}
        />
      </div>

      <div
        className='task-message-input-container'
        style={{
          position: "absolute",
          bottom: "-50px",
          width: "100%",
        }}
      >
        <div style={{ display: "flex", width: "100%", alignItems: "center" }}>
          <FormMaker
            hideSubmit
            fields={[
              {
                name: "name",
                type: "text",
                placeholder: "Enter your message",
                fullWidth: true,
                noMar: true,
                value: message,
                onChange: e => setMessage(e.target.value),
                onFilesChange: uploadedFiles => {
                  console.log("Files changed uploaded:", uploadedFiles)
                  setAttachments(uploadedFiles)
                },
              },
            ]}
          />
          <Button
            busy={busy}
            extraPad
            onClick={handleSubmit}
            style={{
              marginRight: "0px",
              marginLeft: "10px",
            }}
          >
            Send
          </Button>
        </div>
      </div>
    </>
  )
}

export default MessageCard
