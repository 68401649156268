import React from "react"
import { Col, Row } from "uiComponents"
import { PageLayout } from "appComponents"
import Analytics from "./Analytics"
import LiveAnalytics from "./LiveAnalytics"
import Traffic from "./Traffic"
import Feedback from "./Feedback"

const StoreAnalytics = () => {
  return (
    <PageLayout
      mainContent={
        <Row>
          <Col sm={12} md={12} lg={12} xl={9} style={{ padding: "0px" }}>
            <Analytics />
          </Col>
          <Col sm={12} md={12} lg={12} xl={3}>
            <LiveAnalytics />
            <Traffic />
            <Feedback />
          </Col>
        </Row>
      }
    />
  )
}

export default StoreAnalytics
