import { functions } from "firebase"
import { firebaseApp } from "firebaseUtils"
import { useEffect } from "react"
import { useStateValue } from "state"

export const getAndSetEnvs = async dispatch => {
  const getEnvs = functions(firebaseApp).httpsCallable("getEnvs")
  const envs = (await getEnvs())?.data
  dispatch({
    type: "GENERAL",
    data: {
      envs,
    },
  })
}

export const useFetchEnvs = () => {
  const [, dispatch] = useStateValue()
  useEffect(() => {
    getAndSetEnvs(dispatch)
  }, [dispatch])
}
