import React from "react"
import "./divider.css"

const Divider = props => {
  return (
    <div
      className='divider'
      style={{
        margin: props.margin ? "1rem 0" : "",
        backgroundColor: props.color,
        maxWidth: props.fullWidth && "100%",
        ...props.style,
      }}
    ></div>
  )
}

export default Divider
