import React from "react"
import MainContent from "./components/MainContent"
import SideContent from "./components/SideContent"
import { PrimaryMenu, Row, Grid } from "uiComponents"
import { Training } from "../Training"
import { isMobile } from "../../../helpers"

export default function PageLayout({ mainContent, sideContent }) {
  return (
    <>
      {!isMobile && <PrimaryMenu />}
      <Grid fluid style={{ paddingLeft: isMobile ? "" : "12rem" }}>
        <Row>
          <MainContent>{mainContent}</MainContent>
          <SideContent>{sideContent || <Training />}</SideContent>
        </Row>
      </Grid>
    </>
  )
}
