import React from "react"
import { DocMaker } from "firestoreComponents"
import { getTime } from "helpers"
import { useStateValue } from "state"
import { sendNotification, MessagePopup } from "appComponents"
import { getMessageFields } from "./MessageToCustomer"
import { Loader } from "uiComponents"
import { AppPaths, getPath } from "shoppio-constants"

const MessageToSeller = ({ onSelect }) => {
  const [{ user, userMeta, currentStore }] = useStateValue()
  let storeID = currentStore?.id
  let sellerUserID = currentStore?.addedBy
  console.log("Current", currentStore)
  return !storeID ? (
    <Loader />
  ) : (
    <DocMaker
      limit={3}
      sorting={{
        orderBy: "createdAt",
        orderByMethod: "asc",
      }}
      loadOnTop
      formInPortalElement={document.querySelector(".dialog-content")}
      collectionMaxHeight={"60vh"}
      collectionName={`messages/${storeID}/data/`}
      entityName='Message to Seller'
      forMessaging
      addDataOnScopedDoc={{
        name: userMeta?.name || "Shoppio User",
        createdAt: getTime(),
      }}
      scopedOn={user?.uid}
      canAdd={!onSelect}
      loaderType='message'
      onAdd={(id, message) => {
        sendNotification({
          title: `You have got a message from ${
            userMeta?.name || "Shoppio User"
          }`,
          body: message.message,
          link: {
            label: "View Messages",
            link: getPath(AppPaths.CUSTOMER_MESSAGES),
            state: {
              storeid: storeID,
              hash: user?.uid,
            },
          },
          toUserID: sellerUserID,
        })
      }}
      lg={12}
      onSelect={onSelect}
      fields={() =>
        getMessageFields({
          userMeta,
          isSeller: false,
        })
      }
      renderEntity={({ data: { message, createdAt, isSeller }, id }) => (
        <MessagePopup right={!isSeller} createdAt={createdAt}>
          {message}
        </MessagePopup>
      )}
    />
  )
}

export default MessageToSeller
