import { useCallback, useEffect, useState } from "react"

const useMutationObserver = callback => {
  const config = {
    attributes: true,
    childList: true,
    subtree: true,
  }
  const targetNodeRef = document.body

  useEffect(() => {
    if (!targetNodeRef) return

    // Create an instance of MutationObserver and pass in the callback
    const observer = new MutationObserver(callback)

    // Start observing the target node with the specified options
    observer.observe(targetNodeRef, config)

    // Cleanup function to disconnect the observer when the component unmounts
    return () => {
      observer.disconnect()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callback])

  return targetNodeRef
}

export const useGatherImages = () => {
  const [images, setImages] = useState([])
  const [trigger, setTrigger] = useState(false)

  const callback = useCallback(mutationsList => {
    for (let mutation of mutationsList) {
      if (mutation.type === "childList") {
        setTrigger(new Date().getTime())
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useMutationObserver(callback)
  useEffect(() => {
    if (trigger) {
      // Attach Event Listener to all the images in current dome
      const images = document.querySelectorAll(
        "img:not([data-nopreview='true'])",
      )
      setImages(images)
      setTrigger()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger])
  return images
}

const useAddClickInImages = () => {
  const images = useGatherImages()
  const [currentImage, setCurrentImage] = useState()
  const handleImageClick = event => {
    setCurrentImage(event.target)
  }
  useEffect(() => {
    if (images.length) {
      images?.forEach(image => {
        image.style.cursor = "pointer"
        image.addEventListener("click", handleImageClick)
      })
    }
    return () => {
      images?.forEach(image => {
        image.removeEventListener("click", handleImageClick)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [images])
  return { image: currentImage, onClose: () => setCurrentImage(null) }
}

export default useAddClickInImages
