import React from "react"
import Bugsnag from "@bugsnag/js"
import BugsnagPluginReact from "@bugsnag/plugin-react"
import BugsnagPerformance from "@bugsnag/browser-performance"
import { bugsnagConfig } from "config"

Bugsnag.start({
  apiKey: bugsnagConfig.apiKey,
  plugins: [new BugsnagPluginReact()],
})
BugsnagPerformance.start({ apiKey: bugsnagConfig.apiKey })

const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React)
export default function ErrorBoundaryContainer({ children }) {
  return <ErrorBoundary>{children}</ErrorBoundary>
}
