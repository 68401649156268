import React, { useState, useEffect, Fragment, useMemo } from "react"
import { DB } from "firebaseUtils"
import { cleanObject, getRandomString, isDev, isMobile } from "helpers"
import { useStateValue } from "state"
import {
  Grid,
  Col,
  Row,
  Button,
  Divider,
  Jumbotron,
  Space,
  Busy,
} from "uiComponents"
import { FormMaker } from "formComponents"
import { handleError } from "firebaseUtils"
import { CollectionRenderer } from "firestoreComponents"

const DocMaker = ({ onProfile, showBack, canAdd, ...props }) => {
  const parentID = useMemo(() => getRandomString(5), [])
  const [, dispatch] = useStateValue()
  const [busy, setBusy] = useState()
  const [shouldReload, setShouldReload] = useState()
  const [selectedEntity, setSelectedEntity] = useState()
  // const history = useHistory()
  const {
    fields,
    collectionName,
    scopedOn,
    entityName,
    title,
    desc,
    renderEntity,
    icon,
    md = 12,
    lg = 12,
    limit,
    onAdd,
    onUpdate,
    loading,
    validation,
    onDelete,
    addDataOnScopedDoc,
    forMessaging,
    loaderType,
    noDocMessage,
    formInPortalElement,
    noDocComponent,
    sorting,
    collectionMaxHeight,
    loadOnTop,
  } = props || {}
  const passedFields = fields && fields().filter(f => !f.hidden)
  const hiddenFields = fields && fields().filter(f => f.hidden)
  // console.log("addDataOnScopedDoc1", addDataOnScopedDoc)
  const base =
    scopedOn && collectionName
      ? DB.collection(collectionName).doc(scopedOn).collection("data")
      : {}
  const updateEntity = (data, cb) => {
    setBusy(true)
    if (data && data.id) {
      base
        .doc(data.id)
        .update({
          ...data,
        })
        .then(() => {
          if (isDev) console.log(entityName + " Updated")
          setBusy()
          if (onUpdate) onUpdate(data?.id, data)
          if (cb) cb()
          if (addDataOnScopedDoc) {
            DB.collection(collectionName)
              .doc(scopedOn)
              .update(addDataOnScopedDoc)
              .then()
              .catch(err => handleError(err))
          }
        })
        .catch(error => {
          setBusy()
          console.error("Error writing document: ", error)
        })
    } else {
      let extraData = {}
      hiddenFields.forEach(f => {
        extraData[f.name] = f.value
      })
      console.log("Before cleaned", data)
      let cleanedData = cleanObject(data)
      console.log("After cleaned", cleanedData, extraData)
      base
        .add({
          ...cleanedData,
          ...extraData,
        })
        .then(docRef => {
          if (isDev) console.log(entityName + " Added")
          if (addDataOnScopedDoc) {
            console.log("addDataOnScopedDoc", addDataOnScopedDoc)
            DB.collection(collectionName)
              .doc(scopedOn)
              .set(addDataOnScopedDoc, { merge: true })
              .then()
              .catch(err => handleError(err))
          } else {
            console.log("1111")
          }
          if (cb) cb()
          if (onAdd) onAdd(docRef?.id, cleanedData)
          setBusy()
          // console.log(props, "PROP");
          // props.onRegisterSuccess(extra?.user);
        })
        .catch(error => {
          setBusy()
          console.error("Error writing document: ", error)
        })
    }
  }
  const deleteAdd = (id, entity) => {
    setBusy(true)
    base
      .doc(id)
      .delete()
      .then(() => {
        setBusy()
        if (onDelete) onDelete(entity)
        setShouldReload(true)
        dispatch({
          type: "SHOW_ALERT",
          open: true,
          message: entityName + " deleted successfully",
        })
      })
  }
  useEffect(() => {
    if (forMessaging) {
      setSelectedEntity({})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forMessaging])

  useEffect(() => {
    if (shouldReload) {
      setShouldReload()
    }
  }, [shouldReload])
  // console.log(usableEnities);
  return (
    <Busy busy={busy || loading}>
      <Grid>
        <Space />
        <Space />
        <Jumbotron icon={icon} title={title} desc={desc} />
        <Row
          center={"xs"}
          start='md'
          id={parentID}
          style={
            collectionMaxHeight
              ? { maxHeight: collectionMaxHeight, overflowY: "auto" }
              : {}
          }
        >
          {(!selectedEntity || forMessaging) && (
            <CollectionRenderer
              parentID={parentID}
              shouldReload={shouldReload}
              collectionPath={`${collectionName}${scopedOn}/data/`}
              viewType={"custom"}
              loadOnTop={loadOnTop}
              loaderType={loaderType}
              sorting={sorting}
              noDocMessage={noDocMessage}
              noDocComponent={noDocComponent}
              limit={limit || 5}
              renderDoc={({ id, data }) => (
                <Col
                  xs={11}
                  sm={12}
                  md={md}
                  lg={lg}
                  style={{ paddingBottom: "2rem", textAlign: "left" }}
                >
                  {renderEntity({ data, id }, disabled =>
                    canAdd ? (
                      <Fragment>
                        <Button
                          accent
                          noMar
                          small
                          disabled={disabled}
                          onClick={() => {
                            setSelectedEntity({
                              id: id,
                              data: data,
                            })
                          }}
                        >
                          Update
                        </Button>
                        <Button
                          noMar
                          small
                          danger
                          disabled={disabled}
                          onClick={() => deleteAdd(id, data)}
                        >
                          Delete
                        </Button>
                      </Fragment>
                    ) : (
                      ""
                    ),
                  )}
                </Col>
              )}
            />
          )}
        </Row>
        <Row center='xs' style={{ textAlign: "left" }}>
          <Col xs={11} md={12}>
            {(selectedEntity ? false : true) && canAdd && (
              <Button
                accent
                small={isMobile}
                onClick={() => setSelectedEntity({})}
              >
                Add new {entityName}
              </Button>
            )}
            {selectedEntity && (
              <FormMaker
                hideSubmit={forMessaging}
                validation={validation}
                submitLabel={"Save " + entityName}
                fields={() => passedFields}
                initialValues={selectedEntity?.data}
                inPortalElement={formInPortalElement}
                onSubmit={formValues => {
                  setBusy(true)
                  if (selectedEntity?.id) formValues["id"] = selectedEntity?.id
                  updateEntity(formValues, () => {
                    setSelectedEntity(forMessaging ? {} : null)
                    setShouldReload(true)
                  })
                }}
                onCancel={forMessaging ? null : () => setSelectedEntity(null)}
                showBack={showBack}
              />
            )}
          </Col>
        </Row>
        {/* {usableEnities?.length === 0 && !(busy || loading) && (
          <NoRecord type='card' iconClass='fas fa-tag'>
            <div>No data available now</div>
            <Button onClick={() => history.goBack()}>Go Back</Button>
          </NoRecord>
        )} */}
        <Divider />
      </Grid>
    </Busy>
  )
}
export default DocMaker
