import React from "react"
import {
  Card,
  IconButtonCircle,
  Row,
  Header,
  Button,
  Col,
  Space,
} from "uiComponents"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { InputField } from "formComponents"
import { StatusChip } from "./Submissions"

const AppStatusReview = () => {
  const history = useHistory()
  //   const [errorText, setErrorText] = useState("")

  return (
    <>
      <Row>
        <Col sm={12} md={12}>
          <Card>
            <Row>
              <Col xs={8} sm={8} md={12} lg={9} xl={10}>
                <div style={{ display: "flex" }}>
                  <IconButtonCircle
                    icon='chevron-left'
                    mr='10'
                    onClick={() => history.goBack()}
                  />
                  <div>
                    <Header sm bold>
                      App Store Review
                    </Header>
                    <p>Fill the required information to resubmit app</p>
                  </div>
                </div>
              </Col>
              <Col xs={4} sm={4} md={12} lg={3} xl={2}>
                {/* <Button extraPad small color='var(--error)'>
                  Need Clarification
                </Button> */}
                <div>
                  <StatusChip
                    color='var(--error)'
                    text='need clarification'
                    icon='check'
                  />
                </div>
              </Col>
            </Row>
            <Space lg />
            <Space lg />
            <Row>
              <Col sm={12} md={6}>
                <InputField
                  field={{}}
                  form={{
                    errors: {},
                  }}
                  //   errorText={errorText}
                  label={"Permission Clarification"}
                  //   value={{}}
                  onChange={{}}
                  hint={"Permission Clarification"}
                />
                <Button extraPad>ReSubmit</Button>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default AppStatusReview
