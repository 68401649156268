import React from "react"
import { Card, Header, Space, IconButtonCircle } from "uiComponents"
import "./BestSeller.css"
import red from "@mui/material/colors/red"
import orange from "@mui/material/colors/orange"
import green from "@mui/material/colors/green"

export const getStarColor = rating => {
  if (rating <= 2) return red[700]
  if (rating === 3) return orange[700]
  return green[700]
}

const BestSellers = ({ index, img, title, desc, sales, accent, rating }) => {
  return (
    <div className='best-sellers-container'>
      <Card
        key={index}
        noHorPad
        white
        rowStyle={{ backgroundColor: accent && "var(--background)" }}
      >
        <img src={img} alt={title} className='best-seller-card-img' />
        <Header className='best-seller-card-header'>{title}</Header>
        <p className='best-seller-card-text'>{desc}</p>
        <Space lg />
        <div className='best-seller-card-details'>
          {rating ? (
            <div style={{ display: "flex", alignItems: "center" }}>
              {[...Array(5)].map((_, i) => (
                <i
                  key={i}
                  className='fas fa-star'
                  style={{
                    fontSize: "15px",
                    color: i < rating ? getStarColor(rating) : "lightgray",
                  }}
                />
              ))}
            </div>
          ) : (
            <div
              className='best-seller-card-sale'
              style={{ backgroundColor: accent && "var(--foreground)" }}
            >
              Total Sale : <span>{sales}</span>
            </div>
          )}
          <IconButtonCircle
            small
            icon='chevron-right'
            bgColor={accent && "var(--foreground)"}
          />
        </div>
      </Card>
    </div>
  )
}

export default BestSellers

export const BestSellersCategory = ({
  index,
  img,
  title,
  desc,
  sales,
  accent,
  rating,
}) => {
  return (
    <div
      key={index}
      style={{
        backgroundColor: "var(--background)",
        padding: "1rem",
        borderRadius: "var(--radius)",
        marginBottom: "2rem",
      }}
    >
      <div style={{ display: "flex" }}>
        <div style={{ flex: "2" }}>
          <img src={img} alt={title} className='best-seller-category-img' />
        </div>
        <div
          style={{
            flex: "3",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            padding: "5px 10px",
          }}
        >
          <div>
            <Header className='best-seller-card-header'>{title}</Header>
            <p className='best-seller-card-text'>{desc}</p>
          </div>
          <div
            className='best-seller-card-details'
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {rating ? (
              <div style={{ display: "flex", alignItems: "center" }}>
                {[...Array(5)].map((_, i) => (
                  <i
                    key={i}
                    className='fas fa-star'
                    style={{
                      fontSize: "15px",
                      color: i < rating ? getStarColor(rating) : "lightgray",
                    }}
                  />
                ))}
              </div>
            ) : (
              <div
                className='best-seller-card-sale'
                style={{ backgroundColor: accent && "var(--foreground)" }}
              >
                Total Sale: <span>{sales}</span>
              </div>
            )}
            <IconButtonCircle
              small
              icon='chevron-right'
              bgColor={accent && "var(--foreground)"}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
