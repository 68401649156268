import React, { useState } from "react"
import { uploadFilesToStorage } from "../../../firebaseUtils/firebaseOperations"
import { Busy } from "uiComponents"

function FileUploaderInput({ field, onChange, hasErrors }) {
  const [busy, setBusy] = useState()
  const handleChange = async file => {
    if (!file) return
    setBusy(true)

    try {
      let [url] = await uploadFilesToStorage([file], field.name)
      if (onChange) onChange(url)
      setBusy()
    } catch {
      setBusy()
    }
  }
  return (
    <Busy busy={busy} autoSize>
      <input
        type={field.value && typeof field.value === "string" ? "text" : "file"}
        value={field.value}
        name={field.name}
        onChange={event => {
          const file = event.currentTarget.files[0]

          handleChange(file)
        }}
      />
    </Busy>
  )
}

export default FileUploaderInput
