import firebase from "firebase"
import { useEffect, useState } from "react"

export const useResend = ({ phoneNumber }) => {
  const [phone, setPhone] = useState(phoneNumber || "")
  const [busy, setBusy] = useState(false)
  const [confirm, setConfirm] = useState(false)
  const [time, setTime] = useState(0)
  const [resend, setResend] = useState(false)
  useEffect(() => {
    if (resend) {
      setBusy(true)
      setTimeout(async () => {
        window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
          "recaptcha-container",
        )
        const appVerifier = window.recaptchaVerifier
        const confirmationResult = await firebase
          .auth()
          .signInWithPhoneNumber(phone, appVerifier)
        setConfirm(confirmationResult)
        setBusy(false)
        setTime(10)
        setResend()
      }, 1000)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resend])
  return {
    confirm,
    setResend,
    setPhone,
    time,
    busy,
    setBusy,
    setTime,
    setConfirm,
    phone,
  }
}
