import React, { Component } from "react"
import "./PriceCard.css"
import { Link } from "react-router-dom"
export default class PriceCard extends Component {
  render() {
    const { label, checked, price, period, to, gold, blackGold } = this.props
    const extraClass = gold ? " gold" : blackGold ? " black-gold" : " silver"
    return (
      <Link to={to || "#"} style={{ cursor: to ? "pointer" : "default" }}>
        <div className={"price-card " + extraClass}>
          {label && <label>{label}</label>}
          <div className='price'>
            <span className='amount'>
              {price}
              <span>{period}</span>
            </span>
          </div>
          {checked && <span className='fas fa-checked-circle' />}
        </div>
      </Link>
    )
  }
}
