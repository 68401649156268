import React from "react"
import { Chart } from "react-charts"
function LChart({ dataArray }) {
  const data = [
    {
      label: "Order By Day",
      color: "var(--font-color)",
      data: dataArray,
    },
  ]

  const axes = React.useMemo(
    () => [
      {
        primary: true,
        type: "time",
        position: "bottom",
        color: "var(--font-color)",
      },
      { type: "linear", position: "left", color: "var(--font-color)" },
    ],
    [],
  )
  // console.log('>>>>>>>dataArray', dataArray)
  const lineChart = (
    // A react-chart hyper-responsively and continuously fills the available
    // space of its parent element automatically
    <div
      style={{
        width: "100%",
        height: "300px",
      }}
    >
      <Chart data={data} axes={axes} />
    </div>
  )
  return lineChart
}
export default LChart
