const defaultDuration = 3000

class PushNotification {
  constructor(op) {
    this.title = op.title
    this.subtitle = op.subtitle
    this.message = op.message
    this.theme = op.theme
    this.id = Math.random()
    this.styling = op.styling
    this.closeButton = op.closeButton
    this.onClick = op.onClick
  }
}

class Storage {
  Storage = []
  Listener = () => this.Storage

  popAndPush = NotificationId => {
    let i = 0
    while (i < this.Storage.length) {
      if (this.Storage[i].id === NotificationId) {
        this.Storage.splice(i, 1)
      } else {
        ++i
      }
    }
    this.Listener(this.Storage)
  }

  setTimer = (NotificationId, duration) => {
    setTimeout(() => this.popAndPush(NotificationId), duration)
  }

  addListener = listener => {
    this.Listener = listener
  }

  addNativeNotification = async options => {
    const {
      title,
      subtitle,
      message,
      duration,
      icon,
      vibrate,
      silent,
      onClick,
    } = options
    if (
      Notification.permission === "default" ||
      Notification.permission === "denied"
    ) {
      await Notification.requestPermission()
    }
    if (Notification.permission === "granted") {
      const not = new Notification(title, {
        body: message,
        data: subtitle,
        icon,
        vibrate,
        silent,
      })
      not.onclick = onClick || null
      setTimeout(not.close.bind(not), duration || defaultDuration)
    }
  }

  addWebNotification = options => {
    const {
      title,
      subtitle,
      message,
      theme,
      duration,
      backgroundBottom,
      backgroundTop,
      colorBottom,
      colorTop,
      closeButton,
      onClick,
    } = options
    const styling = {
      backgroundTop,
      backgroundBottom,
      colorTop,
      colorBottom,
    }
    const newNotification = new PushNotification({
      title,
      subtitle,
      message,
      theme,
      styling,
      closeButton,
      onClick,
    })
    this.Storage.push(newNotification)
    this.setTimer(newNotification.id, duration || defaultDuration)
    this.Listener(this.Storage)
  }

  addNotification = async options => {
    const { native } = options
    if (native) {
      return this.addNativeNotification(options)
    }
    return this.addWebNotification(options)
  }
}

const NotifObject = new Storage()

export default NotifObject
