import React, { Fragment, useEffect, useState } from "react"
import {
  Col,
  Row,
  Button,
  Space,
  Card,
  ExpandableCard,
  UserCard,
  Header,
  Dialog,
  Loader,
} from "uiComponents"
import { getTime, parseDate } from "helpers"
import { useStateValue } from "state"
import { getDocFromDb, setDataToDb, updateDataToDb } from "firebaseUtils"
import { InputField } from "formComponents"
import { validateReviewText } from "validations"
import { Rater, StoreRaterReview, sendNotification } from "appComponents"
import "./Rating.css"
import { sendEmail } from "emailer"

export const RenderReview = ({ review, reviewBy, createdAt, img, small }) => {
  return (
    <div className='display-review'>
      <UserCard
        imgCol={2}
        reviewCard
        name={reviewBy}
        img={img}
        small={small}
        desc={
          <div>
            <Header noMar bold>
              {review}
            </Header>
            <Space />
            {createdAt && (
              <p style={{ marginBottom: 0 }}>
                Reviewed on: {parseDate(createdAt)}
              </p>
            )}
          </div>
        }
      />
    </div>
  )
}
export const ProductRaterReview = ({ product, storeID }) => {
  const [{ user, userMeta }, dispatch] = useStateValue()
  const [busy, setBusy] = useState(true)
  const [busyReview, setBusyReview] = useState(true)
  const [currentReview, setCurrentReview] = useState()
  const [alreadyRated, setAlreadyRated] = useState(0)
  const [errorText, setErrorText] = useState()
  const [alreadyReviewed, setAlreadyReviewed] = useState()
  const [actualProduct, setActualProduct] = useState()
  const { image, productName, brand, productId } = product || {}
  const template = "general_template"
  useEffect(() => {
    if (currentReview) setErrorText(validateReviewText(currentReview))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentReview])
  useEffect(() => {
    ;(async () => {
      let ratingRef = await getDocFromDb(
        `users_meta/${user?.uid}/productRatings`,
        storeID + productId,
      )
      if (ratingRef) setAlreadyRated(ratingRef.rating)
      setBusy()
    })()
    ;(async () => {
      let reviewRef = await getDocFromDb(
        `users_meta/${user?.uid}/productReviews`,
        storeID + productId,
      )
      if (reviewRef) setAlreadyReviewed(reviewRef.review)
      setBusyReview()
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!actualProduct) {
      const fetchProduct = async () => {
        setActualProduct(
          await getDocFromDb(`products/${storeID}/store_products/`, productId),
        )
      }
      if (!alreadyRated || !alreadyReviewed) fetchProduct()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alreadyRated, alreadyReviewed])

  const getSellerEmailAndSendEmail = async (newReview, newRating, storeID) => {
    let storeData = await getDocFromDb("user_stores", storeID)
    // const sellerReviewMessage = `Your store '${storeData.store_name}' has recieved a new review '${newReview}' by '${userMeta.name}' for the ${productName} product.`
    // const sellerReviewMessage = `New review for your store '${storeData.store_name}' from '${userMeta.name}' on the ${productName} product: '${newReview}'.`
    const sellerRatingMessage = `New rating for your store '${storeData.store_name}' from '${userMeta.name}' on the ${productName} product: '${newRating}'.`

    if (storeData?.exists) {
      let sellerMeta = await getDocFromDb("users_meta", storeData?.addedBy)
      if (sellerMeta?.exists) {
        const emailDataToSeller = {
          email: sellerMeta?.email,
          from_name: "Shoppio",
          message: sellerRatingMessage,
        }
        sendEmail(emailDataToSeller, template)
      }
    }
  }
  const handleRating = async newRating => {
    setBusy(true)
    await setDataToDb(
      `users_meta/${user?.uid}/productRatings`,
      storeID + productId,
      {
        rating: newRating,
      },
    )

    let oldRating = actualProduct?.rating
    let totalRatings = actualProduct?.totalRatings || 0
    await updateDataToDb(
      `products/${storeID}/store_products/`,
      productId,
      {
        rating: oldRating
          ? (Number(oldRating) + Number(newRating)) / 2
          : newRating,
        totalRatings: totalRatings + 1,
      },
      dispatch,
    )
    const ratingMessage = `You rated ${newRating} for the ${productName} product.`

    const emailData = {
      email: user?.email || userMeta?.email,
      from_name: "Shoppio",
      message: ratingMessage,
    }

    sendEmail(emailData, template)
    getSellerEmailAndSendEmail(storeID)

    sendNotification({
      title: "New Rating Submitted",
      body: "Thank you for rating the product!",
      toUserID: user?.uid,
    })

    setBusy()
    setAlreadyRated(newRating)
  }

  const handleReview = async newReview => {
    setBusyReview(true)
    await setDataToDb(
      `users_meta/${user?.uid}/productReviews`,
      storeID + productId,
      {
        review: newReview,
      },
    )
    await setDataToDb(
      `products/${storeID}/store_products/${productId}/reviews`,
      user?.uid,
      {
        review: newReview,
        reviewBy: userMeta?.name || user?.displayName || "Customer",
        createdAt: getTime(),
      },
    )
    const oldReviews = actualProduct?.oldReviews || 0
    await updateDataToDb(
      `products/${storeID}/store_products/`,
      productId,
      {
        totalReviews: Number(oldReviews) + 1,
      },
      dispatch,
    )

    const reviewMessage = `You rated ${newReview} for the ${productName} product.`
    const updateMessage = `Your review for the ${productName} product has been successfully submitted.`

    const emailData = {
      email: user?.email || userMeta?.email,
      from_name: "Shoppio",
      message: newReview.update ? updateMessage : reviewMessage,
    }

    sendEmail(emailData, template)
    if (storeID) {
      getSellerEmailAndSendEmail(storeID)
    }
    sendNotification({
      title: newReview.update ? "Review Updated" : "New Review Submitted",
      body: newReview.update
        ? "Your review has been updated."
        : "Thank you for your review!",
      toUserID: user?.uid,
    })

    setBusyReview()
    setAlreadyReviewed(newReview)
  }
  return (
    <div className='product-rater'>
      <Card>
        <Row>
          <Col xs={3} style={{ padding: 0 }}>
            <img className='product-img' src={image} alt={image} />
          </Col>
          <Col xs={9} style={{ paddingLeft: "3rem" }}>
            <Header bold>{brand}</Header>
            <Header sm>{productName}</Header>
            <div>
              {busy ? (
                <Loader />
              ) : (
                <Rater
                  noHead
                  onClick={alreadyRated ? null : handleRating}
                  readonly={alreadyRated}
                  initialValue={Number(alreadyRated)}
                />
              )}
              <span style={{ paddingLeft: "1rem" }}>
                {alreadyRated ? "Thanks for the rating" : ""}
              </span>
            </div>
            <div>
              {busyReview ? (
                <Loader />
              ) : (
                <Fragment>
                  {alreadyReviewed ? (
                    <RenderReview review={alreadyReviewed} />
                  ) : (
                    <InputField
                      type='textarea'
                      form={{
                        touched: {},
                        errors: {},
                      }}
                      errorText={errorText}
                      field={{
                        disabled: alreadyReviewed,
                        name: "rating",
                        onChange: e => setCurrentReview(e.target.value),
                        value: currentReview,
                      }}
                      hint={"Write a review"}
                    />
                  )}
                  {!alreadyReviewed ? (
                    <Button onClick={() => handleReview(currentReview)}>
                      Add a review
                    </Button>
                  ) : (
                    ""
                  )}
                </Fragment>
              )}
              <span style={{ paddingLeft: "1rem" }}>
                {alreadyReviewed ? "Thanks for the review" : ""}
              </span>
            </div>
          </Col>
        </Row>
      </Card>
    </div>
  )
}

export default function RateReview({ products, storeID, order }) {
  const [open, setOpen] = useState()
  return (
    <div>
      <ExpandableCard
        title={<Header bold>Ratings & Review</Header>}
        onExpand={() => setOpen(true)}
      >
        <Dialog right open={open} onClose={() => setOpen()}>
          <Header bold lg>
            Rating & Reviews
          </Header>
          <Space lg />

          <Header md bold>
            Products
          </Header>
          {products?.map(product => {
            return <ProductRaterReview product={product} storeID={storeID} />
          })}
          <Space lg />
          <Header md bold>
            Store
          </Header>
          <StoreRaterReview storeID={storeID} />
        </Dialog>
      </ExpandableCard>
    </div>
  )
}
