import React from "react"
import { truncate } from "helpers"
import { Space, Header } from "uiComponents"

export default function Item({
  title,
  subtitle,
  reverse,
  big,
  icon,
  flexEnd,
  flexStart,
  url,
}) {
  return reverse ? (
    <div>
      {big ? (
        <Header sm>{title}</Header>
      ) : (
        <div style={{ overflow: "hidden" }}>{title}</div>
      )}
      {!big && <Space />}
      <Header bold md={big}>
        <div style={{ overflow: "hidden" }}>{truncate(subtitle, 80)}</div>
      </Header>
      <Space md />
    </div>
  ) : (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        {!flexStart && (
          <Header bold md={big}>
            <span>{title}</span>
          </Header>
        )}
        <span
          style={{
            fontSize: "2rem",
            fontWeight: "regular",
            color: "var(--theme-color)",
          }}
        >
          {flexStart}
        </span>
        <Header bold md={big}>
          <span>{flexEnd}</span>
          {url && (
            <a
              href={url}
              alt=''
              style={{ color: "var(--theme-color)" }}
              rel='noreferrer'
              target='_blank'
            >
              <u>View</u>
            </a>
          )}
        </Header>
      </div>
      <div style={{ overflow: "hidden" }}>
        {icon && (
          <span
            className={`far fa-${icon}`}
            style={{ marginRight: "5px" }}
          ></span>
        )}
        {!flexStart && truncate(subtitle, 80)}
      </div>
      <Space md />
    </div>
  )
}
