import { Row, Col, Card, Header, Space, Button } from "uiComponents"
import { PageLayout } from "appComponents"
import "./kyc.css"
import KycStep from "./KycStep"
import getSVG from "../../../images/svgs/svg"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import KycStepVertical from "./KycStepVertical"
import { isMobile } from "helpers"
import { AppPaths } from "shoppio-constants"

const cardData = [
  {
    svg: "Forms-cuate",
    title: "Details Needed",
    desc: "You have provided us the details needed to completed KYC ",
    button: "Fill Account Information",
  },
  {
    svg: "Loading-cuate",
    title: "Verification",
    desc: "You have provided us the details needed to completed KYC ",
    status: ["In progress", "Need Clarification"],
  },
  {
    svg: "Verified_KYC",
    title: "KYC Status",
    desc: "You KYC is successfully approved, now you can use Shoppio with full potential",
    status: "Verified",
  },
]

const KYC = () => {
  const opacityValues = [1, 1]

  return (
    <PageLayout
      mainContent={
        <Row>
          <Col sm={12} md={12}>
            <Card>
              <Header sm bold>
                KYC Status
              </Header>
              <p>Complete your account setup to start selling on Shoppio</p>
              <Space lg />
              <Space lg />
              {!isMobile && <KycStep opacities={opacityValues} />}
              <Space lg />
              <Row>
                {isMobile && (
                  <>
                    <Col xs={1} sm={1} md={1}>
                      <KycStepVertical opacities={opacityValues} />
                    </Col>
                    <Col xs={11} sm={11} md={11} lg={12}>
                      <KycCard data={cardData} opacities={opacityValues} />
                    </Col>
                  </>
                )}
                {!isMobile && (
                  <KycCard data={cardData} opacities={opacityValues} />
                )}
              </Row>
            </Card>
          </Col>
        </Row>
      }
    />
  )
}

export default KYC

export const KycCard = ({ data, opacities }) => {
  const history = useHistory()
  return (
    <>
      {data.map((item, index) => (
        <Col sm={12} md={4} key={index}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              padding: "3rem 10rem",
              opacity: opacities[index],
            }}
          >
            {getSVG(item.svg, { width: 150 }, null, true)}
            <Space lg />
            <Space lg />
            <Header sm bold>
              {item.title}
            </Header>
            <p style={{ textAlign: "center" }}>{item.desc}</p>
            <Space lg />
            {item.button && <Button>{item.button}</Button>}
            {item.status && (
              <>
                <Button
                  color={
                    item.status[0] === "In progress"
                      ? "var(--blue)"
                      : item.status === "Verified"
                      ? "var(--green)"
                      : "var(--red)"
                  }
                  small
                >
                  {item.status[0] === "In progress"
                    ? "In Progress"
                    : item.status === "Verified"
                    ? "Verified"
                    : "Need Clarification"}
                </Button>
                {item.status[1] === "Need Clarification" && (
                  <>
                    <Space lg />
                    <Button
                      onClick={() => history.push(AppPaths.REVIEW_STATUS)}
                      alt
                    >
                      Review
                    </Button>
                  </>
                )}
              </>
            )}
          </div>
        </Col>
      ))}
    </>
  )
}
