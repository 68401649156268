import React from "react"
import { Space, Card, Header, Expander } from "uiComponents"
import TrainingVideos from "./TrainingVideos"
import { isMobile } from "../../../helpers"

const Training = () => {
  const data = [
    {
      videoId: "POkeac45dUA",
      title: "How to setup store",
      desc: "This video explains how to create your store",
    },
    {
      videoId: "ddaTI2qa3Bw",
      title: "Adding Product",
      desc: "This video explains how to create your store",
    },
    {
      videoId: "LV44XhzFJis",
      title: "Updating Product & Adding Customization",
      desc: "This video explains how to create your store",
    },
    {
      videoId: "V0zJb2K4Yi8",
      title: " Adding Category & Subcategory",
      desc: "This video explains how to create your store",
      videoUrl: "https://www.youtube.com/watch?v=V0zJb2K4Yi8",
    },
  ]

  const children = (
    <>
      {!isMobile && (
        <Header sm bold>
          Training
        </Header>
      )}
      <Space lg />
      {data.map((item, i) => (
        <div key={i} style={{ marginBottom: "10px" }}>
          <TrainingVideos
            videoId={item.videoId}
            title={item.title}
            desc={item.desc}
            videoUrl={item.videoUrl}
          />
        </div>
      ))}
    </>
  )
  return (
    <>
      {isMobile ? (
        <Card notFull>
          <Expander title='Training Videos'>{children}</Expander>
        </Card>
      ) : (
        <Card noXS notFull>
          {children}
        </Card>
      )}
    </>
  )
}

export default Training
