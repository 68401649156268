import React, { useState } from "react"
import { Col, Row, Card, Header, Space, Button, Dialog } from "uiComponents"
import { PageLayout } from "appComponents"
import { ProvidersLogo } from "../ShippingLogistics/ProvidersLogo"
import chatGPT from "../../../images/chatgpt.png"
import razorpay from "../../../images/rzp.png"
import paypal from "../../../images/pp.webp"
import SpaceBetween from "../../UI/Space/SpaceBetween"
import { InputField } from "formComponents"
import getSVG from "../../../images/svgs/svg"

const activeData = [
  {
    name: "ChatGPT",
    desc: "Enhance customer engagement with ChatGPT's automated messaging. Deliver timely, personalized responses effortlessly.",
    button: "Manage",
    image: chatGPT,
  },
]

const availableData = [
  {
    name: "RazorPay",
    desc: "Accept payments through various methods seamlessly. Leverage the robust capabilities of the Razorpay API for secure and efficient transactions.",
    button: "Integrate",
    image: razorpay,
  },
  {
    name: "Paypal",
    desc: "Accept payments through various methods seamlessly. Leverage the robust capabilities of the Paypal API for secure and efficient transactions.",
    button: "Integrate",
    image: paypal,
  },
]

const Integrations = () => {
  const [dialog, setDialog] = useState(false)
  const [dialogTitle, setDialogTitle] = useState("")

  const handleDialogOpen = name => {
    setDialogTitle(name)
    setDialog(true)
  }

  return (
    <>
      <PageLayout
        mainContent={
          <Row>
            <Col sm={12} md={12}>
              <Card>
                <Header sm bold>
                  Integrations
                </Header>
                <p>Manage the integrations here</p>
                <Space lg />
                <Space lg />
                <Header sm bold>
                  Active Integrations
                </Header>
                <Space />
                <IntegrationsCard
                  data={activeData}
                  onManageClick={handleDialogOpen}
                />
                <Space lg />
                <Header sm bold>
                  Available Integrations
                </Header>
                <Space />
                <IntegrationsCard
                  data={availableData}
                  //   onIntegrateClick={handleDialogOpen}
                />
              </Card>
            </Col>
          </Row>
        }
      />
      <Dialog open={dialog} popUp onClose={() => setDialog(false)}>
        <Header sm bold>
          {dialogTitle}
        </Header>
        <Space lg />
        <Space lg />
        <Space lg />
        <Header sm bold>
          API Key
        </Header>
        <InputField
          field={{ name: "" }}
          value={"80b209dh2038902jnx092n0298u"}
          onChange={{}}
        />
        <Button extraPad fullRound>
          Disconnet
        </Button>
        <Button extraPad fullRound accent>
          {" "}
          Go to Razorpay dashboard
        </Button>
        <div style={{ position: "absolute", bottom: "10px", right: 0 }}>
          {getSVG("Research", { width: 300 }, null, true)}
        </div>
      </Dialog>
    </>
  )
}

export default Integrations

export const IntegrationsCard = ({ data, onManageClick, onIntegrateClick }) => {
  return (
    <div>
      <Row>
        {data.map(item => (
          <Col xs={12} md={6} key={item.name}>
            <Card noHorPad>
              <div style={{ padding: "3rem" }}>
                <Header sm bold>
                  {item.name}
                </Header>
                <Space lg />
                <p>{item.desc}</p>
                <Space lg />
                <SpaceBetween>
                  <Button
                    extraPad
                    onClick={() => {
                      if (item.button === "Manage") {
                        onManageClick(item.name)
                      }
                    }}
                    fullRound
                  >
                    {item.button}
                  </Button>
                  <ProvidersLogo logo={item.image} />
                </SpaceBetween>
              </div>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  )
}
