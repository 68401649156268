import { auth, handleError } from "firebaseUtils"
import React, { useEffect } from "react"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { useStateValue } from "state"

export default function Logout() {
  const history = useHistory()
  const [, dispatch] = useStateValue()
  useEffect(() => {
    auth
      .signOut()
      .then(() => {
        console.log("Logged out")
        dispatch({ type: "LOGOUT" })
        history.push("/")
      })
      .catch(err => handleError(err))
    // eslint-disable-next-line
  }, [])
  return <div></div>
}
