import React from "react"
import { Col, Row, Space } from "uiComponents"
import getSVG from "../../../images/svgs/svg"
import { CardSection, CardSectionSecound, CardWithSVG } from "./WhyChooseCards"
import { isMobile } from "helpers"
import { AppPaths } from "shoppio-constants"

const WhyChoose = () => {
  return (
    <>
      <div className='why-choose-header'>
        <div className='header-text'>
          Why Choose<span>Shoppio?</span>
        </div>
        <p>
          Our range of business-oriented solutions will help you achieve your
          business goals.
        </p>
      </div>
      <Space lg />
      <Space lg />
      <Row>
        <CardWithSVG
          header='Effortless Inventory Management'
          content='Keep track of your stock in real-time, manage orders efficiently, and avoid stockouts with our comprehensive inventory management system.'
          buttonLabel='Learn More'
          svg={getSVG(
            "Time_management",
            { width: isMobile ? 200 : 250 },
            null,
            true,
          )}
        />
        <Col sm={12} md={8}>
          <Row>
            <CardSection
              header={
                <span style={{ fontFamily: "var(--font-two)" }}>
                  Build brand with Shoppio Shopfront &#8482;{" "}
                </span>
              }
              content='Design a unique and engaging storefront that reflects your brand. Customize your website and mobile app to stand out from the competition.'
              buttons={[
                {
                  label: "Start Free Trial",
                  alt: false,
                  onClick: AppPaths.SELLER_SIGNUP,
                },
                { label: "Learn More", alt: true },
              ]}
              svg={getSVG(
                "Advanced_customization",
                { width: isMobile ? 200 : 250 },
                undefined,
                isMobile ? "" : true,
              )}
            />
            <CardSectionSecound
              themed
              header='Boost Customer Relationships with CRM'
              content='Build and maintain strong customer relationships using our integrated CRM tools. Track customer interactions, manage sales leads, and enhance customer service.'
              buttons={[
                {
                  label: "Start Free Trial",
                  alt: true,
                  onClick: AppPaths.SELLER_SIGNUP,
                },
              ]}
              svg={getSVG(
                "Sales_consulting",
                { width: isMobile ? 200 : 250 },
                undefined,
                isMobile ? "" : true,
              )}
            />
          </Row>
        </Col>
      </Row>
      <Space />
      <Row>
        <Col sm={12} md={8}>
          <Row>
            <CardSectionSecound
              themed
              header='Streamlined Logistics and Fulfillment'
              content='Simplify your delivery process with our logistics solutions. Ensure timely and efficient delivery of your products to customers, anywhere they are.'
              buttons={[
                {
                  label: "Start Free Trial",
                  alt: true,
                  onClick: AppPaths.SELLER_SIGNUP,
                },
              ]}
              svg={getSVG(
                "logistics",
                { width: isMobile ? 200 : 250 },
                undefined,
                isMobile ? "" : true,
              )}
            />
            <CardSection
              header='Secure and Easy Payment Gateway'
              content='Design a unique and engaging storefront that reflects your brand. Customize your website and mobile app to stand out from the competition.'
              buttons={[
                {
                  label: "Start Free Trial",
                  alt: false,
                  onClick: AppPaths.SELLER_SIGNUP,
                },
                { label: "Learn More", alt: true },
              ]}
              svg={getSVG(
                "CC_Payment",
                { width: isMobile ? 200 : 250 },
                undefined,
                isMobile ? "" : true,
              )}
            />
          </Row>
        </Col>
        <CardWithSVG
          header='Expand Your Business Horizons'
          content='Take your business to new heights with our all-in-one e-commerce platform. Reach more customers, increase sales, and grow your business effortlessly.'
          buttonLabel='Learn More'
          svg={getSVG(
            "Business_analytics",
            { width: isMobile ? 200 : 250 },
            null,
            true,
          )}
        />
      </Row>
    </>
  )
}

export default WhyChoose
