import React, { useState } from "react"
import {
  Grid,
  Row,
  Col,
  Card,
  Tabs,
  Tab,
  Header,
  Space,
  SpaceBetween,
  ViewBy,
  Button,
  Dialog,
} from "uiComponents"
import SalesAnalytics from "../SalesDashboard/SalesAnalytics"
import { hexToRgba } from "helpers"
import { FormMaker } from "formComponents"
import { tasksFields } from "dummyData"
import { addDataToDb } from "../../firebaseUtils/firebaseOperations"
import { CollectionRenderer } from "firestoreComponents"
import "./EngineeringDashboard.css"
import TaskDetails from "./TaskDetails"
import TicketData from "./TicketData"
import TicketCard from "./components/TicketCard"
import TaskCard from "./components/TaskCard"

const EngineeringDashboard = () => {
  const [dialog, setDialog] = useState(false)
  const [taskDialog, setTaskDialog] = useState(false)
  const [selectedTask, setSelectedTask] = useState(null)
  const [busy, setBusy] = useState(false)
  const [ticketDialog, setTicketDialog] = useState(false)
  const [selectedTicket, setSelectedTicket] = useState(null)

  const handleSubmit = async (data, dispatch, cb) => {
    const url = "tasks"
    console.log("first request from " + data)
    try {
      setBusy(true)
      const result = await addDataToDb(
        url,
        { ...data, createdAt: new Date().toISOString() },
        dispatch,
        "Task added successfully!",
        cb,
      )
      if (result === "success") {
        console.log("Task added successfully!")
      }
      setBusy(false)
      setDialog(false)
    } catch (error) {
      console.error("Error adding user:", error)
    }
  }

  const handleTaskCardClick = taskData => {
    setSelectedTask(taskData)
    setTaskDialog(true)
  }

  const handleTicketCardClick = ticketData => {
    setSelectedTicket(ticketData)
    console.log("Selected Ticket ID:", ticketData.id)
    console.log("card clicked", ticketData)
    setTicketDialog(true)
  }

  return (
    <>
      <Grid fluid>
        <Row>
          <Col sm={12} md={12} lg={6} xl={2}>
            <Card noXS notFull></Card>
          </Col>
          <Col sm={12} md={12} lg={6} xl={7}>
            <SalesAnalytics />
            <Tabs>
              <Tab label='Tasks'>
                <Card>
                  <SpaceBetween>
                    <div>
                      <Header sm bold>
                        Tasks
                      </Header>
                      <p>List of all the tasks available to resolve</p>
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <ViewBy />
                      <Button
                        noMar
                        small
                        fullRound
                        onClick={() => setDialog(true)}
                      >
                        Add Task
                      </Button>
                    </div>
                  </SpaceBetween>
                  <Space lg />
                  <CollectionRenderer
                    collectionPath={"tasks"}
                    viewType={"custom"}
                    noDocMessage={"No tasks available yet. Please create some."}
                    renderDoc={({ id, data }, refreshDoc, isBusy) => {
                      return (
                        <>
                          <Card
                            busy={isBusy}
                            onClick={() => handleTaskCardClick(data)}
                          >
                            <TaskCard data={data} />
                          </Card>
                          <Dialog
                            open={taskDialog}
                            big
                            onClose={() => setTaskDialog(false)}
                          >
                            <TaskDetails
                              taskData={selectedTask}
                              id={id}
                              refreshDoc={refreshDoc}
                              setTaskDialog={setTaskDialog}
                            />
                          </Dialog>
                        </>
                      )
                    }}
                  />
                </Card>
              </Tab>
              <Tab label='Tickets'>
                <Card>
                  <SpaceBetween>
                    <div>
                      <Header sm bold>
                        Tickets
                      </Header>
                      <p>List of all the tickets available</p>
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <ViewBy />
                    </div>
                  </SpaceBetween>
                  <Tabs>
                    <Tab label='General Tickets'>
                      <CollectionRenderer
                        collectionPath={"tickets"}
                        viewType={"custom"}
                        noDocMessage={
                          "No tickets available yet. Please create some."
                        }
                        renderDoc={({ id, data }, refreshDoc, isBusy) => {
                          console.log("eng id..........", id)

                          return (
                            <>
                              <Card
                                busy={isBusy}
                                onClick={() => handleTicketCardClick(data)}
                              >
                                <TicketCard data={data} />
                              </Card>
                              <Dialog
                                open={ticketDialog}
                                big
                                onClose={() => setTicketDialog(false)}
                              >
                                <TicketData
                                  ticketData={selectedTicket}
                                  ticketID={id}
                                  refreshDoc={refreshDoc}
                                  setTicketDialog={setTicketDialog}
                                />
                              </Dialog>
                            </>
                          )
                        }}
                      />
                    </Tab>
                    <Tab label='Store Tickets'>
                      <CollectionRenderer
                        collectionPath={"store_tickets"}
                        viewType={"custom"}
                        noDocMessage={
                          "No tickets available yet. Please create some."
                        }
                        renderDoc={({ id, data }, refreshDoc, isBusy) => {
                          console.log("eng id..........", id)

                          return (
                            <>
                              <Card
                                busy={isBusy}
                                onClick={() => handleTicketCardClick(data)}
                              >
                                <TicketCard data={data} />
                              </Card>
                              <Dialog
                                open={ticketDialog}
                                big
                                onClose={() => setTicketDialog(false)}
                              >
                                <TicketData
                                  ticketData={selectedTicket}
                                  ticketID={id}
                                  refreshDoc={refreshDoc}
                                  setTicketDialog={setTicketDialog}
                                />
                              </Dialog>
                            </>
                          )
                        }}
                      />
                    </Tab>
                  </Tabs>
                </Card>
              </Tab>
            </Tabs>
          </Col>
          <Col sm={12} md={12} lg={6} xl={3}>
            <Card noXS notFull></Card>
          </Col>
        </Row>
      </Grid>
      <Dialog open={dialog} right onClose={() => setDialog(false)}>
        <FormMaker
          fields={tasksFields}
          submitLabel='Create Task'
          onSubmit={handleSubmit}
          busy={busy}
        />
      </Dialog>
    </>
  )
}

export default EngineeringDashboard

export const StatusBar = ({ color, label }) => {
  const textColor = `${color}`

  return (
    <div
      style={{
        backgroundColor: color && hexToRgba(color, 0.1),
        color: color && textColor,
        padding: "5px 15px",
        borderRadius: "25px",
        display: "inline-block",
        textAlign: "center",
        width: "fit-content",
        fontWeight: "600",
        marginRight: "10px",
      }}
    >
      <b>{label}</b>
    </div>
  )
}
