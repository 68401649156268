import React from "react"
import { Col, Row } from "uiComponents"

export default function HorizontalScroll({ children, id }) {
  return (
    <Row start='xs' style={{ padding: "1rem" }}>
      <Col xs={12} className='noscrollbar' id={id}>
        {children}
      </Col>
    </Row>
  )
}
