import React, { useEffect, useState } from "react"
import { Grid, Row, Col, Card, Header, Space, Button } from "uiComponents"
import "./Login.css"
import SimpleCarousel from "../../UI/Carousel/SimpleCarousel"
import { getSVG } from "getSVG"
import icon from "../../../images/Monogram.png"
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min"
import LoginAuth from "./LoginAuth"
import { DB } from "firebaseUtils"
import { useStateValue } from "state"
import { loginCarouselData, loginBottomLinks } from "dummyData"
import firebase from "firebase"
import { isMobile } from "helpers"
import { AppPaths } from "shoppio-constants"

const googleLogo =
  "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c1/Google_%22G%22_logo.svg/480px-Google_%22G%22_logo.svg.png"

const LoginNew = props => {
  const history = useHistory()
  const { currentUser, onACL, onPhoneChange, phoneError } = props
  const [loginSuccess, setLoginSuccess] = useState(false)
  const [{ user, userMeta }] = useStateValue()
  console.log("user........", user, userMeta)
  // const buttonColor = "#B3B3E6"
  // const textColor = "#B3B3E6"
  // console.log("props....", props)
  const checkIfUserExists = async result => {
    const { uid, phoneNumber, displayName, email } = result.user
    if (onACL && result?.user) return
    if (uid) {
      let res = await DB.collection(`users_meta`).doc(uid).get()
      if (res && !res.exists) {
        setTimeout(() => {
          history.push({
            pathname: "/",
            state: {
              user: {
                uid,
                phoneNumber,
                name: displayName,
                email,
              },
            },
          })
        }, 2000)
      }
    }
  }

  const signInWithGoogle = () => {
    firebase
      .auth()
      .signInWithRedirect(new firebase.auth.GoogleAuthProvider())
      .then(result => {
        checkIfUserExists(result)
        history.push("/")
      })
      .catch(error => {
        console.error("Error during sign-in:", error)
      })
  }

  useEffect(() => {
    if (user) history.push(AppPaths.ACCOUNT)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])
  return (
    <>
      <Grid>
        <div>
          <Row middle='xs' style={{ minHeight: "80vh" }}>
            <Col sm={12} md={10} mdOffset={1}>
              <Card
                extraPad
                style={{ position: "relative", marginTop: isMobile && "10rem" }}
                rowStyle={{ overflow: "visible" }}
              >
                <FloatingIcon onClick={() => history.push("/")} />
                <Row
                  style={{ borderBottom: "0.5px solid var(--border-color)" }}
                >
                  <Col
                    sm={12}
                    md={6}
                    style={{
                      padding: "5rem",
                      borderBottom:
                        isMobile && "0.5px solid var(--border-color)",
                    }}
                  >
                    {loginSuccess ? (
                      <div style={{ textAlign: "center" }}>
                        <i
                          className='fas fa-check-circle'
                          style={{
                            fontSize: "30rem",
                            color: "green",
                            marginBottom: "8rem",
                          }}
                        />
                        <Header center lg bold>
                          Login Successful
                        </Header>
                        <Space lg />
                        <p>redirecting to Home Page...</p>
                      </div>
                    ) : (
                      <>
                        <Header lg bold>
                          Login
                        </Header>
                        <p>
                          Login to your Shoppio Account and run your business
                          like never before
                        </p>
                        <Space lg />
                        <Space lg />
                        {!currentUser && (
                          <div className='' style={{ textAlign: "left" }}>
                            <Space />
                            <form onSubmit={e => e.preventDefault()}>
                              <Space />

                              <LoginAuth
                                onChange={onPhoneChange}
                                errorText={phoneError}
                                callback={checkIfUserExists}
                                onLoginSuccess={() => setLoginSuccess(true)}
                              />
                              <Button
                                fullWidth
                                accent
                                onClick={signInWithGoogle}
                              >
                                Continue with Google{" "}
                                <img
                                  className='google_logo'
                                  alt='google'
                                  src={googleLogo}
                                />
                              </Button>
                            </form>
                            <Space />
                          </div>
                        )}

                        <p>
                          By using our services you are agreeing to{" "}
                          <span
                            onClick={() =>
                              window.open(AppPaths.TERMS, "_blank")
                            }
                            className='terms-policies-text'
                          >
                            Shoppio terms of service
                          </span>{" "}
                          &{" "}
                          <span
                            onClick={() =>
                              window.open(AppPaths.POLICIES, "_blank")
                            }
                            className='terms-policies-text'
                          >
                            Privacy Policy
                          </span>
                          .
                        </p>
                      </>
                    )}
                  </Col>

                  <Col
                    sm={12}
                    md={6}
                    style={{
                      borderLeft:
                        !isMobile && "0.5px solid var(--border-color)",
                    }}
                  >
                    <SimpleCarousel interval={5000}>
                      {loginCarouselData.map(item => (
                        <div key={item.title}>
                          {getSVG(item.svg, { width: 200 }, null, true)}
                          <h2>{item.title}</h2>
                          <p>{item.desc}</p>
                        </div>
                      ))}
                    </SimpleCarousel>
                  </Col>
                </Row>
                <Row
                  between='xs'
                  style={{ position: "relative", bottom: "-15px" }}
                >
                  <p>
                    <span
                      className='far fa-copyright'
                      style={{ marginRight: "5px" }}
                    />
                    2024 Shoppio. All rights reserved
                  </p>
                  {!isMobile && (
                    <div style={{ display: "flex", gap: "4rem" }}>
                      {loginBottomLinks.map(item => (
                        <Link to={item.link} key={item.title}>
                          <p>{item.title}</p>
                        </Link>
                      ))}
                    </div>
                  )}
                </Row>
              </Card>
            </Col>
          </Row>
        </div>
      </Grid>
    </>
  )
}

export default LoginNew

export const FloatingIcon = ({ onClick }) => {
  return (
    <div className='floating-icon-container' onClick={onClick}>
      <img
        src={icon}
        alt=''
        style={{ objectFit: "contain" }}
        className='floating-icon'
        data-nopreview
      />
    </div>
  )
}
