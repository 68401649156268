import moment from "moment"
import React, { Fragment, useEffect, useState } from "react"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"
import * as Yup from "yup"
import { storeAccessLevels } from "config"
import { DocMaker } from "firestoreComponents"
import { baseOrderCollection } from "firebaseUtils"
import {
  copyTextToClipboard,
  getExpirableLink,
  getTime,
  parseFromFirebaseTimestamp,
} from "helpers"
import { getStoreData } from "../../../pages/Home/StoreHome"
import { useStateValue } from "state"
import { InputField } from "formComponents"
import { autoValidate } from "validations"
import {
  getDocsFromDbMultipleWheresWithLimit,
  updateDataToDb,
} from "firebaseUtils"
import {
  Col,
  Row,
  Space,
  Button,
  Card,
  Header,
  Divider,
  Highlight,
} from "uiComponents"
import { getSVG } from "getSVG"
import { AppPaths } from "shoppio-constants"

export const hasAdminAccessToStore = (userMeta, storeID, action) => {
  const ownsTheStore = userMeta?.stores?.some(s => s.key === storeID)
  if (ownsTheStore) return true
  const isStoreManager = userMeta?.currentAccessLevel === "Store Manager"
  const isDeliveryManager = userMeta?.currentAccessLevel === "Delivery Manager"
  const isDeliveryBoy = userMeta?.currentAccessLevel === "Delivery Person"
  const isActive = userMeta?.currentStatus === "active"

  const storeManagerActions = [
    "update_product",
    "update_category",
    "update_subcategory",
  ]
  const deliveryManagerActions = []
  const deliveryBoyActions = []
  if (isActive && action && userMeta?.worksUnder?.includes(storeID)) {
    if (isStoreManager) return storeManagerActions.includes(action)
    if (isDeliveryManager) return deliveryManagerActions.includes(action)
    if (isDeliveryBoy) return deliveryBoyActions.includes(action)
  }
}
const ACLUser = ({ data, id, actions }) => {
  const [, dispatch] = useStateValue()
  const [busy, setBusy] = useState()
  const [canBlock, setCanBlock] = useState(true)
  const { store_id } = useParams()
  const blockUser = (uid, nodeID) => {
    setBusy(true)
    updateDataToDb(`users_meta/`, uid, {
      currentStatus: "blocked",
    })
    updateDataToDb(`access_control_list/${store_id}/data`, nodeID, {
      status: "blocked",
    })
    setBusy()
  }
  const unblockUser = (uid, nodeID) => {
    setBusy(true)
    updateDataToDb(`users_meta/`, uid, {
      currentStatus: "active",
    })
    updateDataToDb(`access_control_list/${store_id}/data`, nodeID, {
      status: "active",
    })
    setBusy()
  }

  useEffect(() => {
    const fetchData = async () => {
      let docs = await getDocsFromDbMultipleWheresWithLimit(
        1,
        `${baseOrderCollection}/${store_id}/data/`,
        ["shipmentStatus", "==", "Assigned"],
        ["assignedTo", "==", data?.user_uid],
      )
      setCanBlock(docs.length === 0)
    }
    if (data?.user_uid) fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Card key={id} type='alt' tag={data?.status}>
      <Header bold lg>
        {data.user_name}
      </Header>

      <Header bold>{data.access_level}</Header>
      <p>
        {data.email} {data.email ? <br /> : ""}
        {data.phoneNumber}
      </p>
      <Space />
      <Divider />
      <Space />
      {data?.status === "inactive" && (
        <Fragment>
          <Header sm bold>
            Invite Link
          </Header>
          <p>Please send this to {data.user_name}</p>
          <p>
            Please note that this link will expire on{" "}
            <b>
              {moment(parseFromFirebaseTimestamp(data?.invited_on))
                .add(30, "days")
                .format("DD-MM-YYYY")}{" "}
            </b>
          </p>
          {id && store_id && (
            <InputField
              form={{
                touched: {
                  search: true,
                },
                errors: {},
              }}
              field={{
                name: "Invite Link",
                value: getExpirableLink({
                  liveDuration: 30,
                  linkMade: data?.invited_on,
                  dataToEncode: { userID: id, storeID: store_id },
                  prefix: window.location.host + `${AppPaths.ACL_SIGNUP}/`,
                }),
              }}
              withButton
              hint={"Invite Link"}
              buttonText={<span className='lnr lnr-link' />}
              onButtonClick={link => {
                copyTextToClipboard(link)
                dispatch({
                  type: "SHOW_ALERT",
                  open: true,
                  message: "Invite link copied!",
                })
              }}
            />
          )}
        </Fragment>
      )}
      <Space />
      <Space />
      {actions(!canBlock)}
      {data?.user_uid && (
        <Button
          small
          disabled={!canBlock}
          busy={busy}
          onClick={() =>
            data?.status === "blocked"
              ? unblockUser(data?.user_uid, id)
              : blockUser(data?.user_uid, id)
          }
        >
          {data?.status === "blocked" ? "Unblock user" : "Block User"}
        </Button>
      )}
      {!canBlock && (
        <Highlight type={"warn"}>
          Please note you cannot change, update or delete a user if they are
          assigned a delivery.
        </Highlight>
      )}
      <Space />
    </Card>
  )
}
export default function AccessControlList() {
  const [busy, setBusy] = useState()
  const [{ user, userMeta, currentStore }, dispatch] = useStateValue()

  const { store_id } = useParams()

  const ownsThisStore = hasAdminAccessToStore(userMeta, store_id)
  useEffect(() => {
    if (!currentStore) {
      getStoreData(store_id, dispatch, setBusy)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  // console.log("User", rest);
  const deleteStoreAssociationFromUser = entity => {
    const path = `users_meta/`
    if (entity?.user_uid) {
      updateDataToDb(path, entity?.user_uid, {
        worksUnder: [],
        currentAccessLevel: "",
      })
    }
  }
  const updateUserActualMeta = ({ user_uid, access_level }) => {
    // console.log("user_uid", user_uid, access_level)
    setBusy(true)
    if (access_level && user_uid) {
      updateDataToDb(
        `users_meta/`,
        user_uid,
        {
          currentAccessLevel: access_level,
        },
        dispatch,
        "User access updated successfully",
        () => setBusy(),
      )
    } else {
      setBusy()
    }
  }
  console.log("Current Store", currentStore)
  return (
    <div>
      <DocMaker
        collectionName='access_control_list/'
        entityName='user'
        scopedOn={store_id}
        showBack
        loading={busy}
        canAdd={ownsThisStore}
        onAdd={id => console.log("new user added", id)}
        onDelete={entity => deleteStoreAssociationFromUser(entity)}
        reducerVar='storeAccessList'
        title={"Store Access List"}
        noDocComponent={
          <Row>
            <Col xs={12}>
              <Card noPadTop notFull>
                {getSVG(
                  "category",
                  { width: "200px", marginLeft: 0 },
                  null,
                  true,
                )}
                <Space />
                <Header md bold>
                  Access Control List
                </Header>
                <p>You can add delivery, service person, store manager here.</p>
              </Card>
              <Space lg />
            </Col>
          </Row>
        }
        desc={
          "You can update/add or delete users who can manage your store and can deliver orders"
        }
        onUpdate={(id, newData) => {
          console.log("Updated", newData)
          updateUserActualMeta(newData)
        }}
        icon={"lnr lnr-user"}
        lg={6}
        validation={autoValidate}
        fields={() =>
          currentStore
            ? [
                { group: "User Details" },
                {
                  name: "user_name",
                  label: "User Full Name",
                  type: "text",
                  validate: Yup.string()
                    .required("Please enter User Full Name")
                    .min(3, "Please enter a value more than 3 character")
                    .max(100, "Please enter a value less than 3 character"),
                  fullWidth: true,
                },
                {
                  fullWidth: true,
                  name: "phoneNumber",
                  label: "Phone Number",
                  type: "phoneNumber",
                  validate: Yup.string().required(
                    "Please enter a valid phone number",
                  ),
                },
                {
                  name: "status",
                  value: "inactive",
                  hidden: true,
                },
                {
                  name: "invited_to_store_id",
                  value: currentStore?.id,
                  hidden: true,
                },
                {
                  name: "invited_by",
                  value: user?.email || user?.phoneNumber,
                  hidden: true,
                },
                {
                  name: "invited_by_user_id",
                  value: user?.uid,
                  hidden: true,
                },
                {
                  name: "invited_to",
                  value: currentStore?.store_name,
                  hidden: true,
                },
                {
                  name: "invited_on",
                  value: getTime(),
                  hidden: true,
                },
                {
                  name: "access_level",
                  label: "Access Level ",
                  type: "select",
                  children: storeAccessLevels.map(op => (
                    <option value={op}>{op}</option>
                  )),
                },
              ]
            : []
        }
        renderEntity={({ data, id }, actions) => (
          <ACLUser data={data} id={id} actions={actions} />
        )}
      />
    </div>
  )
}
