import React from "react"
import { Col, Row, Space, Card, Header } from "uiComponents"
import {
  ActiveProviders,
  WhyChooseLogistics,
  AvailableProviders,
  PageLayout,
} from "appComponents"
import getSVG from "../../../images/svgs/svg"

const ShippingLogistics = () => {
  return (
    <PageLayout
      mainContent={
        <Row>
          <Col sm={12} md={12}>
            <Row>
              <Col sm={12} md={12}>
                <Card>
                  <Row between='xs'>
                    <div>
                      <Header sm bold>
                        Shipping & Logistics
                      </Header>
                      <p>
                        You can manage your store shipping & logistics, update
                        providers and much more.
                      </p>
                      <Space lg />
                      <Card notFull style={{ width: "fit-content" }}>
                        <Row between='xs'>
                          <Header noMar>Current Provider</Header>
                          <Header noMar bold>
                            Self
                          </Header>
                        </Row>
                      </Card>
                    </div>
                    <div>
                      {getSVG("Delivery_svg", { width: 200 }, null, true)}
                    </div>
                  </Row>
                  <AvailableProviders />
                  <ActiveProviders />
                </Card>
              </Col>
              <Col sm={12} md={12}>
                <WhyChooseLogistics />
              </Col>
            </Row>
          </Col>
        </Row>
      }
    />
  )
}

export default ShippingLogistics
