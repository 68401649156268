import React from "react"

const Space = ({ lg, md, sm, h, style }) => (
  <div
    style={{
      height: h ? h : sm ? "0.5rem" : lg ? "3rem" : md ? "2rem" : "1rem",
      ...style,
    }}
  />
)
export default Space
