import React from "react"
import CustomerMessages from "./CustomerMessages"
import { PageLayout } from "../PageLayout"

export default function CustomerMessageWrapper() {
  // All BE logic will be handled here @Amit
  return (
    <div>
      <PageLayout mainContent={<CustomerMessages />} />
    </div>
  )
}
