import React from "react"
import { Dialog, Header, Button, Space } from "uiComponents"
import getSVG from "../../../images/svgs/svg"

const ImportDialog = ({ open, onClose, uploading, onChooseFile }) => (
  <Dialog open={open} popUp onClose={onClose}>
    <Header sm bold>
      Import Customers
    </Header>
    <Space lg />
    <Space lg />
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {getSVG("Upload-cuate", { width: 200 }, null, true)}
      <Space lg />
      <Header bold md>
        {uploading
          ? "Uploading..."
          : "You can use Excel to upload customer's data"}
      </Header>
      <p>Max file size 2MB, Max Rows: 4000</p>
      <Space lg />
      <Button extraPad noMar onClick={onChooseFile}>
        Choose File
      </Button>
    </div>
  </Dialog>
)

const ExportDialog = ({ open, onClose, selectedRows, onExport }) => (
  <Dialog open={open} popUp onClose={onClose}>
    <Header sm bold>
      Export Customers
    </Header>
    <Space lg />
    <Space lg />
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {getSVG("Upload-cuate", { width: 200 }, null, true)}
      <Space lg />
      <Header bold md>
        Download customer data as an Excel file.
      </Header>
      <p>Selected Customers : {selectedRows.length} </p>
      <Space lg />
      <Button extraPad noMar onClick={onExport}>
        Export
      </Button>
    </div>
  </Dialog>
)

export { ImportDialog, ExportDialog }
