import React from "react"
import { Card, Row, Col, Header, Space, Expander } from "uiComponents"
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress"
import Box from "@mui/material/Box"
import AnalyticsTile from "./AnalyticsTile"

const CustomerOverview = () => {
  const stateData = [
    {
      state: "UP",
      value: "60",
    },
    {
      state: "MP",
      value: "70",
    },
    {
      state: "MH",
      value: "30",
    },
    {
      state: "UK",
      value: "50",
    },
    {
      state: "TN",
      value: "90",
    },
    {
      state: "BN",
      value: "100",
    },
    {
      state: "GJ",
      value: "10",
    },
  ]
  const cardsData = [
    { title: "New Customers", value: "2024", icon: "user-plus" },
    { title: "Customer Lifetime Value", value: "Rs. 782", icon: "gifts" },
    {
      title: "Buyers Prominent Age Group",
      value: "10-27 Yrs",
      icon: "users",
    },
    {
      title: "Top Selling Product Category",
      value: "Electronics",
      icon: "box",
    },
  ]
  return (
    <Card>
      <Expander
        title={"Customer overview"}
        subTitle={
          "Access customer details, demographics, and CLV to enhance marketing and sales strategies."
        }
      >
        <Space lg />
        <Row>
          <Col sm={12} md={12} lg={6}>
            <Card white>
              <Header bold>Demographics</Header>
              <p>By State</p>
              <Space lg />
              {stateData.map((data, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    mb: "10px",
                  }}
                >
                  <Box sx={{ width: "15%" }}>
                    <Header bold>{data.state}</Header>
                  </Box>
                  <Box sx={{ width: "90%", ml: 2, position: "relative" }}>
                    <LinearProgress
                      variant='determinate'
                      value={data.value}
                      sx={{
                        height: "15px",
                        backgroundColor: "transparent",
                        [`& .${linearProgressClasses.bar}`]: {
                          borderTopRightRadius: "5px",
                          borderBottomRightRadius: "5px",
                          backgroundColor: "var(--header-color)",
                        },
                      }}
                    />
                    <Box
                      sx={{
                        position: "absolute",
                        left: `${data.value - 6}%`,
                        top: "50%",
                        transform: "translate(-50%, -50%)",
                        color: "var(--background)",
                        fontWeight: "bold",
                      }}
                    >
                      {data.value}
                    </Box>
                  </Box>
                </Box>
              ))}
            </Card>
          </Col>
          <Col sm={12} lg={6} md={12}>
            <Space md />
            {cardsData.map(data => (
              <AnalyticsTile
                title={data.title}
                value={data.value}
                icon={data.icon}
                noIcon
                light
              />
            ))}
          </Col>
        </Row>
      </Expander>
      <Space md />
    </Card>
  )
}

export default CustomerOverview
