// For Firebase JS SDK v7.20.0 and later, measurementId is optional
import firebase from "firebase"
import { firebaseConfig } from "../../config/FirebaseConfig"
export const env = process.env.APP_ENV || process.env.NODE_ENV
console.log("env", env, process.env.APP_ENV)

const firebaseApp = firebase.initializeApp(firebaseConfig)

const db = firebaseApp.firestore()
// firebase.functions().useFunctionsEmulator("127.0.0.1", 4000);
const DB = db.collection(env).doc("data")
const auth = firebase.auth()
const storage = firebaseApp.storage()
const FieldValue = firebase.firestore.FieldValue
const FieldPath = firebase.firestore.FieldPath
const getServerTime = () => firebase.firestore.FieldValue.serverTimestamp()
export const mapAPI = "AIzaSyB3pnM6luHr4V0xkvrGJTyjwm3UoNtUxW8"
const Timestamp = firebase.firestore.Timestamp
const messaging = firebase.messaging?.isSupported()
  ? firebaseApp?.messaging(firebaseApp)
  : null
export {
  db,
  auth,
  DB,
  storage,
  FieldValue,
  getServerTime,
  Timestamp,
  firebaseApp,
  messaging,
  FieldPath,
}
