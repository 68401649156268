import { useStateValue } from "state"

export const useAlerter = () => {
  const [, dispatch] = useStateValue()
  const alerter = (message, type) => {
    dispatch({
      type: "SHOW_ALERT",
      open: true,
      message: message,
      alertType: type,
    })
  }
  return {
    alerter,
  }
}
