import React from "react"
import { IconButtonCircle } from "uiComponents"
import "./kyc.css"

const KycStepVertical = ({ opacities }) => {
  return (
    <div className='stepper-container-vertical'>
      <div className='step-vertical'>
        <IconButtonCircle
          icon='check'
          bgColor='var(--header-color)'
          color='white'
        />
      </div>
      <div className='divider-vertical' />
      <div className='step-vertical' style={{ opacity: opacities[1] }}>
        <IconButtonCircle
          icon={opacities[1] ? "" : "check"}
          bgColor='var(--header-color)'
          color='white'
        />
      </div>
      <div className='divider-vertical' />
      <div className='step-vertical' style={{ opacity: opacities[2] }}>
        <IconButtonCircle
          icon={opacities[2] ? "" : "check"}
          bgColor={opacities[2] ? "var(--header-color)" : "var(--green)"}
          color='white'
        />
      </div>
    </div>
  )
}

export default KycStepVertical
