import React from "react"
import { IconButtonCircle, Header } from "uiComponents"
import "./AnalyticsTile.css"

const AnalyticsTile = ({ title, value, icon, noIcon, index, light }) => {
  return (
    <div>
      <div className={`analytics-tile ${light ? "light" : ""}`} key={index}>
        <div className='analytics-icon-container'>
          <i className={`fas fa-${icon} analytics-icon`} />
          <div className='analytics-text-container'>
            <Header>{title}</Header>
            <Header bold>{value}</Header>
          </div>
        </div>
        {noIcon || <IconButtonCircle accent icon={"arrow-right"} />}
      </div>
    </div>
  )
}

export default AnalyticsTile
