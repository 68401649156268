import React from "react"
import getSVG from "../../../images/svgs/svg"
import { Col, Space, Card, Header } from "uiComponents"
import Button from "../../UI/Button/Button"

const Logistics = () => {
  return (
    <Card noXS>
      <Col>
        <div style={{ textAlign: "center" }}>
          {getSVG("logistics", { width: 200 }, null, true)}
        </div>
        <Header bold sm>
          Logistics
        </Header>
        <p>
          Add logistics to complete the deliveries in no time with starting just
          Rs.1200/Month. Our logistics solutions are designed to streamline your
          supply chain operations, ensuring that your goods are delivered
          efficiently and reliably.
        </p>
        <Space lg />
        <Button link>
          Explore more{" "}
          <span className='fas fa-arrow-right' style={{ marginLeft: "10px" }} />
        </Button>
      </Col>
    </Card>
  )
}

export default Logistics
