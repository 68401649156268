import React, { useEffect, useState } from "react"
import {
  Grid,
  Space,
  Button,
  Card,
  Header,
  Dialog,
  DialogHeader,
  Loader,
  Jumbotron,
} from "uiComponents"
import { Link, useLocation, useParams } from "react-router-dom"
import * as Yup from "yup"
import { DocMaker, CollectionRenderer } from "firestoreComponents"
import { useStateValue } from "state"
import { sendNotification, MessagePopup } from "appComponents"
import { flexRowCenterBetween } from "commonStyles"
import { parseDateTime } from "helpers"
import { AppPaths, getPath } from "shoppio-constants"

export const getMessageFields = ({ isSeller, userMeta, message }) => [
  {
    name: "name",
    label: "Name",
    type: "text",
    value: userMeta?.name,
    hidden: true,
  },
  {
    name: "email",
    label: "Email",
    type: "email",
    value: userMeta?.email,
    hidden: true,
  },
  { name: "isSeller", hidden: true, value: isSeller },
  {
    name: "message",
    type: "text",
    placeholder: "Type your message...",
    withButton: true,
    buttonActiveOnValue: true,
    submit: true,
    buttonText: (
      <span>
        <span className='fas fa-paper-plane' />
      </span>
    ),
    fullWidth: true,
    withIcon: <span className='lnr lnr-cross'></span>,
    clearOnIconClick: true,
    validate: Yup.string()
      .required("Please enter your message")
      .min(5, "Please enter a message more than 5 characters")
      .max(200, "Please enter a message less than 200 characters"),
  },
]
const MessageToCustomer = () => {
  const [{ user, userMeta }] = useStateValue()
  const [currentUserID, setCurrentUser] = useState()
  let { store_id } = useParams()
  const { hash } = useLocation()
  useEffect(() => {
    let userID = hash.replace("#", "")
    setCurrentUser(userID)
  }, [hash])

  return store_id ? (
    <>
      <div>
        <Grid>
          <Space lg />
          <Jumbotron title='Customer messages' icon='lnr lnr-user' />
          <Space md />
          {user ? (
            <>
              <Dialog
                right
                noScroll
                open={currentUserID}
                onClose={() => setCurrentUser()}
              >
                <DialogHeader>
                  <Header md bold>
                    Message to Customer
                  </Header>
                  <p>Customer ID: {currentUserID}</p>
                </DialogHeader>
                {currentUserID && store_id ? (
                  <DocMaker
                    limit={3}
                    sorting={{
                      orderBy: "createdAt",
                      orderByMethod: "asc",
                    }}
                    loadOnTop
                    formInPortalElement={document.querySelector(
                      ".dialog-content",
                    )}
                    loaderType={"message"}
                    collectionMaxHeight={"60vh"}
                    collectionName={`messages/${store_id}/data/`}
                    entityName='Message to Customer'
                    scopedOn={currentUserID}
                    canAdd
                    forMessaging
                    onAdd={(id, message) => {
                      sendNotification({
                        title: `You have got a message from ${userMeta.name}`,
                        body: message.message,
                        link: {
                          label: "View Messages",
                          link: getPath(AppPaths.SELLER_MESSAGES),
                          state: {
                            storeid: store_id,
                            hash: "seller_messages",
                          },
                        },
                        toUserID: currentUserID,
                      })
                    }}
                    reducerVar='messages'
                    lg={12}
                    fields={() =>
                      getMessageFields({
                        userMeta,
                        isSeller: true,
                      })
                    }
                    renderEntity={({
                      data: { message, createdAt, isSeller },
                      id,
                    }) => (
                      <div>
                        <MessagePopup right={isSeller} createdAt={createdAt}>
                          {message}
                        </MessagePopup>
                      </div>
                    )}
                  />
                ) : (
                  ""
                )}
              </Dialog>

              <CollectionRenderer
                noReload
                collectionPath={`messages/${store_id}/data`}
                viewType={"custom"}
                noDocMessage={"No messages yet on your store."}
                limit={5}
                sorting={{
                  orderBy: "createdAt",
                  orderByMethod: "asc",
                }}
                renderDoc={({ id, data }) => (
                  <Grid>
                    <div
                      style={{
                        ...flexRowCenterBetween,
                        padding: "1rem 0",
                        borderBottom: "1px solid var(--border-color)",
                      }}
                    >
                      <Header sm bold>
                        {data.name}
                      </Header>

                      <div style={{ ...flexRowCenterBetween }}>
                        <p className='new-tag'>
                          Chat started on: {parseDateTime(data.createdAt)}
                        </p>
                        <Button noMar small onClick={() => setCurrentUser(id)}>
                          Open Chat
                        </Button>
                      </div>
                    </div>
                  </Grid>
                )}
              />
            </>
          ) : (
            <Card>
              <Header>Please Login To Continue</Header>
              <Link to={AppPaths.LOGIN}>
                <Button>Login</Button>
              </Link>
            </Card>
          )}
        </Grid>
      </div>
    </>
  ) : (
    <Loader />
  )
}

export default MessageToCustomer
