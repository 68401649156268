import React, { Fragment, useEffect, useState } from "react"
import { Card } from "uiComponents"

const ExpandableCard = ({ title, children, onExpand }) => {
  const [expanded, setExpandable] = useState(false)
  useEffect(() => {
    if (expanded && onExpand) onExpand()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expanded])
  return (
    <Card noPad noHorPad notFull>
      <div className={"expandable-header " + (onExpand ? "onExpand" : "")}>
        {title}{" "}
        <button onClick={() => setExpandable(!expanded)}>
          {!onExpand && (
            <Fragment>
              {expanded ? (
                <span className='fas fa-chevron-up' />
              ) : (
                <span className='fas fa-chevron-down' />
              )}
            </Fragment>
          )}

          {onExpand ? "View" : ""}
        </button>
      </div>
      {expanded ? <div>{children}</div> : ""}
    </Card>
  )
}

export default ExpandableCard
