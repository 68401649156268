import React, { Fragment } from "react"
import { Rating } from "../../UI/StarRating"
import { Header } from "uiComponents"
import { isMobile } from "helpers"

export default function Rater(props) {
  // console.log(props)
  return (
    <Fragment>
      {props.noHead ? "" : <Header>Rate</Header>}
      <Rating
        allowFraction
        fillColor={"var(--theme-color)"}
        initialValue={props.initialValue || props.value || props.rating}
        {...props}
        size={
          props?.style?.fontSize ? props?.style?.fontSize : isMobile ? 20 : 16
        }
      />
    </Fragment>
  )
}
