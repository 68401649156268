export const formStatus = {
  submitted: "Submitted",
  success: "Submission Successful",
  error: "Submission Error",
  validating: "Validating",
  pending: "Pending",
  approved: "Approved",
  rejected: "Rejected",
}

export const formStatusIcon = {
  submitted: "fa-check",
  success: "fa-check",
  error: "fa-times",
  validating: "fa-hourglass-start",
  pending: "fa-spinner ",
  approved: "fa-check",
  rejected: "fa-times",
}

export const formStatusColors = {
  submitted: "#00796b",
  success: "#388e3c",
  error: "#d32f2f",
  validating: "#E69A28",
  pending: "#fbc02d",
  approved: "#2e7d32",
  rejected: "#c62828",
}
