import React from "react"
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts"

const formatYAxis = tickItem => {
  if (tickItem >= 1000) {
    return `${tickItem / 1000}k`
  }
  return tickItem
}

const DynamicLineChart = ({ data }) => {
  return (
    <ResponsiveContainer width='100%' height={300}>
      <LineChart
        width={350}
        height={200}
        data={data}
        margin={{
          left: -30,
          top: 20,
          bottom: 5,
        }}
      >
        <XAxis dataKey='year' padding={{ left: 30, right: 30 }} />
        <YAxis tickFormatter={formatYAxis} />
        <Tooltip isAnimationActive />
        {/* <Legend /> */}
        <Line
          strokeWidth={2}
          type='monotone'
          dataKey='saleAmmount'
          stroke='var(--header-color)'
          activeDot={{ r: 8 }}
          isAnimationActive={true}
          animationEasing='linear'
        />
      </LineChart>
    </ResponsiveContainer>
  )
}

export default DynamicLineChart
