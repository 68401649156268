import React from "react"
import "./ActionCard.css"
import { Card, Button, Header, Space } from "uiComponents"
import { getSVG } from "getSVG"
import { Link } from "react-router-dom/cjs/react-router-dom.min"
export default function ActionCard({ action, title, desc, svgName }) {
  return (
    <div className='action-card'>
      <Card veryLowPad>
        {svgName ? getSVG(svgName, { width: "100%" }, null, true) : ""}
        <Header bold>{title}</Header>
        <p>{desc}</p>
        <Space />
        <Link to={action.disabled ? "#" : action.link || action.to || "#"}>
          <Button
            small
            noMar
            fullWidth
            onClick={action?.onClick}
            disabled={action?.disabled}
          >
            {action.label}
          </Button>
        </Link>
      </Card>
    </div>
  )
}
