import React, { useState } from "react"
import {
  Row,
  Col,
  Card,
  Space,
  Header,
  Button,
  IconButtonCircle,
  SpaceBetween,
  SlideContainer,
  Slide,
} from "uiComponents"
import "./CustomerMessages.css"
import { FormMaker } from "formComponents"
import { messageData, messageFields } from "./MessageData"
import { useStateValue } from "state"
import { AvatarChip } from "../QueryResolution/QueryResolution"
import Picker from "@emoji-mart/react"
import data from "@emoji-mart/data"
import { isMobile } from "helpers"
import SentimentSatisfiedAltOutlined from "@mui/icons-material/SentimentDissatisfiedOutlined"

export const getInitials = (name = "") => {
  if (typeof name !== "string" || !name.trim()) {
    return ""
  }

  const initials = name
    .split(" ")
    .map(word => word.charAt(0).toUpperCase())
    .join("")

  return initials
}

const isDark = localStorage.getItem("theme") === "dark"

const CustomerMessages = () => {
  const [{ userMeta }] = useStateValue()
  console.log("............", userMeta?.name)
  const [activeIndex, setActiveIndex] = useState(2)

  const senderName = userMeta?.name || "AR"
  const senderInitials = getInitials(senderName)

  const [selectedMessage, setSelectedMessage] = useState(
    messageData[0].username,
  )
  const [newMessage, setNewMessage] = useState("")
  const [messages, setMessages] = useState(messageData)
  const [showEmojiPicker, setShowEmojiPicker] = useState(false)

  const handleCardClick = username => {
    setActiveIndex(2)
    setSelectedMessage(username)
  }

  const selectedMessageData = messages.find(
    message => message.username === selectedMessage,
  )

  const handleInputChange = e => {
    setNewMessage(e.target.value)
  }

  const handleSendClick = () => {
    if (newMessage.trim() !== "") {
      const updatedMessages = messages.map(message => {
        if (message.username === selectedMessage) {
          return {
            ...message,
            messages: [
              ...(message.messages || []),
              {
                text: newMessage,
                sender: "user",
                timestamp: new Date().toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                  hour12: true,
                }),
              },
            ],
          }
        }
        return message
      })
      setMessages(updatedMessages)
      setNewMessage("")
      setShowEmojiPicker(false)
    }
  }

  const handleKeyDown = event => {
    if (event.key === "Enter") {
      handleSendClick()
    }
  }

  const handleEmojiSelect = emoji => {
    setNewMessage(newMessage + emoji.native)
  }

  return (
    <div>
      <Row>
        <Col sm={12} md={12}>
          <Card
            noXS
            style={{
              height: "calc(100vh - 50px - 3rem)",
            }}
            fullHeight
            rowStyle={{ height: "100%" }}
            colStyle={{ height: "100%" }}
            innerStyle={{ height: "100%" }}
            childrenStyle={{ height: "100%" }}
          >
            <SpaceBetween>
              <div>
                <Header bold sm>
                  Customer Messages
                </Header>
                <p>
                  Here is the list of the customers that you have chatted with
                </p>
              </div>
              {!isMobile && (
                <Button withIcon='plus-circle' fullRound>
                  Start New Chat
                </Button>
              )}
            </SpaceBetween>
            <Space lg />
            {isMobile && (
              <SlideContainer activeIndex={activeIndex}>
                <Slide index={1}>
                  {messages.map(data => (
                    <MessageCard
                      key={data.username}
                      data={data}
                      isSelected={selectedMessage === data.username}
                      onClick={() => handleCardClick(data.username)}
                    />
                  ))}
                </Slide>
                <Slide index={2}>
                  <Col
                    sm={12}
                    md={6}
                    style={{
                      padding: "0",
                    }}
                  >
                    <div
                      style={{
                        position: "relative",
                        height: "100%",
                      }}
                    >
                      <div
                        style={{ position: "absolute", top: 0, width: "100%" }}
                      >
                        {selectedMessageData && (
                          <SpaceBetween
                            style={{
                              borderTop: "0.2px solid var(--border-color)",
                              borderBottom: "0.2px solid var(--border-color)",
                              padding: "15px 5px",
                            }}
                          >
                            <Col sm={12} md={1}>
                              <AvatarChip
                                large
                                label={getInitials(
                                  selectedMessageData.username,
                                )}
                                style={{
                                  backgroundColor: "var(--background)",
                                }}
                              />
                            </Col>
                            <Col sm={12} md={10}>
                              <Header sm>{selectedMessageData.username}</Header>
                              <small>
                                Last message: {selectedMessageData.timestamp}
                              </small>
                            </Col>
                            <Col sm={12} md={1}>
                              <IconButtonCircle icon='ellipsis-v' />
                            </Col>
                          </SpaceBetween>
                        )}
                      </div>
                      <div className='chat-messages'>
                        {selectedMessageData &&
                          (selectedMessageData.messages || []).map(
                            (msg, index) => (
                              <div
                                key={index}
                                className={`message-item ${
                                  msg.sender === "user"
                                    ? "message-item-right"
                                    : ""
                                }`}
                              >
                                {msg.sender !== "user" && (
                                  <AvatarChip
                                    label={getInitials(
                                      selectedMessageData.username,
                                    )}
                                    accent
                                  />
                                )}
                                <div
                                  className={`chat-message-text ${
                                    msg.sender === "user"
                                      ? "user-message-text"
                                      : ""
                                  }`}
                                >
                                  <p>{msg.text}</p>
                                  <small>{msg.timestamp}</small>
                                </div>
                                {msg.sender === "user" && (
                                  <AvatarChip
                                    label={senderInitials}
                                    accentAlt
                                  />
                                )}
                              </div>
                            ),
                          )}
                      </div>
                      {showEmojiPicker && (
                        <div className='emoji-picker'>
                          <Picker
                            onClickOutside
                            theme={isDark ? "dark" : "light"}
                            data={data}
                            onEmojiSelect={handleEmojiSelect}
                          />
                        </div>
                      )}
                      <div className='message-input-container'>
                        <div className='message-input-container-children'>
                          <div className='message-input-container-subchildren'>
                            <input
                              type='text'
                              value={newMessage}
                              onChange={handleInputChange}
                              onKeyDown={handleKeyDown}
                              className='message-input'
                              placeholder='Type your message...'
                            />
                            <div>
                              <SentimentSatisfiedAltOutlined
                                onClick={() =>
                                  setShowEmojiPicker(!showEmojiPicker)
                                }
                                style={{
                                  cursor: "pointer",
                                  fontSize: "7px",
                                }}
                              />
                            </div>
                          </div>
                          <Button noMar extraPad onClick={handleSendClick}>
                            Send
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Slide>
              </SlideContainer>
            )}

            <Row style={{ height: "calc(100% - 70px)" }}>
              <Col
                sm={12}
                md={3}
                style={{
                  border: "0.2px solid var(--border-color)",
                  padding: "0",
                  height: "100%",
                }}
              >
                <div
                  style={{
                    height: "100%",
                    maxHeight: "100%",
                    overflowY: "auto",
                    scrollbarWidth: "none",
                    position: "relative",
                  }}
                >
                  <div>
                    {messages.map(data => (
                      <MessageCard
                        key={data.username}
                        data={data}
                        isSelected={selectedMessage === data.username}
                        onClick={() => handleCardClick(data.username)}
                      />
                    ))}
                  </div>
                </div>
              </Col>
              {!isMobile && (
                <>
                  <Col
                    sm={12}
                    md={6}
                    style={{
                      padding: "0",
                    }}
                  >
                    <div
                      style={{
                        position: "relative",
                        height: "100%",
                      }}
                    >
                      <div
                        style={{ position: "absolute", top: 0, width: "100%" }}
                      >
                        {selectedMessageData && (
                          <SpaceBetween
                            style={{
                              borderTop: "0.2px solid var(--border-color)",
                              borderBottom: "0.2px solid var(--border-color)",
                              padding: "15px 5px",
                            }}
                          >
                            <Col sm={12} md={1}>
                              <AvatarChip
                                large
                                label={getInitials(
                                  selectedMessageData.username,
                                )}
                                style={{
                                  backgroundColor: "var(--background)",
                                }}
                              />
                            </Col>
                            <Col sm={12} md={10}>
                              <Header sm>{selectedMessageData.username}</Header>
                              <small>
                                Last message: {selectedMessageData.timestamp}
                              </small>
                            </Col>
                            <Col sm={12} md={1}>
                              <IconButtonCircle icon='ellipsis-v' />
                            </Col>
                          </SpaceBetween>
                        )}
                      </div>
                      <div className='chat-messages'>
                        {selectedMessageData &&
                          (selectedMessageData.messages || []).map(
                            (msg, index) => (
                              <div
                                key={index}
                                className={`message-item ${
                                  msg.sender === "user"
                                    ? "message-item-right"
                                    : ""
                                }`}
                              >
                                {msg.sender !== "user" && (
                                  <AvatarChip
                                    label={getInitials(
                                      selectedMessageData.username,
                                    )}
                                    accent
                                  />
                                )}
                                <div
                                  className={`chat-message-text ${
                                    msg.sender === "user"
                                      ? "user-message-text"
                                      : ""
                                  }`}
                                >
                                  <p>{msg.text}</p>
                                  <small>{msg.timestamp}</small>
                                </div>
                                {msg.sender === "user" && (
                                  <AvatarChip
                                    label={senderInitials}
                                    accentAlt
                                  />
                                )}
                              </div>
                            ),
                          )}
                      </div>
                      {showEmojiPicker && (
                        <div className='emoji-picker'>
                          <Picker
                            onClickOutside
                            theme={isDark ? "dark" : "light"}
                            data={data}
                            onEmojiSelect={handleEmojiSelect}
                          />
                        </div>
                      )}
                      <div className='message-input-container'>
                        <div className='message-input-container-children'>
                          <div className='message-input-container-subchildren'>
                            <input
                              type='text'
                              value={newMessage}
                              onChange={handleInputChange}
                              onKeyDown={handleKeyDown}
                              className='message-input'
                              placeholder='Type your message...'
                            />
                            <div>
                              <SentimentSatisfiedAltOutlined
                                onClick={() =>
                                  setShowEmojiPicker(!showEmojiPicker)
                                }
                                style={{
                                  cursor: "pointer",
                                  fontSize: "7px",
                                }}
                              />
                            </div>
                          </div>
                          <Button noMar extraPad onClick={handleSendClick}>
                            Send
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col
                    sm={12}
                    md={3}
                    style={{
                      border: "0.2px solid var(--border-color)",
                      height: "100%",
                    }}
                  >
                    <div
                      style={{
                        height: "100%",
                      }}
                    >
                      <Space lg />
                      <Header center sm bold>
                        Chat Information
                      </Header>
                      <Space lg />
                      <FormMaker fields={messageFields} hideSubmit />
                      <Header bold>Tags </Header>
                      <div style={{ display: "flex", gap: "5px" }}>
                        <Tag label={"Paid"} />
                        <Tag label={"Old"} />
                      </div>
                    </div>
                  </Col>
                </>
              )}
              {/* {isMobile && <NewChat />} */}
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default CustomerMessages

const MessageCard = ({ data, onClick, isSelected }) => {
  const initials = getInitials(data.username)
  return (
    <div
      className={`message-card ${isSelected ? "selected" : ""}`}
      onClick={onClick}
    >
      <div className='message-content'>
        <SpaceBetween>
          <div style={{ display: "flex" }} className='message-info'>
            <AvatarChip
              large
              label={initials}
              style={{
                backgroundColor: isSelected && "var(--background)",
              }}
            />
            <div className='message-info'>
              <Header bold>{data.username}</Header>
              <p className='message-text'>{data.textMessage}</p>
            </div>
          </div>
          <div className='message-actions'>
            {data.unreadMessage > 0 && (
              <UnreadCount count={data.unreadMessage} />
            )}
            {!isSelected && (
              <i className='fas fa-chevron-right message-chevron' />
            )}
          </div>
        </SpaceBetween>
        <Space />
        <SpaceBetween>
          <div>
            {data.vip && (
              <VipChip label='VIP Customer' isSelected={isSelected === true} />
            )}
          </div>
          <small>{data.timestamp}</small>
        </SpaceBetween>
      </div>
    </div>
  )
}

const VipChip = ({ accent, style, label, isSelected }) => {
  return (
    <div
      className={`vip-chip ${accent ? " accent" : ""}`}
      style={{
        backgroundColor: isSelected && "var(--background)",
        ...style,
      }}
    >
      {label}
    </div>
  )
}

const UnreadCount = ({ count }) => {
  return (
    <div className='unread-count'>
      <span>{count}</span>
    </div>
  )
}

const Tag = ({ label }) => {
  return (
    <div className='tag-class'>
      <span>{label}</span>
    </div>
  )
}

export const NewChat = () => {
  return <div className='new-chat-container'>+</div>
}
