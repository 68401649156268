import React from "react"
import { Header, IconButtonCircle, SpaceBetween } from "uiComponents"
import { StatusBar } from "../EngineeringDashboard"
import { colorMap, complexities } from "dummyData"
import { AvatarChip } from "appComponents/QueryResolution/QueryResolution"
import { getInitials } from "appComponents/Messages/CustomerMessages"

const TaskCard = ({ data }) => {
  let assignee
  try {
    assignee = JSON.parse(data?.assignee)
  } catch (error) {
    console.error("Failed to parse assignee data", error)
    assignee = null
  }

  return (
    <>
      <SpaceBetween>
        <div style={{ display: "flex", gap: "10px" }}>
          <IconButtonCircle size='20' accent icon='palette' />
          <div>
            <Header>{data?.name}</Header>
            {data?.priority && (
              <StatusBar
                color={colorMap.priority[data?.priority]}
                label={data?.priority}
              />
            )}
            {data?.status && (
              <StatusBar
                color={colorMap.status[data?.status]}
                label={data?.status}
              />
            )}
            {data?.task_complexity && (
              <StatusBar
                color={colorMap.complexity[data?.task_complexity]}
                label={
                  complexities.find(
                    complexity => complexity.label === data?.task_complexity,
                  )?.label
                }
              />
            )}

            {data?.due_date && (
              <>
                Due Date:{" "}
                <strong>{new Date(data?.due_date).toLocaleDateString()}</strong>
              </>
            )}
          </div>
        </div>
        <AvatarChip
          large
          accentAlt
          style={{
            color: "var(--theme-color)",
            backgroundColor: "var(--foreground)",
            fontWeight: "bold",
          }}
          label={getInitials(assignee?.value)}
        />
      </SpaceBetween>
    </>
  )
}

export default TaskCard
