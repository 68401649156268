import React from "react"
import { Card, Header, Space, Divider, Button } from "uiComponents"
import SpaceBetween from "../../UI/Space/SpaceBetween"

const formatted = number => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

const BillingCard = ({ planName, price, startDate, endDate }) => {
  const sgst = (price * 0.09).toFixed()
  const cgst = (price * 0.09).toFixed()
  const total = (
    parseFloat(price) +
    parseFloat(sgst) +
    parseFloat(cgst)
  ).toFixed()

  return (
    <Card notFull noXS>
      <span
        style={{
          fontWeight: "bold",
          fontSize: "3rem",
          color: "var(--header-color)",
          fontFamily: "var(--font-two)",
        }}
      >
        Shoppio{" "}
      </span>
      <span style={{ fontWeight: "bold", color: "green", fontSize: "3rem" }}>
        {planName}
      </span>
      <Space lg />
      <SpaceBetween>
        <Header bold>{planName}</Header>
        <Header bold>₹ {formatted(price)}</Header>
      </SpaceBetween>
      <p>
        {startDate} - {endDate}
      </p>
      <Space lg />
      <Divider />
      <Space lg />
      <SpaceBetween>
        <p>Subtotal</p>
        <Header bold>₹ {formatted(price)}</Header>
      </SpaceBetween>
      <SpaceBetween>
        <p>CGST (9%)</p>
        <Header bold>₹ {formatted(cgst)}</Header>
      </SpaceBetween>
      <SpaceBetween>
        <p>SGST (9%)</p>
        <Header bold>₹ {formatted(sgst)}</Header>
      </SpaceBetween>
      <Space lg />
      <Divider />
      <Space lg />
      <SpaceBetween>
        <Header bold>Total</Header>
        <Header bold>₹ {formatted(total)}</Header>
      </SpaceBetween>
      <SpaceBetween>
        <Header bold>Total Amount Due</Header>
        <Header bold>₹ {formatted(total)}</Header>
      </SpaceBetween>
      <Space lg />
      <Button fullWidth>₹ {formatted(total)} Pay</Button>
    </Card>
  )
}

export default BillingCard
