import getSVG from "../../../images/svgs/svg"
import { Space, Button, Col, Card, Header } from "uiComponents"

const GetYourApp = () => {
  return (
    <Card noXS>
      <Col>
        <Header bold sm>
          Get Your Own App
        </Header>
        <p>Upgrade to reach your customer even quicker by mobile apps.</p>
        <Space />
        <p>
          You can get your store app on play store and app store in no time.
        </p>
        <div style={{ textAlign: "center" }}>
          {getSVG("App_data", { width: 200 }, null, true)}
        </div>
        <Button fullRound fullWidth>
          Upgrade Now
        </Button>
      </Col>
    </Card>
  )
}

export default GetYourApp
