import React, { useState } from "react"
import DataTable from "react-data-table-component"
import { SpaceBetween, ViewBy, Space } from "uiComponents"
// import "./DataTableWithFilter.css"

const DataTableWithFilter = ({ columns, data }) => {
  const [searchTerm, setSearchTerm] = useState("")

  const filteredData = data?.filter(row =>
    Object.values(row).some(value =>
      value.toString().toLowerCase().includes(searchTerm.toLowerCase()),
    ),
  )
  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
      },
    },
  }

  return (
    <>
      <SpaceBetween>
        <div className='search-bar-container'>
          <input
            type='text'
            className='search-bar-input-container'
            value={searchTerm}
            placeholder='Search'
            onChange={e => setSearchTerm(e.target.value)}
          />
          <span className='fas fa-lg fa-search' />
        </div>
        <ViewBy />
      </SpaceBetween>
      <Space lg />
      <DataTable
        columns={columns}
        data={filteredData}
        pagination
        highlightOnHover
        striped
        customStyles={customStyles}
      />
    </>
  )
}

export default DataTableWithFilter
