import React, { useState } from "react"
import {
  Card,
  IconButtonCircle,
  Row,
  Header,
  Button,
  Col,
  Space,
} from "uiComponents"
import { PageLayout } from "appComponents"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { InputField } from "formComponents"

const ReviewStatus = () => {
  const history = useHistory()
  const [pan, setPan] = useState("CUDPS4933K")
  const [errorText, setErrorText] = useState("")

  const handlePanChange = e => {
    const value = e.target.value.slice(0, 10)
    setPan(value.toUpperCase())
    setErrorText("")
  }

  const validatePan = () => {
    const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]$/
    if (!panRegex.test(pan)) {
      setErrorText(
        "PAN provided is not in a valid format. Please provide a correct PAN.",
      )
    } else {
      setErrorText("")
    }
  }

  return (
    <>
      <PageLayout
        mainContent={
          <Row>
            <Col sm={12} md={12}>
              <Card>
                <Row between='xs'>
                  <div style={{ display: "flex" }}>
                    <IconButtonCircle
                      icon='chevron-left'
                      mr='10'
                      onClick={() => history.goBack()}
                    />
                    <div>
                      <Header sm bold>
                        Review Details
                      </Header>
                      <p>
                        Complete your account setup to start selling Shoppio
                      </p>
                    </div>
                  </div>
                  <Button extraPad small color='var(--error)'>
                    Need Clarification
                  </Button>
                </Row>
                <Space lg />
                <Space lg />
                <Row>
                  <Col sm={12} md={6}>
                    <InputField
                      field={{ pan: "" }}
                      form={{
                        errors: {},
                      }}
                      errorText={errorText}
                      label={"Pan Number"}
                      value={pan}
                      onChange={handlePanChange}
                      onBlur={validatePan}
                    />
                    <Button extraPad>Update</Button>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        }
      />
    </>
  )
}

export default ReviewStatus
