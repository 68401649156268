import React, { useState } from "react"
import {
  Tab,
  Tabs,
  Card,
  Header,
  LineCharts,
  Dialog,
  Space,
} from "uiComponents"
import DataTableWithFilter from "./DataTableWithFilter"
import "./SalesDashboard.css"
import { leadsTableData, salesTableData } from "dummyData"

const data = [
  { year: "2017", saleAmmount: 201111 },
  { year: "2018", saleAmmount: 601111 },
  { year: "2019", saleAmmount: 401111 },
  { year: "2020", saleAmmount: 101111 },
  { year: "2021", saleAmmount: 601111 },
  { year: "2022", saleAmmount: 501111 },
]

const SalesManagement = () => {
  const [dialog, setDialog] = useState(false)
  const [selectedStore, setSelectedStore] = useState("")

  const handleStoreClick = storeName => {
    setSelectedStore(storeName)
    setDialog(true)
  }

  const salesColumns = [
    {
      name: "#",
      selector: (row, index) => index + 1,
      sortable: true,
      width: "50px",
    },
    {
      name: "Name",
      selector: row => row.name,
      sortable: true,
    },
    {
      name: "Phone Number",
      selector: row => row.phoneNumber,
      sortable: true,
    },
    {
      name: "Purchased On",
      selector: row => row.purchasedOn,
      sortable: true,
      format: row => new Date(row.purchasedOn).toLocaleDateString(),
    },
    {
      name: "Plan Purchased",
      selector: row => row.planPurchased,
      sortable: true,
    },
    {
      name: "Store Name",
      cell: row => (
        <div
          style={{ textDecoration: "underline", cursor: "pointer" }}
          onClick={() => handleStoreClick(row.storeName)}
        >
          {row.storeName}
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ]

  const leadsColumns = [
    {
      name: "#",
      selector: (row, index) => index + 1,
      sortable: true,
      width: "50px",
    },
    {
      name: "Name",
      selector: row => row.name,
      sortable: true,
    },
    {
      name: "Phone Number",
      selector: row => row.phoneNumber,
      sortable: true,
    },
    {
      name: "Signed Up On",
      selector: row => row.purchasedOn,
      sortable: true,
      format: row => new Date(row.purchasedOn).toLocaleDateString(),
    },
    {
      name: "Source",
      selector: row => row.source,
      sortable: true,
    },
    {
      name: "Store Name",
      cell: row => (
        <div
          style={{ textDecoration: "underline", cursor: "pointer" }}
          onClick={() => handleStoreClick(row.storeName)}
        >
          {row.storeName}
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ]

  return (
    <>
      <Tabs>
        <Tab label='Overview'>
          <Card>
            <Header sm bold>
              Performance
            </Header>
            <LineCharts data={data} />
          </Card>
        </Tab>
        <Tab label='Sales'>
          <Card>
            <Header sm bold>
              Sales
            </Header>
            <Space lg />
            <DataTableWithFilter columns={salesColumns} data={salesTableData} />
          </Card>
        </Tab>
        <Tab label='Leads'>
          <Card>
            <Header sm bold>
              Leads
            </Header>
            <Space lg />
            <DataTableWithFilter columns={leadsColumns} data={leadsTableData} />
          </Card>
        </Tab>
        <Tab label='Tickets'></Tab>
      </Tabs>
      <Dialog open={dialog} popUp onClose={() => setDialog(false)}>
        <Header sm bold>
          {selectedStore}
        </Header>
        <p>Additional details about "{selectedStore}" can be shown here.</p>
      </Dialog>
    </>
  )
}

export default SalesManagement
