import React from "react"
import { Col, Row, Space, Button, Card, Grid } from "uiComponents"
import { getSVG } from "getSVG"
import { isMobile } from "helpers"
import "./Career.css"

const Career = () => {
  const scrollToOpenPositions = () => {
    const openPositionsElement = document.getElementById("open-positions")
    if (openPositionsElement) {
      openPositionsElement.scrollIntoView({
        behavior: "smooth",
      })
      openPositionsElement.classList.add("highlight")
    }
  }
  return (
    <Grid>
      <Card>
        <Row middle='xs'>
          <Col sm={12} md={5}>
            {isMobile && getSVG("Profile_data", { width: 250 })}
            <div className='career-header-text'>
              <span>Grow</span> with <span>Shoppio, Boost</span> your
              <span> Career</span>
            </div>
            <Space lg />
            <p className='career-subheader-text'>
              Transform your business operations with our cutting-edge digital
              tools.
            </p>
            <Space lg />
            <Space lg />
            <Button
              fullWidth={isMobile === true}
              extraPad
              fullRound
              onClick={scrollToOpenPositions}
            >
              See Open Positions
            </Button>
            <Button fullWidth={isMobile === true} extraPad fullRound alt>
              Learn More
            </Button>
          </Col>
          <Col sm={12} md={7}>
            {!isMobile &&
              getSVG("Profile_data", { width: 300, margin: "0.5rem 30rem" })}
          </Col>
        </Row>
      </Card>
    </Grid>
  )
}

export default Career
