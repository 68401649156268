import React, { Fragment, useEffect, useState } from "react"
import {
  Grid,
  Row,
  Col,
  Space,
  Button,
  Card,
  Header,
  Expander,
} from "uiComponents"
import getSVG from "./../../../images/svgs/svg"
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min"
import Icon from "./../../../images/Logo.png"
import { Footer, Jumbotron, Emblem } from "appComponents"
import { isMobile } from "helpers"
import { useStateValue } from "state"
import { AppPaths } from "shoppio-constants"

export const Video = ({ url, title }) => {
  return (
    <iframe
      width='100%'
      height='300px'
      src={url}
      title={title}
      frameborder='0'
      allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
      allowfullscreen
    ></iframe>
  )
}
export const HelpVideos = () => {
  const [expandedExpander, setExpandedExpander] = useState(null)

  const handleExpanderToggle = expanderTitle => {
    setExpandedExpander(prevExpanded =>
      prevExpanded === expanderTitle ? null : expanderTitle,
    )
  }
  useEffect(() => window.scrollTo(0, 0), [])
  return (
    <Row start='xs'>
      <Col xs={12} md={12}>
        <Space />
        <Header lg bold>
          How to's Video Guides
        </Header>
      </Col>
      <Col xs={12} md={12}>
        <Expander
          title={"How to sign up & create a store?"}
          expanded={expandedExpander === "How to sign up & create a store?"}
          onToggle={() =>
            handleExpanderToggle("How to sign up & create a store?")
          }
        >
          <Video
            url='https://www.youtube.com/embed/POkeac45dUA?si=bz5UjMWd2vsK2kZO'
            title='Watch: How to sign up & create a store?'
          />
        </Expander>
      </Col>
      <Col xs={12} md={12}>
        <Expander
          title={"How to add category and subcategory?"}
          expanded={expandedExpander === "How to add category and subcategory?"}
          onToggle={() =>
            handleExpanderToggle("How to add category and subcategory?")
          }
        >
          <Video
            url='https://www.youtube.com/embed/-D6WCH2pWr4?si=crmfO4wXHLKrCfzk'
            title={"Watch: How to add category and subcategory?"}
          />
        </Expander>
      </Col>
      <Col xs={12} md={12}>
        <Expander
          title={"How to add product and set category and subcategory?"}
          expanded={
            expandedExpander ===
            "How to add product and set category and subcategory?"
          }
          onToggle={() =>
            handleExpanderToggle(
              "How to add product and set category and subcategory?",
            )
          }
        >
          <Video
            url='https://www.youtube.com/embed/ddaTI2qa3Bw?si=HkoyXuF7cdoOT0ug'
            title={"How to add product and set category and subcategory?"}
          />
        </Expander>
      </Col>
      <Col xs={12} md={12}>
        <Expander
          title={"How to update product and add customizations?"}
          expanded={
            expandedExpander === "How to update product and add customizations?"
          }
          onToggle={() =>
            handleExpanderToggle(
              "How to update product and add customizations?",
            )
          }
        >
          <Video
            url='https://www.youtube.com/embed/LV44XhzFJis?si=aXyvBIZphQEXw63f'
            title={"How to update product and add customizations?"}
          />
        </Expander>
      </Col>
      <Col xs={12} md={12}>
        <Expander
          title={"Create a Unique Link (Shoppio Prime Link) for your store"}
          expanded={
            expandedExpander ===
            "Create a Unique Link (Shoppio Prime Link) for your store"
          }
          onToggle={() =>
            handleExpanderToggle(
              "Create a Unique Link (Shoppio Prime Link) for your store",
            )
          }
        >
          <Video
            url='https://www.youtube.com/embed/x_WAnv6Z47A'
            title={"Create a Unique Link (Shoppio Prime Link) for your store"}
          />
        </Expander>
      </Col>
    </Row>
  )
}
export default function WhyShoppioSellers() {
  const history = useHistory()
  const [{ userMeta }] = useStateValue()

  return (
    <div>
      <Jumbotron
        config={{
          style: {
            marginTop: 0,
          },
          logoStyle: {
            filter: "var(--filter)",
          },
          logo: Icon,
          version: "default",
          title: "Sellers & Services",
          backgroundImage:
            "https://firebasestorage.googleapis.com/v0/b/challenge-576ea.appspot.com/o/production%2Fbanner2.png?alt=media&token=42057dd7-58d5-4a0d-97eb-cc1f657d9daf",
          backgroundImageRight:
            "https://firebasestorage.googleapis.com/v0/b/challenge-576ea.appspot.com/o/production%2Fbanner4.png?alt=media&token=202f690b-8717-491c-bbf4-e722028c4750",
          subtitle: (
            <Fragment>
              Sell your products or provide services locally online at ease with
              our integrated platform
            </Fragment>
          ),
          actions: [
            {
              label: "Get Started",
              onClick: () =>
                history.push(
                  userMeta ? AppPaths.SELLER_PROFILE : AppPaths.SELLER_SIGNUP,
                ),
            },
          ],
        }}
      />
      <Grid>
        <Row>
          <Col xs={12}>
            <Header center md bold>
              Why Choose Shoppio?
            </Header>
          </Col>
          <Col xs={12} md={6}>
            <Card notFull svgIcon={getSVG("platform", null, null, true)}>
              <Header bold sm>
                Integrated Platform
              </Header>
              <p>
                Manage your products/services, orders/bookings and sales from
                one platform.
              </p>
              <Space />
              <Space />
              {/* <Link to=''>
                <Button>Learn More</Button>
              </Link> */}
            </Card>
          </Col>
          <Col xs={12} md={6}>
            <Card notFull svgIcon={getSVG("category", null, null, true)}>
              <Header bold sm>
                E-Commerce Enabled
              </Header>
              <p>
                Add categories, sub categories to your store, manage branding
                and more.
              </p>
              <Space />
              <Space />
              {/* <Link to=''>
                <Button>Learn More</Button>
              </Link> */}
            </Card>
          </Col>
          <Col xs={12} md={6}>
            <Card notFull svgIcon={getSVG("easysetup", null, null, true)}>
              <Header bold sm>
                Easy onboarding
              </Header>
              <p>
                Signup is super quick, you just need a mobile number and a PAN
                to get started.
              </p>
              <Space />
              <Space />
              {/* <Link to=''>
                <Button>Learn More</Button>
              </Link> */}
            </Card>
          </Col>
          <Col xs={12} md={6}>
            <Card notFull svgIcon={getSVG("fee", null, null, true)}>
              <Header bold sm>
                Simple Fee Structure
              </Header>
              <p>
                Shoppio fee structure is designed to be nominal even for the
                sellers who are just starting out.
              </p>
              <Space />
              <Space />
              {/* <Link to=''>
                <Button>Learn More</Button>
              </Link> */}
            </Card>
          </Col>
          <Col xs={12} md={6}>
            <Card notFull svgIcon={getSVG("security", null, null, true)}>
              <Header bold sm>
                Robust Security Measures
              </Header>
              <p>
                Shoppio employs advanced security protocols to ensure the safety
                and privacy of your business data and customer information.
              </p>
              <Space />
              <Space />
              {/* <Link to=''>
        <Button>Learn More</Button>
      </Link> */}
            </Card>
          </Col>
          <Col xs={12} md={6}>
            <Card notFull svgIcon={getSVG("support", null, null, true)}>
              <Header bold sm>
                Dedicated Customer Support
              </Header>
              <p>
                Benefit from our dedicated customer support team, ready to
                assist you with any queries or issues you may encounter.
              </p>
              <Space />
              <Space />
              {/* <Link to=''>
        <Button>Learn More</Button>
      </Link> */}
            </Card>
          </Col>
          <Col xs={12} md={6}>
            <Card notFull svgIcon={getSVG("analytics", null, null, true)}>
              <Header bold sm>
                Powerful Analytics
              </Header>
              <p>
                Gain insights into your business performance with detailed
                analytics and reporting tools provided by Shoppio.
              </p>
              <Space />
              <Space />
              {/* <Link to=''>
        <Button>Learn More</Button>
      </Link> */}
            </Card>
          </Col>
          <Col xs={12} md={6}>
            <Card notFull svgIcon={getSVG("personalization", null, null, true)}>
              <Header bold sm>
                Extensive Customization
              </Header>
              <p>
                Tailor your online store to match your brand with Shoppio's
                extensive customization options for storefronts and product
                listings.
              </p>
              <Space />
              <Space />
              {/* <Link to=''>
        <Button>Learn More</Button>
      </Link> */}
            </Card>
          </Col>
          <Col xs={12} md={6}>
            <Card notFull svgIcon={getSVG("mobile_content", null, null, true)}>
              <Header bold sm>
                Mobile-Friendly Interface
              </Header>
              <p>
                Reach customers on the go with a mobile-friendly interface,
                ensuring a seamless shopping experience on smartphones and
                tablets.
              </p>
              <Space />
              <Space />
              {/* <Link to=''>
        <Button>Learn More</Button>
      </Link> */}
            </Card>
          </Col>
          <Col xs={12} md={6}>
            <Card notFull svgIcon={getSVG("scalability", null, null, true)}>
              <Header bold sm>
                Scalability for Growth
              </Header>
              <p>
                Scale your business effortlessly as Shoppio provides a scalable
                platform that grows with your increasing product range and
                customer base.
              </p>
              <Space />
              <Space />
              {/* <Link to=''>
        <Button>Learn More</Button>
      </Link> */}
            </Card>
          </Col>
        </Row>
        <Space lg />
        <Space lg />
        <Space lg />
        <HelpVideos />
        <Footer />
      </Grid>
    </div>
  )
}

export const SellerBanner = () => {
  const [{ userMeta }] = useStateValue()

  return (
    <div>
      <Grid>
        <Row>
          <Col xs={12}>
            <Card
              extraPad
              colored
              svgIcon={
                <img
                  alt=''
                  src={
                    "https://firebasestorage.googleapis.com/v0/b/challenge-576ea.appspot.com/o/production%2FSeller.png?alt=media&token=bf228538-f1e8-44d3-bb0a-8ef92cca5189"
                  }
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
              }
            >
              <Emblem relative alt />
              <Header lg bold alt>
                Hurry up sellers and service providers!
              </Header>
              <Header sm altTwo>
                No worries, sign up on Shoppio now to get your store online in
                no time.
              </Header>
              <Header altThree>
                Shoppio provides a fast, secure & reliable platform for your
                e-commerce needs. <br />
                Manage products/services, sales & deliveries on a single
                platform.
              </Header>
              <Space lg />
              <div className='block-links'>
                <Link
                  to={
                    userMeta ? AppPaths.SELLER_PROFILE : AppPaths.SELLER_SIGNUP
                  }
                >
                  <Button alt>Get Started</Button>
                </Link>
                <Link to={AppPaths.SHOPPIO_FOR_SELLERS}>
                  <Button accentAlt>Learn More</Button>
                </Link>
              </div>
            </Card>
          </Col>
        </Row>
        <Space lg />
      </Grid>
    </div>
  )
}

export const SellerSmallBanner = () => {
  const [{ userMeta }] = useStateValue()

  return (
    <div>
      <Col xs={12} style={{ padding: !isMobile ? 0 : "" }}>
        <Card extraPad colored noPadTop>
          <Emblem relative alt />
          <Header md bold alt align='left'>
            Need to sell online or are you a service provider?
          </Header>
          <Header altThree>
            Shoppio provides a fast, secure & reliable platform for your
            e-commerce needs. <br />
          </Header>
          <Space lg />
          <div className='block-links'>
            <Link
              to={userMeta ? AppPaths.SELLER_PROFILE : AppPaths.SELLER_SIGNUP}
            >
              <Button noMar alt>
                Get Started
              </Button>
            </Link>
            <Link to={AppPaths.SHOPPIO_FOR_SELLERS}>
              <Button noMar accentAlt>
                Learn More
              </Button>
            </Link>
          </div>
        </Card>
      </Col>
    </div>
  )
}
