import React, { Component } from "react"

export default class Padding extends Component {
  render() {
    const { paddingBottom, paddingTop, paddingLeft, paddingRight, md, lg } =
      this.props
    return (
      <div
        style={{
          paddingBottom: paddingBottom,
          paddingTop: paddingTop,
          paddingLeft: paddingLeft,
          paddingRight: paddingRight,
          padding: lg ? "6rem" : lg ? "4rem" : md ? "3rem" : "2rem",
        }}
        className='normal-pad'
      >
        {this.props.children}
      </div>
    )
  }
}
