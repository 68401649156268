import CryptoJS from "crypto-js"
import moment from "moment"
import { CurrencySign } from "config"
import {
  getDocsFromDbWhere,
  FieldPath,
  auth,
  getServerTime,
  storage,
} from "firebaseUtils"
import { AppPaths } from "shoppio-constants"

const loginRequire = (history, currentPath) => {
  history.push({
    pathname: "/require_login",
    state: {
      goBack: currentPath,
    },
  })
}

export function calculateAge(dobString) {
  const dob = new Date(dobString)
  const today = new Date()
  let age = today.getFullYear() - dob.getFullYear()
  const monthDiff = today.getMonth() - dob.getMonth()
  const dayDiff = today.getDate() - dob.getDate()
  if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
    age--
  }
  return age
}

export const env = process.env.NODE_ENV
export { loginRequire }

export const canShowMenu = () =>
  ![AppPaths.LOGIN].includes(window.location.pathname)

export const isDev = process.env.NODE_ENV === "development"

export const formatTimeAgo = createdAt => {
  const currentTime = getTime()

  const timeDifference = currentTime - createdAt
  const seconds = Math.floor(timeDifference / 1000)
  const minutes = Math.floor(seconds / 60)
  const hours = Math.floor(minutes / 60)

  if (hours > 0) {
    return `${hours} hr ago`
  } else if (minutes > 0) {
    return `${minutes} min ago`
  } else {
    return `${seconds} sec ago`
  }
}

export const currencyformatter = (v, withSign) => {
  if (!v) return "0.00"
  let ans = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  })
    .format(v)
    .toString()
    .split("")
  ans.shift()
  ans = ans.join("")
  return withSign ? CurrencySign + String(ans) : String(ans)
}

export const convertNumberToWords = number => {
  const units = [
    "",
    "One",
    "Two",
    "Three",
    "Four",
    "Five",
    "Six",
    "Seven",
    "Eight",
    "Nine",
  ]
  const teens = [
    "",
    "Eleven",
    "Twelve",
    "Thirteen",
    "Fourteen",
    "Fifteen",
    "Sixteen",
    "Seventeen",
    "Eighteen",
    "Nineteen",
  ]
  const tens = [
    "",
    "Ten",
    "Twenty",
    "Thirty",
    "Forty",
    "Fifty",
    "Sixty",
    "Seventy",
    "Eighty",
    "Ninety",
  ]
  const thousands = ["", "Thousand", "Lakh", "Crore"]

  const getNumberInWords = num => {
    if (num === 0) return ""
    if (num < 10) return units[num]
    if (num < 20) return teens[num - 10]
    return tens[Math.floor(num / 10)] + " " + units[num % 10]
  }

  const getFractionInWords = fraction => {
    if (fraction === undefined || fraction === 0) return ""
    return getNumberInWords(fraction) + " Paise"
  }

  const convertToIndianWords = num => {
    const str = String(num)
    const [wholePart, fractionPart] = str.split(".")
    const chunks = []
    for (let i = wholePart.length; i > 0; i -= 2) {
      chunks.unshift(wholePart.slice(Math.max(0, i - 2), i))
    }
    const result = chunks
      .map((chunk, index) => {
        const chunkNumber = parseInt(chunk, 10)
        if (chunkNumber === 0) return ""
        if (index === 0 && chunks.length > 1) {
          const higherOrderUnits = chunks
            .slice(1)
            .some(c => parseInt(c, 10) !== 0)
          return higherOrderUnits || index === chunks.length - 1
            ? getNumberInWords(chunkNumber) + " Hundred"
            : getNumberInWords(chunkNumber)
        }
        return getNumberInWords(chunkNumber) + " " + thousands[index]
      })
      .join(" ")

    const fractionWords = getFractionInWords(Number("0." + (fractionPart || 0)))
    return fractionPart !== undefined
      ? `${result} Rupees and ${fractionWords}`
      : `${result} Rupees`
  }

  return convertToIndianWords(number)
}

export const getTime = () => getServerTime()
export const parseDateTime = d => {
  if (!d) return "-"
  if (d?.nanoseconds) {
    return moment(new Date(d?.toDate())).format("MMM Do YYYY, h:mma")
  } else {
    return moment(new Date(d)).format("MMM Do YYYY, h:mma")
  }
}
export const parseFromFirebaseTimestamp = d => {
  return d?.toDate ? d?.toDate() : d
}

export const removeTextFromString = (text, s) => {
  return s.length > text.length ? s.replace(text, "") : ""
}
export const disallowedCharactersInURL = [
  "!",
  "#",
  "$",
  "&",
  "'",
  "(",
  ")",
  "*",
  "+",
  ",",
  "/",
  ":",
  ";",
  "=",
  "?",
  "@",
  "[",
  "]",
  "<",
  ">",
  "%",
  "{",
  "}",
  "|",
  "\\",
  "^",
  "~",
  "`",
  " ",
  "\t",
  "\n",
  "\r",
]

export const hexToRgba = (hex, alpha) => {
  hex = hex.replace(/^#/, "")

  let r = parseInt(hex.substring(0, 2), 16)
  let g = parseInt(hex.substring(2, 4), 16)
  let b = parseInt(hex.substring(4, 6), 16)

  return `rgba(${r}, ${g}, ${b}, ${alpha})`
}

export const parseDate = d => {
  // console.log(">Date", d);
  if (d?.nanoseconds) {
    return moment(new Date(d?.toDate())).format("MMMM Do YYYY")
  } else {
    return moment(new Date(d)).format("MMMM Do YYYY")
  }
}

export const getThumbnailFromProduct = product => {
  const { images, image, product_images, thumbnail_image } = product || {}
  let pImage =
    image ||
    (images && images.length > 0 && images[0]) ||
    thumbnail_image ||
    (product_images && product_images.length > 0 && product_images[0])
  return pImage
}

export const getDataAfterImageUpload = (raw, path = "data") =>
  new Promise((resolve, reject) => {
    console.log(1.1)
    let data = { ...raw }
    let possibleFilesKeys = Object.keys(data).filter(
      k => k.includes("file") && data[k],
    )
    let possibleNonFilesKeys = Object.keys(data).filter(
      k => !k.includes("file"),
    )
    console.log(1.2)
    // console.log("possibleNonFilesKeys", possibleNonFilesKeys);
    let newData = {}
    possibleNonFilesKeys.forEach(k => {
      newData[k] = data[k]
    })
    console.log(1.3)
    let images = []
    if (possibleFilesKeys.length === 0) {
      // console.log("Test");
      console.log(1.4)
      resolve(data)
    }
    console.log(1.5, possibleFilesKeys)
    possibleFilesKeys.forEach(key => {
      let im = {
        field: key.split("_file")[0],
        file: data[key],
        key: key,
      }
      images.push(im)
    })
    console.log(1.6)
    let urlBucket = []

    images.forEach((pic, key) => {
      console.log("File: ", pic)
      if (pic) {
        const user = auth.currentUser
        // console.log("user: ", pic);
        var name = getTime() + "-" + user.uid + "-" + pic?.file?.name
        // make ref to your firebase storage and select images folder
        var storageRef = storage.ref(`${env}/${path}/${name}`)
        // put file to Firebase
        if (!pic.file) {
          return
        }
        var uploadTask = storageRef.put(pic.file)
        uploadTask.on(
          "state_changed",
          () => {},
          err => console.log(err),
          async () => {
            console.log("key + 1", key + 1, images.length)
            let backgroundURL = await uploadTask.snapshot.ref.getDownloadURL()
            urlBucket.push(backgroundURL)
            newData[pic.field] = backgroundURL
            newData[pic.key] = null
            delete newData[pic.key]
            if (urlBucket.length === images.length) {
              console.log("main")
              setTimeout(() => {
                resolve({ ...newData })
              }, 2000)
            } else {
              console.log("else")
            }
          },
        )
      }
    })
  })

export const isMobile = window.innerWidth < 768
export const isTablet = window.innerWidth < 1025

export const removeBackground = img => {
  let canvas = document.getElementById("canvas")
  let ctx = canvas.getContext("2d")
  let image = img

  canvas.height = canvas.width = 135
  ctx.drawImage(image, 0, 0)

  var imgd = ctx.getImageData(0, 0, 135, 135),
    pix = imgd.data,
    newColor = { r: 0, g: 0, b: 0, a: 0 }

  for (var i = 0, n = pix.length; i < n; i += 4) {
    var r = pix[i],
      g = pix[i + 1],
      b = pix[i + 2]

    // If its white then change it
    if (r >= 230 && g >= 230 && b >= 230) {
      // console.log("ARAHA");
      // Change the white to whatever.
      pix[i] = newColor.r
      pix[i + 1] = newColor.g
      pix[i + 2] = newColor.b
      pix[i + 3] = newColor.a
    }
  }

  ctx.putImageData(imgd, 0, 0)
}
export const log = isDev ? console.log : () => {}

export const sanitize = obj => {
  console.log("Old Object", obj)
  if (!obj) return {}
  let newObj = { ...obj }
  Object.keys(obj).forEach(k => {
    if (!obj[k] && obj[k] !== 0) delete newObj[k]
  })
  console.log("New Object", newObj)
  return newObj
}

export const removeDuplicate = (arr, k) => {
  if (!arr || arr.length === 0) return []
  if (k) {
    return arr.reduce((unique, o) => {
      if (!unique.some(obj => obj[k] === o[k])) {
        unique.push(o)
      }
      return unique
    }, [])
  } else {
    return arr.reduce((unique, o) => {
      if (!unique.some(obj => obj === o)) {
        unique.push(o)
      }
      return unique
    }, [])
  }
}
const encrypt = text => {
  return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(text))
}
const decrypt = data => {
  return CryptoJS.enc.Base64.parse(data).toString(CryptoJS.enc.Utf8)
}

export const placeHolderPic =
  "https://firebasestorage.googleapis.com/v0/b/challenge-576ea.appspot.com/o/assets%2Fplaceholder3.png?alt=media&token=c3d9a7e6-dcad-4c0f-9f70-4940f196d852"

export const getExpirableLink = ({
  liveDuration,
  date,
  dataToEncode,
  prefix,
}) => {
  let finalDate = new Date(moment(date).add(liveDuration, "days")).getTime()
  let link = ""
  let d = { finalDate, ...dataToEncode }
  link = JSON.stringify(d)
  return prefix + encrypt(link)
}

export const getDataFromExpirableToken = l => {
  if (!l) return {}
  let decryptedLink = decrypt(l)
  // console.log("decryptedLink: ", decryptedLink);
  return JSON.parse(decryptedLink)
}

const fallbackCopyTextToClipboard = text => {
  var textArea = document.createElement("textarea")
  textArea.value = text

  // Avoid scrolling to bottom
  textArea.style.top = "0"
  textArea.style.left = "0"
  textArea.style.position = "fixed"

  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()

  try {
    var successful = document.execCommand("copy")
    var msg = successful ? "successful" : "unsuccessful"
    console.log("Fallback: Copying text command was " + msg)
  } catch (err) {
    console.error("Fallback: Oops, unable to copy", err)
  }

  document.body.removeChild(textArea)
}
export const copyTextToClipboard = text => {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text)
    return
  }
  navigator.clipboard.writeText(text).then(
    function () {
      // console.log("Async: Copying to clipboard was successful!");
    },
    function (err) {
      // console.error("Async: Could not copy text: ", err);
    },
  )
}

export const truncate = (str, n) => {
  if (!str) return "-"
  return str.length > n ? str.slice(0, n - 1) + "..." : str
}

export const getAddressFromOrder = order => {
  if (!order) return "-"
  const { state, house_number, address_line_1, city, pincode } = order || {}
  return (
    <div>
      <div>{`${house_number}, ${address_line_1}`}</div>
      <div>{`${city}, ${state}, ${pincode}`}</div>
    </div>
  )
}

export const getMaxFormArrayProperty = (arr, propertyKey) => {
  if (!arr) return ""
  let result = Math.max(
    ...arr
      .map(a => a[propertyKey] || 7)
      .sort((a, b) => a > b)
      .reverse(),
  )
  return result
}

export const getDateAfterNDays = (d, n) => {
  const newDate = new Date(d)
  newDate.setDate(newDate.getDate() + n)
  return newDate.getTime()
}

export const getMaxDeliveryDate = (products, d = new Date()) => {
  if (!products || products.length === 0) return "NA"
  let n = getMaxFormArrayProperty(products, "deliveryTime")
  return getDateAfterNDays(d, n)
}

export const getRelativeDate = targetDate => {
  const currentDate = new Date()
  let deltaMillis = targetDate - currentDate
  const isFuture = deltaMillis >= 0

  deltaMillis = Math.abs(deltaMillis)

  // Calculate days, months, etc.
  const msPerSecond = 1000
  const msPerMinute = msPerSecond * 60
  const msPerHour = msPerMinute * 60
  const msPerDay = msPerHour * 24

  const days = Math.floor(deltaMillis / msPerDay)
  const months = Math.floor(days / 30) // Approximation, considering 30 days per month

  console.log("days", days, deltaMillis, msPerDay)

  let result = ""
  if (months > 0) {
    result += `${months} month${months === 1 ? "" : "s"} `
  }
  if (days % 30 > 0) {
    result += `${days % 30} day${days % 30 === 1 ? "" : "s"} `
  }

  if (result === "") {
    return "Today"
  }

  return isFuture ? `In ${result.trim()}` : `${result.trim()} ago`
}

export const getUniqueItems = arr => {
  return [...new Set(arr)]
}

export const isPincodeValid = pincode => {
  if (!pincode || (pincode && (pincode.length > 6 || pincode.length < 6))) {
    return false
  } else {
    return true
  }
}
export const cleanObject = obj => {
  if (!obj) return {}
  let filtered = Object.keys(obj).filter(k => obj[k])
  let temp = {}
  filtered.forEach(k => {
    temp[k] = obj[k]
  })
  return temp
}

export const getArrayFromItem = (arr, startingItem) => {
  const startingIndex = arr.indexOf(startingItem)

  if (startingIndex === -1) {
    // Starting item not found in the array
    return []
  }

  return arr.slice(startingIndex)
}

export const deepCopy = obj => {
  if (obj) {
    obj = JSON.stringify(obj)
    return JSON.parse(obj)
  } else return {}
}

export const getStoreAndProductIdFromCompoundId = compoundId => {
  const storeId = compoundId.slice(0, 20)
  const productId = compoundId.slice(20, compoundId.length)
  console.log(storeId, productId)

  return { storeId, productId }
}

export const isMobileChromeOrFirefox = () => {
  const userAgent = navigator.userAgent
  return (
    /Android.*Chrome\//.test(userAgent) || /Android.*Firefox\//.test(userAgent)
  )
}

export const capitalizeFirstLetter = sentence => {
  return sentence && sentence.charAt(0).toUpperCase() + sentence.slice(1)
}

export const getRandomString = (n = 5) => {
  var text = ""
  var possible =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"

  for (var i = 0; i < n; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length))
  }
  return text
}

export const getMinutesFromHoursAndMinutes = (h, m) => {
  let hours = h || 0
  let minutes = m || 0
  return Number(hours) * 60 + Number(minutes)
}
export const getHoursAndMinutesFromMinutes = (m, asString) => {
  if (!m) return 0
  let minutes = m % 60
  let hours = Math.floor(m / 60)
  if (asString) {
    return Number(m) > 60 ? `${hours} Hours ${minutes} Minutes` : `${m} minutes`
  }
  return { minutes, hours }
}

export const getAddress = async (userPincode, user) => {
  if (userPincode) {
    let temp = await getDocsFromDbWhere(
      `users_addresses/${user?.uid}/data`,
      new FieldPath("store_location", "pincode"),
      "==",
      userPincode,
    )
    return temp
  }
}

export const toCamelCase = value => {
  return value
    .replace(/\s(.)/g, function ($1) {
      return $1.toUpperCase()
    })
    .replace(/\s/g, "")
    .replace(/^(.)/, function ($1) {
      return $1.toLowerCase()
    })
}

export const toTitleCase = string =>
  string
    .replace(/([A-Z])/g, " $1")
    .charAt(0)
    .toUpperCase() + string.replace(/([A-Z])/g, " $1").slice(1)

export const toKebabCase = string =>
  string
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]|\b)|[A-Z]?[a-z]+[0-9]|[A-Z]|[0-9]+/g)
    .map(x => x.toLowerCase())
    .join("-")

const avatarColorMap = {
  A: "#8e44ad",
  B: "#FF8C00",
  C: "#DAA520",
  D: "#B8860B",
  E: "#d4ac0d",
  F: "#FF4500",
  G: "#D2B48C",
  H: "#CD5C5C",
  I: "#F4A460",
  J: "#FF6347",
  K: "#FFC0CB",
  L: "#E6E6FA",
  M: "#34495e",
  N: "#FFE4B5",
  O: "#FAFAD2",
  P: "#FFE4C4",
  Q: "#FFDAB9",
  R: "#F5F5DC",
  S: "#F0E68C",
  T: "#D3D3D3",
  U: "#FFF5EE",
  V: "#FAEBD7",
  W: "#FFE4E1",
  X: "#E0FFFF",
  Y: "#F5FFFA",
  Z: "#F0FFFF",
}

export const getAvatarColor = name => {
  if (!name) return "#CCCCCC"
  const firstLetter = name.charAt(0).toUpperCase()
  return avatarColorMap[firstLetter] || "#CCCCCC"
}

export { afterLogout } from "./Auth"
