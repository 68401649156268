import { getInitials } from "appComponents/Messages/CustomerMessages"
import MessageCard from "appComponents/QueryResolution/components/MessageCard"
import { AvatarChip } from "appComponents/QueryResolution/QueryResolution"
import { getText } from "dictionary"
import { assignee, complexities } from "dummyData"
import { updateDataToDb } from "firebaseUtils"
import { FormMaker } from "formComponents"
import { getAvatarColor } from "helpers"
import { useAlerter } from "hooks"
import React, { useState } from "react"
import { useStateValue } from "state"
import { Col, Header, Row, Space } from "uiComponents"

const TicketData = ({ ticketData, ticketID, refreshDoc, setTicketDialog }) => {
  const [busy, setBusy] = useState(false)
  const [, dispatch] = useStateValue()
  const { alerter } = useAlerter()

  const handleSubmit = async newData => {
    console.log("Updating Ticket ID:", newData?.id)
    try {
      setBusy(true)

      await updateDataToDb(
        "tickets",
        newData?.id,
        newData,
        dispatch,
        "Ticket updated successfully",
        () => {
          refreshDoc()
        },
      )
      setBusy(false)
      setTicketDialog(false)
    } catch (error) {
      console.error("Error updating data:", error)
      alerter(getText("ticketUpdateFailed"), "error")
      setBusy(false)
    }
  }

  let ticketOwner = getInitials(
    typeof ticketData?.created_by === "object"
      ? ticketData?.created_by?.user_name
      : ticketData?.created_by,
  )

  return (
    <>
      <div style={{ display: "flex", gap: "10px" }}>
        <AvatarChip
          large
          label={getInitials(ticketOwner)}
          color={getAvatarColor(ticketOwner)}
        />
        <div>
          <Header bold sm>
            {ticketData?.name}
          </Header>
          <p>{ticketData.description}</p>
        </div>
      </div>
      <Space lg />
      <Space lg />
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} xl={3}>
          <Header bold>Task Details</Header>
          <Space lg />
          <FormMaker
            initialValues={ticketData}
            onSubmit={handleSubmit}
            busy={busy}
            submitLabel={"Update Ticket"}
            fields={[
              {
                name: "status",
                label: "Status",
                type: "select",
                children: ["Open", "Closed", "In Progress", "Completed"].map(
                  op => <option value={op}>{op}</option>,
                ),
                fullWidth: true,
              },
              {
                name: "assignee",
                label: "Assignee",
                type: "select",
                children: assignee.map(op => (
                  <option value={JSON.stringify(op)}>{op.value}</option>
                )),
                fullWidth: true,
              },
              {
                name: "ticket_complexity",
                label: "Ticket Complexity",
                dataTip: "Complexity of the ticket",
                type: "select",
                children: complexities.map(op => (
                  <option value={op.label}>{op.label}</option>
                )),
                fullWidth: true,
              },
              {
                name: "issue_type",
                type: "select",
                label: "Issue Type",
                children: ["bug", "feature request"].map(op => (
                  <option value={op}>{op}</option>
                )),
                fullWidth: true,
              },
              {
                name: "priority",
                type: "select",
                label: "Priority",
                children: ["high", "urgent", "medium", "low"].map(op => (
                  <option value={op}>{op}</option>
                )),
                fullWidth: true,
              },
            ]}
          />
        </Col>
        <Col
          xs={12}
          sm={6}
          md={12}
          lg={12}
          xl={9}
          style={{
            position: "relative",
            height: "75vh",
            borderLeft: "1px solid var(--border-color)",
            overflow: "auto",
            scrollbarWidth: "none",
          }}
        >
          <Header bold>Task Comments</Header>
          <div style={{ position: "relative", height: "100%" }}>
            <div
              style={{
                height: "calc(100% - 7rem)",
                overflowY: "auto",
                scrollbarWidth: "none",
                paddingBottom: "10px",
              }}
            >
              <MessageCard ticketID={ticketID} />
            </div>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default TicketData
