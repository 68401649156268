import React from "react"

export const ProvidersLogo = ({ logo, sm, mr }) => {
  return (
    <>
      <img
        src={logo}
        alt=''
        style={{
          width: sm ? "25px" : "45px",
          height: sm ? "25px" : "45px",
          marginRight: `${mr}px`,
          objectFit: "contain",
        }}
      />
    </>
  )
}
