import React from "react"

const SpaceBetween = ({ children, style }) => {
  return (
    <div style={{ display: "flex", justifyContent: "space-between", ...style }}>
      {children}
    </div>
  )
}

export default SpaceBetween
