import React, { useState, useEffect } from "react"
import "./IconButton.css"

function IconButton({
  children,
  lighter,
  disabled,
  onClick,
  large,
  progress,
  active,
  style,
  small,
  selected,
  info,
  ...props
}) {
  const [progressVisible, setProgressVisible] = useState(false)

  const getClasses = () => {
    let initial = "icon-button "
    return (
      initial +
      (lighter ? " lighter" : "") +
      (disabled ? " disabled" : "") +
      (large ? " larger" : "") +
      (active ? " active" : "") +
      (selected ? " selected" : "") +
      (small ? " small" : "") +
      (info ? " info" : "")
    )
  }
  const classes = getClasses()

  const handleClick = () => {
    if (!disabled) {
      if (onClick) {
        onClick()
      }
    }
  }

  useEffect(() => {
    if (active) {
      setProgressVisible(true)
    }
  }, [active])

  useEffect(() => {
    if (progressVisible) {
      const timer = setTimeout(() => {
        setProgressVisible(false)
      }, 5000)
      return () => clearTimeout(timer)
    }
  }, [progressVisible])

  return (
    <div
      className={classes}
      onClick={handleClick}
      style={{ paddingRight: children ? "" : "0", ...style }}
    >
      <div
        className={lighter ? "icon-button-icon lighter" : "icon-button-icon"}
        style={{ marginRight: children ? "" : "1rem" }}
      >
        <i className={`fas fa-${props.icon}`} />
        {progress && (
          <div
            className={`circular-progress ${progressVisible ? "" : "hidden"}`}
          ></div>
        )}
      </div>
      {children ? <span className='icon-button-text'>{children}</span> : ""}
    </div>
  )
}

export default IconButton
