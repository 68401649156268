import React, { useState } from "react"

const ReadMore = ({ text, maxWords }) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded)
  }

  const getDisplayText = () => {
    const words = text.split(" ")
    if (words.length <= maxWords || isExpanded) {
      return text
    }
    return words.slice(0, maxWords).join(" ") + "..."
  }

  return (
    <p>
      {getDisplayText()}
      {text.split(" ").length > maxWords && (
        <span
          onClick={toggleReadMore}
          style={{ color: "var(--header-color)", cursor: "pointer" }}
        >
          {isExpanded ? " Read less" : " Read more"}
        </span>
      )}
    </p>
  )
}

export default ReadMore
