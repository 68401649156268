import React, { useEffect, useState } from "react"
import { Grid, Row, Col, Card, Header, Space } from "uiComponents"
import SimpleCarousel from "uiComponents/Carousel/SimpleCarousel"
import getSVG from "getSVG/svgs/svg"
import { sellerCarouselData } from "./SellerSignup"
import { useStateValue } from "state"
import LoginAuth from "../Login/LoginAuth"
import { useGetUserMeta } from "firebaseUtils/firebaseOperations"
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { FloatingIcon } from "../Login/Login"
import { isMobile } from "helpers"
import { loginBottomLinks } from "dummyData"
import { ShoppioUserTypes } from "config"
import { useAlerter } from "hooks"
import { getText } from "dictionary"
import { AppPaths } from "shoppio-constants"

const SignUpNew = props => {
  const [busy, setBusy] = useState(false)
  const [{ user }] = useStateValue()
  const history = useHistory()
  const { onPhoneChange, phoneError } = props
  const { updateUserMeta } = useGetUserMeta()
  const { alerter } = useAlerter()

  useEffect(() => {
    if (user) {
      const updateUser = async () => {
        setBusy(true)
        try {
          let result = await updateUserMeta(user.uid, {
            type: ShoppioUserTypes.SELLER,
          })
          if (result === "success") {
            console.log("User updated to seller")
            alerter(getText("sellerSignUpSuccess"), "success")
            history.push(AppPaths.ACCOUNT_SETUP)
          }
        } catch (error) {
          console.error("Error adding seller:", error)
        } finally {
          setBusy(false)
        }
      }

      setTimeout(updateUser, 2000)
    }
    // eslint-disable-next-line
  }, [user, history])

  return (
    <div>
      <Grid>
        <Row middle='xs' style={{ minHeight: "80vh" }}>
          <Col sm={12} md={10} mdOffset={1}>
            <Card
              notFull
              style={{ position: "relative", marginTop: isMobile && "10rem" }}
              rowStyle={{ overflow: "visible" }}
            >
              <FloatingIcon />
              <Row
                middle={"xs"}
                style={{ borderBottom: "0.5px solid var(--border-color)" }}
              >
                <Col
                  sm={12}
                  md={6}
                  style={{
                    padding: "5rem",
                    borderBottom: isMobile && "0.5px solid var(--border-color)",
                  }}
                >
                  <Header lg bold>
                    Sign Up
                  </Header>
                  <p>
                    Sign up to your Shoppio Account and run your business like
                    never before
                  </p>
                  <Space lg />
                  <Space lg />
                  <LoginAuth
                    onChange={onPhoneChange}
                    errorText={phoneError}
                    callback={() => setBusy(true)}
                    loading={busy}
                  />
                </Col>
                <Col
                  sm={12}
                  md={6}
                  style={{ borderLeft: "0.5px solid var(--border-color)" }}
                >
                  <SimpleCarousel
                    interval={5000}
                    style={{ padding: "2rem !important" }}
                  >
                    {sellerCarouselData.map(item => (
                      <div key={item.title}>
                        {getSVG(
                          item.svg,
                          { width: "100%", height: "200px" },
                          null,
                          true,
                        )}
                        <Header center sm bold>
                          Shoppio’s Sign Up Feature’s
                        </Header>
                        <Space lg />
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            textAlign: "left",
                          }}
                        >
                          <Header sm bold>
                            {item.subTitle}
                          </Header>
                          {Array.isArray(item.desc) ? (
                            item.desc.map((description, index) => (
                              <ul key={index}>
                                <li>{description}</li>
                              </ul>
                            ))
                          ) : (
                            <p>{item.desc}</p>
                          )}
                        </div>
                      </div>
                    ))}
                  </SimpleCarousel>
                </Col>
              </Row>
              <Row
                between='xs'
                style={{ position: "relative", bottom: "-10px" }}
              >
                <p>
                  <span
                    className='far fa-copyright'
                    style={{ marginRight: "5px" }}
                  />
                  2024 Shoppio. All rights reserved
                </p>
                {!isMobile && (
                  <div style={{ display: "flex", gap: "4rem" }}>
                    {loginBottomLinks.map(item => (
                      <Link to={item.link} key={item.title}>
                        <p>{item.title}</p>
                      </Link>
                    ))}
                  </div>
                )}
              </Row>
            </Card>
          </Col>
        </Row>
      </Grid>
    </div>
  )
}

export default SignUpNew
