import React from "react"
import "./QuickActions.css"
import { IconButton } from "uiComponents"

const QuickActions = ({ data, lighter }) => {
  return (
    <>
      {data.map((item, index) => (
        <IconButton lighter={lighter} icon={item.icon} key={index}>
          <div className='icon-text'>{item.text}</div>
        </IconButton>
      ))}
    </>
  )
}

export default QuickActions
