import React from "react"
import {
  Card,
  LineCharts,
  Row,
  Col,
  Header,
  Space,
  IconButtonCircle,
  Expander,
} from "uiComponents"
import CurrencyRupee from "@mui/icons-material/CurrencyRupee"
import BestSellers from "./BestSellers"
import SpaceBetween from "../../UI/Space/SpaceBetween"

const data = [
  {
    year: "2017",
    saleAmmount: 201111,
  },
  {
    year: "2018",
    saleAmmount: 601111,
  },
  {
    year: "2019",
    saleAmmount: 401111,
  },
  {
    year: "2020",
    saleAmmount: 101111,
  },
  {
    year: "2021",
    saleAmmount: 601111,
  },
  {
    year: "2022",
    saleAmmount: 501111,
  },
]

const bestSellersData = [
  {
    img: "https://images.ctfassets.net/u4vv676b8z52/5NYNFwJPtbocJ1fIwbSCyJ/48d77da8c115b622558d59393bd36bee/kids-sunglasses-hero.gif?fm=jpg&q=80",
    title: "Sunglass RB 802",
    desc: "A cool modern styled sunglasses design to cater Indian rough climate.",
    sales: "2.9K",
  },
  {
    img: "https://img.pikbest.com/png-images/landscape-nature-t-shirt-design-template-free-download_1586362.png!sw800",
    title: "Cupid black t-shirt",
    desc: "A cool modern styled sunglasses design to cater Indian rough climate.",
    sales: "3.1K",
  },
  {
    img: "https://img.freepik.com/free-photo/fedora-hats-arrangement-studio_23-2150737087.jpg?size=626&ext=jpg&ga=GA1.1.553209589.1715040000&semt=ais",
    title: "Class Top hat",
    desc: "A cool modern styled sunglasses design to cater Indian rough climate.",
    sales: "4.5K",
  },
  {
    img: "https://www.007.com/wp-content/uploads/2020/08/007_Omega-Watch_LANDSCAPE.png",
    title: "OMEGA Watch",
    desc: "A cool modern styled sunglasses design to cater Indian rough climate.",
    sales: "1.7K",
  },
]

const SalesOverview = () => {
  return (
    <Card>
      <Expander
        initiallyOpen
        title={"Sales overview"}
        subTitle={
          "Monitor and analyze sales performance, track trends, and manage products and categories."
        }
      >
        <Row>
          <Col xs={12} sm={12} md={12} lg={9}>
            <Card white>
              <Row between='xs'>
                <Header bold>Sales Trend</Header>
              </Row>
              <LineCharts data={data} />
            </Card>
          </Col>
          <Col xs={12} sm={12} md={12} lg={3}>
            <SalesInfoCard
              totalSale={"24.1K"}
              topCategory={"Sunglasses"}
              topSubCategory={"Ray Ban"}
            />
          </Col>
        </Row>
        <Space lg />
        <SpaceBetween>
          <Header bold>Top Selling Products</Header>
          <IconButtonCircle accent icon={"arrow-right"} />
        </SpaceBetween>
        <Space />
        <Row>
          {bestSellersData.map(data => (
            <Col xs={12} sm={6} md={6} lg={3}>
              <BestSellers
                img={data.img}
                title={data.title}
                desc={data.desc}
                sales={data.sales}
              />
            </Col>
          ))}
        </Row>
      </Expander>
      <Space md />
    </Card>
  )
}

export default SalesOverview

export const SalesInfoCard = ({ totalSale, topCategory, topSubCategory }) => {
  return (
    <Card white>
      <div style={{ display: "flex", flexDirection: "column", gap: "30px" }}>
        <div>
          <p>Total Sale</p>
          <Space />
          <Header sm bold style={{ display: "flex", alignItems: "center" }}>
            <CurrencyRupee style={{ fontSize: "6px" }} />
            <span>{totalSale}</span>
          </Header>
        </div>

        <div>
          <p>Top Selling Category</p>
          <Space />
          <Header sm bold>
            {topCategory}
          </Header>
        </div>

        <div>
          <p>Top Selling Sub Category</p>
          <Space />
          <Header sm bold>
            {topSubCategory}
          </Header>
        </div>
      </div>
    </Card>
  )
}
