import React from "react"
import "./Tag.css"

export default function Tag({
  text,
  hideOn,
  abs,
  children,
  noMar,
  dotted,
  bold,
}) {
  const position =
    abs === "top-right"
      ? { top: "2rem", right: "2rem" }
      : abs === "top-left"
      ? { top: "2rem", left: "2rem" }
      : abs === "bottom-right"
      ? { bottom: "2rem", right: "2rem" }
      : { bottom: "2rem", left: "2rem" }
  const dottedStyle = dotted
    ? { border: "2px dotted var(--border-color)", background: "transparent" }
    : {}
  const darkStyle = bold
    ? { fontWeight: "bold", color: "var(--theme-color)" }
    : {}
  return hideOn ? (
    <></>
  ) : (
    <div
      style={{
        ...position,
        marginTop: noMar ? 0 : "",
        ...dottedStyle,
        ...darkStyle,
      }}
      className={abs ? "new-tag new-tag-abs" : "new-tag"}
    >
      {text || children}
    </div>
  )
}
