import React, { useEffect, useState } from "react"
import "./Store.css"
import "../../../styles/theme.css"
import { useHistory, useParams } from "react-router-dom"
import {
  Grid,
  Row,
  Col,
  Header,
  Tabs,
  Tab,
  CompactCard,
  Busy,
} from "uiComponents"
import { ErrorManager, Footer, sendNotification } from "appComponents"
import {
  DB,
  getDocFromDb,
  getDocsFromDbMultipleWheresWithLimit,
} from "firebaseUtils"
import { useStateValue } from "state"
import { FormMaker } from "formComponents"
import { getDataAfterImageUpload, log } from "helpers"
import * as Yup from "yup"
import { sendEmail } from "emailer"
import { AppPaths, getPath } from "shoppio-constants"

export const subcategoryValidationSchema = () => {
  // console.log('llllll');
  return Yup.object().shape({
    subcategory_name: Yup.string()
      .required("Please provide a subcategory name")
      .min(3, "Too short")
      .max(100, "Too long"),
    desc: Yup.string().min(3, "Too short").max(100, "Too long"),
    subcategory_pic: Yup.mixed().required("Please upload a banner picture"),
  })
}

const subcategoryFields = (categoryName, data, setError) => {
  const fetchOld = async subcategoryNew => {
    let docs = await getDocsFromDbMultipleWheresWithLimit(
      1,
      `store_categories/${data?.storeID}/data/${data?.categoryID}/subcategories/`,
      ["val", "==", subcategoryNew],
    )
    setError(
      docs.length > 0
        ? [
            `Please choose a different subcategory name as this subcategory already exists in the category ${categoryName}`,
          ]
        : [],
    )
  }
  return [
    {
      name: "subcategory_name",
      label: "Subcategory Name",
      desc: "Name the subcategory",
      type: "text",
      onBlur: e => {
        console.log(e.target.value)
        if (e.target.value?.length > 3) {
          fetchOld(e.target.value)
        }
      },
    },
    {
      name: "desc",
      label: "Describe the subcategory",
      type: "textarea",
      fullWidth: true,
    },
    {
      name: "subcategory_pic",
      label: "Subcategory Picture",
      info: "Upload a picture to showcase as banner",
      type: "pic",
      fullWidth: true,
    },
    {
      name: "parent_category_name",
      value: categoryName,
      hidden: true,
    },
  ]
}

function AddSubcategories({ update }) {
  const [{ user, userMeta }, dispatch] = useStateValue()
  const [busy, setBusy] = useState()
  const history = useHistory()
  const [extraErrors, setError] = useState([])
  const { subcategory_id, category_id, storeid } = useParams()
  const [subcategoryData, setSubcategoryData] = useState()
  const [categoryData, setCategoryData] = useState()

  const afterAddPath = getPath(AppPaths.CATEGORY_HOME, {
    storeid: storeid,
    category_id: category_id,
  })
  const addSubCategoryToCategory = d => {
    DB.collection(`store_categories`)
      .doc(storeid)
      .collection("data")
      .doc(category_id)
      .collection("subcategories")
      .doc(d.key)
      .set(d)
  }
  const commonDBref = DB.collection(`store_subcategories`)
    .doc(storeid)
    .collection("data")
    .doc(category_id)
    .collection("data")
  const removeSubcategory = () => {
    DB.collection(
      `store_categories/${storeid}/data/${category_id}/subcategories`,
    )
      .doc(subcategory_id)
      .delete()
      .then()
      .catch(err => ErrorManager.notify(err))

    commonDBref
      .doc(subcategory_id)
      .delete()
      .then(() => {
        history.push(afterAddPath)
        dispatch({
          type: "SHOW_ALERT",
          open: true,
          message: "Product deleted successfully",
        })
      })
      .catch(err => ErrorManager.notify(err))
  }
  const addSubcategoryToDB = async subcategoryData => {
    setBusy(true)
    let data = await getDataAfterImageUpload(subcategoryData)
    // console.log("Data After Upload", data);
    if (subcategory_id) {
      commonDBref
        .doc(subcategory_id)
        .update({
          updatedBy: user?.uid,
          ...data,
        })
        .then(() => {
          // console.log("Success");
          setBusy(false)
          history.push(afterAddPath)
        })
        .catch(error => {
          // console.error("Error writing document: ", error);
          setBusy(false)
        })
    } else {
      commonDBref
        .add({
          addedBy: user?.uid,
          ownedBy: userMeta?.seller_name || "Seller Name",
          ...data,
        })
        .then(doc => {
          // console.log("Success");
          addSubCategoryToCategory({
            key: doc.id,
            val: data?.subcategory_name,
          })

          setBusy(false)
          history.push(afterAddPath)
        })
        .catch(error => {
          // console.error("Error writing document: ", error);
          setBusy(false)
        })
    }
  }
  const getUserSubcategoryData = (storeid, subcategoryId, dispatch) => {
    if (!dispatch || !subcategoryId || !storeid) return
    commonDBref
      .doc(subcategoryId)
      .get()
      .then(s => {
        // console.log('SSSSS')
        setSubcategoryData(s.data())
        setBusy(false)
      })
      .catch(() => {
        setBusy(false)
      })
  }
  const getAndSetCategoryData = async () => {
    setCategoryData(
      await getDocFromDb(`store_categories/${storeid}/data`, category_id),
    )
  }
  // console.log(userCategories, 'userCategories');
  useEffect(() => {
    window.scrollTo(0, 0)
    if (subcategory_id && storeid) {
      setBusy(true)
      getUserSubcategoryData(storeid, subcategory_id, dispatch)
    }
    if (storeid && category_id) {
      getAndSetCategoryData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subcategory_id])
  const [values, setValues] = useState({})
  const initialValues = subcategoryData
  log("categoryData?.category_name", categoryData?.category_name)
  const content = (
    <Row>
      <Col xs={12} sm={6} md={8}>
        <Header accent md bold>
          Add Subcategory
        </Header>
        <p>Please fill the required details about your subcategory</p>
        <FormMaker
          submitLabel={update ? "Update Subcategory" : "Add subcategory"}
          fields={() =>
            subcategoryFields(
              categoryData?.category_name,
              {
                storeID: storeid,
                categoryID: category_id,
              },
              setError,
            )
          }
          initialValues={initialValues}
          validation={subcategoryValidationSchema}
          handleChange={newVals => {
            // console.log('Values', newVals)
            setValues({ ...values, ...newVals })
          }}
          secAction={{
            label: "Delete Subcategory",
            onClick: () => removeSubcategory(),
          }}
          onSubmit={async formValues => {
            await addSubcategoryToDB(formValues, update)
            const emailData = {
              email: user?.email || userMeta?.email,
              from_name: "Shoppio",
              message: update
                ? `Congratulations! Your '${subcategoryData.subcategory_name}' subcategory details with Shoppio has been successfully updated.`
                : `Congratulations! Your '${categoryData?.category_name}' category has a new '${subcategoryData.subcategory_name}' subcategory with Shoppio has been successfully added.`,
            }

            const template = "general_template"

            sendEmail(emailData, template)

            sendNotification({
              title: update ? "Account Details Updated" : "Account Created",
              body: update
                ? `Congratulations! Your '${subcategoryData?.subcategory_name}' subcategory details with Shoppio has been successfully updated.`
                : `Congratulations! Your '${categoryData?.category_name}' category has a new '${subcategoryData.subcategory_name}' subcategory with Shoppio has been successfully added.`,
              toUserID: user?.uid,
            })
          }}
          extraErrors={extraErrors}
        />
      </Col>
      <Col xs={12} sm={6} md={4}>
        <Header sm bold>
          Preview
        </Header>
        <p>Subcategory</p>
        <br />
        {[values]?.map(
          (
            { subcategory_name, subcategory_pic, subcategory_logo, desc },
            key,
          ) => {
            return (
              <CompactCard
                notFull
                key={key}
                config={{
                  logo: subcategory_logo,
                  version: "category-new",
                  title: subcategory_name,
                  point: "240+ products",
                  subtitle: desc,
                  backgroundImage: subcategory_pic,
                  action: {
                    label: "View Subcategory",
                  },
                }}
              />
            )
          },
        )}
      </Col>
    </Row>
  )
  return (
    <div className='Home'>
      <div className='home__container'>
        <div className='home-section'>
          <Busy busy={busy}>
            <Grid>
              {!update ? (
                <Tabs>
                  <Tab label={"Add subcategory"}>{content}</Tab>
                  <Tab
                    label={"View Categories"}
                    to={`/${storeid}/view_categories`}
                  ></Tab>
                </Tabs>
              ) : (
                content
              )}

              <Footer />
            </Grid>
          </Busy>
        </div>
      </div>
    </div>
  )
}

export default AddSubcategories
