import React from "react"
import { Loader } from "uiComponents"
import "./Busy.css"

export const Busy = ({ children, busy, type, autoSize }) => {
  return (
    <>
      {type === "my-account" ? (
        <div className='busy-container'>
          {busy && type && (
            <div>
              <Loader type={"my-account"} />
            </div>
          )}
          {children}
        </div>
      ) : (
        <div
          className='busy-container'
          style={{
            width: autoSize ? "100%" : "",
            height: autoSize ? "100%" : "",
          }}
        >
          {busy && (
            <div className='busy-active'>
              <Loader />
            </div>
          )}
          {children}
        </div>
      )}
    </>
  )
}
