import React from "react"
import { Header, Card } from "uiComponents"
import getSVG from "../../../images/svgs/svg"
import AnalyticsTile from "./AnalyticsTile"

const LiveAnalytics = () => {
  const cardsData = [
    { title: "Active Users", value: "2024", icon: "eye" },
    { title: "Active Carts", value: "56", icon: "cart-plus" },
    { title: "Active Checkouts", value: "32", icon: "money-check-alt" },
  ]
  return (
    <Card notFull noXS>
      <Header md bold>
        Live Analytics
      </Header>
      <p>Learn about customers and leads live analytics on your store</p>
      {getSVG("Data_analysis", { width: 150 }, null, null)}
      {cardsData.map(data => (
        <AnalyticsTile title={data.title} value={data.value} icon={data.icon} />
      ))}
    </Card>
  )
}

export default LiveAnalytics
