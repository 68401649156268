import React, { useState } from "react"
import {
  Col,
  Row,
  InfiniteCarousel,
  IconButton,
  IconButtonCircle,
  Space,
  Card,
  Header,
} from "uiComponents"
import getSVG from "../../../images/svgs/svg"
import {
  buttonRelatedCardsData,
  rowOneButtonData,
  rowTwoButtonData,
} from "./IconButtonData"

const ShoppioBenefits = () => {
  const [selectedButton, setSelectedButton] = useState(rowOneButtonData[0])
  const handleButtonClick = buttonData => {
    setSelectedButton(buttonData)
  }

  const Button = ({ data }) => (
    <IconButton
      progress
      style={{
        margin: "20px 30px 0px 0px",
        boxShadow: selectedButton === data && "0px 0px 6px var(--shadow-color)",
      }}
      active={data.text === selectedButton?.text}
      large
      lighter
      icon={data.icon}
      className='shops-icon-button'
      onClick={() => handleButtonClick(data)}
    >
      <div className='icon-text'>{data.text}</div>
    </IconButton>
  )
  return (
    <>
      <div className='why-choose-header'>
        <div className='header-text'>
          Who can <span>benefit</span>&nbsp;from<span>Shoppio</span>?
        </div>
        <p>
          Whether you are shop owner, independent contractor or someone who
          provide some service
        </p>
      </div>
      <Space lg />
      <Space lg />
      <div>
        <InfiniteCarousel rtl withShadow>
          {rowOneButtonData?.map((data, index) => (
            <Button data={data} key={index} />
          ))}
        </InfiniteCarousel>
        <InfiniteCarousel withShadow>
          {rowTwoButtonData?.map((data, index) => (
            <Button data={data} key={index} />
          ))}
        </InfiniteCarousel>
      </div>
      <Row>
        <Space lg />
        <Space lg />
        {selectedButton &&
          buttonRelatedCardsData[selectedButton.id].map((data, index) => (
            <Col sm={12} md={4} key={index}>
              <ShoppioBenefitsCard
                step={data.step}
                title={data.title}
                desc={data.desc}
                svg={data.svg}
              />
            </Col>
          ))}
      </Row>
    </>
  )
}

export default ShoppioBenefits

export const ShoppioBenefitsCard = ({ step, title, desc, svg }) => {
  return (
    <>
      <Card noXS extraPad>
        <Row center='xs'>
          <div
            style={{
              textAlign: "center",
            }}
          >
            <div style={{ marginBottom: "10px" }}>
              <IconButtonCircle
                step={step}
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              />
              <Space lg />
              <Header center sm bold>
                {title}
              </Header>
            </div>
            <p style={{ fontSize: "18px" }}>{desc}</p>
            <div>
              {getSVG(svg, { width: 200, marginTop: 30 }, undefined, true)}
            </div>
          </div>
        </Row>
      </Card>
    </>
  )
}
