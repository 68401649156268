import { getTime } from "helpers"

export const AppName = "Shoppio"
export const CurrencySign = "₹"
export const AppDesc =
  "An online shopping app, orders, tracking and deliveries."
export const OrderStatus = ["Order Placed", "In Process", "Completed"]
export const OrderStatusForDeliveryPerson = [
  "Order Placed",
  "In Process",
  "Completed",
]
export const CancelOrderStatus = ["Order Placed", "Canceled"]
export const ReturnOrderStatus = [
  "Return Requested",
  "Return Approved",
  "Pickup Scheduled",
  "Return Completed",
]
export const ReturnOrderRejectedStatus = ["Return Requested", "Return Rejected"]
export const OrderCancelingReasonCustomer = [
  "I have changed my mind",
  "I bought the wrong item(s)",
  "I found a cheaper alternative",
  "I placed a duplicate order",
  "Delivery takes too long",
]
export const OrderReturnReasonCustomer = [
  "Product Defect or Damage",
  "Wrong Product Received",
  "Change of Mind",
  "Size or Fit Issues",
  "Not as Described",
  "Late Delivery",
  "Unsatisfactory Quality",
  "Received Extra Items",
  "Billing or Pricing Issues",
  "Gift Not Needed",
  "Cancelled Order",
  "Return Policy",
  "Packaging Issues",
  "Defective Accessories",
  "Gift Not as Expected",
  "Unauthorized Purchase",
  "Health or Allergy Concerns",
  "Dissatisfaction with Service",
  "Expired or Near-Expiry Products",
  "Legal or Compliance Issues",
]
export const OrderCancelingReasonSeller = ["Item not available"]
export const ExtendedOrderStatus = ["Return Requested", "Return Completed"]
export const PaymentModes = [
  "UPI",
  "Net Banking",
  "Credit Card",
  "Debit Card",
  "COD",
]
export const StoreStatuses = [
  "listed",
  "unlisted",
  "halted",
  "payment_due",
  "payment_overdue",
  "blocked",
]

export const ClothingTypes = [
  "Kurtas",
  "Tops",
  "Dresses",
  "Sarees",
  "Suits",
  "Ethnic Sets",
  "Bottoms",
  "Bags",
  "Footwear",
  "Kids",
  "Add Ons",
  "Winter",
  "Stiched",
  "Unstiched",
]
export const serviceCategories = [
  "Appliance Repair",
  "Babysitting",
  "Bookkeeping Services",
  "Catering Services",
  "Car Repair",
  "Carpet Cleaning",
  "Content Writing",
  "Data Analysis",
  "Digital Marketing",
  "Dog Walking",
  "E-commerce Solutions",
  "Electrician",
  "Event Management",
  "Event Planning",
  "Fashion Styling",
  "Financial Consulting",
  "Fitness Training",
  "Gardening Services",
  "Graphic Design",
  "Health Coaching",
  "Home Cleaning",
  "Home Renovation",
  "Interior Design",
  "Language Translation",
  "Legal Advice",
  "Mobile App Development",
  "Music Lessons",
  "Online Tutoring",
  "Photography",
  "Plumbing",
  "Pool Cleaning",
  "SEO Consultation",
  "Social Media Marketing",
  "Software Development",
  "Travel Consultancy",
  "Tattoo Artistry",
  "Virtual Assistance",
  "Web Design",
  "Wedding Planning",
]

export const productCategories = [
  "Electronics",
  "Clothing",
  "Home & Furniture",
  "Books & Media",
  "Health & Beauty",
  "Jewelry & Accessories",
  "Toys & Games",
  "Food & Grocery",
  "Handmade & Crafts",
  "Automotive",
  "Sports & Outdoors",
  "Garden & Outdoor",
  "Office Supplies",
  "Electrical Appliances",
  "Pet Supplies",
  // Add more product categories as needed
]
export const ProductFilters = [
  {
    label: "Style",
    value: "style",
    subCategories: ClothingTypes.map(c => ({
      label: c,
      value: c.toLowerCase(),
    })),
  },
  {
    label: "Material",
    value: "material",
    subCategories: [
      {
        label: "Georgette",
        value: "georgette",
      },
      {
        label: "Cotton",
        value: "cotton",
      },
      {
        label: "Net",
        value: "net",
      },
    ],
  },
  {
    label: "Embroidery",
    value: "embroidery",
    subCategories: [
      {
        label: "Chikankari",
        value: "chikankari",
      },
      {
        label: "Kantha",
        value: "kantha",
      },
      {
        label: "Phulkari",
        value: "phulkari",
      },
      {
        label: "Zardori",
        value: "zardori",
      },
      {
        label: "Kashidakari",
        value: "kashidakari",
      },
      {
        label: "Aari",
        value: "aari",
      },
      {
        label: "Mirror",
        value: "mirror",
      },
      {
        label: "Aari",
        value: "aari",
      },
    ],
  },
  {
    label: "Color",
    value: "color",
    subCategories: [
      {
        label: "Red",
        value: "red",
      },
      {
        label: "Blue",
        value: "blue",
      },
      {
        label: "Blue",
        value: "blue",
      },
    ],
  },
  {
    label: "Prints",
    value: "print",
    subCategories: [
      {
        label: "Floral",
        value: "floral",
      },
      {
        label: "Tribal",
        value: "tribal",
      },
      {
        label: "Animal",
        value: "animal",
      },
      {
        label: "Abstract",
        value: "abstract",
      },
      {
        label: "Geometrical",
        value: "geometrical",
      },
      {
        label: "Digital",
        value: "digital",
      },
      {
        label: "Customized",
        value: "customized",
      },
      {
        label: "Cartoon",
        value: "cartoon",
      },
      {
        label: "Faded",
        value: "faded",
      },
      {
        label: "Gothic",
        value: "gothic",
      },
      {
        label: "Floral",
        value: "floral",
      },
      {
        label: "Tribal",
        value: "tribal",
      },
      {
        label: "Animal",
        value: "animal",
      },
      {
        label: "Abstract",
        value: "abstract",
      },
      {
        label: "Geometrical",
        value: "geometrical",
      },
      {
        label: "Digital",
        value: "digital",
      },
      {
        label: "Customized",
        value: "customized",
      },
      {
        label: "Cartoon",
        value: "cartoon",
      },
      {
        label: "Faded",
        value: "faded",
      },
      {
        label: "Gothic",
        value: "gothic",
      },
    ],
  },
  {
    label: "Pattern",
    value: "pattern",
    subCategories: [
      {
        label: "Checks",
        value: "checks",
      },
      {
        label: "Animal Print",
        value: "animal print",
      },
      {
        label: "Animal Print",
        value: "animal print",
      },
      {
        label: "Machine Printing",
        value: "machine printing",
      },
      {
        label: "",
        value: " ",
      },
    ],
  },
]

//Data Model

export const initProduct = ({
  id = "",
  name = "Product Name",
  price = 40000,
  rating = "0",
  stock = 120,
  tax = "10%",
  createdAt = "",
  updatedAt = "",
  discount = 0,
  brand = "Brand Name",
  instructions = "Instructions",
  pattern = null,
  images = [],
  maxQuantity = 1,
  features = [],
  desc = "Brand description",
  material = [],
  stiched = "",
  color = "",
  type = "",
  storeID = null,
  returnAllowed = true,
  addedBy = "",
  addedByEmail = "",
  totalReviews = 0,
  category,
  subcategory,
  deliveryTime = 7,
  variants,
  returnWindow = 7,
  ...props
}) => {
  return {
    id,
    name,
    desc,
    features,
    brand,
    price,
    discount,
    stock,
    deliveryTime,

    returnAllowed,
    returnWindow,
    maxQuantity,
    variants,

    category,
    subcategory,

    tax,
    updatedAt,
    createdAt,
    rating,
    totalReviews,

    storeID,
    addedBy,
    addedByEmail,

    ...props,
  }
}
// User Model
export const initUser = ({
  id = "",
  name = "",
  size = "",
  email = "",
  contactNumbers = [],
  dp = "",
  addresses = [],
  age = "",
  gender = "",
  orders = [],
  lastOrder = "",
  ...props
}) => {
  return {
    id,
    name,
    size,
    dp,
    email,
    contactNumbers,
    addresses,
    age,
    gender,
    orders,
    lastOrder,
    ...props,
  }
}

// Order Model
export const initOrder = ({
  id = "",
  name = "",
  product_id = "",
  buyer_id = "",
  createdAt = getTime(),
  deliveredOn = "",
  status = "Order Placed",
  deliveredBy = "",
  paymentMode = "",
  paid = false,
  data,
  basket = [], //Basket will contain orders {}
  ...props
}) => {
  return {
    id,
    name,
    product_id,
    buyer_id,
    createdAt,
    deliveredOn,
    status,
    deliveredBy,
    paymentMode,
    paid,
    basket,
    ...props,
  }
}

export const initAddress = ({
  address_line1 = "",
  address_line2 = "",
  district = "",
  city = "",
  state = "",
  country = "",
  pincode = "",
  label = "Home",
  ...props
}) => {
  return {
    address_line1,
    address_line2,
    district,
    city,
    state,
    country,
    pincode,
    label,
    ...props,
  }
}

// Please not order is very important in this array, it denotes the heirarchy
export const storeAccessLevels = [
  "Store Manager",
  "Delivery Manager",
  "Delivery Person",
]

export const defaultImage =
  "https://firebasestorage.googleapis.com/v0/b/challenge-576ea.appspot.com/o/production%2Fdefault-product.png?alt=media&token=51ebd738-9e95-4383-98a8-2883776080e2"

// Add https://console.cloud.google.com/apis/credentials/oauthclient/590413366257-bhlb66at48iq89ao11srgirbk3btj2sh.apps.googleusercontent.com?project=challenge-576ea

export const maxImageUploadSizeInBytes = 10145728
export const ShoppioUserTypes = {
  SELLER: "seller",
  BUYER: "buyer",
  ADMIN: "admin",
  SELLER_AGENT: "seller_agent",
}

export { firebaseConfig } from "./FirebaseConfig"
export { bugsnagConfig } from "./AppConfig"
