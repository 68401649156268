import React, { useState, useEffect } from "react"
import Box from "@mui/material/Box"
import Stepper from "@mui/material/Stepper"
import Step from "@mui/material/Step"
import StepLabel from "@mui/material/StepLabel"
import StepContent from "@mui/material/StepContent"
import Paper from "@mui/material/Paper"
import Typography from "@mui/material/Typography"
import { Space, Button } from "uiComponents"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"

import "./VerticalStepper.css"
import { AppPaths } from "shoppio-constants"

function VerticalLinearStepper({ steps, activeStep, onStepChange }) {
  const [completedSteps, setCompletedSteps] = useState([])
  const history = useHistory()
  useEffect(() => {
    if (!completedSteps.includes(activeStep - 1) && activeStep > 0) {
      setCompletedSteps(prev => [...prev, activeStep - 1])
    }
  }, [activeStep, completedSteps])

  const handleBack = () => {
    onStepChange(activeStep - 1)
  }

  const handleReset = () => {
    setCompletedSteps([])
    onStepChange(0)
  }

  return (
    <Box sx={{ maxWidth: 300 }}>
      <Stepper activeStep={activeStep} orientation='vertical'>
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel>{step.label}</StepLabel>
            <StepContent>
              <Typography>{step.description}</Typography>
              <Box sx={{ mt: 2 }}>
                <div>
                  <Button
                    small
                    fullRound
                    // onClick={() => onStepChange(index + 1)}
                    onClick={() => history.push(AppPaths.ACCOUNT_SETUP)}
                  >
                    {index === steps.length - 1 ? "Finish" : "Continue"}
                  </Button>
                  <Button
                    small
                    accent
                    fullRound
                    onClick={handleBack}
                    disabled={index > 0 && completedSteps.includes(index - 1)} // Disable Back if the previous step is completed
                  >
                    Back
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Paper square elevation={0} sx={{ p: 3 }}>
          <Typography>All steps completed - you're finished</Typography>
          <Space />
          <Button small fullRound onClick={handleReset}>
            Reset
          </Button>
        </Paper>
      )}
    </Box>
  )
}

export default VerticalLinearStepper
