import React from "react"
import { Col, Row, Space, Button, Card, Header } from "uiComponents"
import shiprocket from "../../../images/shiprocket.png"
import porter from "../../../images/porter.webp"
import { ProvidersLogo } from "./ProvidersLogo"

const AvailableProviders = () => {
  const data = [
    {
      name: "Shiprocket",
      price: "26/500 grms",
      desc: "Create a delightful online journey by optimising yourshipping process and everything surrounding it.",
      button: "Get it",
      image: shiprocket,
    },
    {
      name: "Porter",
      price: "25/500 grms",
      desc: "Porter started off as a platform to address inefficiencies in the last mile logistics sector...",
      button: "Get it",
      image: porter,
    },
  ]

  return (
    <>
      <Header bold>Available Providers</Header>
      <Row>
        {data.map(item => (
          <Col xs={12} md={6}>
            <Card>
              <div style={{ padding: "3rem" }}>
                <Row between='xs'>
                  <Header sm bold>
                    {item.name}
                  </Header>
                  <Header sm bold>
                    <span
                      className='fas fa-sm fa-rupee-sign'
                      style={{ marginRight: "5px" }}
                    />
                    {item.price}
                  </Header>
                </Row>
                <Space lg />
                <Row>
                  <p>{item.desc}</p>
                </Row>
                <Space lg />
                <Row between='xs'>
                  <Button fullRound>{item.button}</Button>
                  <ProvidersLogo logo={item.image} />
                </Row>
              </div>
            </Card>
          </Col>
        ))}
      </Row>
    </>
  )
}

export default AvailableProviders
