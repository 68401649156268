import React, { useState } from "react"
import { Link } from "react-router-dom/cjs/react-router-dom.min"
import { Grid, Button, Header, Item, NoRecord } from "uiComponents"
import { parseDateTime } from "helpers"
import { CollectionRenderer } from "firestoreComponents"
import { useStateValue } from "state"
import { removeNotification } from "appComponents"
import "./Notifs.css"
const NotifItem = ({ children, action, timestamp }) => {
  return (
    <div className='notif-item'>
      <span className='content'>{children}</span>
      <div className='timestamp'>{timestamp}</div>

      <span className='action'>{action}</span>
    </div>
  )
}
const UserNotifications = () => {
  const [{ user, openDialog }, dispatch] = useStateValue()
  const [busy, setBusy] = useState()
  const toggleDialog = () => {
    dispatch({
      type: "GENERAL",
      data: {
        openDialog: !openDialog,
      },
    })
  }
  return (
    <>
      <Grid>
        <CollectionRenderer
          shouldCache={false}
          noDocComponent={
            <NoRecord iconClass='fas fa-bell'>
              <div>You don't have any notifications.</div>
            </NoRecord>
          }
          collectionPath={"notifications"}
          viewType={"custom"}
          filters={[
            {
              property: "recipientId",
              value: user?.uid,
              operator: "==",
            },
            {
              property: "status",
              value: "active",
              operator: "==",
            },
          ]}
          renderDoc={(
            { id, data },
            onUpdate,
            isBusy,
            onReloadList,
            onDelete,
          ) => {
            const { title, body, createdAt, link } = data || {}
            return (
              <>
                <NotifItem
                  timestamp={
                    <Item subtitle={parseDateTime(createdAt)} icon={"clock"} />
                  }
                  action={
                    <div>
                      {link && (
                        <Link to={link.link}>
                          <Button
                            noMar
                            disabled={busy === id}
                            colored
                            small
                            onClick={() => toggleDialog()}
                          >
                            {link.label}
                          </Button>
                        </Link>
                      )}
                      <Button
                        small
                        accent
                        noMar
                        busy={busy === id}
                        onClick={() => {
                          setBusy(id)
                          removeNotification(id, () => {
                            onDelete(id)
                            setBusy()
                            dispatch({
                              type: "SHOW_ALERT",
                              open: true,
                              message: "Alert dismissed successfully",
                            })
                          })
                        }}
                      >
                        Dismiss
                      </Button>
                    </div>
                  }
                >
                  <Header bold>
                    <span
                      className='far fa-bell'
                      style={{ marginRight: "1rem" }}
                    />

                    {title}
                  </Header>
                  <p>{body}</p>
                </NotifItem>
              </>
            )
          }}
        />
      </Grid>
    </>
  )
}

export default UserNotifications
