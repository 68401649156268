import React, { useState } from "react"
import {
  Header,
  Card,
  IconButtonCircle,
  Space,
  Row,
  Button,
} from "uiComponents"
import "./ExpanderNew.css"
import { openPositionsData } from "dummyData"
import { Link } from "react-router-dom/cjs/react-router-dom.min"
import { isMobile } from "helpers"

const OpenPositions = () => {
  return (
    <>
      <Card>
        <Header lg bold>
          Open Positions
        </Header>
        <p
          style={{
            fontSize: "15px",
            fontWeight: "500",
          }}
        >
          Transform your business operations with our cutting-edge digital
          tools.
        </p>
        <Space lg />
        <Space lg />
        {openPositionsData.map(data => (
          <ExpanderNew title={data.job_title}>
            <Header sm bold>
              Job Description
            </Header>
            <p>{data.job_desc}</p>
            <Space md />
            <Row style={{ padding: "0px 8px" }}>
              <div style={{ display: "flex", gap: "5px" }}>
                <Header bold>Job Type: </Header>
                <Header bold accent>
                  {data.job_type}
                </Header>
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "5px",
                  marginLeft: !isMobile && "3rem",
                  marginTop: isMobile && "10px",
                }}
              >
                <Header bold>Location: </Header>
                <Header bold accent>
                  {data.job_location}
                </Header>
              </div>
            </Row>
            <Space lg />
            <Link to={data.job_link}>
              <Button fullWidth={isMobile} extraPad fullRound>
                Apply
              </Button>
            </Link>
            <Button fullWidth={isMobile} extraPad fullRound alt>
              Learn More
            </Button>
          </ExpanderNew>
        ))}
      </Card>
    </>
  )
}

export default OpenPositions

export const ExpanderNew = ({ title, children }) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const toggleExpand = () => {
    setIsExpanded(!isExpanded)
  }

  return (
    <div className='expander-new-container'>
      {!isExpanded && (
        <div className='expander-new-bar' onClick={toggleExpand}>
          <Header noMar bold md>
            {title}
          </Header>
          <IconButtonCircle
            large
            size='15'
            icon={"chevron-right"}
            bgColor='var(--foreground)'
          />
        </div>
      )}

      <div
        className={`expander-new-content ${
          isExpanded ? "expanded" : "collapsed"
        }`}
      >
        <div className='expander-new-content-inner'>
          <div>
            <Header noMar lg bold>
              {title}
            </Header>
            <Space lg />
          </div>
          <IconButtonCircle
            large
            size='15'
            icon='times'
            onClick={toggleExpand}
          />
        </div>
        <div className='expander-new-children'>{children}</div>
      </div>
    </div>
  )
}
