import React, { Fragment, useEffect, useState } from "react"
import { getTime } from "helpers"
import { useStateValue } from "state"
import { getDocFromDb, setDataToDb, updateDataToDb } from "firebaseUtils"
import { Button, Card, Loader, CompactCard } from "uiComponents"
import { InputField } from "formComponents"
import { validateReviewText } from "validations"
import { sendEmail } from "emailer"
import "./Rating.css"
import { sendNotification, RenderReview, Rater } from "appComponents"

export default function StoreRaterReview({ storeID }) {
  const [{ user, userMeta }, dispatch] = useStateValue()
  const [storeData, setStoreData] = useState()
  const [busy, setBusy] = useState(true)
  const [busyReview, setBusyReview] = useState(true)
  const [currentReview, setCurrentReview] = useState()
  const [alreadyRated, setAlreadyRated] = useState(0)
  const [errorText, setErrorText] = useState()
  const [alreadyReviewed, setAlreadyReviewed] = useState()
  const template = "general_template"

  useEffect(() => {
    if (currentReview) setErrorText(validateReviewText(currentReview))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentReview])
  useEffect(() => {
    const fetchStoreData = async () => {
      setStoreData(await getDocFromDb(`user_stores`, storeID))
    }
    if (storeID) fetchStoreData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeID])
  useEffect(() => {
    ;(async () => {
      let ratingRef = await getDocFromDb(
        `users_meta/${user?.uid}/storeRatings`,
        storeID,
      )
      if (ratingRef) setAlreadyRated(ratingRef.rating)
      setBusy()
    })()
    ;(async () => {
      let reviewRef = await getDocFromDb(
        `users_meta/${user?.uid}/storeReviews`,
        storeID,
      )
      if (reviewRef) setAlreadyReviewed(reviewRef.review)
      setBusyReview()
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const getSellerEmailAndSendEmail = async (newReview, newRating, storeID) => {
    let storeData = await getDocFromDb("user_stores", storeID)
    // const sellerReviewMessage = `Your store '${storeData.store_name}' has recieved a new review '${newReview}' by '${userMeta.name}' for the ${productName} product.`
    const sellerReviewMessage = `New review for your store '${storeData.store_name}' from '${userMeta.name}' : '${newReview}'.`
    // const sellerRatingMessage = `New rating for your store '${storeData.store_name}' from '${userMeta.name}' : '${newRating}'.`

    if (storeData?.exists) {
      let sellerMeta = await getDocFromDb("users_meta", storeData?.addedBy)
      if (sellerMeta?.exists) {
        const emailDataToSeller = {
          email: sellerMeta?.email,
          from_name: "Shoppio",
          message: sellerReviewMessage,
        }
        sendEmail(emailDataToSeller, template)
      }
    }
  }

  const handleRating = async newRating => {
    setBusy(true)
    await setDataToDb(`users_meta/${user?.uid}/storeRatings`, storeID, {
      rating: newRating,
    })
    let oldRating = storeData?.rating
    let totalRatings = storeData?.totalRatings || 0
    await updateDataToDb(
      `user_stores`,
      storeID,
      {
        rating: oldRating
          ? (Number(oldRating) + Number(newRating)) / 2
          : newRating,
        totalRatings: totalRatings + 1,
      },
      dispatch,
    )
    const ratingMessage = `You rated ${newRating} for the ${storeData?.store_name}.`

    const emailData = {
      email: user?.email || userMeta?.email,
      from_name: "Shoppio",
      message: ratingMessage,
    }

    sendEmail(emailData, template)

    getSellerEmailAndSendEmail(storeID)

    sendNotification({
      title: "New Rating Submitted",
      body: "Thank you for rating the store!",
      toUserID: user?.uid,
    })

    setBusy()
    setAlreadyRated(newRating)
  }
  const handleReview = async newReview => {
    setBusyReview(true)
    await setDataToDb(`users_meta/${user?.uid}/storeReviews`, storeID, {
      review: newReview,
    })
    await setDataToDb(`user_stores/${storeID}/reviews`, user?.uid, {
      review: newReview,
      reviewBy: userMeta?.name || user?.displayName || user?.uid,
      createdAt: getTime(),
    })
    const reviewMessage = `Your review '${newReview}' for the '${storeData?.store_name}' store.`

    const emailData = {
      email: user?.email || userMeta?.email,
      from_name: "Shoppio",
      message: reviewMessage,
    }

    const template = "general_template"

    sendEmail(emailData, template)
    getSellerEmailAndSendEmail(storeID)

    sendNotification({
      title: newReview.update ? "Review Updated" : "New Review Submitted",
      body: newReview.update
        ? "Your review has been updated."
        : "Thank you for your review!",
      toUserID: user?.uid,
    })

    setBusyReview()
    setAlreadyReviewed(newReview)
  }
  return !storeData ? (
    <Loader />
  ) : (
    <div className='product-rater'>
      <Card>
        <CompactCard
          config={{
            innerStyle: { width: "100%" },
            logo: storeData?.store_logo,
            version: "store",
            title: storeData?.store_name,
            backgroundImage: storeData.store_pic,
            subtitle: storeData?.store_tagline,
            ownedBy: storeData?.ownedBy,
            tag: String(storeData?.is_sale_on) === "true" ? "Sale On" : null,
          }}
        />
        <div>
          <div>
            {busy ? (
              <Loader />
            ) : (
              <Rater
                onClick={alreadyRated ? null : handleRating}
                readonly={alreadyRated}
                initialValue={Number(alreadyRated)}
              />
            )}
            <span style={{ paddingLeft: "1rem" }}>
              {alreadyRated ? "Thanks for the rating" : ""}
            </span>
          </div>
          <div>
            {busyReview ? (
              <Loader />
            ) : (
              <Fragment>
                {alreadyReviewed ? (
                  <RenderReview
                    review={alreadyReviewed}
                    reviewBy={user?.displayName || userMeta?.name}
                  />
                ) : (
                  <InputField
                    type='textarea'
                    form={{
                      touched: {},
                      errors: {},
                    }}
                    errorText={errorText}
                    field={{
                      disabled: alreadyReviewed,
                      name: "rating",
                      onChange: e => setCurrentReview(e.target.value),
                      value: currentReview,
                    }}
                    hint={"Write a review"}
                  />
                )}
                {!alreadyReviewed ? (
                  <Button noMar onClick={() => handleReview(currentReview)}>
                    Add a review
                  </Button>
                ) : (
                  ""
                )}
              </Fragment>
            )}
            <span style={{ paddingLeft: "1rem" }}>
              {alreadyReviewed ? "Thanks for the review" : ""}
            </span>
          </div>
        </div>
      </Card>
    </div>
  )
}
