import { AvatarChip } from "appComponents/QueryResolution/QueryResolution"
import React from "react"
import { Header, SpaceBetween } from "uiComponents"
import { StatusBar } from "../EngineeringDashboard"
import { getAvatarColor, parseDateTime } from "helpers"
import { getInitials } from "appComponents/Messages/CustomerMessages"
import { colorMap } from "dummyData"

const TicketCard = ({ data }) => {
  let ticketOwner = getInitials(
    typeof data?.created_by === "object"
      ? data?.created_by.user_name
      : data?.created_by,
  )

  let assignee
  try {
    assignee = data?.assignee ? JSON.parse(data?.assignee) : undefined
  } catch (error) {
    console.error("Failed to parse assignee data", error)
    assignee = null
  }
  return (
    <>
      <SpaceBetween>
        <div style={{ display: "flex", gap: "10px" }}>
          <AvatarChip
            label={ticketOwner}
            large
            color={getAvatarColor(ticketOwner)}
          />
          <div>
            <Header>{data?.name}</Header>

            {data?.status && (
              <StatusBar
                color={colorMap?.ticketStatus[data?.status]}
                label={data?.status}
              />
            )}
            {data.issue_type && (
              <StatusBar
                color={colorMap?.ticketType[data.issue_type]}
                label={data.issue_type}
              />
            )}
            {data.ticket_complexity && (
              <StatusBar
                color={colorMap?.complexity[data.ticket_complexity]}
                label={data.ticket_complexity}
              />
            )}
            {data.createdAt && (
              <>
                created on: <strong>{parseDateTime(data.createdAt)}</strong>
              </>
            )}
            {", "}
            {data.updatedAt && (
              <>
                updated on: <strong>{parseDateTime(data.updatedAt)}</strong>
              </>
            )}
          </div>
        </div>
        {assignee && assignee.value && (
          <AvatarChip
            label={getInitials(assignee.value)}
            large
            color={getAvatarColor(assignee.value)}
          />
        )}
      </SpaceBetween>
    </>
  )
}

export default TicketCard
