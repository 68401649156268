import React, { useEffect, useState } from "react"
import {
  Col,
  Space,
  Button,
  Header,
  Dialog,
  Toggle,
  CompactCard,
} from "uiComponents"
import { useHistory, useParams } from "react-router-dom"
import { useStateValue } from "state"
import { getDocFromDb, updateDataToDb } from "firebaseUtils"
import { DB, FieldValue } from "firebaseUtils"
import "./Product.css"
import { getOrCreateMeta } from "../../Routes"
import { defaultImage } from "config"
import { hasAdminAccessToStore } from "./../../components/App/Stores/AccessControlList"
import { AppPaths, getPath } from "shoppio-constants"

export const ProductStatusUpdater = ({ product, callback, small }) => {
  const [, dispatch] = useStateValue()

  const { status, productID, storeID } = product || {}
  const path = `products/${storeID}/store_products/`
  const [busy, setBusy] = useState()
  const handleToggle = async () => {
    setBusy(true)
    if (!status || ["live", "listed"].includes(status)) {
      await updateDataToDb(
        path,
        productID,
        {
          status: "unlisted",
        },
        dispatch,
        "Success! Your product is unlisted from your store",
        () => {
          setBusy()
          if (callback) callback()
        },
      )
    } else if (status === "unlisted") {
      await updateDataToDb(
        path,
        productID,
        {
          status: "listed",
        },
        dispatch,
        "Success! Your store is listed on your store.",
        () => {
          setBusy()
          if (callback) callback()
        },
      )
    }
  }
  return small ? (
    <span>
      <Toggle
        busy={busy}
        activeIcon={<span className='far fa-eye' />}
        icon={<span className='far fa-eye-slash' />}
        active={status === "listed"}
        onClick={handleToggle}
        activeLabel={"Listed"}
        label={"Unlisted"}
        textAlign='left'
        horizontal
      />
    </span>
  ) : (
    <div>
      <div>
        <Space />
        <Header xs bold>
          Product Visibility
        </Header>
        <div>
          <Toggle
            busy={busy}
            activeIcon={<span className='far fa-eye' />}
            icon={<span className='far fa-eye-slash' />}
            active={status === "listed"}
            onClick={handleToggle}
            activeLabel={"Listed"}
            label={"Unlisted"}
            textAlign='left'
            horizontal
          />
        </div>
      </div>
      <Space lg />
    </div>
  )
}

const isMobile = () => window.innerWidth < 500

function Product(product) {
  const {
    productId,
    title,
    image,
    price,
    rating,
    discount,
    onView,
    brand,
    color,
    desc,
    totalReviews,
    storeId,
    totalRatings,
    filterOpen,
    showReview,
    status,
    reviewComponent,
    refresh,
    thumbnail_image,
    product_images,
    withoutCol,
  } = product || {}
  const [dialog, setDialog] = useState()
  const [isSaved, setIsSaved] = useState(false)
  const { id } = useParams()
  // console.log('productId', productId);
  const history = useHistory()
  const [{ userMeta, user }, dispatch] = useStateValue()
  const [busy, setBusy] = useState(false)
  // console.log(isSaved, 'isSaved')

  useEffect(() => {
    let isSaved = userMeta?.savedProducts?.includes(`${storeId}/${productId}`)
    setIsSaved(isSaved)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const removeProduct = productID => {
    DB.collection(`products`)
      .doc(id)
      .collection("store_products")
      .doc(productID)
      .delete()
      .then(() => {
        setDialog()
        dispatch({
          type: "SHOW_ALERT",
          open: true,
          message: "Product deleted successfully",
        })
      })
  }

  const ownsThisStore = hasAdminAccessToStore(
    userMeta,
    storeId,
    "update_product",
  )
  const productComponent = (
    <CompactCard
      key={new Date().getTime()}
      notFull={withoutCol}
      config={{
        version: "product",
        showReview,
        reviewComponent,
        brand: brand,
        name: title,
        price: price,
        discount: discount,
        colors: color?.map(c => ({ value: c, label: c })),
        desc: desc,
        backgroundImage:
          image ||
          thumbnail_image ||
          (product_images && product_images[0]) ||
          defaultImage,
        totalReviews: totalReviews,
        totalRatings: totalRatings,
        rating: rating,
        onView: onView,
        adminActions: ownsThisStore ? (
          <div>
            <ProductStatusUpdater
              callback={refresh}
              product={{
                productID: productId,
                storeID: storeId,
                status: status,
              }}
            />
          </div>
        ) : (
          ""
        ),
        secAction: !ownsThisStore
          ? null
          : {
              label: "Update Product",
              icon: <span className={"fas fa-edit"} />,
              onClick: () =>
                history.push(
                  getPath(AppPaths.UPDATE_PRODUCT),

                  {
                    storeid: storeId,
                    product_id: productId,
                  },
                ),
            },
        hideSaveButton: ownsThisStore,
        onSave: !user
          ? () => history.push(AppPaths.LOGIN)
          : async () => {
              setBusy(true)

              const check = await getDocFromDb("users_meta", user?.uid)
              if (check?.savedProducts?.includes(`${storeId}/${productId}`)) {
                const result = await updateDataToDb("users_meta", user?.uid, {
                  savedProducts: FieldValue.arrayRemove(
                    `${storeId}/${productId}`,
                  ),
                })

                if (result === "success") {
                  setIsSaved(false)
                  dispatch({
                    type: "SHOW_ALERT",
                    open: true,
                    message: "Removed from wishlist",
                  })
                }
              } else {
                const result = await updateDataToDb("users_meta", user?.uid, {
                  savedProducts: FieldValue.arrayUnion(
                    `${storeId}/${productId}`,
                  ),
                })
                if (result === "success") {
                  setIsSaved(true)
                  dispatch({
                    type: "SHOW_ALERT",
                    open: true,
                    message: "Added to wishlist",
                  })
                }
              }

              getOrCreateMeta(user.uid, dispatch)

              setBusy(false)
            },
        isSaved: isSaved,
        busy,
        setBusy,
      }}
    />
  )
  return withoutCol ? (
    productComponent
  ) : (
    <Col md={6} xs={6} sm={12} lg={filterOpen ? 4 : 3}>
      {productComponent}
      <Dialog open={dialog} onClose={() => setDialog(false)}>
        <Header md bold>
          Warning
        </Header>
        <Space />
        <p>This will delete the product from the database.</p>
        <p>You cannot recover this product after deleting.</p>
        <Space />
        <Space />

        <Button
          fullRound
          fullWidth={isMobile()}
          colored
          onClick={() => removeProduct(productId)}
        >
          Delete from database
        </Button>
        <Space />
        <Button
          fullRound
          accent
          fullWidth={isMobile()}
          onClick={() => setDialog(false)}
        >
          No
        </Button>
      </Dialog>
    </Col>
  )
}

export default Product
