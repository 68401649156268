import razorpay from "../../../images/rzp.png"
import payU from "../../../images/PayU.png"

export const cardData = [
  {
    title: "Pro",
    price: "₹1800",
    features: ["Store Website", "Theming", "Mobile App"],
  },
  {
    title: "Elite",
    price: "₹3000",
    features: ["Coupon Codes", "Ads Module", "Product AR View"],
  },
]

export const fields = [
  { name: "first_name", label: "First Name", type: "text" },
  {
    name: "last_name",
    label: "Last Name",
    type: "text",
  },
  {
    name: "bussiness_name",
    label: "Business Name",
    type: "email",
    fullWidth: true,
  },
  {
    name: "business_gst",
    label: "Business GST number",
    type: "text",
  },
  {
    name: "email",
    label: "Email Address",
    type: "email",
  },
  {
    name: "address",
    label: "Address",
    type: "textarea",
    fullWidth: true,
  },
  {
    name: "country",
    label: "Country",
    type: "text",
  },
  {
    name: "state",
    label: "State",
    type: "text",
  },
  {
    name: "city",
    label: "City",
    type: "text",
  },
  {
    name: "pin_code",
    label: "Pin Code",
    type: "text",
  },
]

export const paymentsMethodData = [
  {
    label: "Razor Pay",
    desc: "UPI, Debit Card, Credit Card, Wallet and Net Banking",
    logo: razorpay,
  },
  {
    label: "PayU",
    desc: "UPI, Debit Card, Credit Card, Wallet and Net Banking",
    logo: payU,
  },
]
