import React from "react"
import { Header, Flex } from "uiComponents"
import "./TrainingVideos.css"

const TrainingVideos = ({ videoId, title, desc, videoUrl }) => {
  const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/0.jpg`

  const handleVideoUrl = () => {
    console.log("Video URL function called")
    window.open(videoUrl, "_blank")
  }

  return (
    <>
      <div className='thumbnail-container'>
        <div className='thumbnail-image-container'>
          <img
            src={thumbnailUrl}
            alt='YouTube Thumbnail'
            className='thumbnail-image'
          />
          <div onClick={() => handleVideoUrl()} className='play-button'>
            <i className='fas fa-play play-button-icon' />
          </div>
        </div>
        <div className='details-container'>
          <Flex auto>
            <div>
              <Header bold>{title}</Header>
              <p className='details-description'>{desc}</p>
            </div>
          </Flex>
        </div>
      </div>
    </>
  )
}

export default TrainingVideos
