import { SelectField } from "formComponents"
import { useGetStoreData } from "hooks"
import { useStateValue } from "state"
import { ActionCard, Header } from "uiComponents"
import { useHistory, useLocation, useParams } from "react-router-dom"
import { useEffect } from "react"
import "./StoreSelector.css"
import { Cache } from "cache"
import { AppPaths, CacheKeys } from "shoppio-constants"

export const CreateStoreCard = () => {
  return (
    <div className='create-store-card'>
      <ActionCard
        title='Create Store'
        desc={"Create your first store"}
        svgName={"Ecommerce_web_page"}
        action={{
          label: "Create Store",
          link: AppPaths.createStore,
        }}
      />
    </div>
  )
}

export const StoreSelector = () => {
  const { store_id: storeIdFromParams } = useParams()
  const { pathname } = useLocation()
  const history = useHistory()
  const [{ userMeta, currentStoreID }, dispatch] = useStateValue()
  // const [stores, setStores] = useState([])
  const { busy } = useGetStoreData()
  // log("currentStoreData", currentStoreData)

  const handleStoreChange = e => {
    const newStoreID = e.target.value
    if (newStoreID) {
      Cache.set(CacheKeys.selectedStoreId, newStoreID)
      dispatch({
        type: "GENERAL",
        data: {
          currentStoreID: newStoreID,
        },
      })
    }
  }

  useEffect(() => {
    if (
      currentStoreID &&
      currentStoreID !== storeIdFromParams &&
      storeIdFromParams
    ) {
      history.replace(pathname.replace(storeIdFromParams, currentStoreID))
    } else {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStoreID])

  return (
    <div className='primary-menu-store-select'>
      {!userMeta?.stores?.length && !busy ? <CreateStoreCard /> : null}

      {userMeta?.stores?.length ? (
        <>
          <Header bold>Manage Store {busy}</Header>
          <SelectField
            loading={busy}
            form={{ errors: {} }}
            field={{ name: "selectedStore", value: currentStoreID }}
            onChange={handleStoreChange}
            value={currentStoreID}
          >
            {userMeta?.stores.map(store => (
              <option key={store.key} value={store.key}>
                {store.value}
              </option>
            ))}
          </SelectField>
        </>
      ) : null}
    </div>
  )
}

export default StoreSelector
