import React from "react"
import "./MessagePopup.css"
import { parseDateTime } from "helpers"

export default function MessagePopup({ right, createdAt, children }) {
  return (
    <div
      className={right ? "message-popup message-popup-right" : "message-popup"}
    >
      <div>
        <div>{children}</div>
        <span className='time-stamp'>{parseDateTime(createdAt)}</span>
      </div>
    </div>
  )
}
