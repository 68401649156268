import React, { useEffect } from "react"
import { Col, Grid, Row } from "uiComponents"
import {
  FooterNew,
  GetStarted,
  ShoppioBenefits,
  SuccessStory,
  WhyChoose,
} from "appComponents"
import { Space } from "uiComponents"
import { useLocation } from "react-router-dom/cjs/react-router-dom.min"

const LandingPage = () => {
  const location = useLocation()
  useEffect(() => {
    if (location.state?.scrollToWhyChoose) {
      const element = document.getElementById("open-whychoose")
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" })
      }
    }
  }, [location])
  return (
    <Grid>
      <Row>
        <Col sm={12} md={12}>
          <GetStarted />
        </Col>
        <Col sm={12} md={12} id='open-whychoose'>
          <Space lg />
          <WhyChoose />
        </Col>

        <Col sm={12} md={12}>
          <ShoppioBenefits />
        </Col>
        <Col sm={12} md={12}>
          <Space lg />
          <SuccessStory />
        </Col>
        <Col sm={12} md={12}>
          <Space lg />
          <FooterNew />
        </Col>
      </Row>
    </Grid>
  )
}

export default LandingPage
