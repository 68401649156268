import React from "react"
import "./Carousel.css"

const InfiniteCarousel = ({ rtl, children, flex, withShadow }) => {
  return (
    <div
      className='infinite-carousel'
      style={
        flex
          ? { display: "inline-flex" }
          : { overflow: "hidden", whiteSpace: "nowrap" }
      }
    >
      {withShadow ? <div className='left-shadow' /> : ""}
      <div
        className={rtl ? "scroll-right-to-left" : "scroll-left-to-right"}
        style={{ display: flex ? "inline-flex" : "inline-block" }}
      >
        {children}
        {children}
      </div>
      {withShadow ? <div className='right-shadow' /> : ""}
    </div>
  )
}

export default InfiniteCarousel
