import React from "react"
import { Button, Icon } from "uiComponents"

export default function RenderVariants({
  variants,
  variantSelected,
  onSelect,
}) {
  return (
    <div>
      {variants
        ?.filter(v => v?.data?.length > 0)
        .map((v, k) => {
          const isColor = [
            "color",
            "Colors",
            "Color",
            "Colour",
            "colour",
            "Colours",
            "colours",
          ].includes(v?.name)
          console.log("isColor", isColor)
          return (
            <div key={k}>
              <h1 className='product-page-head'>{v?.name}</h1>
              {isColor ? (
                <div
                  className={
                    false ? "available-colors compact" : "available-colors"
                  }
                >
                  {v?.data?.map((d, k2) => {
                    const isSelected =
                      variantSelected &&
                      variantSelected[v?.name]?.name === d.name
                    return (
                      <span
                        key={k2}
                        className={
                          "colors " + (isSelected ? "selected-color" : "")
                        }
                        style={{
                          backgroundColor: d?.value?.hex,
                        }}
                        onClick={() =>
                          onSelect && onSelect(v?.name, isSelected ? null : d)
                        }
                        data-tip={d.label}
                      >
                        <span
                          className='shadow'
                          style={{ backgroundColor: d?.value?.hex }}
                        />
                        {isSelected && (
                          <Icon
                            iconClass='fa fa-check'
                            style={{
                              opacity: 0.7,
                              color:
                                d?.value?.hex === "#ffffff"
                                  ? "var(--font-color)"
                                  : "var(--white)",
                              height: "100%",
                              width: "100%",
                            }}
                          />
                        )}
                      </span>
                    )
                  })}
                </div>
              ) : (
                <div>
                  {v?.data?.map((d, k2) => {
                    const isSelected =
                      variantSelected &&
                      variantSelected[v?.name]?.name === d.name
                    return (
                      <span
                        key={k2}
                        className={
                          "colors " + (isSelected ? "selected-color" : "")
                        }
                        style={{
                          backgroundColor: d?.value?.hex,
                        }}
                        onClick={() =>
                          onSelect && onSelect(v?.name, isSelected ? null : d)
                        }
                        data-tip={d.label}
                      >
                        <span
                          className='shadow'
                          style={{ backgroundColor: d?.value?.hex }}
                        />
                        <Button
                          colored={isSelected}
                          accent={!isSelected}
                          onClick={() =>
                            onSelect(v?.name, isSelected ? null : d)
                          }
                        >
                          {d?.name}
                        </Button>
                      </span>
                    )
                  })}
                </div>
              )}
            </div>
          )
        })}
    </div>
  )
}
