import createTheme from "@mui/material/styles/createTheme"
import ThemeProvider from "@mui/material/styles/ThemeProvider"
import React from "react"
import ReactDOM from "react-dom/client"
import Routes from "./Routes"
import "./index.css"
import reportWebVitals from "./reportWebVitals"
import { StateProvider, reducer, initialState } from "state"
import ErrorBoundaryContainer from "appComponents/ErrorBoundary/ErrorBoundary"

require("dotenv").config()
const element = document.querySelector(".default")

const mainColor = getComputedStyle(element).getPropertyValue("--theme-color")

let theme = createTheme({
  typography: {
    fontFamily: "Source Sans 3",
  },
  palette: {
    primary: {
      main: mainColor,
    },
  },
})

const root = ReactDOM.createRoot(document.getElementById("root"))

root.render(
  <ErrorBoundaryContainer>
    <StateProvider initialState={initialState} reducer={reducer}>
      <ThemeProvider theme={theme}>
        <Routes />
      </ThemeProvider>
    </StateProvider>
  </ErrorBoundaryContainer>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("firebase-messaging-sw.js")
    .then(register => {
      console.log("Register successfully")
    })
    .catch(() => {
      console.log("Unregister successfully")
    })
} else {
  console.log("Service Worker not supported")
}
