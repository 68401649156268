import React from "react"
import { Col, Row, Space, Card, Header } from "uiComponents"
import { getSVG } from "getSVG"
import { joinReasonsData } from "dummyData"

const ReasonsToJoin = () => {
  return (
    <>
      <div className='join-reason-header'>
        <div className='join-reason-header-text'>
          <span>6 Reasons </span>&nbsp;to Join
          <span>Shoppio</span>
        </div>
        <p>
          Our range of business-oriented solutions will help you achieve your
          business goals.
        </p>
      </div>
      <Space lg />
      <Space lg />
      <Row>
        {joinReasonsData.map(data => (
          <Col sm={12} md={4}>
            <Card themed={data.theme}>
              {getSVG(data.svg, { width: 200 }, undefined, true)}
              <Space />
              <Header themed={data.theme} lg bold>
                {data.title}
              </Header>
              <Space />
              <p
                style={{
                  fontSize: "15px",
                  fontWeight: "500",
                  color: data.theme && "var(--foreground)",
                }}
              >
                {data.description}
              </p>
            </Card>
          </Col>
        ))}
      </Row>
    </>
  )
}

export default ReasonsToJoin
