export const personalInformationsFields = [
  {
    name: "name",
    label: "Full Name",
    dataTip: "Please tell us your good name",
    type: "text",
    small: true,
  },
  {
    name: "email",
    label: "Email Address",
    dataTip: "Please enter your email ID",
    type: "text",
    small: true,
  },
  {
    name: "dob",
    label: "Date of Birth",
    dataTip: "Please enter your phone number",
    type: "date",
    small: true,
  },
  {
    name: "phoneNumber",
    label: "Phone Number",
    dataTip: "Please enter your phone number",
    type: "phoneNumber",
    small: true,
  },
  {
    name: "gender",
    label: "Gender",
    type: "radio",
    dataTip: "Please choose your gender",
    inline: true,
    options: ["Male", "Female", "Other"].map(v => ({ label: v, value: v })),
  },
  {
    group: "Residential Address",
  },
  {
    name: "addressLine1",
    label: "Address line 1",
    type: "text",
    fullWidth: true,
  },
  {
    name: "house_number",
    label: "House Number, Lane Number",
    type: "text",
    small: true,
  },
  {
    name: "pinCode",
    label: "Pin Code",
    type: "text",
    small: true,
  },
  {
    name: "area_locality",
    label: "Area Locality",
    type: "text",
    small: true,
  },
]

export const businessInformationsFields = [
  {
    name: "pan_card",
    label: "Pan Card",
    dataTip:
      "Enter the Permanent Account Number (PAN) issued by the Income Tax Department of India. This number is used for tax purposes.",
    type: "text",
    small: true,
  },
  {
    name: "gst",
    label: "GST",
    dataTip:
      "Enter your Goods and Services Tax (GST) Identification Number. This is required for businesses to comply with tax regulations.",
    type: "text",
    small: true,
  },
  {
    name: "whatsappNumber",
    label: "Business Whatsapp Number",
    dataTip:
      "Provide the Whatsapp number used for business communications. This will be used for customer interactions and notifications.",
    type: "phoneNumber",
    small: true,
  },
  {
    group: "Residential Address",
  },
  {
    name: "addressLine1",
    label: "Address line 1",
    type: "text",
    fullWidth: true,
  },
  {
    name: "house_number",
    label: "House Number, Lane Number",
    type: "text",
    small: true,
  },
  {
    name: "pinCode",
    label: "Pin Code",
    type: "text",
    small: true,
  },
  {
    name: "area_locality",
    label: "Area Locality",
    type: "text",
    small: true,
  },
]

export const kycFields = [
  {
    name: "proofOfIdentityType",
    label: "Proof of Identity Type",
    type: "select",
    children: [
      "Passport",
      "Voter's Identity Card",
      "Driving Licence",
      "Aadhaar Letter/Card",
    ].map(op => <option value={op}>{op}</option>),
    small: false,
    dataTip: "Select the type of ID document you are uploading",
  },
  {
    name: "proofOfIdentityFile",
    label: "Upload Proof of Identity",
    type: "file",
    small: false,
    dataTip: "Upload a clear copy of your identity document",
  },

  {
    name: "proofOfAddressType",
    label: "Proof of Address Type",
    type: "select",
    children: [
      "Passport",
      "Voter's Identity Card",
      "Driving Licence",
      "Aadhaar Letter/Card",
    ].map(op => <option value={op}>{op}</option>),
    small: false,
    dataTip:
      "Select the type of document you will upload to verify your address",
  },
  {
    name: "proofOfAddressFile",
    label: "Upload Proof of Address",
    type: "file",
    small: false,
    dataTip: "Upload a clear copy of your address proof document",
  },
]
