import React, { useState } from "react"
import { Row, Col, Card, Header, Button } from "uiComponents"
import SpaceBetween from "../../UI/Space/SpaceBetween"
import "./AppDetails.css"
import AppStatusReview from "./AppStatusReview"

const Submissions = () => {
  const [viewChange, setViewChange] = useState(false)

  const handleViewChange = () => {
    setViewChange(!viewChange)
  }

  const data = [
    {
      title: "App Details",
      desc: "Please fill the required application details",
      chipText: "Verified",
      chipIcon: "check",
      color: "#2B8D52",
    },
    {
      title: "Play Store",
      desc: "Please fill the required application details",
      icon: "google-play",
      chipText: "In Review",
      chipIcon: "eye",
      color: "#D39347",
    },
    {
      title: "Apple Store",
      desc: "Please fill the required application details",
      icon: "behance",
      chipText: "App Rejected",
      chipIcon: "times",
      color: "#AF3A3A",
      btn: "Review",
    },
  ]
  return !viewChange ? (
    <Row>
      {data.map(item => (
        <Col sm={12} md={12}>
          <Card>
            <SpaceBetween>
              <div>
                <Header sm bold>
                  {item.icon && (
                    <span
                      className={`fab fa-${item.icon}`}
                      style={{
                        marginRight: "10px",
                      }}
                    />
                  )}
                  {item.title}
                </Header>
                <p>{item.desc}</p>
              </div>
              <div style={{ display: "flex" }}>
                {item.btn && (
                  <Button onClick={handleViewChange}>{item.btn}</Button>
                )}
                <StatusChip
                  color={item.color}
                  text={item.chipText}
                  icon={item.chipIcon}
                />
              </div>
            </SpaceBetween>
          </Card>
        </Col>
      ))}
    </Row>
  ) : (
    <AppStatusReview />
  )
}

export default Submissions

export const StatusChip = ({ text, icon, color }) => {
  return (
    <div className='app-status-chip' style={{ backgroundColor: color }}>
      <span className='chip-text'>{text}</span>
      <i className={`fas fa-${icon} chip-icon`} />
    </div>
  )
}
